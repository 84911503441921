import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

export const createAddRowsComponent = () => ({addRows}) => {
  const [value, setValue] = useState(1)
  const [rawValue, setRawValue] = useState(String(value))

  return (
    <div className="dsg-add-row d-none">

      <button
        type="button"
        className="dsg-add-row-btn"
        onClick={() => addRows(value)}
      >
        <FormattedMessage id={"common.addRow"}/>
      </button>
      {' '}

    </div>
  )
}
