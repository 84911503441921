import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import { Modal } from '../ui/Modal';
import { translate } from '../../utils/utils';

export const ModalAgreement = (props) => {
  const { isShown, content, onAcceptAgreement, onClose } = props;

  return (
    <Modal
      title={translate({ id: 'agreement.modal.title' })}
      isModal
      isShow={isShown}
      onClose={onClose}
      className="agreementModal"
    >
      <Row>
        <Col xs={12}>
          <pre className="pre-wrap" style={{ maxHeight: '80vh' }}>
            {/*eslint-disable*/}
            <div dangerouslySetInnerHTML={{ __html: content }} />
            {/*eslint-enable*/}
            <Button
              id="button-agreement"
              color="primary"
              onClick={() => {
                onAcceptAgreement();
                onClose();
              }}
            >
              <FormattedMessage id="agreement.modal.btnAgree" />
            </Button>
          </pre>
        </Col>
      </Row>
    </Modal>
  );
};

ModalAgreement.propTypes = {
  isShown: PropTypes.bool,
  content: PropTypes.string,
  onAcceptAgreement: PropTypes.func,
  onClose: PropTypes.func,
};
