import HttpProvider from './httpProvider';
import { API } from '../constants/api';

class OrderDeliveryService {
  constructor() {
    this.http = HttpProvider;
  }

  async getRequestGroup(id) {
    return await this.http.get(API.ORDER_DELIVERY.REQUEST_GROUP({ id }));
  }

  async updateRequestGroupStatus(params) {
    return await this.http.put(API.ORDER_DELIVERY.UPDATE_REQUEST_GROUP_STATUS(params));
  }

  async getRequest(id) {
    return await this.http.get(API.ORDER_DELIVERY.REQUESTS({ id }), false);
  }

  async updateRequestStatus(params) {
    return await this.http.put(API.ORDER_DELIVERY.UPDATE_REQUEST_STATUS(params));
  }

  async getOrderTemplates() {
    return await this.http.get(API.ORDER_DELIVERY.GET_ORDER_TEMPLATES());
  }

  async sendLogisticBlock(id, data) {
    return await this.http.post(API.ORDER_DELIVERY.SEND_LOGISTIC_BLOCK({ id }), data);
  }

  async getOrderTracking(id) {
    return await this.http.get(API.ORDER_DELIVERY.GET_TRACKING({ id }));
  }

  async sendOrderTracking(id, data) {
    return await this.http.post(API.ORDER_DELIVERY.TRACKING({ id }), data);
  }

  async recalculateTrackingDate(id, data){
    return await this.http.post(API.ORDER_DELIVERY.RECALCULATE_TRACKING_DATE({id: id}), data)
  }

  async sendStatus(id, status) {
    return await this.http.put(API.ORDER_DELIVERY.SEND_STATUS({ id, status }));
  }

  async setStatus(id, status) {
    return await this.http.put(API.ORDER_DELIVERY.SET_STATUS({ id, status }));
  }

  async sendManualCost(id, data) {
    return await this.http.post(API.ORDER_DELIVERY.MANUAL_COST({ id }), data);
  }

  async updateOrderTracking(id, data) {
    return await this.http.put(API.ORDER_DELIVERY.TRACKING({ id }), data);
  }

  async sendDescription(id, extId) {
    return await this.http.post(API.ORDER_DELIVERY.SEND_DESCRIPTION({ id, extId }));
  }

  async checkChangeOwner(id){
    return await this.http.get(API.ORDER_DELIVERY.CHECK_CHANGE_OWNER({id}))
  }
  async changeOwner(organization, user, id){
    return await this.http.post(API.ORDER_DELIVERY.CHANGE_OWNER(), {organization, user, id})
  }

  async updateCreateDate(id, value) {
    return await this.http.put(API.ORDER_DELIVERY.UPDATE_CREATE_DATE({ id }), value);
  }
}

export default new OrderDeliveryService();
