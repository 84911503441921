import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import FlashStore from '../../stores/flashStore';

export const InfoStatus = ({ children }) => {
  return (
    <Alert color="info">{children}</Alert>
  );
};

export const ErrorStatus = ({ children }) => {
  return (
    <Alert color="danger">
      {children}
    </Alert>
  );
};

export const WarningStatus = ({ children }) => {
  return (
    <Alert color="warning">
      {children}
    </Alert>
  );
};

export const SuccessStatus = ({ children }) => {
  return (
    <Alert color="success">
      {children}
    </Alert>
  );
};

InfoStatus.propTypes = {
  children: PropTypes.any,
};
ErrorStatus.propTypes = {
  children: PropTypes.any,
};
WarningStatus.propTypes = {
  children: PropTypes.any,
};
SuccessStatus.propTypes = {
  children: PropTypes.any,
};

export const WaitingStatus = () => {
  return (
    <InfoStatus>
      <FontAwesome name="refresh" className="spinning" />
      <span style={{ paddingLeft: 10 }}>
        <FormattedMessage id="waiting.text" /> {'...'}
      </span>
    </InfoStatus>
  );
};

export const UnavailableServerStatus = () => {
  return (
    <WaitingStatus>
      <FontAwesome name="exclamation-triangle" style={{ paddingRight: 10 }} />
      <FormattedMessage id="service.unavailable.text" />
    </WaitingStatus>
  );
};

@observer
export class FlashStatus extends Component {
  render() {
    const info = FlashStore.message.info ? <InfoStatus>{FlashStore.message.info}</InfoStatus> : '';
    const error = FlashStore.message.error ? <ErrorStatus>{FlashStore.message.error}</ErrorStatus> : '';
    const success = FlashStore.message.success ?
      <SuccessStatus>{FlashStore.message.success}</SuccessStatus> : '';
    const warning = FlashStore.message.warning ?
      <WarningStatus>{FlashStore.message.warning}</WarningStatus> : '';
    const unavailable = FlashStore.message.unavailable ? <UnavailableServerStatus /> : '';

    return (
      <div className="g-flash-messages">
        {unavailable}
        {info}
        {error}
        {success}
        {warning}
      </div>
    );
  }
}
