import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import { get } from 'lodash';
import { TextInput } from '../ui/form-control';
import { Preloader } from '../../components/ui/Preloader';
import { Modal } from '../ui/Modal';
import { translate } from '../../utils/utils';

export const ModalConfirmCode = (props) => {
  const { code, isShow, onChange, onClose, onRegenerate, onSubmit, isWaiting, error, isUserNotConfirm } = props;

  const onCodeChange = (e) => {
    onChange(e);

    if (get(e.target, 'value.length') === 6) {
      onSubmit();
    }
  };

  return (
    <Modal
      title={translate({ id: 'confirm.modal.title' })}
      isModal
      isShow={isShow}
      onClose={onClose}
      className="small"
    >
      <Row>
        <Col>
          <h6>
            {isUserNotConfirm===false?
              <FormattedMessage id="confirm.help.status"/>
                : <FormattedMessage id="confirm.help.statusNotConfirm"/>
            }
          </h6>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12}>
          <TextInput
            value={code}
            placeholder="form.field.confirmCode.placeholder"
            onChange={onCodeChange}
            leftAddon={<FormattedMessage id="form.field.confirmCode" />}
            error={error}
          />
        </Col>
      </Row>
      <Row className="mt-4 btn-controls no-gutters" style={{ justifyContent: 'flex-start' }}>
        <Button
          className="g-cursor-pointer"
          type="button"
          color="primary"
          onClick={onSubmit}
        >
          <FormattedMessage id="register.submit" />
        </Button>
        <Button
          className="g-cursor-pointer"
          type="button"
          color="secondary"
          onClick={onRegenerate}
        >
          <FormattedMessage id="confirm.regenerate" />
        </Button>
      </Row>
      <Preloader isShow={isWaiting} />
    </Modal>
  );
};

ModalConfirmCode.propTypes = {
  isUserNotConfirm: PropTypes.bool,
  code: PropTypes.string,
  isShow: PropTypes.bool,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  onRegenerate: PropTypes.func,
  onSubmit: PropTypes.func,
  isWaiting: PropTypes.bool,
  error: PropTypes.string,
};
