import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';

import { observer } from 'mobx-react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import { Link } from 'react-router-dom';

import CrudStore from '../../../stores/crudStore';

@observer
class CrudPagination extends React.Component {
  UNSAFE_componentWillMount() {
    if (parseInt(window.location.hash.substring(6), 10) !== this.props.store.currentPage) {
      if ((this.props.store.currentPage !== 1) && (parseInt(window.location.hash.substring(6), 10) !== 1)) {
        window.location.hash = `page-${this.props.store.currentPage}`;
      }
    }
  }

  async handleClick(page, isInit = false) {
    const { store, startPagination, finishPagination } = this.props;

    if (!isInit) {
      startPagination();
    }
    await store.list(page).then(() => {
      if (!isInit) {
        finishPagination();
      }
    });
  }

  render() {
    const { store, isOrdersList } = this.props;

    if ((store.pageCount < 2) && !isOrdersList) {
      return (
        <div className="crud-list__inner_pagination">
          {!isOrdersList &&
            <span className="crud-list__inner_pagination_label">
              <FormattedMessage id="crud.pagination.title" />
              {' - '}
              <span className="total">{store.total}</span>
            </span>
          }
          <Pagination />
        </div>
      );
    }

    const items = [];
    const currentPage = store.currentPage;
    const pageCount = store.pageCount;

    const periodCount = 3;
    let periodCountLeft = periodCount;
    let periodCountRight = periodCount;

    if (currentPage - periodCount < 0) {
      periodCountLeft = Math.abs(currentPage - periodCount);
      periodCountRight += periodCountLeft;
    }

    if (currentPage + periodCount > pageCount) {
      periodCountRight = ((currentPage + periodCount) - pageCount) + 1;
      periodCountLeft += periodCountRight;
    }

    for (let i = 0; i < store.pageCount; i += 1) {
      const page = i + 1;

      if (page < currentPage - periodCountLeft || page > currentPage + periodCountRight) {
        //
      } else {
        const item = (
          <PaginationLink
            href={`#page-${page}`}
            onClick={() => this.handleClick(page)}
          >
            {page}
          </PaginationLink>
        );

        items.push(
          <PaginationItem key={i} active={currentPage === page}>{item}</PaginationItem>,
        );
      }
    }

    const nextPage = currentPage + 1;
    const prevPage = currentPage - 1 < 1 ? 1 : currentPage - 1;

    return (
      <div className="crud-list__inner_pagination">
        <span style={{ padding: '12px 10px' }} className="crud-list__inner_pagination_label">
          <FormattedMessage id="crud.pagination.title" />
          <span className="total"> {store.total}</span>
        </span>
        <Pagination>
          <PaginationItem disabled={currentPage - 1 < 1}>
            <Link to={`#page-${prevPage}`} onClick={() => this.handleClick(prevPage)} className="page-link page-link-down">{'<'}</Link>
          </PaginationItem>

          {items}

          <PaginationItem disabled={nextPage === store.pageCount + 1}>
            <Link to={`${window.location.pathname}#page-${nextPage}`} onClick={() => this.handleClick(nextPage)} className="page-link page-link-up">{'>'}</Link>
          </PaginationItem>
        </Pagination>
      </div>
    );
  }
}

CrudPagination.defaultProps = {
  isOrdersList: false,
};

CrudPagination.propTypes = {
  store: PropTypes.instanceOf(CrudStore).isRequired,
  isOrdersList: PropTypes.bool,
  startPagination: PropTypes.func,
  finishPagination: PropTypes.func,
};

export default injectIntl(CrudPagination);

