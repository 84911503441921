import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { cloneDeep, get, set, has, isEmpty } from 'lodash';
import { Col, Row, Button } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import { NumberInput } from '../../../components/ui/form-control';
import TranslationText from '../Crud/fields/TranslationText';
import { Error } from './common';

const WeightList = (props) => {
  const { fields, onChange, errors, readOnly } = props;

  const addRange = () => {
    const ranges = cloneDeep(fields.generalCargoRangeRate.rangeRates);

    const initialRange = {
      max: '',
      min: ranges[ranges.length - 1].max + 1 || 1,
      price: {
        currency: 'RUB',
        value: '',
      },
    };
    ranges.push(initialRange);

    onChange(ranges, 'generalCargoRangeRate.rangeRates');
  };

  const removeRange = (index) => {
    if (index !== 0) {
      const clonedRates = cloneDeep(fields.generalCargoRangeRate.rangeRates);
      clonedRates.splice(index, 1);

      onChange(clonedRates, 'generalCargoRangeRate.rangeRates');
    }
  };

  const onChangeMaxValue = (value, index) => {
    const clonedRates = cloneDeep(fields.generalCargoRangeRate.rangeRates);
    if (index !== clonedRates.length - 1) {
      set(clonedRates, `[${index + 1}].min`, value + 1);
    }
    set(clonedRates, `[${index}].max`, value);

    onChange(clonedRates, 'generalCargoRangeRate.rangeRates');
  };

  const getRangesJSX = () => {
    return (
      fields.generalCargoRangeRate.rangeRates.map((item, i) => {
        const key = i;
        return (
          <Row className="container-unit" key={key}>
            <Col xs={4}>
              <NumberInput
                value={item.min}
                label="crudForm.field.weightFrom"
                placeholder="crudForm.field.weightFrom"
                onChange={value => onChange(value, `generalCargoRangeRate.rangeRates[${i}].min`)}
                error={get(errors, `generalCargoRangeRate.rangeRates[${i}].min`, null)}
              />
            </Col>
            <Col xs={4}>
              <NumberInput
                value={item.max}
                label="crudForm.field.weightTo"
                placeholder="crudForm.field.weightTo"
                onChange={value => onChangeMaxValue(value, i)}
                error={get(errors, `generalCargoRangeRate.rangeRates[${i}].max`, null)}
              />
            </Col>
            <Col xs={12} md={4} className="d-flex align-self-baseline">
              <Row className="no-gutters">
                <Col xs={10}>
                  <NumberInput
                    id="cost-rates"
                    disabled={readOnly}
                    style={{ paddingRight: '10px' }}
                    value={item.price.value}
                    placeholder="crudForm.field.cost"
                    label="crudForm.field.cost"
                    onChange={value => onChange(value, `generalCargoRangeRate.rangeRates[${i}].price.value`, 'price')}
                    error={get(errors, `generalCargoRangeRate.rangeRates[${i}].price.value`, null)}
                    showErrors={false}
                  />
                </Col>
                <Col xs={2} className={has(errors, `commonRanges[${i}].percent`) ? 'align-self-center' : 'align-self-end'}>
                  <Button
                    style={{ height: 40, marginBottom: 12 }}
                    size="sm"
                    color="danger"
                    onClick={() => removeRange(i)}
                    disabled={i === 0 || readOnly}
                  >
                    <FontAwesome name="times" />
                  </Button>
                </Col>
                { !isEmpty(errors[`generalCargoRangeRate.rangeRates[${i}].price.value`]) &&
                  <Error>{errors[`generalCargoRangeRate.rangeRates[${i}].price.value`]}</Error>
                }
              </Row>
            </Col>
          </Row>
        );
      }));
  };

  return (
    <div className="no-gutters">
      { has(fields, 'generalCargoRangeRate.rangeRates') && getRangesJSX() }
      { !readOnly &&
        <Row className="w-100 no-gutters justify-content-center">
          <Button
            outline
            color="primary"
            className="mb-3"
            onClick={() => addRange()}
          >
            <FontAwesome name="plus" />{' '}
            <FormattedMessage id="crudForm.field.intraportForwarding.addRange" />
          </Button>
        </Row>
      }
      <TranslationText
        fields={fields}
        fieldName="comment"
        onChange={onChange}
        disabled={readOnly}
        error={errors.comment}
        type={'textarea'}
      />
    </div>
  );
};

WeightList.propTypes = {
  fields: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default WeightList;
