import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, InputGroupAddon, InputGroup } from 'reactstrap';
import { uniqueId } from 'lodash';
import { Label, Error, Note } from './common';

const FieldWrapper = (props) => {
  const { leftAddon, rightAddon, className, label, note, id = null, error, children, showErrors } = props;
  const uniqId = id || uniqueId('f-element-text-');

  let input = children;

  if (leftAddon || rightAddon) {
    input = (
      <InputGroup>
        {leftAddon && <InputGroupAddon className="left-addon" addonType="prepend"><span className="input-group-text">{leftAddon}</span></InputGroupAddon>}
        {input}
        {rightAddon && <InputGroupAddon className="right-addon" addonType="append"><span className="input-group-text">{rightAddon}</span></InputGroupAddon>}
      </InputGroup>
    );
  }

  return (
    <FormGroup className={`f-element size-input f-element-text ${error ? 'has-danger' : ''} ${className}`}>
      <Label elementId={uniqId}>{label}</Label>
      {input}
      <Note>{note}</Note>

      { showErrors && <Error>{error}</Error> }
    </FormGroup>
  );
};

FieldWrapper.defaultProps = {
  className: '',
  name: '',
  disabled: false,
  showErrors: true,
};

FieldWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.string,
  note: PropTypes.string,
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showErrors: PropTypes.bool,
};

export default FieldWrapper;

