import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { Select } from '../ui/form-control';
import { CUSTOM_HOUSE, CUSTOM_HOUSE_AIR } from '../../constants/staticData';
import { addFormatNameToConstant } from '../../utils/utils';
import { TYPE_OF_LOCATIONS } from '../../constants/global';

export const CommonForm = (props) => {
  const { onChange, fields, customsHouseState } = props;

  const isAirHub = fields.fromLocation.type === TYPE_OF_LOCATIONS.AIRPORT_HUB || false;
  const prepareCustomsHouse = isAirHub ? addFormatNameToConstant(CUSTOM_HOUSE_AIR) : addFormatNameToConstant(CUSTOM_HOUSE);
  const fieldValue = prepareCustomsHouse.find(item => item.id === fields.customsHouse);

  return (
    <div className="container block-margin-top mb-4">
      <Row className="wizard__common-block">
        <Col xs={12}>
          <Row className="wizard__common-block_header align-items-center">
            <Col xs={12} className="wizard__direction-block_header_title">
              <FormattedMessage id="form.wizard.title.customsOfCargo" />
            </Col>
          </Row>

          <Row className="my-2 px-2">
            <Col xs={12} md={5} className="custom-cargo">
              <Select
                classNameGroup="wizard__select-custom mb-0"
                label="wizard.cargo.customsOfCargo"
                value={fieldValue}
                values={prepareCustomsHouse}
                labelKey="formatName"
                valueKey="id"
                onChange={value => onChange(value.id, 'customsHouse')}
                disabled={customsHouseState.isDisabled}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

CommonForm.propTypes = {
  onChange: PropTypes.func,
  fields: PropTypes.object,
  customsHouseState: PropTypes.object,
};
