import HttpProvider from './httpProvider';
import { API } from '../constants/api';

class CdekService {
  constructor() {
    this.http = HttpProvider;
  }

  async createOrder(id, data) {
    return await this.http.post(API.CDEK.CREATE_ORDER(id, data.tariffCode), data);
  }

  async getOrderInfo(id, tariffCode) {
    return await this.http.get(API.CDEK.GET_ORDER(id,tariffCode));
  }

  async updateOrderInfo(id, tariffCode, data) {
    return await this.http.put(API.CDEK.UPDATE_ORDER(id,tariffCode), data);
  }

  async updateOrderStatus(uuid) {
    return await this.http.get(API.CDEK.UPDATE_ORDER_STATUS(uuid));
  }

  async intakeCourier(objRequest) {
    return await this.http.post(API.CDEK.INTAKE_COURIER(), objRequest)
  }

  async getEventsByUUID(uuid) {
    return await this.http.get(API.CDEK.GET_EVENTS_BY_UUID({uuid}))
  }
}

export default new CdekService();
