import React from 'react';
import userStore from "../../../stores/userStore";
import Page from "../../../layouts/main";
import {getExistLangValue, translate} from "../../../utils/utils";
import {FormattedMessage} from "react-intl";
import CrudFilters from "../../../components/ui/Crud/CrudFilters";
import CrudForm from "../../../components/ui/Crud/CrudForm";
import DictionaryForm from "../../../components/ui/Crud/forms/DictionaryForm";
import CrudField from "../../../components/ui/Crud/CrudField";
import CrudList from "../../../components/ui/Crud/CrudList";
import CrudColumn from "../../../components/ui/Crud/CrudColumn";
import CrudFormats from "../../../components/ui/Crud/CrudFormats";
import Crud from "../../../components/ui/Crud/Crud";
import HttpProvider from "../../../services/httpProvider";
import {API} from "../../../constants/api";
import {FILTER_EDITORS, FILTER_FIELD_TYPES} from "../../../constants/global";
import DetailedUserReport from "../../../components/statistics/DetailedUserReport";

const AdminRatingUser = (props) => {

    const text = {
        ru: 'Страница недоступна для данного профиля или не существует',
        en: 'This page is not available for this profile or does not exist',
    };
    if (userStore.isAdmin() === false)
        return (
            <Page
                pageTitle={getExistLangValue(text)}
                scope="user"
                {...props}
            >
                <h3>{getExistLangValue(text)}</h3>
            </Page>
        );

    const crudEndpoint = 'rating-user';
    const crudActions = {
        list: async (offset, limit, query) => await HttpProvider.get(API.BASE(`${crudEndpoint}/sort?offset=${offset}&limit=${limit}${query}`), false),
        create: async data => await HttpProvider.post(API.BASE(crudEndpoint), data),
        read: async id => await HttpProvider.get(API.BASE(`${crudEndpoint}/${id}`), false),
        update: async (id, data) => await HttpProvider.put(API.BASE(`${crudEndpoint}/${id}`), data),
        delete: async id => await HttpProvider.remove(API.BASE(`${crudEndpoint}/${id}`)),
    };
    const {CONTAINS, SIMPLE, DATE_RANGE} = FILTER_FIELD_TYPES;
    const {SELECT_USER, DATE, DATE_RANGE_EDITOR} = FILTER_EDITORS

    const formatUser = (value) => {
        if (value)
            return (
                <div>
                    {value.lastName}{" "}{value.firstName}{" | "}{value.email}
                </div>
            );
        return "-";
    }

    const formatCargoTypeCalculationCount = (value) => {
        if (value)
            return (
                <div>
                    <div><span><FormattedMessage id={"modal.orderInfo.container"}/>{": "}</span>{value.container}</div>
                    <div><span><FormattedMessage id={"modal.orderInfo.general"}/>{": "}</span>{value.general}</div>
                    <div><span><FormattedMessage id={"modal.orderInfo.vehicle"}/>{": "}</span>{value.vehicle}</div>
                </div>
            );
        return ("-")
    }

    return (
        <Page scope="user" {...props} pageTitle={<FormattedMessage id="rating.page.title"/>}>
            <Crud
                endpoint={'rating-user'}
                crudActions={crudActions}
            >
                <CrudFilters context={"adminRatingUser"} fields={[
                    {
                        name: 'user',
                        type: SIMPLE,
                        editor: SELECT_USER,
                        label: 'crud.field.user.title',
                    },
                    {
                        name: 'historyTimeCalculations',
                        type: DATE_RANGE,
                        editor: DATE_RANGE_EDITOR,
                        label: 'crud.field.createOrderDate.title',
                    }
                ]}/>
                <CrudList isAdd={false} isEdit={false} isDelete={false}>
                    <CrudColumn unsortable name={'id'}/>
                    <CrudColumn unsortable name={'user'} format={formatUser}/>
                    <CrudColumn unsortable name={'cargoTypeCalculation'} format={formatCargoTypeCalculationCount}/>
                    <CrudColumn unsortable name={'summary'}/>
                    <CrudColumn unsortable virtual name={'reportDetail'} format={(value)=><DetailedUserReport data={value} />}/>
                    <CrudColumn unsortable name={'lastCalculationDateTime'} format={value => {
                        return ((new Date(value)).toLocaleDateString() + " " + (new Date(value)).toLocaleTimeString())
                    }}/>
                </CrudList>
            </Crud>
        </Page>
    );
};

export default AdminRatingUser;