import { action, computed, observable, runInAction } from 'mobx';
import { merge, get } from 'lodash';
import CrudService from '../services/crudService';
import FlashStore from './flashStore';

class FilterStore {
  @observable items = [];
  @observable total = 0;
  @observable item = null;

  @observable isEdit = false;

  @observable errors = [];

  @observable offset = 0;
  @observable limit = 20;

  constructor(crudUrlPrefix, defaultListParams = {}, defaultCreateParams = {}, crudActions) {
    this.service = new CrudService(crudUrlPrefix, crudActions);
    this.defaultListParams = defaultListParams;
    this.defaultCreateParams = defaultCreateParams;
  }

  @computed get hasErrors() {
    return this.errors !== null && this.errors.length > 0;
  }

  @action
  async get(id) {
    const res = await this.service.get(id);

    if (res.status === 'success') {
      this.item = res.data;
    } else {
      this.item = null;
      FlashStore.error(`Unable to get by ${id}.`);
    }

    return this.item;
  }

  @action
  async edit(id) {
    this.isEdit = true;
    this.item = null;

    if (id) {
      await this.get(id);
    }
  }

  @action
  cancelEdit() {
    this.isEdit = false;
    this.item = null;
  }

  @action
  async reloadList() {
    return this.list();
  }

  @action
  async list(page = 1) {
    this.offset = (page - 1) * this.limit;

    const res = await this.service.list(this.offset, this.limit, this.defaultListParams);

    runInAction(() => {
      if (res.status === 'success') {
        this.items = res.data;
      } else {
        this.items = [];
        FlashStore.error('Unable to list.');
      }
    });

    return this.items;
  }

  @action
  async create(data) {
    const res = await this.service.create(merge(data, this.defaultCreateParams));
    this.errors = [];

    if (res.status === 'success') {
      this.item = res.data;
    } else {
      this.item = null;
      this.errors = get(res, 'data.errors', []);
    }

    return this.item;
  }

  @action
  async update(id, data) {
    const res = await this.service.update(id, data);

    this.errors = [];

    if (res.status === 'success') {
      this.item = res.data;
    } else {
      this.errors = get(res, 'data.errors', []);
    }

    return this.item;
  }

  @action
  async remove(id) {
    const res = await this.service.remove(id);

    this.errors = [];

    if (res.status === 'success') {
      this.item = res.data;
    } else {
      this.item = null;
    }

    return this.item;
  }

  hasService() {
    return !!this.service.urlPrefix;
  }
}

export default FilterStore;
