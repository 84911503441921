import _Select from './Select';
import _AddressInput from './AddressInput';
import _TextInput from './TextInput';
import _NumberInput from './NumberInput';
import _Checkbox from './Checkbox';
import _Switcher from './Switcher';
import _SelectBox from './SelectBox';
import _DateInput from './DateInput';
import _ViewSpan from './ViewSpan';
import _SizeInput from './SizeInput';
import _HatchSizeInput from './HatchSizeInput';
import _CoordinatesInput from './CoordinatesInput';
import _ContainersList from './ContainersList';
import _SelectCity from './SelectCity';
import _SelectCountry from './SelectCountry';
import _SelectCountries from './SelectCountries';
import _SelectRegion from './SelectRegion';
import _SelectWorldRegion from './SelectWorldRegion';
import _SelectCountryMulti from './SelectCountryMulti';
import _SelectServiceCategory from './SelectServiceCategory';
import _SelectServiceProfile from './SelectServiceProfile';
import _SelectDirectionOfTransportation from './SelectDirectionOfTransportation';
import _SelectCargoType from './SelectCartoType';
import _SelectChargingType from './SelectChargingType';
import _SelectRailwayCompany from './SelectRailwayCompany';
import _SelectHub from './SelectHub';
import _SelectAirline from './SelectAirline';
import _SelectAirport from './SelectAirport';
import _SelectRailwayStation from './SelectRailwayStation';
import _InterlayerAsyncSelect from './InterlayerAsyncSelect';
import _AllHubsSelect from './AllHubsSelect';
import _DateRangeInput from './DateRangeInput';
import _SelectSeaPort from './SelectSeaPort';
import _SelectSeaPortByCountry from './SelectSeaPortByCountry';
import _RangesGroup from './RangesGroup';
import _SelectSeaLine from './SelectSeaLine';
import _SelectForwarder from './SelectForwarder';
import _SelectLanguage from './SelectLanguage';
import _WeightList from './WeightList';
import _LdmRates from './LdmRates';
import _FieldWrapper from './FieldWrapper';

// TODO: Сделать все компоненты наследуемыми от одного базового компонента так как у них идентичная структура разметки.
export const Select = _Select;
export const AddressInput = _AddressInput;
export const TextInput = _TextInput;
export const NumberInput = _NumberInput;
export const Checkbox = _Checkbox;
export const Switcher = _Switcher;
export const SelectBox = _SelectBox;
export const DateInput = _DateInput;
export const SizeInput = _SizeInput;
export const HatchSizeInput = _HatchSizeInput;
export const CoordinatesInput = _CoordinatesInput;
export const ViewSpan = _ViewSpan;
export const ContainersList = _ContainersList;
export const SelectCity = _SelectCity;
export const SelectCountry = _SelectCountry;
export const SelectCountries = _SelectCountries;
export const SelectRegion = _SelectRegion;
export const SelectWorldRegion = _SelectWorldRegion;
export const SelectCountryMulti = _SelectCountryMulti;
export const SelectSeaPort = _SelectSeaPort;
export const SelectSeaPortByCountry = _SelectSeaPortByCountry;
export const RangesGroup = _RangesGroup;
export const SelectSeaLine = _SelectSeaLine;
export const SelectForwarder = _SelectForwarder;
export const SelectLanguage = _SelectLanguage;
export const WeightList = _WeightList;
export const LdmRates = _LdmRates;
export const FieldWrapper = _FieldWrapper;

export const SelectServiceCategory = _SelectServiceCategory;
export const SelectServiceProfile = _SelectServiceProfile;
export const SelectCargoType = _SelectCargoType;
export const SelectChargingType = _SelectChargingType;
export const SelectRailwayCompany = _SelectRailwayCompany;
export const SelectHub = _SelectHub;
export const SelectAirline = _SelectAirline;
export const SelectAirport = _SelectAirport;
export const SelectRailwayStation = _SelectRailwayStation;
export const InterlayerAsyncSelect = _InterlayerAsyncSelect;
export const AllHubsSelect = _AllHubsSelect;
export const SelectDirectionOfTransportation = _SelectDirectionOfTransportation;

export const DateRangeInput = _DateRangeInput;
