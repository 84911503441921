import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DictionaryService from '../../../services/dictionaryService';
import Select from './Select';
import { setName } from '../../../utils/utils';

export default class SelectSeaLine extends Component {
  state = {
    values: [],
    value: '',
  };

  UNSAFE_componentWillMount() {
    this.setState({ value: this.props.value });
  }

  async UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ value: newProps.value });

    if (newProps.isConsolidator !== this.props.isConsolidator) {
      this.loadOptions('', newProps.isConsolidator);
    }
    // if (newProps.value) {
    //   const city = await DictionaryService.getSeaLines(newProps.value);
    //
    //   const value = {
    //     value: city.id,
    //     name: setName(city),
    //   };
    //
    //   const clonedState = cloneDeep(this.state.values) || [];
    //
    //   clonedState.push(value);
    //
    //   if (this.state.value === '') {
    //     clonedState.pop();
    //   }
    //   const prepareClone = this.prepareAndFilterHubs(clonedState);
    //
    //   this.setState({
    //     values: prepareClone,
    //   });
    // }
  }

  prepareAndFilterHubs(hubs) {
    const used = {};

    return hubs.filter(obj => obj).filter((obj) => {
      if (obj.value in used) {
        return 0;
      }
      used[obj.value] = 1;

      return used[obj.value];
    });
  }

  async loadOptions(input, isConsolidator) {
    const filter = (isConsolidator) ? { name: input, type: 'CONSOLIDATOR' } : { name: input };
    const seaHubs = await DictionaryService.getSeaLines(filter);

    const preparedHubs = seaHubs.map((hub) => { return { value: hub.id, name: setName(hub) }; });
    const clonedValues = [];
    const allHubs = this.prepareAndFilterHubs([...clonedValues, ...preparedHubs]);

    this.setState({
      values: allHubs,
    });

    return { options: this.state.values };
  }

  render() {
    const { leftAddon, error, label, onChange, disabled, isConsolidator } = this.props;
    const { values, value } = this.state;

    return (
      <Select
        async
        searchable
        disabled={disabled}
        label={label}
        leftAddon={leftAddon}
        values={values}
        labelKey="name"
        value={value}
        error={error}
        onChange={onChange}
        loadOptions={input => this.loadOptions(input, isConsolidator)}
        cache={false}
      />
    );
  }
}

SelectSeaLine.defaultProps = {
  noResultsText: 'form.select.noResultText',
  showErrors: true,
  disabled: false,
};

SelectSeaLine.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  isConsolidator: PropTypes.bool,
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
};
