import React from "react";
import "../../assets/styles/ui/tooltip.css";

export default function Tooltip({ children, text, disabled, className = '', onShow = ()=> false, onHide = () => false, ...rest }) {
    const [show, setShow] = React.useState(false);

    const handleMouseEnter = () => {
        if (!disabled) {
            setShow(true);
            onShow();
        }
    }
    const handleMouseLeave = () => {
        if (!disabled) {
            setShow(false);
            onHide();
        }
    }

    return (
            <div
                className={`tooltip-ui__wrapper ${className}`}
                {...rest}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="tooltip-ui" style={show ? { visibility: "visible", opacity:1 } : {}}>
                    {text}
                    <span className="tooltip-ui__arrow" />
                </div>

                {children}
            </div>
    );
}
