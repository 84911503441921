import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';
import { Error, Label, Note } from '../ui/form-control/common';
import { keyGenerator } from '../../utils/utils';

class SelectBoxRefs extends Component {
    getControl() {
        const {
            labelKey,
            value,
            style,
            values,
            disabled,
            onClick,
            buttonClassName,
        } = this.props;

        return values.map((item, index) => {
            return (
                <button
                    id={"btnContainer"+index}
                    type="button"
                    key={keyGenerator()}
                    style={style}
                    onClick={() => onClick(item)}
                    disabled={disabled}
                    className={`${buttonClassName}${value === item ? ' active' : ''}`}
                >
                    {item[labelKey]}
                </button>
            );
        });
    }

    render() {
        const { value, error, className, label, note, showErrors } = this.props;
        const newError = value ? null : error;

        return (
            <FormGroup className={`f-element f-element-select-box no-gutters ${value ? ' has-value' : ''}${newError ? ' has-danger' : ''} ${className}`}>
                <Label>{label}</Label>
                <div className="f-control">
                    {this.getControl()}
                </div>
                <Note>{note}</Note>
                { showErrors ? <Error>{newError}</Error> : null }
            </FormGroup>
        );
    }
}

SelectBoxRefs.defaultProps = {
    labelKey: 'label',
    buttonClassName: 'btn btn-container-box',
    showErrors: true,
};

SelectBoxRefs.propTypes = {
    className: PropTypes.string,
    buttonClassName: PropTypes.string,
    label: PropTypes.string,
    note: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
    values: PropTypes.arrayOf(PropTypes.object),
    labelKey: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    showErrors: PropTypes.bool,
};

export default SelectBoxRefs;
