import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal as ModalR, ModalHeader, ModalBody } from 'reactstrap';

export class Modal extends Component {
  static propTypes = {
    isModal: PropTypes.bool,
    keyboard: PropTypes.bool,
    className: PropTypes.string,
    backdropClassName: PropTypes.string,
    isShow: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    top: PropTypes.bool,
    showHeader: PropTypes.bool,
  };

  static defaultProps = {
    keyboard: true,
    showHeader: true,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isShow && this.props.isShow) {
      window.parent.postMessage('[wizard] scroll top', '*');
    }
  }

  render() {
    const { title, top, onClose, className, backdropClassName, isModal, keyboard, showHeader } = this.props;

    let headerContent = null;

    if (title) {
      headerContent = (
        <div className="title">
          {title}
        </div>
      );
    }

    const header = (<ModalHeader
      className={`${headerContent ? 'has-title' : ''}`}
      toggle={onClose}
    >
      {headerContent}
    </ModalHeader>);

    const content = (
      <ModalR
        isOpen={this.props.isShow}
        toggle={!isModal ? onClose : null}
        modalClassName={`b-popup-wrapper ${top ? '' : 'v-center'}`}
        className={`b-popup b-popup-info modal-md ${className || ''}`}
        backdropClassName={`b-popup-backdrop ${backdropClassName || ''}`}
        keyboard={keyboard}
      >
        {showHeader && header}
        <ModalBody>
          {this.props.children}
        </ModalBody>
      </ModalR>
    );

    return content;
  }
}
