import moment from 'moment/moment';
import { DATE_FORMAT } from '../../constants/global';

export const DEFAULT_CONTAINER = {
  amount: 1,
  typeId: '',
  weight: '20000',
  weightUnit: 'KG',
};

export const DEFAULT_GENERAL = {
  typeId: 1,
  amount: 1,
  palletId: '',
  sizeUnit: 'CM',
  prevSizeUnit: 'CM',
  cubSizeUnit: 'M3',
  weight: '',
  weightUnit: 'KG',
  length: '',
  width: '',
  height: '',
  cubeVolume: '',
  errorOverload: false,
};

export const DEFAULT_CUBES = {
  cubeVolume: '',
  cubsizeUnit: 'M3',

};

export const DEFAULT_VEHICLE = {
  typeId: '',
  bodyType: 'TENT',
};

export const DEFAULT_FIELDS = {
  cargo: {
    containers: [
      { ...DEFAULT_CONTAINER,idCargo:"c"+(new Date()).getTime() },
    ],
    generals: [
      { ...DEFAULT_GENERAL,idCargo:"c"+(new Date()).getTime() },
    ],
    vehicle: { ...DEFAULT_VEHICLE,idCargo:"c"+(new Date()).getTime() },
  },
  customsClearance: {
    code: '',
    enabled: true,
  },
  insurance: {
    enabled: true,
    value: {
      currency: 'RUB',
      value: 0.0,
    },
  },
  fromLocation: {
    address: null,
    countryCode: 'CN',
    postcode: '',
    seaPortId: '',
    airportId: '',
    stationId: '',
    type: 'ADDRESS',
  },
  toLocation: {
    address: null,
    countryCode: 'RU',
    postcode: '',
    seaPortId: '',
    airportId: '',
    stationId: '',
    type: 'ADDRESS',
  },
  kind: 'EXPORT',
  resultType: 'BEST_PRICE',
  shippingDate: moment().format(Array.isArray(DATE_FORMAT.ru) ? DATE_FORMAT.ru[0] : DATE_FORMAT.ru),
  customsHouse: null,
  cargoInsurances: [{
    cargoCost: {
      currency: 'RUB',
      value: '',
    },
    interestTypeId: '',
  }],
  hasCustomsClearance: false,
  terms: '',
};

export const TAB_TO_CARGO_TYPE = {
  GENERAL_CARGO: 'box',
  CONTAINER: 'containers',
  VEHICLE: 'car',
};

