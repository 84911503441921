import React, {useEffect, useState} from 'react';
import {formatCost, getExistLangValue} from "../../utils/utils";
import {FormattedMessage} from "react-intl";

const VehicleRateFormat = (props) => {

    const [item, setItem] = useState(props.item)
    const [showAll, setShowAll] = useState(false)
    useEffect(() => {
        setItem(props.item)
    }, [props])
    if (!item)
        return "-"

    const renderValue = (value) => {
        return (
            <div className={"mt-3"}>
                <div><span><FormattedMessage id={"wizard.cargo.form.vehicleType"}/>: </span>{getExistLangValue(value.vehicleType.name)}</div>
                <div><span><FormattedMessage id={"wizard.cargo.form.vehicleBody"}/>: </span><FormattedMessage
                    id={`constants.vehicleBodyType.${value.vehicleBody.toLowerCase()}`}/></div>
                {value.distanceRange && <div><span><FormattedMessage id={"crud.field.vehicleRates.distanceRange.title"}/>: </span><FormattedMessage
                    id={`constants.vehicleDistanceRange.${value.distanceRange}`}/></div>}
                <div><span><FormattedMessage id={"crud.field.price.title"}/>: </span>{formatCost(value.price.value)}{" "}{value.price.currency}</div>

            </div>
        )
    }
    return (
        <div>
            {showAll === false &&
                <div>
                    {item.map((value, index) => {
                        if (index === 0)
                            return renderValue(value)
                    })}
                </div>
            }
            {showAll === true &&
                <div>
                    {item.map((value, index) => {
                            return renderValue(value)
                    })}
                </div>
            }
            <div onClick={()=>setShowAll(!showAll)} className={"btn btn-link"} style={{fontSize: "12px"}}>
                <FormattedMessage id={`${showAll===false?'crud.filter.show':'crud.filter.hide'}`}/>
            </div>
        < /div>
    )
};

export default VehicleRateFormat;