import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {cloneDeep} from "lodash";
import {FormattedMessage} from "react-intl";
import {Col, Row} from "reactstrap";

const CdekTracking = ({cdekSteps}) => {

    const getClassName4StepCdek = (step, steps = [], globalStepsByLocal, globalSteps = []) => {
        let isArchive = false;
        let isCurrent = false;
        const currentIndex = globalSteps.findIndex(v => v === step)
        if (currentIndex !== globalSteps.length) {
            if (steps.filter(cdekStep => globalStepsByLocal[step].includes(cdekStep.code)).length > 0) {
                isCurrent = true
            }
            if (currentIndex !== globalSteps.length - 1) {
                if (steps.filter(cdekStep => globalStepsByLocal[globalSteps[currentIndex + 1]].includes(cdekStep.code)).length > 0) {
                    isCurrent = false
                    isArchive = true
                }
            } else {
                if (steps.filter(cdekStep => cdekStep.code === "DELIVERED").length > 0) {
                    isCurrent = false
                    isArchive = true
                }
            }
        }
        if (isCurrent === true)
            return " current"
        if (isArchive === true)
            return " completed"
    }

    const trackingInfoPathCdek = (step, steps = [], globalStepsByLocal, globalSteps = []) => {
        const icons = [
            {type: "CREATED", icon: "point"},
            {type: "TRANSIT", icon: "point"},
            {type: "COURIER_DELIVERY", icon: "point"},
            {type: "DELIVERY", icon: "calendar"}
        ]

        let isArchive = false;
        let isCurrent = false;

        const currentIndex = globalSteps.findIndex(v => v === step)
        if (currentIndex !== globalSteps.length) {
            if (steps.filter(cdekStep => globalStepsByLocal[step].includes(cdekStep.code)).length > 0) {
                isCurrent = true
            }
            if (currentIndex !== globalSteps.length - 1) {
                if (steps.filter(cdekStep => globalStepsByLocal[globalSteps[currentIndex + 1]].includes(cdekStep.code)).length > 0) {
                    isCurrent = false
                    isArchive = true
                }
            } else {
                if (steps.filter(cdekStep => cdekStep.code === "DELIVERED").length > 0) {
                    isCurrent = false
                    isArchive = true
                }
            }
        }

        const isLast = currentIndex === globalSteps.length - 1


        return (<div className="tracking-modal-row__icon-container">
                <div className={isCurrent === true ? 'icon current' : 'icon'}>
                    <div className={'icon-data'}>
                        <div
                            className={isArchive === true ? `${icons.find(value => value.type === step).icon} active` : `${icons.find(value => value.type === step).icon}`}></div>
                    </div>
                </div>
                {isLast === false &&
                    <div className={isArchive === true ? 'bottom-line active' : 'bottom-line'}></div>
                }
            </div>
        )
    }
    //Test data
    /*cdekSteps = cloneDeep([
        {
            "code": "ACCEPTED",
            "name": "Принят",
            "dateTime": "2024-02-16T07:36:34+0000",
            "city": "Офис СДЭК"
        },
        {
            "code": "CREATED",
            "name": "Создан",
            "dateTime": "2024-02-16T07:36:34+0000",
            "city": "Офис СДЭК"
        },
        {
            "code": "RECEIVED_AT_SHIPMENT_WAREHOUSE",
            "name": "Принят на склад отправителя",
            "dateTime": "2024-02-16T15:12:28+0000",
            "city": "Воронеж"
        },
        {
            "code": "READY_FOR_SHIPMENT_IN_SENDER_CITY",
            "name": "Выдан на отправку в г. отправителе",
            "dateTime": "2024-02-16T15:12:34+0000",
            "city": "Воронеж"
        },
        {
            "code": "TAKEN_BY_TRANSPORTER_FROM_SENDER_CITY",
            "name": "Сдан перевозчику в г. отправителе",
            "dateTime": "2024-02-16T20:28:53+0000",
            "city": "Воронеж"
        },
        {
            "code": "SENT_TO_TRANSIT_CITY",
            "name": "Отправлен в г. транзит",
            "dateTime": "2024-02-16T20:47:00+0000",
            "city": "Воронеж"
        },
        {
            "code": "ACCEPTED_IN_TRANSIT_CITY",
            "name": "Встречен в г. транзите",
            "dateTime": "2024-02-17T04:08:06+0000",
            "city": "Москва"
        },
        {
            "code": "ACCEPTED_AT_TRANSIT_WAREHOUSE",
            "name": "Принят на склад транзита",
            "dateTime": "2024-02-17T04:33:45+0000",
            "city": "Москва"
        },
        {
            "code": "READY_FOR_SHIPMENT_IN_TRANSIT_CITY",
            "name": "Выдан на отправку в г. транзите",
            "dateTime": "2024-02-17T04:44:52+0000",
            "city": "Москва"
        },
        {
            "code": "TAKEN_BY_TRANSPORTER_FROM_TRANSIT_CITY",
            "name": "Сдан перевозчику в г. транзите",
            "dateTime": "2024-02-17T06:23:50+0000",
            "city": "Москва"
        },
        {
            "code": "SENT_TO_RECIPIENT_CITY",
            "name": "Отправлен в г. получатель",
            "dateTime": "2024-02-17T06:31:08+0000",
            "city": "Москва"
        },
        {
            "code": "ACCEPTED_IN_RECIPIENT_CITY",
            "name": "Встречен в г. получателе",
            "dateTime": "2024-02-17T07:28:52+0000",
            "city": "Москва"
        },
        {
            "code": "ACCEPTED_AT_RECIPIENT_CITY_WAREHOUSE",
            "name": "Принят на склад доставки",
            "dateTime": "2024-02-17T08:08:54+0000",
            "city": "Москва"
        },
        {
            "code": "ACCEPTED_AT_RECIPIENT_CITY_WAREHOUSE",
            "name": "Принят на склад доставки",
            "dateTime": "2024-02-18T21:13:50+0000",
            "city": "Москва"
        },
        {
            "code": "TAKEN_BY_COURIER",
            "name": "Выдан на доставку",
            "dateTime": "2024-02-19T09:31:15+0000",
            "city": "Москва"
        },
        {
            "code": "DELIVERED",
            "name": "Вручен",
            "dateTime": "2024-02-19T12:10:56+0000",
            "city": "Москва"
        }
    ])*/
    const cdekStepsFormatted = []

    let cityNamesUnique = (cdekSteps.map(v => v.city))
    cityNamesUnique = new Set(cityNamesUnique)
    let prepareArrayCities = []
    for (let val of cityNamesUnique.values())
        prepareArrayCities.push(val)

    const globalSteps = ["CREATED", "TRANSIT", "COURIER_DELIVERY", "DELIVERY"]
    const globalStepsByLocal = {
        CREATED: ["ACCEPTED", "CREATED"],
        TRANSIT: ["RECEIVED_AT_SHIPMENT_WAREHOUSE",
            "READY_TO_SHIP_AT_SENDING_OFFICE",
            "READY_FOR_SHIPMENT_IN_TRANSIT_CITY",
            "RETURNED_TO_SENDER_CITY_WAREHOUSE",
            "TAKEN_BY_TRANSPORTER_FROM_SENDER_CITY",
            "SENT_TO_TRANSIT_CITY",
            "ACCEPTED_IN_TRANSIT_CITY",
            "ACCEPTED_AT_TRANSIT_WAREHOUSE",
            "RETURNED_TO_TRANSIT_WAREHOUSE",
            "READY_TO_SHIP_IN_TRANSIT_OFFICE",
            "TAKEN_BY_TRANSPORTER_FROM_TRANSIT_CITY",
            "SENT_TO_SENDER_CITY",
            "SENT_TO_RECIPIENT_CITY",
            "ACCEPTED_IN_SENDER_CITY",
            "ACCEPTED_IN_RECIPIENT_CITY",
            "ACCEPTED_AT_RECIPIENT_CITY_WAREHOUSE",
            "ACCEPTED_AT_PICK_UP_POINT",
            "IN_CUSTOMS_INTERNATIONAL",
            "SHIPPED_TO_DESTINATION",
            "PASSED_TO_TRANSIT_CARRIER",
            "IN_CUSTOMS_LOCAL",
            "CUSTOMS_COMPLETE"],
        COURIER_DELIVERY: ["TAKEN_BY_COURIER", "RETURNED_TO_RECIPIENT_CITY_WAREHOUSE",],
        DELIVERY: ["DELIVERED", "NOT_DELIVERED"]
    }
    return (
        <Fragment>
            <div className="tracking-modal-container">
                {globalSteps.map((globalStep, globalStepIndex) => {
                    return (<div className={"tracking-modal-row"}>
                        {trackingInfoPathCdek(globalStep, cdekSteps, globalStepsByLocal, globalSteps)}
                        <div className={"tracking-modal-row__item"}>
                            <div className="tracking-modal-row__title"><FormattedMessage
                                id={`cdek.tracking.global.${globalStep}`}/></div>
                            {cdekSteps.filter(cdekStep => globalStepsByLocal[globalStep].includes(cdekStep.code)).length > 0 &&
                                <div style={{paddingBottom: "8px"}}
                                     className={"card-item " + getClassName4StepCdek(globalStep, cdekSteps, globalStepsByLocal, globalSteps)}>
                                    {cdekSteps.filter(cdekStep => globalStepsByLocal[globalStep].includes(cdekStep.code)).map((cdekStep, cdekStepIndex) => {
                                        return (<Row key={`cdek-step-${cdekStepIndex}`} style={{lineHeight: "18px"}} className={"mt-2"}>
                                            <Col xs={12}
                                                 style={{color: "#8d98a6"}}>{globalStep !== "CREATED" ? `${cdekStep.city} - ` : ''} {cdekStep.dateTime.split("T")[0]}</Col>
                                            <Col xs={12}><FormattedMessage
                                                id={`cdek.tracking.local.step.${cdekStep.code}`}/></Col>
                                        </Row>)
                                    })}
                                </div>
                            }
                        </div>
                    </div>)
                })}
            </div>
        </Fragment>
    )
};

CdekTracking.propTypes = {};

export default CdekTracking;
