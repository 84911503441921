import React, {useEffect, useState} from 'react';
import {Button, Col, FormGroup, InputGroup, InputGroupAddon, Label, Row} from "reactstrap";
import PropTypes, {number} from "prop-types";
import subscriptionService from "../../services/subscriptionService";
import {Modal} from "../ui/Modal";
import {Select, TextInput} from "../ui/form-control";
import {getExistLangValue, translate} from "../../utils/utils";
import Input from "../ui/Input";
import {FormattedMessage} from "react-intl";

const AddSubscription = (props) => {

    const [subscription, setSubscription] = useState(null)
    const [tariffPlans, setTariffPlans] = useState([])
    const [showModalCreateNewSubscription, setShowModalCreateNewSubscription] = useState(false)
    const [selectTariff, setSelectTariff] = useState(null)
    const [transactionId, setTransactionId] = useState("")

    useEffect(() => {
        if (subscription) {
            subscriptionService.getTariffs().then(res => {
                let formatted = []
                for (let item of res.data) {
                    let obj = item;
                    obj.formattedName = getExistLangValue(item.name)

                    if (obj.id !== subscription.historyBillingList[subscription.historyBillingList.length - 1].tariffPlan.id)
                        formatted.push(obj)
                }
                setTariffPlans(formatted)
            })
        }
    }, [subscription])

    useEffect(() => {
        if (props.idSubscription) {
            subscriptionService.getDetailsSubscription(props.idSubscription).then(res => {
                setSubscription(res.data)
            })
        }
    }, [props])

    const getBody = () => {
        return (
            <div>
                <Label className={"flag-required"}>
                    <FormattedMessage id={"subscription.tariff.create.modal.title"}/>
                </Label>
                <Select
                    values={tariffPlans}
                    valueKey={"id"}
                    labelKey={"formattedName"}
                    value={selectTariff}
                    onChange={(value) => setSelectTariff(value.id)}
                />
                <Label className={"flag-required"}>
                    <FormattedMessage id={"subscription.history.idContract"}/>
                </Label>
                <TextInput
                    value={transactionId}
                    onChange={(value) => {
                        setTransactionId(value.target.value)
                    }}
                    placeholder={translate("subscription.history.idContract")}
                />
                {selectTariff !== null && transactionId.length > 0 &&
                    <Button onClick={() => {
                        props.onSave({
                            tariffId: selectTariff,
                            transactionId: transactionId,
                            userId: props.userId
                        })
                        setShowModalCreateNewSubscription(false)
                    }}
                            className={"btn-primary-new mr-2"}><FormattedMessage id={"subscription.modal.add.btn"}/>
                    </Button>
                }
                <Button onClick={()=>setShowModalCreateNewSubscription(false)} className={"btn-outline-primary-new"}>
                    <FormattedMessage id={"common.close"}/>
                </Button>
            </div>
        )
    }

    return (
        <div>
            <Modal
                isShow={showModalCreateNewSubscription}
                onClose={() => setShowModalCreateNewSubscription(false)}
                title={<FormattedMessage id={"subscription.modal.add.title"} />}
            >
                {getBody()}
            </Modal>
            <InputGroup className={"d-none d-lg-flex"}>
                <InputGroupAddon className="left-addon" style={{marginRight: "15px"}}
                                 addonType="prepend"><span
                    className="input-group-text"><FormattedMessage id={"subscription.modal.add.btn.label"}/></span>
                </InputGroupAddon>
                <Button className={"btn-primary-new"}
                        onClick={() => setShowModalCreateNewSubscription(true)}><FormattedMessage id={"subscription.modal.add.btn"}/></Button>
            </InputGroup>
            <FormGroup className={"d-block d-lg-none"}>
                <Label className={"d-block f-label"}><FormattedMessage id={"subscription.modal.add.btn.label"}/></Label>
                <Button className={"btn-primary-new"}
                        onClick={() => setShowModalCreateNewSubscription(true)}><FormattedMessage id={"subscription.modal.add.btn"}/></Button>
            </FormGroup>
        </div>
    );
};
AddSubscription.props = {
    onSave: PropTypes.func,
    idSubscription: PropTypes.number,
    userId: number
}
export default AddSubscription;