import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, cloneDeep } from 'lodash';
import DictionaryService from '../../../services/dictionaryService';
import Select from './Select';
import { setName } from '../../../utils/utils';

export default class SelectRegion extends Component {
  state = {
    values: [],
    value: '',
  };

  UNSAFE_componentWillMount() {
    this.setState({ value: this.props.value });
  }

  async UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ value: newProps.value });

    if (newProps.parent !== this.props.parent) {
      this.setState({
        values: [],
        value: '',
      });
    }

    if (newProps.parent) {
      const country = await DictionaryService.getGeoObjectById(newProps.parent);

      if (country.code) {
        const cities = await DictionaryService.getRegions({ code: country.code });

        const values = map(cities, (city) => {
          return { value: city.id, name: setName(city) };
        });

        this.setState({ values });
      }
    }

    if (newProps.value) {
      const city = await DictionaryService.getGeoObjectById(newProps.value);

      const value = {
        value: city.id,
        name: setName(city),
      };

      let clone = cloneDeep(this.state.values);

      if (!clone) {
        clone = [];
      }

      clone.push(value);

      if (this.state.value === '') {
        clone.pop();
      }

      const used = {};
      const prepareClone = clone.filter((obj) => {
        if (obj.value in used) {
          return 0;
        }
        used[obj.value] = 1;

        return used[obj.value];
      });

      this.setState({
        values: prepareClone,
      });
    }
  }

  async loadOptions(input) {
    let country = null;
    let cities = [];

    if (this.props.parent) {
      country = await DictionaryService.getGeoObjectById(this.props.parent);
      cities = await DictionaryService.getRegions({ code: country.code, name: input });
    }

    let clone = cloneDeep(this.state.values);

    if (!clone) {
      clone = [];
    }

    map(cities, (city) => {
      clone.push({ value: city.id, name: setName(city) });
    });

    const used = {};
    const prepareClone = clone.filter((obj) => {
      if (obj.value in used) {
        return 0;
      }
      used[obj.value] = 1;

      return used[obj.value];
    });

    this.setState({
      values: prepareClone,
    });

    return { options: this.state.values };
  }

  render() {
    const { leftAddon, error, label, onChange, disabled } = this.props;
    const { values, value } = this.state;

    return (
      <Select
        async
        searchable
        disabled={disabled}
        label={label}
        leftAddon={leftAddon}
        values={values}
        labelKey="name"
        value={value}
        error={error}
        onChange={onChange}
        loadOptions={input => this.loadOptions(input)}
        cache={false}
      />
    );
  }
}

SelectRegion.defaultProps = {
  className: '',
  classNameGroup: '',
  noResultsText: 'form.select.noResultText',
  searchable: false,
  showErrors: true,
  async: false,
  multi: false,
  //resetValue: null,
};

SelectRegion.propTypes = {
  async: PropTypes.bool, // eslint-disable-line
  multi: PropTypes.bool, // eslint-disable-line
  loadOptions: PropTypes.func, // eslint-disable-line
  className: PropTypes.string, // eslint-disable-line
  classNameGroup: PropTypes.string, // eslint-disable-line
  label: PropTypes.string, // eslint-disable-line
  placeholder: PropTypes.string, // eslint-disable-line
  note: PropTypes.string, // eslint-disable-line
  error: PropTypes.string, // eslint-disable-line
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]), // eslint-disable-line
  noResultsText: PropTypes.string, // eslint-disable-line
  code: PropTypes.string, // eslint-disable-line
  onChange: PropTypes.func, // eslint-disable-line
  optionRenderer: PropTypes.func, // eslint-disable-line
  optionComponent: PropTypes.func, // eslint-disable-line
  valueRenderer: PropTypes.func, // eslint-disable-line
  searchable: PropTypes.bool, // eslint-disable-line
  onInputChange: PropTypes.func, // eslint-disable-line
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.number]), // eslint-disable-line
  disabled: PropTypes.bool, // eslint-disable-line
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // eslint-disable-line
  rightAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // eslint-disable-line
  isButton: PropTypes.bool, // eslint-disable-line
  showErrors: PropTypes.bool, // eslint-disable-line
  parent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
