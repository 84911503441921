import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, InputGroupAddon, InputGroup, InputGroupText } from 'reactstrap';
import { uniqueId } from 'lodash';
import { Label, Error, Note } from './common';

class ViewSpan extends Component {
  state = {
    id: '',
  };

  UNSAFE_componentWillMount() {
    if (!this.props.id) {
      this.setState({
        id: uniqueId('f-element-text-'),
      });
    }
  }

  render() {
    const { note, className, label, value, name, leftAddon, rightAddon, readOnly, id, error } = this.props;

    let span = (
      <span
        readOnly={readOnly}
        value={value}
        id={id || this.state.id}
        name={name}
        className={className}
      >
        {value}
      </span>
    );

    if (leftAddon || rightAddon) {
      span = (
        <InputGroup>
          {leftAddon && <InputGroupAddon addonType="prepend"><InputGroupText>{leftAddon}</InputGroupText></InputGroupAddon>}
          {span}
          {rightAddon && <InputGroupAddon addonType="append"><InputGroupText>{rightAddon}</InputGroupText></InputGroupAddon>}
        </InputGroup>
      );
    }

    return (
      <FormGroup className={`f-element f-element-text ${error ? 'has-danger' : ''}`}>
        <Label elementId={id || this.state.id}>{label}</Label>
        {span}
        <Note>{note}</Note>
        <Error>{error}</Error>
      </FormGroup>
    );
  }
}

ViewSpan.defaultProps = {
  className: 'form-control',
  name: '',
  readOnly: false,
};

ViewSpan.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  note: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.number]),
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  readOnly: PropTypes.bool,
};

export default ViewSpan;
