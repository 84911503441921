import React from 'react';
import PropTypes from 'prop-types';
import {translate} from "../../utils/utils";
import {Button} from "reactstrap";
import FileService from "../../services/fileService";
import {FormattedMessage} from "react-intl";
import {saveAs} from "file-saver";
import FlashStore from "../../stores/flashStore";

const GenerateXlsx = ({exportXLSXApi, exportXLSXName, isEmpty}) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const generateXLSX = async (endpoint, exportXLSXName) => {
        setIsLoading(true);
        FileService.fetchFile(endpoint())
            .then(res => res.blob())
            .then((blob) => {
                setIsLoading(false);
                saveAs(blob, exportXLSXName);
            })
            .catch((err) => {
                console.error(err, 'Fetch failed');
                setIsLoading(false);
                FlashStore.errorNow(translate({ id:"service.serverError"}), true)
            });

    }
    return (
        <div>
            <Button
                disabled={isLoading || isEmpty}
                className={"btn-primary-new"}
                onClick={() => generateXLSX(exportXLSXApi, exportXLSXName)}
            >
                <div className={"d-flex justify-content-center"}>
                    {(isLoading) &&
                        <div className="fa-1x mr-2">
                            <i className="fa fa-spinner fa-spin" style={{color: '#fff'}}/>
                        </div>}
                    <FormattedMessage id="crud.edit.generateXLSX"/>
                </div>
            </Button>
        </div>
    );
};

GenerateXlsx.propTypes = {};

export default GenerateXlsx;