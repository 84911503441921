/* eslint-disable */
import React, {Component, Fragment} from 'react';
import {Modal} from "./Modal";
import {get, isEmpty, cloneDeep, isEqual, set} from 'lodash';
import {copyToBuffer, getLangInStorage, getWordDeclension, translate} from "../../utils/utils";
import {Button, Col, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";
import FontAwesome from "react-fontawesome";
import HttpProvider from '../../services/httpProvider';
import FlashStore from "../../stores/flashStore";
import {API} from "../../constants/api";
import {DOC_TYPES_MAPPINGS} from "../../constants/staticData";
import {DateInput, TextInput} from "./form-control";
import orderDeliveryService from "../../services/orderDeliveryService";
import {DATE_FORMAT} from "../../constants/global";
import AppStore from "../../stores/appStore";
import {getContainerNumber, getIndicatorById} from "../Orders/OrderCommon";
import moment from 'moment';
import PropTypes from "prop-types";
import OrderDeliveryService from "../../services/orderDeliveryService";
import CdekService from "../../services/cdekService";
import CdekTracking from "../Orders/partners/cdek/CdekTracking";
import userStore from "../../stores/userStore";
/*
Код вынесен из LogisticBlock
 */

export default class LogisticPopupModal extends Component {
    state = {
        item: this.props.item,
        trackingStatus: this.props.trackingStatus,
        mainDocument: '',
        homeDocument: '',
        indicator: this.props.indicator,
        trackingData: this.props.dataTracking,
        trackingSteps: [],
        cdekSteps:[],
        partner:null
    };


    orderedStages = [
        'pointOfPickup.ready', 'pointOfPickup.load', 'pointOfPickup.departure',
        'pointOfOrigin.arrival', 'pointOfOrigin.departure',
        'pointOfArrival.arrival', 'pointOfArrival.receiptOfDocuments', 'pointOfArrival.departure',
        'customsClearance.arrival', 'customsClearance.departure',
        'pointOfDestination.arrival',
        'deliveryToRecipientDate',
    ];

    // используется для изменения длительности плеча
    transitTimeRules   = new Map([
        ['pointOfPickup', {from:'pointOfPickup.departure', to:'pointOfOrigin.arrival', }],
        ['pointOfOrigin', {from:'pointOfOrigin.departure', to:'pointOfArrival.arrival', }],
        ['pointOfArrival', {from:'pointOfArrival.departure', to:'pointOfDestination.arrival', }],
    ]);

    updateState(newState, callback=()=>{}){
        setTimeout(
          ()=>{this.setState(newState, callback)}
        )
    }


    componentDidUpdate(prevProps) {
        if (this.props.isShow && !prevProps.isShow) {
            this.getDocuments();
            this.updateState({
                item: {
                    ...this.state.item,
                    trackingInfo: this.calcTransitTimes(this.props.item.trackingInfo),
                },
                indicator: this.props.indicator,
            });
        } else {
            if (!_.isEqual(prevProps.indicator, this.props.indicator) || !_.isEqual(prevProps.item.trackingInfo, this.props.item.trackingInfo) || !_.isEqual(prevProps.item.createDate, this.props.item.createDate)) {
                this.updateState({
                    item: {
                        ...this.props.item,
                        createDate: this.props.item.createDate,
                        trackingInfo: this.calcTransitTimes(this.props.item.trackingInfo),
                    },
                    indicator: this.props.indicator,
                })
            }
        }
    }

    componentWillMount() {
        this.getCdekStatuses()
    }

    getContainerNumber = () => {
        return getContainerNumber(this.state.item);
    };

    getCode = (items, type) => {
        const code = DOC_TYPES_MAPPINGS[type];
        if (code && items) {
            return items.filter(item => item.kind && item.kind.uid === code);
        }

        return null;
    };

    fillDocumentTitle = (docTypeitem, items, docToSave) => {
        if (docTypeitem) {
            const {typeDescription} = docTypeitem;
            const transportDoc = this.getCode(items, typeDescription);

            if (!isEmpty(transportDoc) && this.state.item.documentContainer) {
                this.updateState({
                    [docToSave]: transportDoc[0].title,
                });
            } else {
                this.updateState({
                    [docToSave]: '',
                });
            }
        }

    }

    getCdekStatuses=async()=>{
        const {id} = this.state.item;
        await OrderDeliveryService.getRequestGroup(id)
          .then((response) => {
              if (isEmpty(response.data)) {
                  return;
              }
              this.setState(prevState => {
                  // CRBR-282 определяем есть ли плечи СДЭК
                  const pathParts = get(response.data, 'orderCalculation.pathParts', []);
                  let partner = prevState?.partner;
                  pathParts.forEach(item => {
                      if (get(item, 'partner.name', '') === 'CDEK') {
                          partner = {name: 'CDEK', data: {}}; // плечи есть
                          item.services.forEach(s => {
                              const [prefix, code] = s.service.uid.split('-');
                              if (prefix === 'cdek') {
                                  set(partner, `data.id` ,response.data?.id);
                                  set(partner,`data.tariffCode`,code);
                                  set(partner,`data.tariffName`, s.service.name);
                                  set(partner,`data.serviceUi`, s.service.uid);
                                  set(partner,`data.serviceId`,s.service.id);
                                  set(partner,`data.cargoDescription `,get(response.data, 'requestDetails.cargoDescription', "Коробка"));
                              }
                          });
                      }
                  });
                  return {...prevState,  requestGroup: response.data, partner: partner}
              }, async ()=>{
                  if(get(this.state, "partner.data", false)) {
                      await this.getOrderInfo(this.state?.partner?.data);
                  }
              });
          });
    }

    getOrderInfo = async (data) => {
        CdekService.getOrderInfo(data.id, data.tariffCode).then(orderInfoResult => {
            try {
                if (orderInfoResult.code && orderInfoResult.code === 200) {
                    const getOrderResult = orderInfoResult.data;
                    /*CdekService.getEventsByUUID(getOrderResult.uuid).then(res=>{
                        if(res.code===200){
                            this.updateState({
                                cdekSteps:res.data
                            })
                        }
                    })*/
                } else {
                }
            } catch (e) {
            }
        })
    }

    getDocuments = () => {
        const {id} = this.state.item;
        const crudEndpoint = `documents/docs/${id}`;
        const {item} = this.state;

        HttpProvider.get(API.BASE(`${crudEndpoint}`), false).then(
          (response) => {
              if (!response) {
                  return;
              }

              this.fillDocumentTitle(item.documentContainer.mainDocument, response.data.items, 'mainDocument');
              this.fillDocumentTitle(item.documentContainer.homeDocument, response.data.items, 'homeDocument');
          }
        );
    };

    sendStatus = (id, status) => {
        orderDeliveryService.sendStatus(id, status)
          .then((response) => {
              if (response.status === 'success') {
                  FlashStore.successNow(translate({id: 'common.save.success'}), true);
              } else {
                  FlashStore.errorNow(translate({id: 'common.documents.error.fieldsWithoutFile'}), true);
              }
          });
    };

    onChangeStatus = (value) => {
        this.updateState({
            trackingStatus: value,
        });
    };

    /**
     * Checks if a fact date exists for a given block and direction.
     *
     * @param {string} block - The block identifier.
     * @param {string} direction - The direction identifier.
     * @param {string} type - The type of tracking info.
     * @return {boolean} - Returns true if a fact date exists, false otherwise.
     */
    isFactDateExists(block, direction, type) {
        if (type === 'plan') {
            const factDate = get(this.state.item.trackingInfo, `${block}.${direction}.fact`, null)
            if (factDate !== null) {
                if (factDate.length > 0)
                    return true
            }
        }
        return false;
    }

    canEditTransitTime(block) {
        const rule = this.transitTimeRules.get(block);
        if (rule) {
            const [toBlock, toDirection] = rule.to.split('.');
            return this.isFactDateExists(toBlock, toDirection, 'plan');
        }
        return false;
    }

    setDate = (value, block, direction, type, state) => {
        let newState;
        if (direction) {
            newState = {
                ...state,
                item: {
                    ...state.item,
                    trackingInfo: {
                        ...state.item.trackingInfo,
                        [block]: {
                            ...state.item.trackingInfo[block],
                            [direction]: {
                                ...state.item.trackingInfo[block][direction],
                                [type]: value,
                            },
                        },
                    },
                },
            }
        } else {
            if (type) {
                // double date (deliveryToRecipientDate)
                newState = {
                    ...state,
                    item: {
                        ...state.item,
                        trackingInfo: {
                            ...state.item.trackingInfo,
                            [block]: {
                                ...state.item.trackingInfo[block],
                                date: {
                                    ...state.item.trackingInfo[block].date,
                                    [type]: value
                                },
                            },
                        },
                    },
                };
            } else {
                // single date (invoiceDate, invoicePaymentDate)
                newState = {
                    ...state,
                    item: {
                        ...state.item,
                        trackingInfo: {
                            ...state.item.trackingInfo,
                            [block]: {
                                ...state.item.trackingInfo[block],
                                date: {date: value},

                            },
                        },
                    },
                };
            }
        }
        return newState;
    }

    calcTransitTimes = (trackingInfo) => {
        const result = cloneDeep(trackingInfo);
        for (const [block, directions] of this.transitTimeRules) {
            if (result[block] !== undefined) {
                const [fromBlock, fromDirection] = directions.from.split('.');
                const dateFrom = get(trackingInfo, `${fromBlock}.${fromDirection}.plan`, null);
                const [toBlock, toDirection] = directions.to.split('.');
                const dateTo = get(trackingInfo, `${toBlock}.${toDirection}.plan`, null);
                if (dateFrom && dateTo && result[block]) {
                    const delta = moment(dateTo).diff(moment(dateFrom), 'days');
                    result[block].transitTime = delta;
                } else {
                    result[block].transitTime = null;
                }
            }
        }
        return result;
    };

    onChangeTransitTime = (block,transitTime) => {
        const rule = this.transitTimeRules.get(block);
        if (rule) {
            const [toBlock, toDirection] = rule.to.split('.');
            const oldTransitTime = get(this.state.item.trackingInfo, `${block}.transitTime`, 0);
            const delta = transitTime - oldTransitTime;
            const oldPlanDate = get(this.state.item.trackingInfo, `${toBlock}.${toDirection}.plan`, null);
            const newPlanDate = moment(oldPlanDate).add(delta, 'days').format('YYYY-MM-DD');
            const newState = this.shiftPlanDates(newPlanDate, oldPlanDate, toBlock, toDirection, this.state, true);
            this.updateState(newState, this.changeDateCallback);
        }
    }

    /**
     * Shifts the plan dates for a given block and direction based on the specified new and old dates.
     *
     * @param {Date} newDate - The new date to shift to.
     * @param {Date} oldDate - The old date to shift from.
     * @param {string} block - The block identifier.
     * @param {string} direction - The direction identifier.
     * @param {object} state - The current state object.
     * @param {boolean} [includeCurrent=false] - Whether to include the current stage in the shifting.
     * @return {object} The new state object with the shifted plan dates.
     */
    shiftPlanDates(newDate, oldDate, block, direction, state, includeCurrent = false) {
        let newState = {...state};
        const stage = this.orderedStages.indexOf(`${block}.${direction}`);
        if (stage !== -1) {
            const subsequence = this.orderedStages.slice(stage + (includeCurrent ? 0 : 1));
            const delta = moment(newDate).diff(moment(oldDate), 'days');
            subsequence.forEach(s => {
                const [sBlock, sDirection] = s.split('.');
                let sDate;
                if (sDirection) {
                    sDate = state.item.trackingInfo[sBlock] && state.item.trackingInfo[sBlock][sDirection]['plan'];
                } else {
                    sDate = state.item.trackingInfo[sBlock] && state.item.trackingInfo[sBlock].date && state.item.trackingInfo[sBlock].date.plan;
                }
                if (sDate) {
                    const nDate = moment(sDate).add(delta, 'days').format('YYYY-MM-DD');
                    newState = this.setDate(nDate, sBlock, sDirection, 'plan', newState);
                }
            });
        }
        return newState;
    }

    changeDateCallback = () => {
        orderDeliveryService.sendLogisticBlock(this.state.item.id, this.state.item.trackingInfo)
          .then((response) => {
              if ((response.status === 'success')) {
                  this.props.onUpdateTrackingInfo(response.data.trackingInfo);
                  this.updateDateFields(response.data);
                  FlashStore.successNow(translate({id: 'common.save.success'}), true);
              } else {
                  FlashStore.errorNow(translate({id: 'tracking.fields.error.update'}), true);
              }
          });
    }

    onChangeDate = (value, block, direction, type) => {
        console.log("CHANGE: ", value, block, direction, type)
        if (!this.isFactDateExists(block, direction, type)) {
            let newState = {};
            if(value===undefined || value===null || value?.length===0){
                newState = this.setDate(value, block, direction, type, this.state);
                this.updateState(newState, this.changeDateCallback);
            }else {
                if (type === "fact") {
                    const dateReadyPlan = new Date(this.state.item.trackingInfo.pointOfPickup.ready?.plan);
                    const dateValue = new Date(value)
                    let delta = dateValue.getTime() - dateReadyPlan.getTime();
                    if (delta >= 0) {
                        newState = this.setDate(value, block, direction, type, this.state);
                        this.updateState(newState, this.changeDateCallback);
                    } else {
                        FlashStore.errorNow(translate({id: 'tracking.fields.error.dateFactError'}), true);
                    }

                } else {
                    newState = this.setDate(value, block, direction, type, this.state);
                    this.updateState(newState, this.changeDateCallback);
                }
                if (type === 'plan' && this.state.item.trackingInfo[block][direction][type]) {
                    newState = this.shiftPlanDates(value, this.state.item.trackingInfo[block][direction][type], block, direction, newState);
                    this.updateState(newState, this.changeDateCallback);
                }
            }
        } else {
            FlashStore.errorNow(translate({id: 'tracking.fields.error.dateFactError'}), true);
        }
    };

    onUpdateTrackingInfoShiftDate = (pointOfPickupReady, name) => {
        if(name==="pointOfPickup.ready.plan") {
            let id = this.state.item.id;
            let datePlan = new Date(pointOfPickupReady.plan);
            let orderDate = new Date(this.state.item.createDate.split(' ')[0]);
            let delta = datePlan.getTime() - orderDate.getTime();
            if (delta >= 0) {
                OrderDeliveryService.recalculateTrackingDate(id, pointOfPickupReady).then(response => {
                    if ((response.status === 'success')) {
                        FlashStore.successNow(translate({id: 'common.save.success'}), true);
                        this.props.onUpdateTrackingInfo(response.data.trackingInfo);
                    }
                });
            } else {
                FlashStore.errorNow(translate({id: 'tracking.fields.error.datePlanError'}), true);
            }
        }
        if(name==="pointOfPickup.ready.fact") {
            let id = this.state.item.id;
            let datePlan = new Date(pointOfPickupReady.plan);
            let dateFact = new Date(pointOfPickupReady.fact);
            let delta = dateFact.getTime() - datePlan.getTime();
            if (pointOfPickupReady.fact===''|| delta >= 0) {
                OrderDeliveryService.recalculateTrackingDate(id, pointOfPickupReady).then(response => {
                    if ((response.status === 'success')) {
                        FlashStore.successNow(translate({id: 'common.save.success'}), true);
                        this.props.onUpdateTrackingInfo(response.data.trackingInfo);
                    }
                });
            } else {
                FlashStore.errorNow(translate({id: 'tracking.fields.error.dateFactError'}), true);
            }
        }

    }

    onChangeSingleDate = (value, block) => {
        const newState = this.setDate(value, block, false, false, this.state);
        this.updateState(newState, this.changeDateCallback);
    };

    onChangeDoubleDate = (value, block, type) => {
        let newState = {}
        if(type==="fact"){
            const dateReadyPlan = new Date(this.state.item.trackingInfo.pointOfPickup.ready?.plan);
            const dateValue = new Date(value)
            let delta = dateValue.getTime() - dateReadyPlan.getTime();
            if(value===null || delta >= 0) {
                newState = this.setDate(value, block, false, type, this.state);
                this.updateState(newState, this.changeDateCallback);
            }else {
                FlashStore.errorNow(translate({id: 'tracking.fields.error.update'}), true);
            }

        }else{
            newState = this.setDate(value, block, false, type, this.state);
            this.updateState(newState, this.changeDateCallback);
        }

    };

    updateDateFields = (data) => {
        this.updateState({
            item: {
                ...this.state.item,
                trackingInfo: this.calcTransitTimes( data.trackingInfo )
            },
        });
    }

    disableDateRow = (block, direction) => {
        this.updateState({
            item: {
                ...this.state.item,
                trackingInfo: {
                    ...this.state.item.trackingInfo,
                    [block]: {
                        ...this.state.item.trackingInfo[block],
                        [direction]: {
                            ...this.state.item.trackingInfo[block][direction],
                            enabled: !this.state.item.trackingInfo[block][direction].enabled,
                        },
                    },
                },
            },
        }, () => {
            orderDeliveryService.sendLogisticBlock(this.state.item.id, this.state.item.trackingInfo)
              .then((response) => {
                  if ((response.status === 'success')) {
                      FlashStore.successNow(translate({id: 'common.save.success'}), true);
                  } else {
                      FlashStore.errorNow(translate({id: 'common.documents.error.fieldsWithoutFile'}), true);
                  }
              });
        });
    };

    checkIsExistFactDate = (type="") =>{
        const indexElem = this.orderedStages.findIndex(value => value===type);
        if(indexElem===-1)
            return false

        for(let i=indexElem; i< this.orderedStages.length; i++){
            let elem = this.orderedStages[i]
            let value = get(this.state.item.trackingInfo, elem, null)
            if(value!==null){
                elem += ".fact"
                value= get(this.state.item.trackingInfo, elem, null)
                if(value!==null){
                    if(value.length>0)
                        return true
                }
            }
        }
        return false
    }

    render() {
        const {isShow, onClose, isModal} = this.props;
        const {mainDocument, homeDocument} = this.state;
        const docsBlock = (<Fragment>
            <p style={{fontSize: '20px'}}><b><FormattedMessage id="common.documents"/></b></p>
            <Row>
                <Col xs={12} md={6}>
                    {this.state.item.documentContainer.mainDocument &&
                      <div style={{display: 'inline-flex'}}>
                          <p className="label" style={{minWidth: '140px'}}>
                              <b>
                                  <FormattedMessage
                                    id={`${this.state.item.documentContainer.mainDocument.typeDescription}`}/>
                              </b>
                              <br/>
                              {this.state.item.documentContainer.mainDocument ? this.state.mainDocument
                                : 'N/A '}
                          </p>
                          <FontAwesome
                            name="copy"
                            style={{marginTop: '8px', marginLeft: '10px', cursor: 'pointer'}}
                            onClick={() =>
                              copyToBuffer(mainDocument ?
                                mainDocument : 'N/A'
                              )}
                          />
                      </div>
                    }
                    {this.state.item.documentContainer.homeDocument &&
                      <div style={{display: 'inline-flex'}}>
                          <p className="label" style={{minWidth: '140px'}}>
                              <b>
                                  <FormattedMessage
                                    id={`${this.state.item.documentContainer.homeDocument.typeDescription}`}/>
                              </b>
                              <br/>
                              {this.state.item.documentContainer.homeDocument ? this.state.homeDocument : 'N/A'}
                          </p>
                          <FontAwesome
                            name="copy"
                            style={{marginTop: '8px', marginLeft: '10px', cursor: 'pointer'}}
                            onClick={() => this.copyToBuffer(homeDocument ? homeDocument : 'N/A')}/>
                      </div>
                    }
                    {/* {this.state.item.transportDocument && this.state.item.transportDocument.railwayNumber &&
                            // <div style={{ display: 'flex', alignItems: 'center' }}>
                            //   <p className="label" style={{ minWidth: '140px' }} ><b><FormattedMessage id="RAILWAY" /></b><br />{this.state.item.transportDocument.railwayNumber}</p>
                            //   <FontAwesome name="copy" style={{ marginTop: '8px', marginLeft: '10px', cursor: 'pointer' }} onClick={() => this.copyToBuffer(this.state.item.transportDocument.railwayNumber)} />
                            // </div>} */}
                </Col>
                {(this.getContainerNumber() !== 'N/A') &&
                  <Col xs={12} md={6}>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                          <p className="label" style={{minWidth: '140px'}}><b><FormattedMessage
                            id="orderForm.numberOfFirstContainer"/></b><br/>{this.getContainerNumber()}</p>
                          <FontAwesome name="copy" style={{marginTop: '8px', marginLeft: '10px', cursor: 'pointer'}}
                                       onClick={() => this.copyToBuffer(this.getContainerNumber())}/>
                      </div>
                  </Col>
                }
            </Row>
        </Fragment>);

        const trackingStatusBlock = (<Fragment>
            <Row>
                <Col xs={12} md={12} className={"px-0"}>
                    <div className={"tracking-header"}>
                        <p><FormattedMessage id="orderForm.currentStatus"/></p>
                        <TextInput
                          placeholder="orderForm.currentStatus"
                          onChange={ev => this.onChangeStatus(ev.target.value)}
                          value={this.state.trackingStatus} // пусть в trackingDetails приходит хотя пустая строка для статуса
                          type="text"
                          onBlur={() => this.sendStatus(this.state.item.id, this.state.trackingStatus)}
                        />
                    </div>
                </Col>
            </Row>
        </Fragment>);

        // заголовок для каждого блока в форме
        const trackingBlockTitle = (title, label1, label2) => (
          <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap'
          }}>
              <p className="tracking-block-title">
                  {title}
              </p>
              <div className="tracking-item-date">
                  <div className="input-group tracking-block-label">{label1}</div>
              </div>
              <div className="tracking-item-date">
                  <div className="input-group tracking-block-label">{label2}</div>
              </div>
              <span className="fa"></span>
          </div>);



        const trackingInfoPath = (steps = {type: 0, isCurrent: false, isArchive: false, isLast: false}) => {
            const icons = [
                {type: 0, icon: "point"},
                {type: STAGES.pointOfPickup, icon: "point"},
                {type: STAGES.invoicePaymentDate, icon: "payment"},
                {type: STAGES.invoiceDate, icon: "payment"},
                {type: STAGES.pointOfOrigin, icon: "point"},
                {type: STAGES.pointOfArrival, icon: "point"},
                {type: STAGES.customsClearance, icon: "point"},
                {type: STAGES.pointOfDestination, icon: "point"},
                {type: STAGES.deliveryToRecipientDate, icon: "calendar"}
            ]
            return (<div className="tracking-modal-row__icon-container">
                  <div className={steps.isCurrent === true ? 'icon current' : 'icon'}>
                      <div className={'icon-data'}>
                          <div
                            className={steps.isArchive === true ? `${icons.find(value => value.type === steps.type).icon} active` : `${icons.find(value => value.type === steps.type).icon}`}></div>
                      </div>
                  </div>
                  {steps.isLast === false &&
                    <div className={steps.isArchive === true ? 'bottom-line active' : 'bottom-line'}></div>
                  }
              </div>
            )
        }



        const STAGES = {
            pointOfPickup: 1,
            invoicePaymentDate: 2,
            invoiceDate: 2,
            pointOfOrigin: 3,
            pointOfArrival: 4,
            customsClearance: 5,
            pointOfDestination: 6,
            deliveryToRecipientDate: 7
        }

        const prepareArraySteps = () => {
            let result = []
            if (this.state.item.trackingInfo) {
                if (this.state.item.trackingInfo.pointOfPickup) {
                    let item = {
                        id: STAGES.pointOfPickup,
                        value: false
                    }
                    if (this.state.item.trackingInfo.pointOfPickup.departure && this.state.item.trackingInfo.pointOfPickup.departure.fact) {
                        item.value = true
                    }
                    result.push(item)
                }
                if (this.state.item.trackingInfo.invoicePaymentDate) {
                    let item = {
                        id: STAGES.invoicePaymentDate,
                        value: false
                    }
                    if (((this.state.item.trackingInfo.invoicePaymentDate.date && this.state.item.trackingInfo.invoicePaymentDate.date.date)
                      ? this.state.item.trackingInfo.invoicePaymentDate.date.date : 0) !== 0
                    ) {
                        item.value = true
                    }
                    result.push(item)
                }
                if (this.state.item.trackingInfo.pointOfOrigin) {
                    let item = {
                        id: STAGES.pointOfOrigin,
                        value: false
                    }
                    if (this.state.item.trackingInfo.pointOfOrigin.departure.fact) {
                        item.value = true
                    }
                    result.push(item)
                }
                if (this.state.item.trackingInfo.pointOfArrival) {
                    let item = {
                        id: STAGES.pointOfArrival,
                        value: false
                    }
                    if (this.state.item.trackingInfo.pointOfArrival.departure.fact)
                        item.value = true
                    result.push(item)
                }
                if (this.state.item.trackingInfo.customsClearance) {
                    let item = {
                        id: STAGES.customsClearance,
                        value: false
                    }
                    if (this.state.item.trackingInfo.customsClearance.departure.fact)
                        item.value = true
                    result.push(item)
                }
                if (this.state.item.trackingInfo.pointOfDestination) {
                    let item = {
                        id: STAGES.pointOfDestination,
                        value: false
                    }
                    if (this.state.item.trackingInfo.pointOfDestination.arrival.fact)
                        item.value = true
                    result.push(item)
                }
                if (this.state.item.trackingInfo.deliveryToRecipientDate) {
                    let item = {
                        id: STAGES.deliveryToRecipientDate,
                        value: false
                    }
                    if (this.state.item.trackingInfo.deliveryToRecipientDate.date && this.state.item.trackingInfo.deliveryToRecipientDate.date.fact)
                        item.value = true
                    result.push(item)
                }
            }
            return result
        }

        const prepareTrackingSteps = (type) => {
            const stepsArray = prepareArraySteps()
            let result = {
                type: 0,
                isCurrent: false,
                isArchive: false,
                isLast: false
            }
            result.type = type

            const currItem = stepsArray.find(value => value.id === type)

            if (currItem === undefined || currItem === null)
                return result

            const indexCurrItem = stepsArray.findIndex(value => value.id === type)

            if (indexCurrItem === stepsArray.length - 1) {
                result.isLast = true
            }

            for (let i = 0; i < indexCurrItem; i++) {
                if (stepsArray[i].value === false)
                    return result
            }

            if (currItem.value === true) {
                if (indexCurrItem > 0) {
                    if (stepsArray[indexCurrItem - 1].value === true) {
                        result.isArchive = true
                    }
                } else {
                    result.isArchive = true
                }
            } else {
                if (indexCurrItem > 0) {
                    if (stepsArray[indexCurrItem - 1].value === true) {
                        result.isCurrent = true
                    }
                } else {
                    result.isCurrent = true
                }
            }

            return result
        }

        const getStatus = (stepData) => {
            let statusStyle = "status" + getClassName4Step(stepData)
            if (stepData.isCurrent === true)
                return (
                  <div className={statusStyle}><FormattedMessage id={"common.status.current"}/></div>
                )
            if(stepData.isArchive === true)
                return (
                  <div className={statusStyle}><FormattedMessage id={"common.status.completed"}/></div>
                )
            return (<div className={"no-status"}></div>)
        }

        const getClassName4Step = (stepData) => {
            if (stepData.isCurrent === true)
                return " current"
            if (stepData.isArchive === true)
                return " completed"
        }

        const trackingInfoBlock = () => {

            const pointOfPickupStep = prepareTrackingSteps(STAGES.pointOfPickup)
            const invoicePaymentDate = prepareTrackingSteps(STAGES.invoicePaymentDate)
            const pointOfOrigin = prepareTrackingSteps(STAGES.pointOfOrigin)
            const pointOfArrival = prepareTrackingSteps(STAGES.pointOfArrival)
            const customsClearance = prepareTrackingSteps(STAGES.customsClearance)
            const pointOfDestination = prepareTrackingSteps(STAGES.pointOfDestination)
            const deliveryToRecipientDate = prepareTrackingSteps(STAGES.deliveryToRecipientDate)

            /*if (userStore.isAdmin() && this.state.partner !==null && this.state.partner.name==="CDEK")
                return (
                  <CdekTracking cdekSteps={this.state.cdekSteps} />
                )*/

            return (<Fragment>
                  <div className="tracking-modal-container">
                      {/* pointOfPickup */}
                      {this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup &&
                        <div className="tracking-modal-row">
                            {trackingInfoPath(pointOfPickupStep)}
                            <div className="tracking-modal-row__item">
                                <div className="tracking-modal-row__title">
                                    {this.state.item.trackingInfo.pointOfPickup.name ? this.state.item.trackingInfo.pointOfPickup.name[getLangInStorage()] : ''}
                                </div>
                                <div className={"card-item " + getClassName4Step(pointOfPickupStep)}>
                                    <Row>
                                        <Col xs={12} className={"px-0"}>
                                            <div>{getStatus(pointOfPickupStep)}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <Row className={"header-date-columns d-none d-md-flex"}>
                                                <Col xs={2} md={3} className={"pr-md-0"}></Col>
                                                <Col xs={4} md={4} className={"pr-md-0"}><FormattedMessage id={"logistBlock.plan"}/></Col>
                                                <Col xs={4} md={4} className={"pr-md-0"}><FormattedMessage id={"logistBlock.fact"}/></Col>
                                                <Col xs={2} md={1}></Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"} style={{lineHeight:"24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                          id="logistBlock.cargoReadyDate"/></span>
                                                    <div className={"actions d-flex d-md-none ml-auto"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.ready.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfPickup', 'ready')}/>
                                                        <FontAwesome
                                                          name={(!this.checkIsExistFactDate("pointOfPickup.ready") && (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.ready.enabled)) ? 'eraser' : 'eraser disabled'}
                                                          onClick={() => {
                                                              if (!this.checkIsExistFactDate("pointOfPickup.ready") && (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.ready.enabled)) {
                                                                  this.onChangeDate("", 'pointOfPickup', 'ready', 'fact');
                                                              }
                                                          }}/>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.plan"}/>
                                                    </div>
                                                    <DateInput
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfPickup.ready.plan : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={(this.checkIsExistFactDate("pointOfPickup.ready") || !(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.ready.enabled))}
                                                      readOnly
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => {
                                                          /*console.log("IS EXISTS: ", this.checkIsExistFactDate("pointOfPickup.ready"))*/
                                                          /*this.onChangeDate(value, 'pointOfPickup', 'ready', 'plan')*/
                                                          if(value)
                                                              this.onUpdateTrackingInfoShiftDate({
                                                                  fact: this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfPickup.ready.fact : '',
                                                                  plan: value ? value : ''
                                                              }, "pointOfPickup.ready.plan")
                                                      }}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.fact"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfPickup.ready.fact : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={!(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.ready.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value =>{
                                                          //this.onChangeDate(value, 'pointOfPickup', 'ready', 'fact')
                                                          this.onUpdateTrackingInfoShiftDate({
                                                              fact: value ? value : '',
                                                              plan: this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfPickup.ready.plan : ''
                                                          }, "pointOfPickup.ready.fact")
                                                      }}
                                                      error={getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.ready', 1) ? '-' : ''}
                                                      note={getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.ready', 2) ? '-' : ''}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                    <div className={"actions"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.ready.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfPickup', 'ready')}/>
                                                        {/*<FontAwesome
                                                                name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.ready.enabled) ? 'eraser' : 'eraser disabled'}
                                                                onClick={() => {
                                                                    if (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.ready.enabled === true) {
                                                                        this.onChangeDate("", 'pointOfPickup', 'ready', 'fact');
                                                                        this.onChangeDate("", 'pointOfPickup', 'ready', 'plan');
                                                                    }
                                                                }}/>*/}
                                                    </div>
                                                </Col>
                                                <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"} style={{lineHeight:"24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                          id="logistBlock.loadingDate"/></span>
                                                    <div className={"actions d-flex d-md-none ml-auto"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.load.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfPickup', 'load')}/>
                                                        <FontAwesome
                                                          name={(!this.checkIsExistFactDate("pointOfPickup.load") &&(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.load.enabled)) ? 'eraser' : 'eraser disabled'}
                                                          onClick={() => {
                                                              if(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.load.enabled){
                                                                  this.onChangeDate("", 'pointOfPickup', 'load', 'fact');
                                                              }
                                                              if (!this.checkIsExistFactDate("pointOfPickup.load") && (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.load.enabled)) {
                                                                  this.onChangeDate("", 'pointOfPickup', 'load', 'plan');
                                                              }
                                                          }}/>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.plan"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfPickup.load.plan : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={this.checkIsExistFactDate("pointOfPickup.load") || !(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.load.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'pointOfPickup', 'load', 'plan')}
                                                      //error={errors.dateFrom}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.fact"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfPickup.load.fact : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={!(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.load.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'pointOfPickup', 'load', 'fact')}
                                                      error={getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.load', 1) ? '-' : ''}
                                                      note={getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.load', 2) ? '-' : ''}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                    <div className={"actions"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.load.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfPickup', 'load')}/>
                                                        {/* <FontAwesome
                                                                name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.load.enabled) ? 'eraser' : 'eraser disabled'}
                                                                onClick={() => {
                                                                    if (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.load.enabled === true) {
                                                                        this.onChangeDate("", 'pointOfPickup', 'load', 'fact');
                                                                        this.onChangeDate("", 'pointOfPickup', 'load', 'plan');
                                                                    }
                                                                }}/>*/}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"} style={{lineHeight:"24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                          id="logistBlock.departureDate"/></span>
                                                    <div className={"actions d-flex d-md-none ml-auto"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.departure.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfPickup', 'departure')}/>
                                                        <FontAwesome
                                                          name={(!this.checkIsExistFactDate("pointOfPickup.departure")&&(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.departure.enabled)) ? 'eraser' : 'eraser disabled'}
                                                          onClick={() => {
                                                              if (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.departure.enabled === true) {
                                                                  this.onChangeDate("", 'pointOfPickup', 'departure', 'fact');
                                                              }
                                                              if(!this.checkIsExistFactDate("pointOfPickup.departure") && this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.departure.enabled === true){
                                                                  this.onChangeDate("", 'pointOfPickup', 'departure', 'plan');
                                                              }
                                                          }}/>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.plan"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfPickup.departure.plan : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={this.checkIsExistFactDate("pointOfPickup.departure") || !(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.departure.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'pointOfPickup', 'departure', 'plan')}
                                                      //error={errors.dateFrom}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.fact"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfPickup.departure.fact : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={!(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.departure.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'pointOfPickup', 'departure', 'fact')}
                                                      error={getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.departure', 1) ? '-' : ''}
                                                      note={getIndicatorById(this.state.indicator, 'indicator.pointOfPickup.departure', 2) ? '-' : ''}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                    <div className={"actions"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.departure.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfPickup', 'departure')}/>
                                                        {/*<FontAwesome
                                                                name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.departure.enabled) ? 'eraser' : 'eraser disabled'}
                                                                onClick={() => {
                                                                    if (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfPickup.departure.enabled === true) {
                                                                        this.onChangeDate("", 'pointOfPickup', 'departure', 'fact');
                                                                        this.onChangeDate("", 'pointOfPickup', 'departure', 'plan');
                                                                    }
                                                                }}/>*/}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                      }

                      {/* ---------------------------------   */}

                      {/* invoice */}
                      {this.state.item.trackingInfo
                        && (this.state.item.trackingInfo.invoiceDate || this.state.item.trackingInfo.invoicePaymentDate)
                        &&
                        <div className="tracking-modal-row">
                            {trackingInfoPath(invoicePaymentDate)}
                            <div className="tracking-modal-row__item">
                                <div className="tracking-modal-row__title">
                                    <FormattedMessage id={"logistBlock.invoice"}/>
                                </div>
                                <div className={"card-item " + getClassName4Step(invoicePaymentDate)}>
                                    <Row>
                                        <Col xs={12} className={"px-0"}>
                                            <div>{getStatus(invoicePaymentDate)}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <Row className={"header-date-columns d-none d-md-flex"}>
                                                <Col xs={2} md={3} className={"pr-md-0"}></Col>
                                                <Col xs={4} md={4} className={"pr-md-0"}>
                                                    <FormattedMessage id={"logistBlock.invoiceDate"}/>
                                                </Col>
                                                <Col xs={4} md={4} className={"pr-md-0"}>
                                                    <FormattedMessage id={"logistBlock.invoicePaymentDate"}/>
                                                </Col>
                                                <Col xs={2} md={1}></Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"} style={{lineHeight:"24px"}}>
                                                        <span className={"mobile-header"}>{/*<FormattedMessage
                                                            id="logistBlock.cargoReadyDate"/>*/}</span>
                                                    <div className={"actions d-flex d-md-none ml-auto"}>
                                                        <FontAwesome
                                                          name={'eraser'}
                                                          onClick={() => {
                                                              this.onChangeSingleDate('', 'invoiceDate');
                                                              this.onChangeSingleDate('', 'invoicePaymentDate');
                                                          }}/>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.invoiceDate"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo
                                                        ? ((this.state.item.trackingInfo.invoiceDate.date && this.state.item.trackingInfo.invoiceDate.date.date)
                                                          ? this.state.item.trackingInfo.invoiceDate.date.date
                                                          : '')
                                                        : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={false}
                                                      onChange={value => this.onChangeSingleDate(value, 'invoiceDate')}
                                                      error={getIndicatorById(this.state.indicator, 'indicator.invoiceDate', 1) ? '-' : ''}
                                                      note={getIndicatorById(this.state.indicator, 'indicator.invoiceDate', 2) ? '-' : ''}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.invoicePaymentDate"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo
                                                        ? ((this.state.item.trackingInfo.invoicePaymentDate.date && this.state.item.trackingInfo.invoicePaymentDate.date.date)
                                                          ? this.state.item.trackingInfo.invoicePaymentDate.date.date
                                                          : '')
                                                        : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={false}
                                                      onChange={value => this.onChangeSingleDate(value, 'invoicePaymentDate')}
                                                      error={getIndicatorById(this.state.indicator, 'indicator.invoicePaymentDate', 1) ? '-' : ''}
                                                      note={getIndicatorById(this.state.indicator, 'indicator.invoicePaymentDate', 2) ? '-' : ''}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                    <div className={"actions"}>
                                                        {/*<FontAwesome
                                                                name={'eraser'}
                                                                onClick={() => {
                                                                    this.onChangeSingleDate("", 'invoiceDate');
                                                                    this.onChangeSingleDate("", 'invoicePaymentDate');
                                                                }}/>*/}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                      }


                      {/* ---------------------------------   */}

                      {/* pointOfOrigin */}
                      {this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin &&
                        <div className="tracking-modal-row">
                            {trackingInfoPath(pointOfOrigin)}
                            <div className="tracking-modal-row__item">
                                <div className="tracking-modal-row__title">
                                    {this.state.item.trackingInfo.pointOfOrigin.name ? this.state.item.trackingInfo.pointOfOrigin.name[getLangInStorage()] : ''}
                                </div>
                                <div className={"card-item " + getClassName4Step(pointOfOrigin)}>
                                    <Row>
                                        <Col xs={12} className={"px-0"}>
                                            <div>{getStatus(pointOfOrigin)}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <Row className={"header-date-columns d-none d-md-flex"}>
                                                <Col xs={2} md={3} className={"pr-md-0"}></Col>
                                                <Col xs={4} md={4} className={"pr-md-0"}><FormattedMessage id={"logistBlock.plan"}/></Col>
                                                <Col xs={4} md={4} className={"pr-md-0"}><FormattedMessage id={"logistBlock.fact"}/></Col>
                                                <Col xs={2} md={1} className={"pr-md-0"}></Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"} style={{lineHeight:"24px"}}>
                                                        <span className={"mobile-header"}>
                                                            <FormattedMessage id="logistBlock.arrivalDate"/>
                                                        </span>
                                                    <div className={"actions d-flex d-md-none ml-auto"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.arrival.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfOrigin', 'arrival')}/>
                                                        <FontAwesome
                                                          name={(!this.checkIsExistFactDate("pointOfOrigin.arrival") && (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.arrival.enabled)) ? 'eraser' : 'eraser disabled'}
                                                          onClick={() => {
                                                              if (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.arrival.enabled === true) {
                                                                  this.onChangeDate("", 'pointOfOrigin', 'arrival', 'fact');
                                                              }
                                                              if(!this.checkIsExistFactDate("pointOfOrigin.arrival") && this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.arrival.enabled === true){
                                                                  this.onChangeDate("", 'pointOfOrigin', 'arrival', 'plan');
                                                              }
                                                          }}/>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.plan"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfOrigin.arrival.plan : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={this.checkIsExistFactDate("pointOfOrigin.arrival") || !(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.arrival.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value =>{
                                                          this.onChangeDate(value, 'pointOfOrigin', 'arrival', 'plan');
                                                      }}
                                                      //error={errors.dateFrom}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.fact"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfOrigin.arrival.fact : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={!(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.arrival.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'pointOfOrigin', 'arrival', 'fact')}
                                                      error={getIndicatorById(this.state.indicator, 'indicator.pointOfOrigin.arrival', 1) ? '-' : ''}
                                                      note={getIndicatorById(this.state.indicator, 'indicator.pointOfOrigin.arrival', 2) ? '-' : ''}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                    <div className={"actions"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.arrival.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfOrigin', 'arrival')}/>
                                                        {/*<FontAwesome
                                                                name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.arrival.enabled) ? 'eraser' : 'eraser disabled'}
                                                                onClick={() => {
                                                                    if (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.arrival.enabled === true) {
                                                                        this.onChangeDate("", 'pointOfOrigin', 'arrival', 'fact');
                                                                        this.onChangeDate("", 'pointOfOrigin', 'arrival', 'plan');
                                                                    }
                                                                }}/>*/}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"} style={{lineHeight:"24px"}}>
                                                        <span className={"mobile-header"}>
                                                            <FormattedMessage id="logistBlock.departureDate"/>
                                                        </span>
                                                    <div className={"actions d-flex d-md-none ml-auto"}>
                                                        <FontAwesome
                                                          name={( (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.departure.enabled)) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfOrigin', 'departure')}/>
                                                        <FontAwesome
                                                          name={(!this.checkIsExistFactDate("pointOfOrigin.departure") &&this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.departure.enabled) ? 'eraser' : 'eraser disabled'}
                                                          onClick={() => {
                                                              if (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.departure.enabled === true) {
                                                                  this.onChangeDate("", 'pointOfOrigin', 'departure', 'fact');

                                                              }
                                                              if(!this.checkIsExistFactDate("pointOfOrigin.departure") && this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.departure.enabled === true){
                                                                  this.onChangeDate("", 'pointOfOrigin', 'departure', 'plan');
                                                              }
                                                          }}/>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.plan"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfOrigin.departure.plan : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={this.checkIsExistFactDate("pointOfOrigin.departure") ||!(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.departure.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'pointOfOrigin', 'departure', 'plan')}
                                                      //error={errors.dateFrom}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.fact"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfOrigin.departure.fact : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={!(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.departure.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'pointOfOrigin', 'departure', 'fact')}
                                                      error={getIndicatorById(this.state.indicator, 'indicator.pointOfOrigin.departure', 1) ? '-' : ''}
                                                      note={getIndicatorById(this.state.indicator, 'indicator.pointOfOrigin.departure', 2) ? '-' : ''}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                    <div className={"actions"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.departure.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfOrigin', 'departure')}/>
                                                        {/* <FontAwesome
                                                                name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.departure.enabled) ? 'eraser' : 'eraser disabled'}
                                                                onClick={() => {
                                                                    if (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfOrigin.departure.enabled === true) {
                                                                        this.onChangeDate("", 'pointOfOrigin', 'departure', 'fact');
                                                                        this.onChangeDate("", 'pointOfOrigin', 'departure', 'plan');
                                                                    }
                                                                }}/>*/}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                      }

                      {/* pointOfArrival */}
                      {this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival &&
                        <div className="tracking-modal-row">
                            {trackingInfoPath(pointOfArrival)}
                            <div className="tracking-modal-row__item">
                                <div className="tracking-modal-row__title">
                                    {this.state.item.trackingInfo.pointOfArrival.name ? this.state.item.trackingInfo.pointOfArrival.name[getLangInStorage()] : ''}
                                </div>
                                <div className={"card-item " + getClassName4Step(pointOfArrival)}>
                                    <Row>
                                        <Col xs={12} className={"px-0"}>
                                            <div>{getStatus(pointOfArrival)}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <Row className={"header-date-columns d-none d-md-flex"}>
                                                <Col xs={2} md={3} className={"pr-md-0"}></Col>
                                                <Col xs={4} md={4} className={"pr-md-0"}><FormattedMessage id={"logistBlock.plan"}/></Col>
                                                <Col xs={4} md={4} className={"pr-md-0"}><FormattedMessage id={"logistBlock.fact"}/></Col>
                                                <Col xs={2} md={1} className={"pr-md-0"}></Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"} style={{lineHeight:"24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                          id="logistBlock.arrivalDate"/></span>
                                                    <div className={"actions d-flex d-md-none ml-auto"}>
                                                        <FontAwesome
                                                          name={( (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.arrival.enabled)) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfArrival', 'arrival')}/>
                                                        <FontAwesome
                                                          name={(!this.checkIsExistFactDate("pointOfArrival.arrival") &&this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.arrival.enabled) ? 'eraser' : 'eraser disabled'}
                                                          onClick={() => {
                                                              if (!this.checkIsExistFactDate("pointOfArrival.arrival") && this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.arrival.enabled === true) {
                                                                  this.onChangeDate("", 'pointOfArrival', 'arrival', 'plan');
                                                              }
                                                              if(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.arrival.enabled === true){
                                                                  this.onChangeDate("", 'pointOfArrival', 'arrival', 'fact');
                                                              }
                                                          }}/>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.plan"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfArrival.arrival.plan : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={this.checkIsExistFactDate("pointOfArrival.arrival") ||!(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.arrival.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'pointOfArrival', 'arrival', 'plan')}
                                                      //error={errors.dateFrom}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.fact"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfArrival.arrival.fact : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={!(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.arrival.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'pointOfArrival', 'arrival', 'fact')}
                                                      error={getIndicatorById(this.state.indicator, 'indicator.pointOfArrival.arrival', 1) ? '-' : ''}
                                                      note={getIndicatorById(this.state.indicator, 'indicator.pointOfArrival.arrival', 2) ? '-' : ''}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                    <div className={"actions"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.arrival.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfArrival', 'arrival')}/>
                                                        {/*<FontAwesome
                                                                name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.arrival.enabled) ? 'eraser' : 'eraser disabled'}
                                                                onClick={() => {
                                                                    if (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.arrival.enabled === true) {
                                                                        this.onChangeDate("", 'pointOfArrival', 'arrival', 'fact');
                                                                        this.onChangeDate("", 'pointOfArrival', 'arrival', 'plan');
                                                                    }
                                                                }}/>*/}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                                     style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}>
                                                            <FormattedMessage id="logistBlock.documentsDate"/>
                                                        </span>
                                                    <div className={"actions d-flex d-md-none ml-auto"}>
                                                        <FontAwesome
                                                          name={((this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.receiptOfDocuments.enabled)) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfArrival', 'receiptOfDocuments')}/>
                                                        <FontAwesome
                                                          name={(!this.checkIsExistFactDate("pointOfArrival.receiptOfDocuments")&&this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.receiptOfDocuments.enabled) ? 'eraser' : 'eraser disabled'}
                                                          onClick={() => {
                                                              if (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.receiptOfDocuments.enabled === true) {
                                                                  this.onChangeDate("", 'pointOfArrival', 'receiptOfDocuments', 'fact');
                                                              }
                                                              if(!this.checkIsExistFactDate("pointOfArrival.receiptOfDocuments") && this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.receiptOfDocuments.enabled === true){
                                                                  this.onChangeDate("", 'pointOfArrival', 'receiptOfDocuments', 'plan');
                                                              }
                                                          }}/>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.plan"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfArrival.receiptOfDocuments.plan : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={this.checkIsExistFactDate("pointOfArrival.receiptOfDocuments") ||!(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.receiptOfDocuments.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'pointOfArrival', 'receiptOfDocuments', 'plan')}
                                                      //error={errors.dateFrom}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.fact"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfArrival.receiptOfDocuments.fact : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={!(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.receiptOfDocuments.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'pointOfArrival', 'receiptOfDocuments', 'fact')}
                                                      error={getIndicatorById(this.state.indicator, 'indicator.pointOfArrival.arrival', 1) ? '-' : ''}
                                                      note={getIndicatorById(this.state.indicator, 'indicator.pointOfArrival.arrival', 2) ? '-' : ''}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                    <div className={"actions"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.receiptOfDocuments.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfArrival', 'receiptOfDocuments')}/>
                                                        {/*<FontAwesome
                                                                name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.receiptOfDocuments.enabled) ? 'eraser' : 'eraser disabled'}
                                                                onClick={() => {
                                                                    if (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.receiptOfDocuments.enabled === true) {
                                                                        this.onChangeDate("", 'pointOfArrival', 'receiptOfDocuments', 'fact');
                                                                        this.onChangeDate("", 'pointOfArrival', 'receiptOfDocuments', 'plan');
                                                                    }
                                                                }}/>*/}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"} style={{lineHeight:"24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                          id="logistBlock.export"/></span>
                                                    <div className={"actions d-flex d-md-none ml-auto"}>
                                                        <FontAwesome
                                                          name={((this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.departure.enabled)) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfArrival', 'departure')}/>
                                                        <FontAwesome
                                                          name={(!this.checkIsExistFactDate("pointOfArrival.departure")&&this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.departure.enabled) ? 'eraser' : 'eraser disabled'}
                                                          onClick={() => {
                                                              if (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.departure.enabled === true) {
                                                                  this.onChangeDate("", 'pointOfArrival', 'departure', 'fact');
                                                              }
                                                              if(!this.checkIsExistFactDate("pointOfArrival.departure") && this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.departure.enabled === true){
                                                                  this.onChangeDate("", 'pointOfArrival', 'departure', 'plan');
                                                              }
                                                          }}/>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.plan"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfArrival.departure.plan : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={this.checkIsExistFactDate("pointOfArrival.departure") || !(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.departure.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'pointOfArrival', 'departure', 'plan')}
                                                      //error={errors.dateFrom}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.fact"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfArrival.departure.fact : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={!(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.departure.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'pointOfArrival', 'departure', 'fact')}
                                                      error={getIndicatorById(this.state.indicator, 'indicator.pointOfArrival.departure', 1) ? '-' : ''}
                                                      note={getIndicatorById(this.state.indicator, 'indicator.pointOfArrival.departure', 2) ? '-' : ''}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                    <div className={"actions"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.departure.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfArrival', 'departure')}/>
                                                        {/*<FontAwesome
                                                                name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.departure.enabled) ? 'eraser' : 'eraser disabled'}
                                                                onClick={() => {
                                                                    if (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfArrival.departure.enabled === true) {
                                                                        this.onChangeDate("", 'pointOfArrival', 'departure', 'fact');
                                                                        this.onChangeDate("", 'pointOfArrival', 'departure', 'plan');
                                                                    }
                                                                }}/>*/}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                      }

                      {/* customsClearance */}
                      {this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance &&
                        <div className="tracking-modal-row">
                            {trackingInfoPath(customsClearance)}
                            <div className="tracking-modal-row__item">
                                <div className="tracking-modal-row__title">
                                    <FormattedMessage id="form.field.customsClearance"/>
                                </div>
                                <div className={"card-item " + getClassName4Step(customsClearance)}>
                                    <Row>
                                        <Col xs={12} className={"px-0"}>
                                            <div>{getStatus(customsClearance)}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <Row className={"header-date-columns d-none d-md-flex"}>
                                                <Col xs={2} md={3} className={"pr-md-0"}></Col>
                                                <Col xs={4} md={4} className={"pr-md-0"}><FormattedMessage id={"logistBlock.plan"}/></Col>
                                                <Col xs={4} md={4} className={"pr-md-0"}><FormattedMessage id={"logistBlock.fact"}/></Col>
                                                <Col xs={2} md={1}></Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"} style={{lineHeight:"24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                          id="logistBlock.arrivalOrInnings"/></span>
                                                    <div className={"actions d-flex d-md-none ml-auto"}>
                                                        <FontAwesome
                                                          name={((this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.arrival.enabled)) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('customsClearance', 'arrival')}/>
                                                        <FontAwesome
                                                          name={(!this.checkIsExistFactDate("customsClearance.arrival") && this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.arrival.enabled) ? 'eraser' : 'eraser disabled'}
                                                          onClick={() => {
                                                              if (this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.arrival.enabled === true) {
                                                                  this.onChangeDate("", 'customsClearance', 'arrival', 'fact');
                                                              }
                                                              if(!this.checkIsExistFactDate("customsClearance.arrival") && this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.arrival.enabled === true){
                                                                  this.onChangeDate("", 'customsClearance', 'arrival', 'plan');
                                                              }
                                                          }}/>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.plan"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.customsClearance.arrival.plan : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={this.checkIsExistFactDate("customsClearance.arrival") ||!(this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.arrival.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'customsClearance', 'arrival', 'plan')}
                                                      //error={errors.dateFrom}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.fact"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.customsClearance.arrival.fact : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={!(this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.arrival.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'customsClearance', 'arrival', 'fact')}
                                                      error={getIndicatorById(this.state.indicator, 'indicator.customsClearance.arrival', 1) ? '-' : ''}
                                                      note={getIndicatorById(this.state.indicator, 'indicator.customsClearance.arrival', 2) ? '-' : ''}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                    <div className={"actions"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.arrival.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('customsClearance', 'arrival')}/>
                                                        {/* <FontAwesome
                                                                name={(this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.arrival.enabled) ? 'eraser' : 'eraser disabled'}
                                                                onClick={() => {
                                                                    if (this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.arrival.enabled === true) {
                                                                        this.onChangeDate("", 'customsClearance', 'arrival', 'fact');
                                                                        this.onChangeDate("", 'customsClearance', 'arrival', 'plan');
                                                                    }
                                                                }}/>*/}
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"} style={{lineHeight:"24px"}}>
                                                        <span className={"mobile-header"}>
                                                            <FormattedMessage id="logistBlock.departureOrIssueDate"/>
                                                        </span>
                                                    <div className={"actions d-flex d-md-none ml-auto"}>
                                                        <FontAwesome
                                                          name={((this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.departure.enabled)) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('customsClearance', 'departure')}/>
                                                        <FontAwesome
                                                          name={(!this.checkIsExistFactDate("customsClearance.departure") && (this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.departure.enabled)) ? 'eraser' : 'eraser disabled'}
                                                          onClick={() => {
                                                              if (this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.departure.enabled === true) {
                                                                  this.onChangeDate("", 'customsClearance', 'departure', 'fact');
                                                              }
                                                              if(!this.checkIsExistFactDate("customsClearance.departure") && this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.departure.enabled === true){
                                                                  this.onChangeDate("", 'customsClearance', 'departure', 'plan');
                                                              }
                                                          }}/>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.plan"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.customsClearance.departure.plan : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={this.checkIsExistFactDate("customsClearance.departure") ||!(this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.departure.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'customsClearance', 'departure', 'plan')}
                                                      //error={errors.dateFrom}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.fact"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.customsClearance.departure.fact : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={!(this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.departure.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'customsClearance', 'departure', 'fact')}
                                                      error={getIndicatorById(this.state.indicator, 'indicator.customsClearance.departure', 1) ? '-' : ''}
                                                      note={getIndicatorById(this.state.indicator, 'indicator.customsClearance.departure', 2) ? '-' : ''}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                    <div className={"actions"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.departure.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('customsClearance', 'departure')}/>
                                                        {/*<FontAwesome
                                                                name={(this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.departure.enabled) ? 'eraser' : 'eraser disabled'}
                                                                onClick={() => {
                                                                    if (this.state.item.trackingInfo && this.state.item.trackingInfo.customsClearance.departure.enabled === true) {
                                                                        this.onChangeDate("", 'customsClearance', 'departure', 'fact');
                                                                        this.onChangeDate("", 'customsClearance', 'departure', 'plan');
                                                                    }
                                                                }}/>*/}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                      }

                      {/* pointIfDestination */}
                      {this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfDestination &&
                        <div className="tracking-modal-row">
                            {trackingInfoPath(pointOfDestination)}
                            <div className="tracking-modal-row__item">
                                <div className="tracking-modal-row__title">
                                    {this.state.item.trackingInfo.pointOfDestination.name ? this.state.item.trackingInfo.pointOfDestination.name[getLangInStorage()] : ''}
                                </div>
                                <div className={"card-item " + getClassName4Step(pointOfDestination)}>
                                    <Row>
                                        <Col xs={12} className={"px-0"}>
                                            <div>{getStatus(pointOfDestination)}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <Row className={"header-date-columns d-none d-md-flex"}>
                                                <Col xs={2} md={3} className={"pr-md-0"}></Col>
                                                <Col xs={4} md={4} className={"pr-md-0"}><FormattedMessage id={"logistBlock.plan"}/></Col>
                                                <Col xs={4} md={4} className={"pr-md-0"}><FormattedMessage id={"logistBlock.fact"}/></Col>
                                                <Col xs={2} md={1}></Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"} style={{lineHeight:"24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                          id="logistBlock.arrivalDate"/></span>
                                                    <div className={"actions d-flex d-md-none ml-auto"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfDestination.arrival.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfDestination', 'arrival')}/>
                                                        <FontAwesome
                                                          name={(!this.checkIsExistFactDate("pointOfDestination.arrival") && (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfDestination.arrival.enabled)) ? 'eraser' : 'eraser disabled'}
                                                          onClick={() => {
                                                              if (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfDestination.arrival.enabled === true) {
                                                                  this.onChangeDate("", 'pointOfDestination', 'arrival', 'fact');
                                                              }
                                                              if(!this.checkIsExistFactDate("pointOfDestination.arrival") && this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfDestination.arrival.enabled === true){
                                                                  this.onChangeDate("", 'pointOfDestination', 'arrival', 'plan');
                                                              }
                                                          }}/>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.plan"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfDestination.arrival.plan : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={this.checkIsExistFactDate("pointOfDestination.arrival") ||!(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfDestination.arrival.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'pointOfDestination', 'arrival', 'plan')}
                                                      //error={errors.dateFrom}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.fact"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.pointOfDestination.arrival.fact : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={!(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfDestination.arrival.enabled)}
                                                      //leftAddon={<FormattedMessage id="crudForm.field.from" />}
                                                      onChange={value => this.onChangeDate(value, 'pointOfDestination', 'arrival', 'fact')}
                                                      error={getIndicatorById(this.state.indicator, 'indicator.pointOfDestination.arrival', 1) ? '-' : ''}
                                                      note={getIndicatorById(this.state.indicator, 'indicator.pointOfDestination.arrival', 2) ? '-' : ''}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                    <div className={"actions"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfDestination.arrival.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('pointOfDestination', 'arrival')}/>
                                                        {/*<FontAwesome
                                                                name={(this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfDestination.arrival.enabled) ? 'eraser' : 'eraser disabled'}
                                                                onClick={() => {
                                                                    if (this.state.item.trackingInfo && this.state.item.trackingInfo.pointOfDestination.arrival.enabled === true) {
                                                                        this.onChangeDate("", 'pointOfDestination', 'arrival', 'fact');
                                                                        this.onChangeDate("", 'pointOfDestination', 'arrival', 'plan');
                                                                    }
                                                                }}/>*/}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>}

                      {/* deliveryToRecipientDate */}
                      {this.state.item.trackingInfo && this.state.item.trackingInfo.deliveryToRecipientDate &&
                        <div className="tracking-modal-row">
                            {trackingInfoPath(deliveryToRecipientDate)}
                            <div className="tracking-modal-row__item">
                                <div className="tracking-modal-row__title">
                                    <FormattedMessage id="logistBlock.deliveryToRecipientDate"/>
                                </div>
                                <div className={"card-item " + getClassName4Step(deliveryToRecipientDate)}>
                                    <Row>
                                        <Col xs={12} className={"px-0"}>
                                            <div>{getStatus(deliveryToRecipientDate)}</div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <Row className={"header-date-columns d-none d-md-flex"}>
                                                <Col xs={2} md={3} className={"pr-md-0"}></Col>
                                                <Col xs={4} md={4} className={"pr-md-0"}><FormattedMessage id={"logistBlock.plan"}/></Col>
                                                <Col xs={4} md={4} className={"pr-md-0"}><FormattedMessage id={"logistBlock.fact"}/></Col>
                                                <Col xs={2} md={1}></Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={3} className={"d-flex d-md-block pr-md-0"}
                                                     style={{lineHeight: "24px"}}>
                                                        <span className={"mobile-header"}><FormattedMessage
                                                          id="logistBlock.deliveryToRecipientDate"/></span>
                                                    <div className={"actions d-flex d-md-none ml-auto"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.deliveryToRecipientDate.date.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('deliveryToRecipientDate', 'date')}/>
                                                        <FontAwesome
                                                          name={(!this.checkIsExistFactDate("deliveryToRecipientDate.date") && (this.state.item.trackingInfo && this.state.item.trackingInfo.deliveryToRecipientDate.date.enabled)) ? 'eraser' : 'eraser disabled'}
                                                          onClick={() => {
                                                              if (this.state.item.trackingInfo && this.state.item.trackingInfo.deliveryToRecipientDate.date.enabled === true) {
                                                                  this.onChangeDoubleDate("", 'deliveryToRecipientDate', 'plan');
                                                              }
                                                              if(!this.checkIsExistFactDate("deliveryToRecipientDate.date") && this.state.item.trackingInfo && this.state.item.trackingInfo.deliveryToRecipientDate.date.enabled === true){
                                                                  this.onChangeDoubleDate("", 'deliveryToRecipientDate', 'fact');
                                                              }
                                                          }}/>
                                                    </div>
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.plan"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.deliveryToRecipientDate.date && this.state.item.trackingInfo.deliveryToRecipientDate.date.plan : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={this.checkIsExistFactDate("deliveryToRecipientDate.date") ||!(this.state.item.trackingInfo && this.state.item.trackingInfo.deliveryToRecipientDate.date.enabled)}
                                                      onChange={value => this.onChangeDoubleDate(value, 'deliveryToRecipientDate', 'plan')}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={12} md={4} className={"pr-md-0"}>
                                                    <div className={"d-block d-md-none"}><FormattedMessage
                                                      id={"logistBlock.fact"}/>
                                                    </div>
                                                    <DateInput
                                                      onChangeOnDelete={true}
                                                      value={this.state.item.trackingInfo ? this.state.item.trackingInfo.deliveryToRecipientDate.date && this.state.item.trackingInfo.deliveryToRecipientDate.date.fact : ''}
                                                      dateFormat={DATE_FORMAT[AppStore.userLang][0]}
                                                      icon={<i className="fa fa-calendar" aria-hidden="true"/>}
                                                      disabled={!(this.state.item.trackingInfo && this.state.item.trackingInfo.deliveryToRecipientDate.date.enabled)}
                                                      onChange={value => this.onChangeDoubleDate(value, 'deliveryToRecipientDate', 'fact')}
                                                      error={getIndicatorById(this.state.indicator, 'indicator.deliveryToRecipientDate', 1) ? '-' : ''}
                                                      note={getIndicatorById(this.state.indicator, 'indicator.deliveryToRecipientDate', 2) ? '-' : ''}
                                                      className={'tracking-item-date'}
                                                    />
                                                </Col>
                                                <Col xs={2} md={1} className={"d-none d-md-block"}>
                                                    <div className={"actions"}>
                                                        <FontAwesome
                                                          name={(this.state.item.trackingInfo && this.state.item.trackingInfo.deliveryToRecipientDate.date.enabled) ? 'unlock-alt' : 'lock'}
                                                          onClick={() => this.disableDateRow('deliveryToRecipientDate', 'date')}/>
                                                        {/*<FontAwesome
                                                                name={(this.state.item.trackingInfo && this.state.item.trackingInfo.deliveryToRecipientDate.date.enabled) ? 'eraser' : 'eraser disabled'}
                                                                onClick={() => {
                                                                    if (this.state.item.trackingInfo && this.state.item.trackingInfo.deliveryToRecipientDate.date.enabled === true) {
                                                                        this.onChangeDoubleDate("", 'deliveryToRecipientDate', 'plan');
                                                                        this.onChangeDoubleDate("", 'deliveryToRecipientDate', 'fact');
                                                                    }
                                                                }}/>*/}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>

                            </div>
                        </div>
                      }
                  </div>
              </Fragment>
            )
        };

        if (isModal === true) {
            return (
              <Modal
                isShow={isShow}
                className="logistic-modal edit-order-modal"
                title={translate({id: 'crudForm.field.edit'})}
                onClose={() => {
                    onClose(false);
                }}
                top
              >
                  <div>
                      <Col xs={12}>
                          <div>
                              {/* docsBlock */}
                              {trackingStatusBlock}
                              {trackingInfoBlock()}
                          </div>
                      </Col>
                  </div>
              </Modal>
            );
        } else {
            return (
              <div>
                  <Col xs={12}>
                      <div className={"logistic-tab"}>
                          {/* docsBlock */}
                          {trackingStatusBlock}
                          <p className={"tracking-header"}><FormattedMessage id={"logistBlock.cargoTracking"}/></p>
                          {trackingInfoBlock()}
                          <div className={"ml-auto"} style={{width:"fit-content"}}>
                              <Button color="secondary" onClick={() => {
                                  this.props.onClose(false);
                              }}>
                                  <FormattedMessage id="common.btn.ready"/>
                              </Button>
                          </div>
                      </div>
                  </Col>
              </div>);
        }
    }
}

LogisticPopupModal.defaultProps = {
    isModal: false,
    dataTracking: []
}

LogisticPopupModal.propTypes = {
    isModal: PropTypes.bool,
    dataTracking: PropTypes.array,
}
