import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { isEmpty } from 'lodash';
import { Modal } from './Modal';
import { TextInput } from './form-control/index';
import UserService from '../../services/userService';
import FlashStore from '../../stores/flashStore';
import { convertErrorsArrayToObject, translate } from '../../utils/utils';

export default class ModalChangePassword extends Component {
  initialState = {
    isShowModal: false,
    errors: {},
    data: {
      oldPassword: '',
      password: '',
      passwordConfirmation: '',
    },
  };

  state = {
    ...this.initialState,
  };

  show = () => this.setState({ isShowModal: true });

  hide = () => this.setState({ isShowModal: false });

  onChangeField = ({ target: { name, value } }) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  };

  onSubmit = () => {
    UserService.clientChangePassword(this.state.data)
      .then((response) => {
        if (response.status === 'success') {
          FlashStore.successNow(translate(response.message), true);
          this.setState(this.initialState);
          return;
        }

        if (isEmpty(response.data.errors)) {
          return;
        }

        this.setState({
          errors: convertErrorsArrayToObject(response.data.errors),
        });
      });
  };

  render() {
    const { data, errors } = this.state;

    return (
      <span>
        <Button
          color="secondary"
          className="btn btn-default"
          onClick={this.show}
        >
          <FormattedMessage id="person.button.changePassword" />
        </Button>
        <Modal
          isShow={this.state.isShowModal}
          onClose={this.hide}
          title={<FormattedMessage id="modal.changePassword" />}
        >
          <div className="change-password">
            <TextInput
              type="password"
              name="oldPassword"
              value={data.oldPassword}
              placeholder="modal.changePassword.oldPassword.placeholder"
              onChange={this.onChangeField}
              error={errors.oldPassword}
              leftAddon={
                <FormattedMessage id={'modal.changePassword.oldPassword'} />
              }
            />
            <TextInput
              type="password"
              name="password"
              value={data.password}
              placeholder="modal.changePassword.password.placeholder"
              onChange={this.onChangeField}
              error={errors.password}
              leftAddon={
                <FormattedMessage id={'modal.changePassword.password'} />
              }
            />
            <TextInput
              type="password"
              name="passwordConfirmation"
              value={data.passwordConfirmation}
              placeholder="modal.changePassword.passwordConfirmation.placeholder"
              onChange={this.onChangeField}
              error={errors.passwordConfirmation}
              leftAddon={
                <FormattedMessage
                  id={'modal.changePassword.passwordConfirmation'}
                />
              }
            />
            <Button
              onClick={this.onSubmit}
              className="btn-block"
            >
              <FormattedMessage
                id="modal.changePassword.buttonSave"
              />
            </Button>
          </div>
        </Modal>
      </span>
    );
  }
}
