/* eslint-disable */

import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import { FormGroup, InputGroupAddon, InputGroup, InputGroupText } from 'reactstrap';
import { uniqueId } from 'lodash';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import NumberFormat from 'react-number-format';
import { Label, Error, Note } from './common';
import { translate } from './../../../utils/utils';
import '../../../assets/styles/ui/numberInput.css';

export class NumberInput extends Component {
  state = {
    id: '',
  };

  UNSAFE_componentWillMount() {
    if (!this.props.id) {
      this.setState({
        id: uniqueId('f-element-'),
      });
    }
  }

  componentDidMount() {
    if (isMobile && isIOS) {
      this.inputElem.setAttribute('type', 'number');
      this.inputElem.addEventListener('keypress', this.onKeyPress, false);
    }
  }

  getInput() {
    const { placeholder, value, name, onChange, leftAddon, rightAddon, id, min, max, disabled, step, precision,
      isSpinner, type, format, mask, className, allowNegative, required,onBlur } = this.props;

    const isAndroidOS = isMobile && isAndroid; // for numeric keyboard
    const inputType = isAndroidOS ? 'tel' : type;
    const isStepBtnShown = (inputType === 'tel' && !isAndroidOS) ? false : isSpinner;
    const decimal = 10 ** this.props.precision;

    const onIncrease = () => {
      const newValue = Math.round((+value + step) * decimal) / decimal;

      onChange(newValue < max ? newValue : max);
      this.inputElem.focus();
    };

    const onDecrease = () => {
      const newValue = Math.round((+value - step) * decimal) / decimal;

      onChange(newValue > min ? newValue : min);
      this.inputElem.focus();
    };

    const onChangeHandler = (values) => {
      // if (isSpinner) {
      //   newValue = !values.floatValue && min !== null // very nice
      //   ? min
      //   : values.floatValue;
      // }
      onChange(values.floatValue);
    };

    let inputBtns = disabled?
        (<Fragment>
          { isStepBtnShown && <b disabled={disabled}><i /></b> }
          { isStepBtnShown && <b disabled={disabled}><i /></b> }
        </Fragment>):
        (<Fragment>
          { isStepBtnShown && <b onClick={onIncrease}><i /></b> }
          { isStepBtnShown && <b onClick={onDecrease}><i /></b> }
        </Fragment>);

    let input = (
      <span className="react-numeric-input">
        <NumberFormat
          value={value}
          id={id || this.state.id}
          name={name}
          type={inputType}
          onValueChange={values => onChangeHandler(values)}
          placeholder={placeholder ? translate(placeholder) : ''}
          min={min}
          max={max}
          disabled={disabled}
          decimalScale={precision}
          allowNegative={allowNegative}
          className={`form-control ${className}`}
          getInputRef={(el) => { this.inputElem = el; }}
          format={format}
          mask={mask}
          decimalSeparator={','}
          onBlur={onBlur}
        />
        { inputBtns }
      </span>
    );

    if (leftAddon || rightAddon) {
      input = (
        <InputGroup>
          {leftAddon && <InputGroupAddon className="left-addon" addonType="prepend">
            <InputGroupText style={{maxHeight:"38px"}}>
              <span className={required? 'flag-required': ''}>{leftAddon}</span>
            </InputGroupText>
          </InputGroupAddon>}
          <div className="f-control">{input}</div>
          {rightAddon && <InputGroupAddon className="right-addon" addonType="append">
            <InputGroupText>
              {rightAddon}
            </InputGroupText>
          </InputGroupAddon>}
        </InputGroup>
      );
    }

    return input;
  }

  render() {
    const { note, className, label, id, isSpinner, showErrors, style, error, required,labelClasses } = this.props;
    let isStr = false;
    if(typeof label === 'string')
      isStr = true
    return (
      <FormGroup style={style} className={`f-element f-element-number ${error ? ' has-danger' : ''} ${isSpinner ? '' : ' hide-spinner'} ${className}`}>
        {isStr ?
            <Label className={labelClasses} elementId={id || this.state.id}>{label}</Label>
            :
            <Label className={labelClasses}>{label}</Label>
        }

        {this.getInput()}

        <Note>{note}</Note>
        { showErrors ? <Error>{error}</Error> : null }

      </FormGroup>
    );
  }
}

NumberInput.defaultProps = {
  allowNegative: false,
  placeholder: '',
  className: '',
  name: '',
  type: 'text',
  min: 0,
  max: Infinity,
  step: 0.1,
  precision: 2,
  isSpinner: true,
  showErrors: true,
  required: false,
};

NumberInput.propTypes = {
  id: PropTypes.string,
  allowNegative: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  note: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  step: PropTypes.number,
  precision: PropTypes.number,
  disabled: PropTypes.bool,
  isSpinner: PropTypes.bool,
  showErrors: PropTypes.bool,
  style: PropTypes.object,
  type: PropTypes.string,
  format: PropTypes.string,
  mask: PropTypes.string,
  required: PropTypes.bool,
  onBlur: PropTypes.func
};

export default NumberInput;
