import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { addFormatName, addFormatNameToConstant, keyGenerator } from './../../utils/utils';
import { VEHICLE_BODY_TYPE } from './../../constants/staticData';
import { Select } from './../ui/form-control';

export const CargoCarsForm = (props) => {
  const { vehicle, vehicleTypes, isShowVehicleType, onChange, errors, errorsRef, onErrorsRefs } = props;
  const vehicleBodyTypes = addFormatNameToConstant(VEHICLE_BODY_TYPE);
  const preparedVehicleTypes = vehicleTypes && addFormatName(vehicleTypes);

  return (
    <div ref={(ref)=>{
      if (errors['cargo.vehicle.typeId'] ||
          errors['cargo.vehicle.bodyType'] ){
        errorsRef.push(ref);
        onErrorsRefs();
      }
    }} className="wizard__cargo-vehicle-block">
      <div className="container">
        <Row key={`vehicles-item-${keyGenerator()}`} className="mt-3">
          <Col xs={12} sm={6} md={4}>
            <Select
              values={preparedVehicleTypes}
              labelKey="formatName"
              valueKey="id"
              value={vehicle.typeId}
              label="wizard.cargo.form.vehicleType"
              onChange={value => onChange(value.id, 'cargo.vehicle.typeId')}
              error={errors['cargo.vehicle.typeId']}
              clearable={false}
            />
          </Col>
          { isShowVehicleType &&
            <Col xs={12} sm={6} md={4}>
              <Select
                values={vehicleBodyTypes}
                labelKey="formatName"
                valueKey="value"
                value={vehicle.bodyType}
                label="wizard.cargo.form.vehiclePayload"
                onChange={value => onChange(value.value, 'cargo.vehicle.bodyType')}
                error={errors['cargo.vehicle.bodyType']}
                clearable={false}
              />
            </Col>
          }
        </Row>
      </div>
    </div>
  );
};

CargoCarsForm.defaultProps = {
  vehicleTypes: [],
};

CargoCarsForm.propTypes = {
  vehicle: PropTypes.object,
  onChange: PropTypes.func,
  isShowVehicleType: PropTypes.bool,
  errors: PropTypes.object,
  vehicleTypes: PropTypes.array,
};
