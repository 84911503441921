import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { FormGroup, InputGroupAddon, InputGroup, InputGroupText } from 'reactstrap';
import { uniqueId } from 'lodash';
import { Label, Error, Note } from './common';

import { NumberInput, Select } from './index';
import DictionaryService from './../../../services/dictionaryService';

export default class HatchSizeInput extends React.Component {
  state = {
    sizeUnits: [],
    value: {
      length: '',
      width: '',
      unit: 'M',
    },
  };

  async UNSAFE_componentWillMount() {
    if (!this.props.id) {
      this.setState({
        id: uniqueId('f-element-text-'),
      });
    }

    this.setState({ sizeUnits: await DictionaryService.getSizeUnits() });
  }

  UNSAFE_componentWillReceiveProps(props) {
    const value = toJS(props.value);
    if (!value) {
      return;
    }

    this.setState({ value: { length: value.length, width: value.width, unit: value.unit } });
  }

  handleChangeField(name, value) {
    const { onChange } = this.props;
    const sValue = this.state.value;

    sValue[name] = value;

    this.setState({ value: sValue });

    onChange(this.state.value);
  }

  getSizeUnitSelect() {
    const { sizeUnits, value } = this.state;
    const sizeUnit = sizeUnits.find(item => item.code === value.unit);

    return (
      <Select
        className="size-unit-select"
        values={sizeUnits}
        labelKey="code"
        valueKey="code"
        value={sizeUnit}
        onChange={v => this.handleChangeField('unit', v.code)}
      />
    );
  }

  renderInput() {
    const { disabled, error } = this.props;
    const { value } = this.state;

    return (<div className="f-group">
      <NumberInput
        value={value.length}
        onChange={v => this.handleChangeField('length', v)}
        disabled={disabled}
        isSpinner
        placeholder="wizard.cargo.form.size.length.placeholder"
        error={error['hatchSize.length']}
      />
      <NumberInput
        value={value.width}
        onChange={v => this.handleChangeField('width', v)}
        disabled={disabled}
        isSpinner
        placeholder="wizard.cargo.form.size.width.placeholder"
        error={error['hatchSize.width']}
      />
      {this.getSizeUnitSelect()}
    </div>);
  }

  render() {
    const { leftAddon, rightAddon, className, label, note, id, error } = this.props;

    let input = this.renderInput();

    if (leftAddon || rightAddon) {
      input = (
        <InputGroup>
          {leftAddon && <InputGroupAddon className="left-addon" addonType="prepend">
            <InputGroupText>
              {leftAddon}
            </InputGroupText>
          </InputGroupAddon>}
          {input}
          {rightAddon && <InputGroupAddon className="right-addon" addonType="append">
            <InputGroupText>
              {rightAddon}
            </InputGroupText>
          </InputGroupAddon>}
        </InputGroup>
      );
    }

    return (
      <FormGroup className={`f-element hatch-size-input f-element-text ${error ? 'has-danger' : ''} ${className}`}>
        <Label elementId={id || this.state.id}>{label}</Label>
        {input}
        <Note>{note}</Note>

        <Error>{error}</Error>
      </FormGroup>
    );
  }
}

HatchSizeInput.defaultProps = {
  type: 'size',
  className: '',
  name: '',
  disabled: false,
};

HatchSizeInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  note: PropTypes.string,
  //value: PropTypes.any,
  onChange: PropTypes.func,
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
};

