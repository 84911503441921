/* eslint-disable */
import config from '../config';

const metricsEventNames = [
    { key: 'ButtonSendCallbackForm', eventName: 'ButtonSendCallbackForm'},
    { key: 'ButtonSearch', eventName: 'ButtonSearch'},
    { key: 'ButtonSave', eventName: 'ButtonSave'},
    // { key: 'ButtonOrder', eventName: 'ButtonOrder'},
    { key: 'ButtonSend2', eventName: 'ButtonSend2'},
    { key: 'ButtonDownload', eventName: 'ButtonDownload'},
    { key: 'ButtonChange', eventName: 'ButtonChange'},
    { key: 'ButtonSendHelp', eventName: 'ButtonSendHelp'},
    { key: 'ButtonSendFormHelp', eventName: 'ButtonSendFormHelp'},
    // { key: 'ButtonOrderSend', eventName: 'ButtonOrderSend'},
    { key: 'CalculationSuccess', eventName: 'CalculationSuccess'},
    { key: 'CalculationFail', eventName: 'CalculationFail'},
    { key: 'ButtonAuthorization', eventName: 'ButtonAuthorization'},
    // { key: 'ButtonRegistration', eventName: 'ButtonRegistration'},
    { key: 'newsletter', eventName: 'newsletter'},
    { key: 'emptyCalculation', eventName: 'holostoy_raschet'},
    { key: 'contactUs', eventName: 'svyazatsya_s_nami'},
    { key: 'sendEmail4OrderCalculation', eventName: 'otpravit_po_pochte'},
    { key: 'ButtonOrderSend', eventName: 'razmestil_zakaz'},
    { key: 'newUserRegistered', eventName: 'reg'},
    { key: 'wizardSubscription ', eventName: 'rassylka'},
    { key: 'wizardBtnLeftMenu', eventName: 'wBtnLM'},
    { key: 'wizardBtnHeader', eventName: 'wBtnH'}
];

const eventPrefix = config.isProd()? 'prod': 'test';

const metricsEvents = {};
for(let ev of metricsEventNames){
    metricsEvents[ev.key] = `${eventPrefix}_${ev.eventName}`;
}

const tagManagerArgs = {
    gtmId: config.gtmId,
    events: metricsEvents,
};

const getTagManagerArgs = () => {
    return tagManagerArgs;
};

const pushAnalyticalEvent = (eventName, isWizard = false) => {
    window.dataLayer.push({ 'event': eventName });
    if (isWizard) {
        window.parent.postMessage(`[wizard] event:${eventName} `, '*');
    }
    console.log('%c Event: '+ eventName, 'background: #22f; color: #fff');
};

export {
    getTagManagerArgs,
    metricsEvents,
    pushAnalyticalEvent
}
