
import { get, find } from 'lodash';

export const getTotalUnits = (data) => {
  return data.reduce((amount, packing) => {
    const result = amount + packing.amount;

    return result || 0;
  }, 0);
};

export const getGeneralTotalWeight = (data) => {
  return data.reduce((result, packing) => {
    const weight = packing.weightUnit === 'LB' ? (packing.weight / 2.205).toFixed(2) : packing.weight;
    const sumPackingWeight = weight * packing.amount;

    return +(result + sumPackingWeight).toFixed(2) || 0;
  }, 0);
};

export const calcGeneralVolume = (items, sizeUnits) => {
  return items.reduce((result, packing) => {
    let volume = 0;
    const currSizeUnit = find(sizeUnits, el => el.code === packing.sizeUnit);

    if (packing.typeId == 1 || packing.typeId == 2) {
      volume = packing.amount * packing.height * packing.length * packing.width * (get(currSizeUnit, 'koeffInMetr', 1) ** 3);
    }

    if(packing.typeId == 3) {
      if (packing.cubeVolume === undefined) volume = 0;
      volume = packing.cubeVolume * (get(currSizeUnit, 'koeffInCubMetr', 1));
    }

    return +(result + volume).toFixed(2) || 0;
  }, 0);
};

export const getContainerTotalWeight = (data) => {
  return data.reduce((weight, packing) => {
    const sumPackingWeight = packing.weight * packing.amount;

    return +(weight + sumPackingWeight).toFixed(2) || 0;
  }, 0);
};

