class AppService {
  async getLanguageByCountry() {
    const region = navigator.language || navigator.userLanguage;
    const lang = region.indexOf('-') > -1 ? region.split('-')[0] : region;
    if (lang.toLowerCase() === 'en') {
      return 'en';
    }
    return 'ru';
  }
}

export default new AppService();
