/* eslint-disable */
import React, {Fragment, useState} from 'react';
import moment from "moment";
import {
    getCargotypeLogoCssClass,
    getOrderMiniCardTransportTypeCssClass,
    AgoraDocActions,
    getOrderStatusName, AgoraStatusActions,
} from './OrderCommon';
import './orders.css';
import {translate} from "../../utils/utils";
import userStore from "../../stores/userStore";
import {ADMIN_DELIVERY_ORDERS, CLIENT_DELIVERY_ORDERS, PARTNER_DELIVERY_ORDERS} from "../../constants/routes";
import { ORDER_DELIVERY_STATUS, ORDER_DELIVERY_STATUS_MANUAL } from '../../constants/global';
import OrderIndicator from "./OrderIndicator";
import OrderDeliveryService from '../../services/orderDeliveryService';
import UserStore from '../../stores/userStore';

const DATE_TIME_FORMAT = 'DD.MM.YYYY';
const defaultStrVal = '-';

const getLink = () => {
    if (userStore.isClient()) {
        return CLIENT_DELIVERY_ORDERS;
    }
    if (userStore.isPartner()) {
        return PARTNER_DELIVERY_ORDERS;
    }
    return ADMIN_DELIVERY_ORDERS;
};

const getStatusActions = (onStatusChange = false) => {
    const res = Array.from(ORDER_DELIVERY_STATUS_MANUAL.keys()).map((s) => {
        const item = {
            type: s,
            title: getOrderStatusName(s),
            onClick: (id) => {
                console.log('Status Edit', id, s);
                if (onStatusChange && typeof onStatusChange === 'function') {
                    onStatusChange(id, s);
                }
            },
        };
        return item;
    });
    return res;
};


const getActions = (canEdit,
    canEditLogisticBlock,
    onEditClick,
    onPopupEditClick,
    onPopupChangeOwner,
    orderId,
    onClickNavToOrder,
    onStatusEditClick = false
) =>{
    const res = [{
            type: 'details',
            title: 'Подробнее',
            onClick: (id) => {
                location.href = `${getLink()}/${id}`;
            }
        }
    ];

    if (!!canEdit) {
        res.push({
            type: 'edit',
            title: 'Редактировать',
            onClick: (id) => {
                if (onEditClick && typeof onEditClick === 'function'){
                    onEditClick(id);
                }
            }
        });
    }

    if (!!canEditLogisticBlock) {
        res.push({
            type: 'editLogistic',
            title: 'Редактировать (логист)',
            onClick: (id) => {
                if (onPopupEditClick && typeof onPopupEditClick === 'function'){
                    onPopupEditClick(id);
                }
            }
        });
    }

    OrderDeliveryService.checkChangeOwner(orderId).then(resp=>{
        if(resp.code===200){
            if(resp.data===true){
                res.push({
                    type: 'editOwner',
                    title: 'Изменить владельца',
                    onClick: (id) => {
                        if (onPopupChangeOwner && typeof onPopupChangeOwner === 'function'){
                            onPopupChangeOwner(id);
                        }
                    }
                });
            }
        }
    });

    if(UserStore.isManagerOrGreater()){
        res.push({
            type: 'moveToCalculation',
            title: "Показать расчет",
            onClick: (id) => {
                if(onClickNavToOrder && typeof onClickNavToOrder === 'function'){
                    onClickNavToOrder(id);
                }
            }
        })
    }

    // res.push({
    //     type: 'subscribe',
    //     title: 'получать уведомления',
    //     useToggle: true,
    //     checked: true,
    //     onChange: function () {
    //
    //     }
    // });
    //
    // res.push({
    //     type: 'remove',
    //     title: 'Удалить'
    // });
    return res;
};

const OrderLocationPoint = ({title, subtitle, transportType}) => {
    return <div className={'order-m-card-location-info'}>
        <div className={getOrderMiniCardTransportTypeCssClass(transportType)}></div>
        <div>
            <div>{title}</div>
            <div className={'subtitle pale-font'}>{subtitle}</div>
        </div>
    </div>;
};

const OrderMiniCard = (props) => {
    const {
        orderNumber
        , orderDate
        , loadingDate
        , deliveryDate
        , price
        , currency
        , cargoTypeId
        , cargoTypeName
        , cargoAmount
        , cargoDimensionsValue
        , cargoWeight
        , statusId
        , statusName
        , isSubscribed
        , fromTitle
        , fromSubtitle
        , toTitle
        , toSubtitle
        , isShortenInfo
        , fromTransportType
        , toTransportType
        , unitName
        , cargoWeightUnit
        , cargoDimensionsUnit
        , onEditClick
        , onPopupEditClick
        , onStatusEditClick
        , onPopupChangeOwnerClick
        , onClickToNavOrder
        , canEdit
        , customerCompanyName
        , internalId
        , shippingDate
        , arrivalDate
        , indicator
    } = {...props};

    const getPointToCssClass = (statusId) => {
        switch (statusId) {
            case 1: return 'card-path-point-marker disabled';
            case 2: return 'card-path-point-marker';
            case 3: return 'card-path-point-marker not-delivered';
            default: return 'card-path-point-marker'
        }
    };

    const onCardClick = (id) => {
        location.href = `${getLink()}/${id}`;
    };

    const getDetailedCard = (indicator) => {
        indicator = indicator || [];
        if (!(userStore.isPartner() || userStore.isAdmin())) indicator = [];

        const orderLocation = (
            <Fragment>
                <div>
                    <div>
                        <div className={'card-path-point-marker'}></div>
                        <div className={statusId !== 2? 'card-path-line-border': 'card-path-line-border active'}></div>
                    </div>
                    <div className={'location-title-first'}>
                        <div className={'main-title'}>{fromTitle}</div>
                        <div className={'subtitle'}>{fromSubtitle}</div>
                    </div>
                </div>
                <div>
                    <div>
                        <div className={statusId !== 2? 'card-path-line-border card-path-line-border-point-to': 'card-path-line-border card-path-line-border-point-to active'}></div>
                        <div className={getPointToCssClass(statusId)}></div>
                    </div>
                    <div>
                        <div className={statusId !== 2? 'main-title': 'main-title pale-font'}>{toTitle}</div>
                        <div className={'subtitle'}>{toSubtitle}</div>
                    </div>
                </div>
            </Fragment>
        );
        const isFloat = (n) =>{
            return Number(n) === n && n % 1 !== 0;
        }
        const checkChangeOwner = () => {
            return OrderDeliveryService.checkChangeOwner(orderNumber);
        }
        const content = (<div className={'order-m-card'}>
            <div onClick={() => onCardClick(orderNumber)}>
                <div>
                    <div className={'pale-font'}>{translate({ id: 'deliveryOrders.listItem.orderNumber'})}</div>
                    <div>{orderNumber}</div>
                </div>
                <div>
                    <div className={'pale-font'}>{translate({ id: 'deliveryOrders.orderList.headerTable.internalId'})}:</div>
                    <div>{internalId}</div>
                </div>
            </div>
            <div onClick={() => onCardClick(orderNumber)}>
                <div>
                    <div className={'pale-font'}>{translate({ id: 'deliveryOrders.listItem.orderDate'})}:</div>
                    <div>{orderDate? moment(orderDate).format(DATE_TIME_FORMAT): '-'}</div>
                </div>
                <div>
                    <div className={'pale-font'}>{translate({ id: 'deliveryOrders.orderList.headerTable.dateReady'})}:</div>
                    <div>{shippingDate ? moment(shippingDate).format(DATE_TIME_FORMAT): '-'}</div>
                </div>
            </div>
            <div onClick={() => onCardClick(orderNumber)}>
                <div>
                    <div className={'pale-font'}>{translate({ id: 'deliveryOrders.listItem.loadingDate'})}:</div>
                    <div>{loadingDate? moment(loadingDate).format(DATE_TIME_FORMAT): '-'}</div>
                </div>
                <div>
                    <div className={'pale-font'}>{translate({ id: 'logistBlock.arrivalDate'})}:</div>
                    <div>{deliveryDate? moment(deliveryDate).format(DATE_TIME_FORMAT): '-'}</div>
                </div>
            </div>
            <div className={'m-card-order-location'} onClick={() => onCardClick(orderNumber)}>{orderLocation}</div>
            <div onClick={() => onCardClick(orderNumber)}>
                <div>
                    <div className={'pale-font'}>{translate({ id: 'deliveryOrders.listItem.totalPrice'})}</div>
                    <div>{price} {currency}</div>
                </div>
                <div>
                    <div className={'pale-font'}>{translate({ id: 'deliveryOrders.listItem.cargoType'})}</div>
                    <div>
                        <div className={getCargotypeLogoCssClass(cargoTypeId)}></div>{cargoTypeName || defaultStrVal}
                    </div>
                </div>
            </div>
            <div onClick={() => onCardClick(orderNumber)}>
                <div>
                    <div className={'pale-font'}>
                        {translate({ id: 'deliveryOrders.listItem.amount'})}
                    </div>
                    <div>{cargoAmount || defaultStrVal}</div>
                </div>
                <div>
                    {(!isNaN(cargoWeight) && cargoWeight !== undefined) &&(
                        <Fragment>
                            <div className={'pale-font'}>
                                {translate({ id: 'deliveryOrders.listItem.weight'})}, {cargoWeightUnit}
                            </div>
                            <div>{isFloat(cargoWeight)? parseFloat(cargoWeight).toFixed(2):cargoWeight}</div>
                        </Fragment>
                    )}
                </div>
            </div>
            <div>
                <div>
                    <div className={'pale-font'}>
                        {translate({ id: 'deliveryOrders.listItem.status'})}
                    </div>
                    <div>
                        <AgoraStatusActions
                          actions={getStatusActions(onStatusEditClick)}
                          itemId={orderNumber}
                          statusId={statusId}
                          statusName={statusName}
                        />
                    </div>
                </div>
                <div>
                    <div className={'pale-font'}>
                        {translate({ id: 'orderForm.arrivalDate'})}
                    </div>
                    <div>{arrivalDate}</div>
                </div>
            </div>
            <div>
                <div className={'minicard-right-item'}>
                    <AgoraDocActions
                        actions={getActions(canEdit, canEdit, onEditClick, onPopupEditClick, onPopupChangeOwnerClick, orderNumber, onClickToNavOrder)}
                        itemId={orderNumber}
                    />
                </div>
                <div className={'minicard-right-item'}>
                    <div className={isSubscribed? 'order-subscribed': 'order-without-subscribe'}></div>
                </div>
            </div>
        </div>);

        return (<div className={'order-m-card-container'} >
            <div className={'minicard-title pale-font'} style={{position:'relative'}}>
                {customerCompanyName}
                <OrderIndicator indicator={indicator} onPopupEditClick={onPopupEditClick} />
            </div>
            {content}
        </div>);
    };

    const getShortenCard = (indicator) => {
        return (
            <tr key={orderNumber} className='order-grid' >
                <td onClick={() => onCardClick(orderNumber)}>{orderNumber}</td>
                <td onClick={() => onCardClick(orderNumber)}>
                    <OrderLocationPoint
                        title={fromTitle}
                        subtitle={fromSubtitle}
                        transportType={fromTransportType}
                    />
                </td>
                <td onClick={() => onCardClick(orderNumber)}>
                    <OrderLocationPoint
                        title={toTitle}
                        subtitle={toSubtitle}
                        transportType={toTransportType}
                    />
                </td>
                <td className={'company-name'} onClick={() => onCardClick(orderNumber)}>
                    {customerCompanyName}
                </td>
                <td onClick={() => onCardClick(orderNumber)} className={'cargo-info'}>
                    <div>{cargoAmount} {unitName}</div>
                    <div className={'pale-font subtitle'}>{cargoDimensionsValue}</div>
                </td>
                <td onClick={() => onCardClick(orderNumber)}>
                    {price} {currency}
                </td>
                <td>
                    <AgoraStatusActions
                      actions={getStatusActions(onStatusEditClick)}
                      itemId={orderNumber}
                      statusId={statusId}
                      statusName={statusName}
                    />
                </td>
                <td>
                    <div className={'minicard-actions'}>
                        <div className={'minicard-right-item'}>
                            <div className={isSubscribed? 'order-subscribed': 'order-without-subscribe'}></div>
                        </div>
                        <div className={'minicard-right-item'}>
                            <AgoraDocActions
                                actions={getActions(canEdit, canEdit, onEditClick, onPopupEditClick, onPopupChangeOwnerClick, orderNumber, onClickToNavOrder)}
                                itemId={orderNumber}
                            />
                        </div>
                    </div>
                </td>
            </tr>
        );
    };
    return isShortenInfo? getShortenCard(indicator): getDetailedCard(indicator);
};

export {
    OrderMiniCard
};
