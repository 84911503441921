/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Row, Col } from 'reactstrap';
import { isEmpty } from 'lodash';
import { getFooterPageUrl, getLandingHref, getLangInStorage } from '../../utils/utils';
import UserStore from '../../stores/userStore';
import AppStore from '../../stores/appStore';
import { metricsEvents, pushAnalyticalEvent } from '../../utils/analyticsMetrics';
import env from '../../config';

class Footer extends Component {
  state = {
    email: null,
    subscribeResp: {
      success: '',
      error: '',
    },
  };

  redirectToLandingPage = (e) => {
    e.preventDefault();
    const baseHostHref = getLandingHref();
    const lang = getLangInStorage();
    window.location.replace(`${baseHostHref}/${lang === 'ru' ? '' : 'en'}`);
  };

  onFieldChange = (e, name) => {
    this.setState({ [name]: e.target.value });
  };

  onSubscribe = () => {
    if (!this.emailInput.checkValidity()) {
      this.emailInput.reportValidity();
      return;
    }
    pushAnalyticalEvent(metricsEvents.newsletter);
    return true;
/*
    const userLang = AppStore.userLang;
    const formData = new FormData();
    formData.append('ne', this.state.email);
    formData.append('na', 's');
    formData.append('nlang', userLang);

    window.location = `https://new.agorafreight.com/?na=s&nlang=${userLang}&ne=${this.state.email}`
*/

/*
    fetch('https://new.agorafreight.com/', {
      method: 'POST',
      headers: {
        // eslint-disable-next-line quote-props
        'Accept': 'application/json, text/javascript, *!/!*; q=0.01',
      },
      body: formData,
    }).then(res => res.json())
      .then((res) => {
        console.log('>> subscribe result >>', res);
        const message = this.state.subscribeResp;

        if (res.status === 'ok') {
          message.success = res.message;
          message.error = '';
        } else {
          message.error = res.message;
        }

        this.setState({ subscribeResp: message });
      }).catch(e => {
        console.log(e)
        const message = this.state.subscribeResp;
        message.error = e.message;
      });
*/
  };

  render() {
    const { isMenuOpen } = this.props;
    const { subscribeResp } = this.state;
    const urlToStartPage = !isEmpty(UserStore.user) ? getFooterPageUrl(UserStore.user.generalRole) : false;

    const agreementPage = getLangInStorage()==="ru"?`https://cargobar.ru/agreement/`:'';
    const isRates = window.location.pathname.split('/').includes('rates');
    const userLang = AppStore.userLang;
    const subscribeUrl = env.isProd() ? `https://agorafreight.com/?na=s` : `https://new.agorafreight.com/?na=s`;
    return (
      <div className={`footer py-4 ${isMenuOpen ? 'menu-open' : 'menu-close'}`} style={isRates ? { marginTop: '300px' } : { marginTop: '100px' }} >
        <div className="container">
          <Row>
            <Col xs={12} md={4} className="footer__logo">
                  <div className="footer__copyright ml-2 ml-md-0 pt-0">
                    <FormattedHTMLMessage
                      id="site.footer.copyright"
                      values={{
                        thisYear: new Date().getFullYear(),
                      }}
                    />
                  </div>
            </Col>
            <Col xs={12} md={4} className="footer__menu">
              <ul>
                { urlToStartPage &&
                  <li>
                    <Link to={urlToStartPage}><FormattedMessage id="site.footer.menu.personalArea" /></Link>
                  </li>
                }
                <li>
                  {AppStore.userLang==="ru" &&
                  <a href={agreementPage}><FormattedMessage id="site.footer.menu.agreement" /></a>
                  }
                </li>
              </ul>
            </Col>
            <Col xs={12} md={4} className="footer__subscribe">
              {/*<p className="footer__subscribe_title">
                <FormattedMessage id="site.footer.subscribe.title" />
              </p>
              { subscribeResp.success ?
                <p>{subscribeResp}</p>
                :
                <div className="subscribe">
                  <form className="subscribe__form" method="POST" action={subscribeUrl} target="_blank}">
                    <input
                      type="hidden"
                      name="nlang"
                      value={userLang}
                    />
                    <input
                        type="email"
                        placeholder="E-mail"
                        name = "ne"
                        required className="subscribe__input"
                        onChange={e => this.onFieldChange(e, 'email')} ref={(el) => { this.emailInput = el; }} />
                    <input
                        type="submit"
                        className="subscribe__submit"
                        value=""
                        onClick={() => this.onSubscribe()}
                    />
                  </form>
                  { subscribeResp.error && <div style={{ color: 'red' }}>{subscribeResp.error}</div> }
                </div>
              }*/}
              <div className="footer__developer mt-3">
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  isMenuOpen: PropTypes.bool,
};
Footer.defaultProps = {};

export default Footer;
