/*eslint-disable*/
import { observable, action } from 'mobx';
import { filter, get } from 'lodash';
import OrganizationService from '../services/organizationService';
import ServicesService from '../services/servicesService';
import UserStore from './userStore';

class OrganizationProfilesStore {
  @observable currentUserOrganizationProfiles = [];

  @action
  updateCurrentOrganizationProfiles() {
    console.log('updateCurrentOrganizationProfiles');
    const currentUserOrganizationId = get(UserStore, 'user.organization.id', 0);
    console.log('currentUserOrganizationId', currentUserOrganizationId);
    if (!currentUserOrganizationId) {
      console.log('currentUserOrganizationId exist resolving promise');
      return Promise.resolve();
    }
    console.log('currentUserOrganizationId is NOT exist');
    return this.getOrganizationProfiles(currentUserOrganizationId)
      .then((profiles) => {
        console.log('updateCurrentOrganizationProfiles resturning promise from getOrganizationProfiles profiles = ', profiles);
        this.currentUserOrganizationProfiles = profiles;
      });
  }

  @action
  async getAllOrganizationProfiles() {
    return get(await ServicesService.getAllOrganizationProfiles(), 'data.items', []);
  }

  @action
  async getOrganizationProfiles(id = 0) {
    console.log('getOrganizationProfiles id', id)
    const res = await OrganizationService.getOrganizationProfiles(id)
    console.log('getOrganizationProfiles res', res);
    const result = get(res, 'data.items', []);
    console.log('get(res, "data.items", []) is', result);
    return result;
  }

  @action
  async deleteProfileFromOrganization(params) {
    const response = await OrganizationService.deleteProfileFromOrganization(params);
    const currentUserOrganizationId = get(UserStore, 'user.organization.id', 0);

    if (params.id === currentUserOrganizationId) {
      this.currentUserOrganizationProfiles = filter(
        this.currentUserOrganizationProfiles,
        ({ value: { id } }) => id !== params.profileId
      );
    }

    return response;
  }

  @action
  async addProfileToOrganization(params, data) {
    const response = await OrganizationService.addProfileToOrganization(params, data);
    const currentUserOrganizationId = get(UserStore, 'user.organization.id', 0);

    if (params.id === currentUserOrganizationId) {
      this.currentUserOrganizationProfiles = await this.getOrganizationProfiles(
        currentUserOrganizationId
      );
    }

    return response;
  }

  @action
  rejectOrganizationProfile(params) {
    return OrganizationService.rejectOrganizationProfile(params);
  }

  @action
  approveOrganizationProfile(params) {
    return OrganizationService.approveOrganizationProfile(params);
  }
}

export default new OrganizationProfilesStore();
