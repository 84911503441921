/* eslint-disable */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import userStore from '../../stores/userStore';
import { translate, getLangInStorage } from '../../utils/utils';
import { CLIENT_DELIVERY_ORDERS, PARTNER_DELIVERY_ORDERS, ADMIN_DELIVERY_ORDERS } from '../../constants/routes';
import orderDeliveryService from '../../services/orderDeliveryService';
import LogisticModal from './LogisticModal';
import LogisticPopupModal from './LogisticPopupModal';
import FlashStore from '../../stores/flashStore';
import {OrderMiniCard} from '../Orders/OrderMiniCard'
import {
    getCargoSummaryForOrder,
    getCargoTypeIdByCargoInfo,
    getFinishDateOfOrder,
    getStartDateOfOrder,
    getTransportationTypeIconId, getOrderStatusName, getIndicator, getContainerNumber,
} from '../Orders/OrderCommon';
import ChangeOwnerOrderModal from "../Orders/ChangeOwnerOrderModal";
import UserStore from "../../stores/userStore";
import {get} from "lodash";


class LogisticBlock extends Component {
    state = {
      item: this.props.item,
      index: this.props.index,
      modalIsOpen: false,
      popupModalIsOpen: false,
      isExpand: false,
      currentIndex: 0,
      isAllLogisticBlocksExpanded: this.props.isAllLogisticBlocksExpanded,
      trackingStatus: this.props.item.trackingDetails.lastCompleted ? this.props.item.trackingDetails.lastCompleted.name : '',
        showChangeOwnerOrderModal: false,
    };

    updateTrackingInfo = (trackingInfo) => {
        this.setState({
            ...this.state,
            requestGroup: {
                ...this.state.requestGroup,
                trackingInfo,
            },
        });
    };

    componentDidUpdate(prevProps) {
      if (this.props.isAllLogisticBlocksExpanded !== prevProps.isAllLogisticBlocksExpanded) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ isExpand: this.props.isAllLogisticBlocksExpanded });
      }
      if (this.props.item !== prevProps.item) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ item: this.props.item });
      }
    }

    getLink = () => {
      if (userStore.isClient()) {
        return CLIENT_DELIVERY_ORDERS;
      }
      if (userStore.isPartner()) {
        return PARTNER_DELIVERY_ORDERS;
      }
      return ADMIN_DELIVERY_ORDERS;
    };

    openModal = (index) => {
      this.setState({
        currentIndex: index,
        modalIsOpen: true,
      });
    };

    openPopupModal = (index) => {
        this.setState({
            currentIndex: index,
            popupModalIsOpen: true,
        });
    };

    closeModal = async (isSave) => {
      if (isSave) {
        const { store } = this.props;
        await store.reloadList();

        this.setState({
          modalIsOpen: false,
        });
      } else {
        this.setState({
          modalIsOpen: false,
        });
      }
    };

    closePopupModal = async (isSave) => {
        if (isSave) {
            const { store } = this.props;
            await store.reloadList();
            this.setState({
                popupModalIsOpen: false,
            });
        } else {
            this.setState({
                popupModalIsOpen: false,
            });
        }
    };

    expandInfo = () => {
      this.setState({
        isExpand: !this.state.isExpand,
      });
    };

    copyToBuffer = (value) => {
      navigator.clipboard.writeText(value)
        .then(() => {
          FlashStore.successNow(translate({ id: 'common.copy.success' }), true);
        })
        .catch((err) => {
          FlashStore.errorNow(translate({ id: 'common.copy.error' }), true);
          console.log('Something went wrong', err);
        });
    };

    getClientName = (userInfo) => {
      if (userInfo) {
        if (userInfo.organizationName) {
          return userInfo.organizationName;
        }
        if (userInfo.userName.trim()) {
          return userInfo.userName;
        }
        if (userInfo.email) {
          return userInfo.email;
        }
      }
      return '-';
    };

    getStartPointName = (trackingInfo) => {
      let pointName = '';
      if (!trackingInfo) {
        return this.state.item.order.fromLocation.city.name[getLangInStorage()];
      }
      if (trackingInfo.pointOfPickup) {
        pointName = trackingInfo.pointOfPickup.name[getLangInStorage()];
      } else if (trackingInfo.pointOfOrigin) {
        pointName = trackingInfo.pointOfOrigin.name[getLangInStorage()];
      } else {
        pointName = '';
      }
      return pointName;
    };

    getFinishPointName = (trackingInfo) => {
      let pointName = '';
      if (!trackingInfo) {
        return this.state.item.order.toLocation.city.name[getLangInStorage()];
      }
      if (trackingInfo.pointOfDestination) {
        pointName = trackingInfo.pointOfDestination.name[getLangInStorage()];
      } else if (trackingInfo.pointOfArrival) {
        pointName = trackingInfo.pointOfArrival.name[getLangInStorage()];
      } else {
        pointName = '';
      }
      return pointName;
    };

    disableDateRow = (block, direction) => {
      this.setState({
        ...this.state,
        item: {
          ...this.state.item,
          trackingInfo: {
            ...this.state.item.trackingInfo,
            [block]: {
              ...this.state.item.trackingInfo[block],
              [direction]: {
                ...this.state.item.trackingInfo[block][direction],
                enabled: !this.state.item.trackingInfo[block][direction].enabled,
              },
            },
          },
        },
      }, () => {
        orderDeliveryService.sendLogisticBlock(this.state.item.id, this.state.item.trackingInfo)
          .then((response) => {
            if ((response.status === 'success')) {
              FlashStore.successNow(translate({ id: 'common.save.success' }), true);
            } else {
              FlashStore.errorNow(translate({ id: 'common.documents.error.fieldsWithoutFile' }), true);
            }
          });
      });
    };

    onChangeDate = (value, block, direction, type) => {
      this.setState({
        ...this.state,
        item: {
          ...this.state.item,
          trackingInfo: {
            ...this.state.item.trackingInfo,
            [block]: {
              ...this.state.item.trackingInfo[block],
              [direction]: {
                ...this.state.item.trackingInfo[block][direction],
                [type]: value,
              },
            },
          },
        },
      }, () => {
        orderDeliveryService.sendLogisticBlock(this.state.item.id, this.state.item.trackingInfo)
          .then((response) => {
            if ((response.status === 'success')) {
              FlashStore.successNow(translate({ id: 'common.save.success' }), true);
            } else {
              FlashStore.errorNow(translate({ id: 'common.documents.error.fieldsWithoutFile' }), true);
            }
          });
      });
    };

    onChangeStatus = (value) => {
      this.setState({
        ...this.state,
        trackingStatus: value,
      });
    };

    sendStatus = (id, status) => {
      orderDeliveryService.sendStatus(id, status)
        .then((response) => {
          if (response.status === 'success') {
            FlashStore.successNow(translate({ id: 'common.save.success' }), true);
          } else {
            FlashStore.errorNow(translate({ id: 'common.documents.error.fieldsWithoutFile' }), true);
          }
        });
    };

  setStatus = (id, status) => {
    orderDeliveryService.setStatus(id, status)
      .then( async (response) => {
        if (response.status === 'success') {
          FlashStore.successNow(translate({ id: 'common.save.success' }), true);
          const {store} = this.props;
          await store.reloadList();
        } else {
          FlashStore.errorNow(translate({ id: 'common.documents.error.fieldsWithoutFile' }), true);
        }
      });
  };

    getContainerNumber = () => {
        return getContainerNumber(this.state.item);
    };

    prepareDataItem = () => {
        const lang = getLangInStorage();
        const ruLang = 'ru';
        const useEnLanguage = lang !== ruLang;

        const {
            id: orderNumber,
            createDate: orderDate,
            totalCost: {value: price, currency} = {},
            cargoInfo,
            order: {
                fromLocation: {
                    city: {
                        parent: {
                            name: {
                                ru: from1stLevelRu,
                                en: from1stLevelEn,
                            } ={},
                            parent: {
                                name: {
                                    ru: from2ndLevelRu,
                                    en: from2ndLevelEn,
                                } ={}
                            } = {}
                        } = {},
                        name: {
                            ru: fromRu,
                            en: fromEn,
                        } ={}
                    } = {},
                    type: fromLocationTypeName
                } = {},
                toLocation: {
                    city: {
                        parent: {
                            name: {
                                ru: to1stLevelRu,
                                en: to1stLevelEn,
                            } ={},
                            parent: {
                                name: {
                                    ru: to2ndLevelRu,
                                    en: to2ndLevelEn,
                                } ={}
                            } = {}
                        } = {},
                        name: {
                            ru: toRu,
                            en: toEn,
                        } ={}
                    } = {},
                    type: toLocationTypeName
                },
            } = {},
            trackingInfo: {
                pointOfDestination: {arrival: {fact: arrivalDateFact} = {}} = {}
            } = {},
            requestDetails:  {
                companyName: customerCompanyName,
            } = {},
        } = this.state.item;
        const summaryLengthLimit = 100;

        const loadingDate = getStartDateOfOrder(this.state.item);
        const deliveryDate = getFinishDateOfOrder(this.state.item);
        const cargoSummary = getCargoSummaryForOrder(this.state.item.cargoInfo, summaryLengthLimit);
        const cargoDimensionsValue = cargoSummary.data;
        const cargoAmount = cargoSummary.amount;
        // const statusId = !arrivalDateFact? 1: 2;
        /* временно всем заказам проставлен этот статус */
        const statusId = this.state.item.status;

        const from1stLevel = useEnLanguage? from1stLevelEn: from1stLevelRu;
        const from2ndLevel = useEnLanguage? from2ndLevelEn: from2ndLevelRu;
        const fromSubTitle = from2ndLevel? `${from1stLevel}, ${from2ndLevel}`: from1stLevel;
        let fromTitle = useEnLanguage? fromEn: fromRu;
        let commaIndex = fromTitle.indexOf(',');
        if (commaIndex) {
            fromTitle = fromTitle.substr(0, commaIndex);
        }

        const to1stLevel = useEnLanguage? to1stLevelEn: to1stLevelRu;
        const to2ndLevel = useEnLanguage? to2ndLevelEn: to2ndLevelRu;
        const toSubTitle = to2ndLevel? `${to1stLevel}, ${to2ndLevel}`: to1stLevel;
        let toTitle = useEnLanguage? toEn: toRu;
        commaIndex = toTitle.indexOf(',');
        if (commaIndex) {
            toTitle = toTitle.substr(0, commaIndex);
        }

        //Определяем тип сборного груза. У старых заказов typeId может быть 0,
        // typeId:
        //   1 - Коробки
        //   2 - Паллеты
        //   3 - Кубы
        let typeId = get(cargoInfo, "generals.typeId", 0);
        if (typeId === 0) {
            typeId = get(cargoInfo, "generals[0].palletType.id", false) ? 2 : 1;
        }

        const data = {
            orderNumber,
            orderDate,
            price,
            currency,
            loadingDate,
            deliveryDate,
            cargoDimensionsValue,
            cargoAmount,
            statusId,
            fromTitle: fromTitle,
            fromSubtitle: fromSubTitle,
            toTitle: toTitle,
            toSubtitle: toSubTitle,
            cargoTypeId: getCargoTypeIdByCargoInfo(cargoInfo),
            cargoTypeName: 'Палеты',
            typeId: typeId,
            unitName: '',
            cargoWeight: 0,
            statusName: getOrderStatusName(statusId),
            isSubscribed: false,
            fromTransportType: getTransportationTypeIconId(fromLocationTypeName),
            toTransportType: getTransportationTypeIconId(toLocationTypeName),
            customerCompanyName,
        };

        return data;
    };

  onUpdateTrackingInfo = async (trackingInfo) => {
    this.updateTrackingInfo(trackingInfo);
    await this.props.store.reloadList();
  };

  openChangeOwnerModal = (index) => {
    this.setState({
      currentIndex: index,
      showChangeOwnerOrderModal: true,
    });
  };

  closeChangeOwnerModal = async (isSave) => {
    if (isSave) {
      const { store } = this.props;
      await store.reloadList();
      this.setState({
        showChangeOwnerOrderModal: false,
      });
    } else {
      this.setState({
        showChangeOwnerOrderModal: false,
      });
    }
  };

  onClickNavToOrder=(item)=>{
    if(item && item.order && item.orderCalculation) {
      let role = "";
      if (UserStore.isClient())
        role = "client"
      if (UserStore.isPartner())
        role = "partner"
      if (UserStore.isOperator())
        role = "admin"
      window.location.assign(`/${role}/orders/${item.order.id}#${item.orderCalculation.id}`);
    }
  }

render() {
      const {item} = this.state;
      const indicator = getIndicator(item.trackingInfo, item.status);
      return (
        <Fragment>
          <OrderMiniCard
              {...this.prepareDataItem()}
              isShortenInfo={true}
              canEdit={this.props.canEdit}
              onEditClick={() => {this.openModal(this.props.index);}}
              onPopupEditClick={() => {this.openPopupModal(this.props.index);}}
              onStatusEditClick={this.setStatus}
              indicator={indicator}
              onPopupChangeOwnerClick={()=>{this.openChangeOwnerModal(this.props.index)}}
              onClickToNavOrder={()=>this.onClickNavToOrder(this.state.item)}
          />
          <ChangeOwnerOrderModal
            id={item.id}
            isShow={this.state.showChangeOwnerOrderModal && this.state.currentIndex === this.state.index}
            onClose={this.closeChangeOwnerModal}

          />
            <LogisticModal
                isShow={this.state.modalIsOpen && this.state.currentIndex === this.state.index}
                isModal={true}
                onClose={() => this.closeModal()}
                bol={(this.state.item.cargoInfo && this.state.item.cargoInfo.transportDocument) ? this.state.item.cargoInfo.transportDocument.bol : ''}
                awb={(this.state.item.cargoInfo && this.state.item.cargoInfo.transportDocument) ? this.state.item.cargoInfo.transportDocument.awb : ''}
                sealine={this.state.item.cargoInfo ? this.state.item.cargoInfo.carrierCode : ''}
                containerId={this.state.item.transportDocument ? this.state.item.transportDocument.containerNumber : ''}
                manualCost={this.state.item.manualCost}
                manualCostDescription={this.state.item.manualCostDescription}
                estCost={this.state.item.totalCost}
                containers={this.state.item.cargoInfo ? toJS(this.state.item.cargoInfo.containers) : []}
                generals={this.state.item.cargoInfo ? toJS(this.state.item.cargoInfo.generals) : []}
                id={this.state.item.id}
                internalId={this.state.item.internalId}
                driverMobile={this.state.item.deliveryTruckDetail ? this.state.item.deliveryTruckDetail.driverMobile : ''}
                driverName={this.state.item.deliveryTruckDetail ? this.state.item.deliveryTruckDetail.driverName : ''}
                truckNumber={this.state.item.deliveryTruckDetail ? this.state.item.deliveryTruckDetail.truckNumber : ''}
                documentContainer={this.state.item.documentContainer ? this.state.item.documentContainer : {}}
                transportDocument={this.state.item.transportDocument ? this.state.item.transportDocument : {}}
                isShowDelivery={this.state.item.order.toLocation.type === 'ADDRESS'}
                onUpdateTrackingInfo= {this.onUpdateTrackingInfo}
                railwayNumber={this.state.item.transportDocument ? this.state.item.transportDocument.railwayNumber : ''}
                remarks={item.requestDetails && item.requestDetails.remarks ?
                  item.requestDetails.remarks : ''}
                canEditCommercialData={true}
                shippingDatePlan={  item.trackingInfo && item.trackingInfo.pointOfPickup && item.trackingInfo.pointOfPickup.ready.plan || null}
                shippingDateFact={  item.trackingInfo && item.trackingInfo.pointOfPickup && item.trackingInfo.pointOfPickup.ready.fact || null}
                item={this.state.item}
            />
            <LogisticPopupModal
                isShow={this.state.popupModalIsOpen && this.state.currentIndex === this.state.index}
                isModal={true}
                onClose={() => this.closePopupModal()}
                onUpdateTrackingInfo= {this.onUpdateTrackingInfo}
                item={this.state.item}
                trackingStatus={this.props.item.trackingDetails.lastCompleted ? this.props.item.trackingDetails.lastCompleted.name : ''}
                indicator={indicator}
            />
        </Fragment>
      );
    }
}

LogisticBlock.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  store: PropTypes.object,
  isAllLogisticBlocksExpanded: PropTypes.bool,
};

export default LogisticBlock;
