/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import AppStore from '../../../stores/appStore';
import {getLandingHref, getLangInStorage} from "../../../utils/utils";
import LanguageSwitcher from '../../common/LanguageSwitcher';
import {Button} from "reactstrap";
import * as URL from "../../../constants/routes";
import {FormattedMessage} from "react-intl";
import {metricsEvents, pushAnalyticalEvent} from "../../../utils/analyticsMetrics";
import staticFileService from '../../../services/staticFileService';
import { API } from '../../../constants/api';
import userStore from '../../../stores/userStore';

@observer
class Menu extends Component {
  state = {
    pageWidth: window.innerWidth,
    logoUrl:""
  };

  componentDidMount() {
    /* eslint-disable */
    const { leftMenu } = this.refs;

    window.addEventListener('resize', this.updateMenuVisibility);
    AppStore.changeStatusLeftMenu(this.isDesktop());

    leftMenu.scrollTop = AppStore.getScrollPositionLeftMenu();
    leftMenu.addEventListener('scroll', this.changeScrollPositionLeftMenu);
    let domain = null;
    if(window.location.host.includes("localhost")){
      domain = (new URLSearchParams(window.location.search)).get("domain");
    }else{
      if(window.location.host.split(".").length===3){
        domain = window.location.host.split(".")[0];
      }
    }
    if(domain!==null)
      staticFileService.checkExistLogo(domain,"LOGOINVERTED").then(res=>{
        if(res.code===200 && res.data===true){
          this.setState({logoUrl: API.STATIC_FILE_SERVER.DOWNLOAD_FILE_BY_DOMAIN({domain,organizationId:userStore.getOrganizationId(),type:'LOGOINVERTED'})})
        }else{
          this.setState({logoUrl:"https://cargobar.ru/wp-content/uploads/2023/03/cargobar_logo_form.svg"})
        }
      })
    else
      this.setState({logoUrl:"https://cargobar.ru/wp-content/uploads/2023/03/cargobar_logo_form.svg"})
  }

  UNSAFE_componentWillMount() {
    // this.updateMenuVisibility();
  }

  redirectToLandingPage = (e) => {
    e.preventDefault();
    const baseHostHref = getLandingHref();
    const lang = getLangInStorage();
    window.location.replace(`${baseHostHref}/${lang === 'ru' ? '' : 'en'}`);
  };

  componentWillUnmount() {
    /* eslint-disable */
    const { leftMenu } = this.refs;

    window.removeEventListener('resize', this.updateMenuVisibility);
    AppStore.changeScrollPositionLeftMenu(this.refs.leftMenu.scrollTop);
    leftMenu.removeEventListener('scroll', this.changeScrollPositionLeftMenu);
  }

  changeScrollPositionLeftMenu = ({ target: { scrollTop }}) => {
    AppStore.changeScrollPositionLeftMenu(scrollTop);
  }

  isDesktop = () => window.innerWidth > 768;

  updateMenuVisibility = () => {
    if (window.innerWidth !== this.state.pageWidth) {
      AppStore.changeStatusLeftMenu(this.isDesktop());
      this.setState({ pageWidth: window.innerWidth });
    }
  };

  render() {
    /* eslint-disable */
    return (
      <aside className={`left-menu ${AppStore.isLeftMenuOpen ? 'open' : 'hidden'}`}>
        <input
          type="checkbox"
          id="left-menu_toggle"
          checked={AppStore.isLeftMenuOpen}
          readOnly
          hidden
        />
        <nav className="left-menu_nav">
          <div className="left-menu_nav_btn">
            <label
              htmlFor="left-menu_toggle"
              className="left-menu_nav_btn_toggle"
              onClick={() => AppStore.changeStatusLeftMenu()}
            />
            {AppStore.isLeftMenuOpen &&
            <div className="header__right_buttons">
              <div className="header__logo_image_white" style={{"--logoUrl": `url(${this.state.logoUrl})`}} onClick={this.redirectToLandingPage} />
              <div className="lang_switcher"><LanguageSwitcher changeGoogleApi={this.props.changeGoogleApi} /></div>
            </div>
            }

          </div>
            {AppStore.isLeftMenuOpen &&
                <div className="header__buttons_wizard_btn d-flex w-100 mt-3 ">
                    <Button className="p-0" style={{width: "100%"}} onClick={() => {
                      pushAnalyticalEvent(metricsEvents.wizardBtnLeftMenu)
                      window.location.replace(URL.WIZARD)
                    }}>
                        {<FormattedMessage id="person.wizard"/>}
                    </Button>
                </div>
            }
            <hr style={{opacity: 0.2,height: "1px",width: "100%",backgroundColor: "#fff",borderTop: 0,marginBottom: "10px"}}/>
          <ul className="left-menu_nav_list" ref="leftMenu">
            {this.props.children}
          </ul>
        </nav>
      </aside>
    );
  }
}

Menu.propTypes = {
  changeGoogleApi: PropTypes.func,
  history: PropTypes.oneOfType([PropTypes.func,PropTypes.object])
};

export default Menu;
