/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {cloneDeep, set, find, isEmpty, map, get} from 'lodash';
import { Col, Row, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Select, NumberInput } from '../../../components/ui/form-control';
import { addFormatName, translate } from '../../../utils/utils';
import DictionaryService from '../../../services/dictionaryService';
import '../../../assets/styles/blocks/containersList.css';

class ContainersList extends Component {
  state = {
    isWaiting: false,
    containerTypes: [],
    containerTypesOnRender: [],
    currencies: [],
    weightUnits: [],
  };

  UNSAFE_componentWillMount() {
    this.setState({
      isWaiting: true,
    });

    const gettingContainerTypes = isEmpty(this.props.kinds) ? (
      [DictionaryService.getContainerTypes()]
    ) : (
      map(this.props.kinds, kind => DictionaryService.getCargoContainerTypes(kind))
    );

    const promises = [
      gettingContainerTypes,
      DictionaryService.getCurrencies(),
      DictionaryService.getWeightUnits(),
    ];

    Promise.all(promises).then((res) => {
      Promise.all(res[0]).then((typeRes) => {
        const [...containerTypes] = typeRes;
        const containers = addFormatName([].concat(...containerTypes)).map(containerType => containerType);

        this.setState({
          isWaiting: false,
          containerTypes: containers,
          currencies: addFormatName(res[1]),
          weightUnits: addFormatName(res[2]),
        });

        // формирует массив пустых объектов всех типов контейнеров
        const rates = containers.map((el, ind) => {
          if (this.props.isShowWeight) {
            const currentItem = this.props.cargoContainerRates
              ? this.props.cargoContainerRates.find(x => x.containerTypeId === ind + 1) : null;
            const currentItemExists = !!currentItem;

            return {
              containerTypeId: el.id,
              maxWeight: {
                unit: currentItemExists ? currentItem.maxWeight.unit : this.props.weightUnit,
                value: currentItemExists ? currentItem.maxWeight.value : '',
              },
              price: {
                currency: currentItemExists ? currentItem.price.currency : this.props.currency,
                value: currentItemExists ? currentItem.price.value : '',
              },
              transitSurcharge: {
                currency: currentItemExists && currentItem.transitSurcharge ?
                  currentItem.transitSurcharge.currency : this.props.currency,
                value: currentItemExists && currentItem.transitSurcharge ? currentItem.transitSurcharge.value : '',
              },
            };
          }

          return {
            containerTypeId: el.id,
            price: {
              currency: this.props.currency,
              value: '',
            },
            transitSurcharge: {
              currency: this.props.currency,
              value: '',
            },
          };
        });

        this.setState({
          containerTypesOnRender: rates,
        }, ()=>{
          if (!isEmpty(this.props, 'cargoContainerRates', false)) {
            const cargo = this.getCargo(this.props.cargoContainerRates, rates);
            this.setState({
              containerTypesOnRender: cargo,
            });
          }
        });

      });
    });
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (isEmpty(newProps.cargoContainerRates)) {
      return;
    }
    const validCargo = newProps.cargoContainerRates.map((el) => {
      if (newProps.isShowWeight) {
        return {
          containerTypeId: el.containerTypeId,
          maxWeight: {
            unit: el.maxWeight.unit,
            value: el.maxWeight.value,
          },
          price: {
            currency: newProps.currency,
            value: el.price.value,
          },
          transitSurcharge: {
            currency: newProps.currency,
            value: el.transitSurcharge ? el.transitSurcharge.value : '',
          },
        };
      }

      return {
        containerTypeId: el.containerTypeId,
        price: {
          currency: newProps.currency,
          value: el.price.value,
        },
        transitSurcharge: {
          currency: newProps.currency,
          value: el.transitSurcharge ? el.transitSurcharge.value : '',
        },
      };
    });

    const cargo = this.getCargo(validCargo, this.state.containerTypesOnRender);

    this.setState({
      containerTypesOnRender: cargo,
    });
  }

  getCargo(cargoContainerRates, defaults) {
    const containers = cloneDeep(defaults);

    return containers.map((container) => {
      const filledContainerType = find(cargoContainerRates, { containerTypeId: container.containerTypeId });

      if (filledContainerType) {
        if (this.props.isShowWeight) {
          return {
            containerTypeId: container.containerTypeId,
            maxWeight: {
              unit: filledContainerType.maxWeight.unit,
              value: filledContainerType.maxWeight.value,
            },
            price: {
              currency: filledContainerType.price.currency,
              value: filledContainerType.price.value,
            },
            transitSurcharge: {
              currency: filledContainerType.price.currency,
              value: filledContainerType.transitSurcharge ? filledContainerType.transitSurcharge.value : '',
            },
          };
        }

        return {
          containerTypeId: container.containerTypeId,
          price: {
            currency: filledContainerType.price.currency,
            value: filledContainerType.price.value,
          },
          transitSurcharge: {
            currency: filledContainerType.price.currency,
            value: filledContainerType.transitSurcharge ? filledContainerType.transitSurcharge.value : '',
          },
        };
      }

      return container;
    });
  }

  cargoFilter(cargo) {
    return cargo.filter((el) => {
      if (this.props.isShowWeight) {
        if (el.price.value !== '' || el.transitSurcharge.value !== '' || el.maxWeight.value !== '') {
          return el;
        }

        return null;
      }

      if (!this.props.isShowWeight) {
        if (el.price.value !== '' || el.transitSurcharge.value !== '') {
          return el;
        }

        return null;
      }

      return null;
    });
  }

  isEraseBtnDisabled(container) {
    if (this.props.isShowWeight) {
      if (this.props.isCustomsRoadTransportation) {
        return container.maxWeight.value === '' && container.price.value === '' && container.transitSurcharge.value === '';
      }

      return container.maxWeight.value === '' && container.price.value === '';
    }

    return container.price.value === '';
  }

  renderEraseButton(container, index) {
    return (
      <Button
        id="erase-container"
        style={{ marginLeft: '10px' }}
        color="danger"
        onClick={() => this.onErase(container, index)}
        disabled={this.isEraseBtnDisabled(container) || this.props.readOnly}
      >
        <span className="fa fa-eraser" aria-hidden="true" />
      </Button>
    );
  }

  async onErase(container, index) {
    const containers = cloneDeep(this.state.containerTypesOnRender);
    if (this.props.isShowWeight) {
      containers[index] = {
        containerTypeId: container.containerTypeId,
        maxWeight: {
          unit: container.maxWeight.unit,
          // value: container.maxWeight.value,
          value: '',
        },
        price: {
          currency: container.price.currency,
          value: '',
        },
        transitSurcharge: {
          currency: container.transitSurcharge.currency || container.price.value,
          value: '',
        },
      };
    } else {
      containers[index] = {
        containerTypeId: container.containerTypeId,
        price: {
          currency: container.price.currency,
          value: '',
        },
        transitSurcharge: {
          currency: container.transitSurcharge.currency || container.price.value,
          value: '',
        },
      };
    }
    const newContainers = []
    for(let i=0; i<containers.length;i++){
      const item = containers[i]
      if(this.props.isShowWeight && item.price.value !== '' && item.maxWeight.value !== '')
        newContainers.push(item);
      if(!this.props.isShowWeight)
        newContainers.push(item);
    }


    const rates = this.cargoFilter(newContainers);
    this.props.onChange(rates, 'cargoContainerRates');

    this.setState({
      ...this.state,
      containerTypesOnRender: await containers,
    });
  }

  onChangeFields(value, name, type, index) {
    const fields = cloneDeep(this.state.containerTypesOnRender);
    set(fields, name, value);

    if (type === 'price') {
      set(fields, `[${index}].maxWeight.value`, this.state.containerTypes[index].maxWeight.value);
    }

    const rates = this.cargoFilter(fields);

    this.setState({
      containerTypesOnRender: fields,
    }, () => this.props.onChange(rates, 'cargoContainerRates'));
  }

  renderWeightFields(container, index) {
    const { containerTypesOnRender } = this.state;
    const { errors, weightUnit } = this.props;
    // const weightUnit = this.state.weightUnits.find(element => element.code === container.maxWeight.unit);

    return (
      <Col xs={10} md={3}>
        {
          index === 0 &&
            <label htmlFor="weight-rates">
              <FormattedMessage id="crudForm.field.weightLimit" />
            </label>
        }

        <div className="f-group">
          <NumberInput
            id="weight-rates"
            disabled={this.props.readOnly}
            value={containerTypesOnRender[index].maxWeight.value}
            placeholder={`wizard.cargo.form.weight.placeholder.${weightUnit}`}
            onChange={_value => this.onChangeFields(_value, `[${index}].maxWeight.value`, 'maxWeight', index)}
            error={((containerTypesOnRender[index].price.value !== '' && containerTypesOnRender[index].maxWeight.value === '')) ? 'common.required' : (errors['distanceContainerRate.containers']) ? 'common.someRequired' : errors[`cargoContainerRates[${index}].maxWeight.value`]}
          />
          {/*<Select*/}
          {/*className="weight-unit-select"*/}
          {/*values={this.state.weightUnits}*/}
          {/*labelKey="code"*/}
          {/*value={weightUnit}*/}
          {/*onChange={_value => this.onChangeFields(_value.code, `[${index}].maxWeight.unit`)}*/}
          {/*/>*/}
        </div>

      </Col>
    );
  }


  renderTransitSurcharge = (transit, index) => {
    return (
      <Col xs={12} md={3}>
        <NumberInput
          id="transit-price-rates"
          disabled={this.props.readOnly}
          value={this.state.containerTypesOnRender[index].transitSurcharge.value}
          placeholder="crudForm.field.cost"
          label={index === 0 ? 'crudForm.field.transitSurcharge' : ''}
          onChange={value => this.onChangeFields(value, `[${index}].transitSurcharge.value`, 'transitSurcharge', index)}
        />
      </Col>
    );
  };

  getColSize = (transit) => {
    if (transit) {
      return 3;
    }

    return 4;
  };

  rows() {
    const { isShowWeight, isCustomsRoadTransportation, readOnly ,errors} = this.props;

    const { containerTypesOnRender } = this.state;
    return containerTypesOnRender.map((container, index) => {
      const containerType = this.state.containerTypes.find(element => element.id === container.containerTypeId);
      const error = errors[`cargoContainerRates[${index}].price.value`]?errors[`cargoContainerRates[${index}].price.value`]: errors[`cargoContainerRates[${index}].price`]
      return (
        <Row className="col-md-12 container-unit" key={`containers-item-${container.containerTypeId}`}>
          <Col xs={4} md={isCustomsRoadTransportation ? 2 : 4}>
            {
              index === 0 &&
                <label htmlFor="cost-rates">
                  { translate('crudForm.field.containerType') }
                </label>
            }
            <span className="btn btn-outline-primary container-type-label">{containerType.formatName}</span>
          </Col>
          { !this.props.isRadius &&
            <Col xs={8} md={isShowWeight ? (this.getColSize(isCustomsRoadTransportation)) : 7}>
              <NumberInput
                id="cost-rates"
                disabled={readOnly}
                value={containerTypesOnRender[index].price.value}
                placeholder="crudForm.field.cost"
                label={index === 0 ? 'crudForm.field.cost' : ''}
                onChange={_value => this.onChangeFields(_value, `[${index}].price.value`, 'price', index)}
                error={error}
              />
            </Col>
          }

          { isCustomsRoadTransportation && this.renderTransitSurcharge(isCustomsRoadTransportation, index) }

          { isShowWeight && this.renderWeightFields(container, index) }

          <Col xs={2} md={1}>
            {
              index === 0 &&
                <label htmlFor="erase-container">
                  { translate('crudForm.field.clearing') }
                </label>
            }
            {this.renderEraseButton(container, index)}
          </Col>
        </Row>
      );
    });
  }

  onChangeContainersOrWeight(value, name) {
    const newState = this.state.containerTypesOnRender.map((item) => {
      return set(item, name, value.code);
    });

    const rates = this.cargoFilter(newState);

    this.setState({
      containerTypesOnRender: newState,
    });

    this.props.onChange(rates, 'cargoContainerRates');
  }

  render() {
    const { errors, currency, weightUnit, onChange, readOnly } = this.props;
    const _currency = this.state.currencies.find(element => element.code === currency);
    const _weightUnit = this.state.weightUnits.find(element => element.code === weightUnit);

    return (
      <Row className="no-gutters">
        <Select
          placeholder="crudForm.field.currency"
          leftAddon={<FormattedMessage id="crudForm.field.currency" />}
          labelKey="code"
          valueKey="code"
          value={_currency}
          values={this.state.currencies}
          className="crud-select"
          disabled={readOnly}
          error={errors.currency}
          onChange={async (value) => { await this.onChangeContainersOrWeight(value, 'price.currency'); onChange(value.code, 'currency'); }}
          clearable={false}
        />
        { this.props.isShowWeight &&
          <Select
            leftAddon={<FormattedMessage id="crudForm.field.weightUnit" />}
            className="weight-unit-select"
            values={this.state.weightUnits}
            labelKey="code"
            valueKey="code"
            value={_weightUnit}
            disabled={readOnly}
            onChange={async (value) => { await this.onChangeContainersOrWeight(value, 'maxWeight.unit'); onChange(value.code, 'weightUnit'); }}
            clearable={false}
          />
        }

        <Row className="container-list">{ this.rows() }</Row>

        { errors.cargoContainerRates && <span style={{ color: '#d9534f' }}>{translate({ id: 'common.required' })}</span> }
      </Row>
    );
  }
}

ContainersList.defaultProps = {
  isShowWeight: true,
  isCustomsRoadTransportation: false,
  isRadius: false,
  onChange: () => {},
};

ContainersList.propTypes = {
  cargoContainerRates: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  kinds: PropTypes.array,
  errors: PropTypes.object,
  currency: PropTypes.string,
  weightUnit: PropTypes.string,
  onChange: PropTypes.func,
  isRadius: PropTypes.bool,
  isShowWeight: PropTypes.bool,
  isCustomsRoadTransportation: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default ContainersList;
