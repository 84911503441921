import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {Button, Col, Form} from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import { FormattedMessage } from 'react-intl';
import {TextInput, NumberInput, SelectLanguage} from '../ui/form-control';
import { AUTH_LOGIN, AUTH_LOGIN_FACEBOOK, AUTH_LOGIN_VK } from '../../constants/routes';
import { metricsEvents, pushAnalyticalEvent } from '../../utils/analyticsMetrics';
import {translate} from "../../utils/utils";
import AppStore from "../../stores/appStore";

export const RegisterClientForm = (props) => {
  const { fields, errors, showAgreement, onChange, onSubmit, isShowHeader, chooseLogin } = props;

  const onChangeField = (ev, name) => {
    onChange(ev.target.value, name);
  };
    const onChangeEmailLang = (value, name) => {
        onChange(value, name);
    };

  const onChangeTextField = (ev, name) => {
    const value = ev.target.value;

    if (!value) {
      onChange('', name);
    }

    const letters = /^[A-ZА-я]+$/i;

    if (value.match(letters)) {
      onChange(value, name);
    }
  };

  const onChangeNumberField = (val, name) => {
    onChange(val, name);
  };

  const getField = (name, type = 'text', translateKey, required = false, textOnly = false, id = '') => {
    const tKey = translateKey || name;
    const label = `form.field.${tKey}`;
    const placeholder = `form.field.${tKey}.placeholder`;
    const error = errors[name];
    const value = fields[name];
    const changeFunc = textOnly ? onChangeTextField : onChangeField;

    return (
        <TextInput
            id={id}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={ev => changeFunc(ev, name)}
            leftAddon={<span className={required ? 'flag-required' : ''}><FormattedMessage id={label} /></span>}
            error={error}
        />
    );
  };

  const authButtonsContainer = {
    display: 'flex',
    flexDirection: 'column',
    float: 'right',
    marginTop: '-5px',
  };

  const buttonAuthStyle = {
    border: '1px solid transparent',
    borderRadius: '7px',
    borderColor: '#1a25ab',
    padding: '0.4rem 0.75rem',
    color: '#313131',
    backgroundColor: 'transparent',
    textDecoration: 'none',
    marginTop: '8px',
  };

    const renderLanguageField4User = (errors, fieldValue) => {
        let inputValue = "";
        if (fieldValue !== null && fieldValue !== undefined) {
            inputValue = fieldValue.toUpperCase()
            if(AppStore.userLang.toUpperCase()==="ZH")
                inputValue="EN"
        }
        else {
            inputValue = AppStore.userLang.toUpperCase()
            if(AppStore.userLang.toUpperCase()==="ZH")
                inputValue="EN"
        }

        return (
            <Col xs={12} className={"px-0"}>
                <SelectLanguage
                    leftAddon={
                        <span className="flag-required">
                  {translate('form.field.languageEmail')}
                </span>
                    }
                    labelKey={'name'}
                    onChange={(value) => {
                        onChangeEmailLang(value.value, "languageEmail")
                    }}
                    value={inputValue}
                    error={errors}
                />
            </Col>
        );
    }


  return (
      <Form className="registration-client">
        <br />
        {/*{ isShowAdditionalFields && getField('middleName', 'text', null, false, true) } */}
        { getField('email', 'text', null, true, false, 'form-registration-email') }
          {renderLanguageField4User(errors.languageEmail, fields.languageEmail)}
        { getField('firstName', 'text', null, true, true) }
        { getField('lastName', 'text', null, false, true) }
        <hr />

        { <NumberInput
            id="form-registration-phone"
            value={fields.phone}
            leftAddon={<FormattedMessage id={'form.field.phone'} />}
            placeholder={'form.field.phone.placeholder'}
            onChange={val => onChangeNumberField(val, 'phone')}
            isSpinner={false}
            // format={'+# (###) ###-##-##'}
            required
            error={errors.phone}


        /> }
        { getField('password', 'password', null, true) }
        { getField('passwordConfirmation', 'password', 'confirmPassword', true) }
        {/* { isShowAdditionalFields && getField('organizationName') }
      { isShowAdditionalFields && getField('position') } */}

        <div className="mb-3">
          <Button color="link" onClick={showAgreement} >
            * <FormattedMessage id="agreement.title" />
          </Button>
          { fields.privateInfoConfirmed && <span className="fa fa-check ml-2" style={{ verticalAlign: 'middle', color: '#5baf5b' }} /> }
        </div>

        <Button
            disabled={false}
            className="mt-1"
            type="button"
            color="primary"
            id="form-registration-register"
            onClick={() => {
              onSubmit();
            }}
        >
          <FormattedMessage id="register.submit" />
        </Button>
        <small>
          {isShowHeader ?
              <Link className="btn btn-link" style={{ marginLeft: 20 }} to={AUTH_LOGIN}>
                <FormattedMessage id="login.title" />
              </Link>
              :
              <Button className="btn btn-link" style={{ marginLeft: 20, borderColor: 'transparent' }} onClick={() => chooseLogin()}>
                <FormattedMessage id="login.title" />
              </Button>
          }
        </small>
        <div style={authButtonsContainer}>
            {/*Регистрация через FB*/}
          {/*<a
              style={buttonAuthStyle}
              href={AUTH_LOGIN_FACEBOOK}
              onClick={() => {
                // eslint-disable-next-line quote-props
                pushAnalyticalEvent(metricsEvents.ButtonRegistration);
              }}
          >
            <FontAwesome name="facebook-square" className="fb-btn" />
            <FormattedMessage id="login.submit.facebook" />
          </a>*/}
            {AppStore.userLang==="ru" &&
                <a
                  style={buttonAuthStyle}
                  href={AUTH_LOGIN_VK}
                  onClick={() => {
                    // eslint-disable-next-line quote-props
                    pushAnalyticalEvent(metricsEvents.ButtonRegistration);
                  }}
                >
                    <FontAwesome name="vk" className="fb-btn" />
                    <FormattedMessage id="login.submit.vk" />
                </a>
            }
        </div>
      </Form>
  );
};

RegisterClientForm.propTypes = {
  fields: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  showAgreement: PropTypes.func,
  isShowHeader: PropTypes.bool,
  chooseLogin: PropTypes.func,
  // isShowAdditionalFields: PropTypes.bool,
};
