import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "../ui/Modal";
import {FormattedMessage} from "react-intl";
import {Select} from "../ui/form-control";
import OrganizationService from "../../services/organizationService";
import UserService from "../../services/userService";
import {cloneDeep} from "lodash";
import {Button, Col, FormGroup, Row} from "reactstrap";
import OrderDeliveryService from "../../services/orderDeliveryService";
import FlashStore from "../../stores/flashStore";
import {translate} from "../../utils/utils";
import UserStore from "../../stores/userStore";

const ChangeOwnerOrderModal = props => {
  const [organizations, setOrganizations] = useState([])
  const [organization, setOrganization] = useState()
  const [users, setUsers] = useState([])
  const [user, setUser] = useState()
  const adminMode = UserStore.isManagerOrGreater()

  useEffect(() => {
    if (adminMode === false && UserStore.user.organization && UserStore.user.organization.id) {
      setOrganization(UserStore.user.organization.id)
    }
    if(adminMode===true)
      if(UserStore.isAdmin())
        OrganizationService.getOrganizationsAll().then(res => {
          if (res.code === 200) {
            setOrganizations(res.data)
          }
        })
      else
        OrganizationService.getOrganizationsClients(UserStore.user.organization.id).then(async res => {
          if (res.code === 200) {
            setOrganizations(res.data)
          }
        })
    else if(UserStore.user.organization && UserStore.user.organization.id)
      OrganizationService.getOrganizationsClients(UserStore.user.organization.id).then(async res => {
        if (res.code === 200) {
          setOrganizations(res.data)
        }
      })
  }, [])

  useEffect(() => {
    if (organization !== undefined && organization !== null) {
      UserService.getUsersInOrganization(organization).then(res => {
        if (res.code === 200) {
          let userFormatted = [];
          for (let user of res.data) {
            let userFormat = cloneDeep(user)
            userFormat.formatName = `${user.admin===true?`(${translate({id:"role.user.adminOrganization"})})`:''} ${user.firstName} ${user.lastName}, ${user.email}`
            userFormatted.push(userFormat)
          }
          setUsers(userFormatted);
          setUser(undefined)
        }
      })
    }
  }, [organization])

  const onSave = () => {
    if (organization !== undefined && user !== undefined && props.id !== undefined) {
      OrderDeliveryService.changeOwner(organization, user, props.id).then(res => {
        if (res.code === 200) {
          FlashStore.successNow(translate({id: 'common.save.success'}), 4000);
          setOrganization(undefined)
          setUser(undefined)
          props.onClose(true)
        } else {
          FlashStore.errorNow(translate({id: 'service.serverError'}), 4000);
          props.onClose(false)
        }
      }).catch(e => {
        FlashStore.errorNow(translate({id: 'service.serverError'}), 4000);
        props.onClose(false)
      })
    }
  }

  if (props.isBlock === true)
    return (
      <div>
        <Row>

          <Col xs={12} lg={4}>
            {adminMode === true ?
              <FormGroup>
                <Select
                  disabled={!adminMode}
                  searchable
                  values={organizations}
                  value={organization}
                  labelKey={"name"}
                  valueKey={"id"}
                  label={<span className={"form-group f-label"}><FormattedMessage
                    id={"order.createNewManual.detailsOrder.changeOwnerOrg.title"}/></span>}
                  onChange={(v) => {
                    setOrganization(v.id)
                    props.onChange("organization", v.id)
                  }}
                />
              </FormGroup>
              : null
            }
          </Col>
          <Col xs={12} lg={4}>
            {organization !== undefined &&
              <FormGroup>
                <Select
                  searchable
                  values={users}
                  value={user}
                  labelKey={"formatName"}
                  valueKey={"id"}
                  label={<span className={"form-group f-label"}><FormattedMessage
                    id={"form.field.user"}/></span>}
                  onChange={(v) => {
                    setUser(v.id)
                    props.onChange("user", v.id)
                  }}
                />
              </FormGroup>
            }
          </Col>
        </Row>
      </div>
    )


  return (
      <Modal
        isModal={false}
        isShow={props.isShow}
        onClose={() => {
          props.onClose(false)
        }}
        title={<FormattedMessage id={"deliveryOrders.orderBlock.orderActions.changeOwner"}/>}
        top
      >
        <Row>

          <Col xs={12}>
              <Select
                searchable
                values={organizations}
                value={organization}
                labelKey={"name"}
                valueKey={"id"}
                label={<span className={"form-group f-label"}><FormattedMessage
                  id={"form.field.organizationName"}/></span>}
                onChange={(v) => {
                  setOrganization(v.id)
                }}
              />
          </Col>
          <Col xs={12}>
            {organization !== undefined &&
              <Select
                searchable
                values={users}
                value={user}
                labelKey={"formatName"}
                valueKey={"id"}
                label={<span className={"form-group f-label"}><FormattedMessage id={"form.field.user"}/></span>}
                onChange={(v) => {
                  setUser(v.id)
                }}
              />
            }
          </Col>
          <Col xs={12} className={"d-flex"}>
            <FormGroup className="btn-controls">
              <Row className="no-gutters" style={{justifyContent: 'flex-start'}}>
                {organization !== undefined && user !== undefined &&
                  <Button
                    className="btn-primary-new"
                    onClick={() => {
                      onSave()
                    }}
                  >
                    <FormattedMessage id={"crudForm.button.save"}/>
                  </Button>
                }
                <Button
                  className="btn-outline-primary-new"
                  onClick={() => {
                    props.onClose(false)
                  }}
                >
                  <FormattedMessage id={"crudForm.button.cancel"}/>
                </Button>
              </Row>
            </FormGroup>
          </Col>
        </Row>
      </Modal>
  );
};

ChangeOwnerOrderModal.defaultProps={
  isShow:false,
  onClose:()=>{},
  id:-1,
  isBlock:false
}

ChangeOwnerOrderModal.propTypes = {
  id: PropTypes.number,
  isShow: PropTypes.bool,
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  isBlock: PropTypes.bool
};

export default ChangeOwnerOrderModal;
