import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { includes } from 'lodash';
import { translate } from '../../../utils/utils';

const CrudTabs = (props) => {
  const { navArray, errorArr, tabIndex, setTab } = props;

  const getNavItems = navArray.map((nav, i) => (
    <NavItem key={i.toString()}>
      <NavLink
        className={`${tabIndex === i + 1 ? 'active' : ''}`}
        onClick={() => setTab(i + 1)}
      >
        <FormattedMessage id={nav} />
        { includes(errorArr, i) &&
          <span className="badge badge-danger" style={{ marginLeft: '3px' }}>{translate({ id: 'common.error' })}</span>
        }
      </NavLink>
    </NavItem>
  ));

  return (
    <Nav tabs>
      { getNavItems }
    </Nav>
  );
};

CrudTabs.defaultProps = {
  errorArr: [],
};

CrudTabs.propTypes = {
  navArray: PropTypes.array,
  errorArr: PropTypes.array,
  tabIndex: PropTypes.number,
  setTab: PropTypes.func,
};

export default CrudTabs;
