/* eslint-disable */
import React, {Component, Fragment} from 'react';
import PropTypes from "prop-types";
import {Modal} from "./Modal";
import {translate} from "../../utils/utils";
import {FormattedMessage} from "react-intl";
import {Button, Row} from "reactstrap";
import { isMobile } from 'react-device-detect';
import {metricsEvents, pushAnalyticalEvent} from "../../utils/analyticsMetrics";
import AppStore from '../../stores/appStore';
import env from '../../config';
export default class ModalWizardSubscription extends Component {
    onFieldChange = (e, name) => {
        this.setState({ [name]: e.target.value });
    };

    onSubscribe = () => {
        if (!this.emailInput.checkValidity()) {
            this.emailInput.reportValidity();
            return;
        }

        pushAnalyticalEvent(metricsEvents.wizardSubscription);
        this.props.onClose(true,true);
        return true;

/*
        const formData = new FormData();
        formData.append('email', this.state.email);
        formData.append('tildaspec-referer', 'https://agorafreight.com/');
        formData.append('tildaspec-formid', 'form151894492');
        formData.append('tildaspec-formskey', '74b5640c13d91114fdb05a3d31927424');
        formData.append('tildaspec-version-lib', '01.001');
        formData.append('tildaspec-pageid', '8771140');
        formData.append('tildaspec-projectid', '1909365');


        fetch('https://forms.tildacdn.com/procces/', {
            method: 'POST',
            headers: {
                // eslint-disable-next-line quote-props
                'Accept': 'application/json, text/javascript, *!/!*; q=0.01',
            },
            body: formData,
        }).then(res => res.json())
            .then((res) => {
                if (res.status === 'ok' || !res.error) {
                    FlashStore.successNow(res.message || translate({ id: 'common.save.success'}), true);
                    this.props.onClose(true, true);
                } else {
                    FlashStore.errorNow(translate({ id: 'reports.alert.fail'}), true);
                }
            });
*/
    };

    render() {
        const { isShown, onClose } = this.props;
        const subscribeUrl = env.isProd() ? `https://agorafreight.com/?na=s` : `https://new.agorafreight.com/?na=s`;
        const userLang = AppStore.userLang;
        const msg = isMobile? <Fragment>
                {translate('wizard.subscribe.message.first')}
                <br/>
                {translate('wizard.subscribe.message.second')}
            </Fragment>:
            <Fragment>
                {translate('wizard.subscribe.message.mobile')}
            </Fragment>;

        return (
            <div>
                <Modal
                    isShow={isShown}
                    className=''
                    title={''}
                    onClose={() => onClose(false)}
                    showHeader={false}
                >
                    <div style={{padding: '12px'}}>
                        {msg}
                        <form className="subscribe__form" method="POST" action={subscribeUrl} target="_blank}">
                            <input type="hidden" name="nlang" value={userLang} />
                        <div style={{marginTop: '25px', marginBottom: '35px'}}>
                            <input
                                id="subscribe-form-email"
                                type="email"
                                placeholder={translate('wizard.subscribe.placeholder')}
                                name="ne"
                                required className="subscribe__input"
                                onChange={e => this.onFieldChange(e, 'email')} ref={(el) => { this.emailInput = el; }}
                                style={{height: '48px', border: '1px solid #e6e6e8', paddingLeft: '17px', width: '100%'}}
                            />
                        </div>

                        <Row className='no-gutters' style={{ justifyContent: 'flex-start' }}>
                            <Button
                                id="subscribe-form-submit"
                                type="submit"
                                color="primary"
                                onClick={this.onSubscribe}
                            >
                                <FormattedMessage id={'wizard.subscribe.subscribeBtnText'} />
                            </Button>
                            <Button
                                id="subscribe-form-cancel"
                                color="btn btn-secondary"
                                onClick={() => onClose(true)}
                                style={{marginLeft: '12px'}}
                            >
                                <FormattedMessage id="wizard.subscribe.cancelBtnText" />
                            </Button>

                        </Row>
                        </form>
                    </div>
                </Modal>
            </div>
        );
    }
}

ModalWizardSubscription.propTypes = {
    isShown: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};
