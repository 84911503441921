import React from 'react';
import PropTypes from 'prop-types';
// import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'reactstrap';
import { Modal } from '../ui/Modal';
import { FormattedMessage } from 'react-intl';
// import { translate } from '../../utils/utils';

export const ErrorsModal = (props) => {
  const { isShown, errors, onClose } = props;

  return (
    <Modal
      title={<FormattedMessage id={"trunking.form.uploadError"} />}
      isModal
      isShow={isShown}
      onClose={onClose}
      className="crud-modal"
    >
      <Row>
        <Col xs={12}>
          <p><FormattedMessage id={"uploadFile.error.text"}/></p>
          <ol>
            {errors.map((item) => {
              return (
                <li>{item}</li>
              );
            })}
          </ol>
        </Col>
      </Row>
    </Modal>
  );
};

ErrorsModal.propTypes = {
  isShown: PropTypes.bool,
  errors: PropTypes.array,
  onClose: PropTypes.func,
};
