import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, uniqueId } from 'lodash';
import moment from 'moment';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import '../../../assets/styles/blocks/dataRangePicker.css';
import FlashStore from '../../../stores/flashStore';
import AppStore from '../../../stores/appStore';
import OrganizationStore from '../../../stores/organizationStore';
import { translate } from '../../../utils/utils';
import { DATE_FORMAT } from '../../../constants/global';

class DateRangeInput extends Component {
  state = {
    focusedInput: null,
    date: {
      startDate: null,
      endDate: null,
    },
  };

  UNSAFE_componentWillMount() {
    const { item } = this.props;

    if (item) {
      this.setState({
        startDate: moment(item.dateFrom),
        endDate: moment(item.dateTo),
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { item } = nextProps;

    if (item) {
      this.setState({
        startDate: moment(item.dateFrom),
        endDate: moment(item.dateTo),
      });
    }
  }


  onDatesChange({ startDate, endDate }) {
    this.setState({ startDate, endDate });

    this.onSave(startDate, endDate);
  }

  async onSave(startDate, endDate) {
    const { store, item } = this.props;

    if (!startDate || !endDate) {
      return;
    }
    const newState = {
      seaFieldsErrors: {},
    };

    const data = {
      dateFrom: startDate.format(Array.isArray(DATE_FORMAT.ru) ? DATE_FORMAT.ru[0] : DATE_FORMAT.ru),
      dateTo: endDate.format(Array.isArray(DATE_FORMAT.ru) ? DATE_FORMAT.ru[0] : DATE_FORMAT.ru),
    };

    await store.updateDates(item.id, data);

    if (!store.hasErrors) {
      this.setState({
        errors: {},
      });
      await store.reloadList();
      store.cancelEdit();
      OrganizationStore.reloadExpiredTariffs();
      FlashStore.successNow(translate({ id: 'common.save.success' }), true);
    } else {
      const errors = {};

      get(store, 'errors', []).forEach((error) => {
        errors[error.fieldName] = error.errorText;
      });

      // Вывод ошибки
      Object.values(errors).map(err => FlashStore.errorNow(translate({ id: err })));

      newState.errors = errors;
    }

    this.setState(newState);
  }


  render() {
    const { startDate, endDate, focusedInput } = this.state;
    const { disabled } = this.props;
    moment.locale(AppStore.userLang);

    return (
      <div className={AppStore.userLang!=='zh'?'data-range-picker':'data-range-picker-zh'}>
        <DateRangePicker
          isOutsideRange={() => false}
          readOnly
          showDefaultInputIcon
          small
          onDatesChange={value => this.onDatesChange(value)} // PropTypes.func.isRequired,
          onFocusChange={focused => this.setState({ focusedInput: focused })} // PropTypes.func.isRequired,
          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          startDate={startDate} // momentPropTypes.momentObj or null,
          endDate={endDate} // momentPropTypes.momentObj or null,
          endDateId={`endDate${uniqueId()}`}
          startDateId={`startDate${uniqueId()}`}
          disabled={disabled}
          noBorder
        />
      </div>
    );
  }
}

DateRangeInput.defaultProps = {};

DateRangeInput.propTypes = {
  item: PropTypes.object,
  store: PropTypes.object,
  disabled: PropTypes.bool,
};

export default DateRangeInput;
