import React from 'react';
import { FormattedMessage } from 'react-intl';
import AppStore from '../../stores/appStore';
import { name_routes } from '../../constants/routes';
import UserStore from '../../stores/userStore';

class Breadcrumbs extends React.Component {
    findNameRoute=(route) => {
      const lang = AppStore.userLang;
      const obj = name_routes.find((item) => {
        if (item.path.includes(route)) { return true; }
      });
      if (obj !== undefined) {
        if (obj.isIntermediate) { this.findNameRoute(obj.path); }
        if (obj.path[0].split('/')[1] === 'number') {
          return { name: `${obj.name[lang]} ${window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}`, href: obj.href };
        }
        return { name: obj.name[lang], href: obj.href };
      }
      return '';
    }

    onClickBreadCrumb = (item) => {
      if (item.href !== undefined) {
        let mainRoute = '';
        if (UserStore.isAdmin()) {
          mainRoute = '/admin';
        }
        if (UserStore.isClient()) {
          mainRoute = '/client';
        }

        if (UserStore.isPartner()) {
          mainRoute = '/partner';
        }
        this.props.history.push(mainRoute + item.href);
      }
    }

    getBreadcrumbs = () => {
      const lang = AppStore.userLang;
      const mainName = {
        ru: 'Главная',
        en: 'Main',
        zh: '主页',
      };
      const getAllPath = window.location.pathname.split('/').slice(1, window.location.pathname.split('/').length);
      if (!isNaN(Number(getAllPath[getAllPath.length - 1]))) {
        getAllPath[getAllPath.length - 1] = `${getAllPath[getAllPath.length - 2]}/number`;
      }
      return (
        <div style={{ alignItems: 'center' }} className="d-flex header-breadcrumb">
          {!UserStore.isGuest() &&
          <div><a href="/">{mainName[lang]}</a> >&#160;</div>
          }
          {!UserStore.isGuest() &&
                    getAllPath.map((item, i) => {
                      let addStyle = {};
                      let separateSymbol = ' >';
                      if (item !== '') {
                        if (i === getAllPath.length - 1) {
                          addStyle = { ...addStyle, color: '#929292' };
                          separateSymbol = '';
                        }

                        const routeItem = this.findNameRoute(item);
                        if (routeItem.href !== undefined && i !== getAllPath.length - 1) {
                          addStyle = { ...addStyle, cursor: 'pointer' };
                        }
                        const routeId = routeItem.name;
                        if (routeId !== undefined && routeId !== '' && (routeItem.href === undefined || i === getAllPath.length - 1)) {
                          return (<div key={`bc-${routeId}`} onClick={() => this.onClickBreadCrumb(routeItem)}>{routeId.split(' ').length === 2 ?
                            <div style={addStyle}><FormattedMessage id={routeId.split(' ')[0]} /> {routeId.split(' ')[1]}{separateSymbol}&#160;</div>
                            :
                            <div style={addStyle}><FormattedMessage id={routeId} />{separateSymbol}&#160;</div>}</div>);
                        }
                        if (routeId !== undefined && routeId !== '' && routeItem.href !== undefined && i !== getAllPath.length - 1) {
                          return (<div key={`bc-${routeId}`} onClick={() => this.onClickBreadCrumb(routeItem)}>{routeId.split(' ').length === 2 ?
                            <div className={'link-hover'} style={addStyle}><FormattedMessage id={routeId.split(' ')[0]} /> {routeId.split(' ')[1]}{separateSymbol}&#160;</div>
                            :
                            <div className={'link-hover'} style={addStyle}><FormattedMessage id={routeId} />{separateSymbol}&#160;</div>}</div>);
                        }
                      }
                    })
          }
        </div>
      );
    }
    render() {
      return (this.getBreadcrumbs());
    }
}

export default Breadcrumbs;
