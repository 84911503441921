import HttpProvider from "./httpProvider";
import {API} from "../constants/api";
import {saveAs} from "file-saver";
import FileService from "./fileService";
import {getLangInStorage} from "../utils/utils";
import moment from "moment";
import { set } from 'lodash';
import userStore from '../stores/userStore';

class StaticFileService {
    static requests = {};

    constructor() {
        this.http = HttpProvider;
    }

    async getAll() {
        return this.http.get(API.STATIC_FILE_SERVER.GET_ALL());
    }

    async uploadFile(formData, old) {
        let headers={}
        let token = localStorage.getItem("token")
        if(token!==null)
            set(headers, 'Authorization', `Bearer ${token}`)
        return await fetch(API.STATIC_FILE_SERVER.BASE, {
            method: old ? "PUT" : "POST",
            body: formData,
            headers
        });
    }

    async fetchFile(url) {
        let headers={
            'Accept-Language': getLangInStorage(),
            'X-TimeZone': moment.tz.guess(),
        }
        let token = localStorage.getItem("token")
        if(token!==null)
            set(headers, 'Authorization', `Bearer ${token}`)
        return await fetch(this.getUrlWithTimeSuffix(url), {
            headers,
        });
    }

    async fetchFileGet(url) {
        let headers={
            'Accept-Language': getLangInStorage(),
            'X-TimeZone': moment.tz.guess(),
        }
        let token = localStorage.getItem("token")
        if(token!==null)
            set(headers, 'Authorization', `Bearer ${token}`)
        return await fetch(this.getUrlWithTimeSuffix(url), {
            method:"GET",
            headers,
        });
    }

    getUrlWithTimeSuffix(url) {
        const timestamp = (new Date()).getTime();

        return url.indexOf('?') > -1 ? `${url}&v=${timestamp}` : `${url}?v=${timestamp}`;
    }

    downloadFile(file) {
        let orgId = userStore.getOrganizationId()
        let url = API.STATIC_FILE_SERVER.DOWNLOAD_FILE({organizationId:orgId,type: file.fileType});
        console.log("URL: ", url)
        /*return new Promise((resolve, reject) => {
            StaticFileService.requests[url] = StaticFileService.requests[url] || [];
            StaticFileService.requests[url].push({resolve, reject});

            if (StaticFileService.requests[url].length === 1) {
                this.fetchFileGet(url)
                    .then(res => res.blob())
                    .then((blob) => {
                        saveAs(blob, file.templateName);
                        delete StaticFileService.requests[url];
                    })
                    .catch((err) => {
                        console.error(err, 'Fetch failed');
                        delete StaticFileService.requests[url];
                    });
            }
        });*/
        window.open(url, "_self");
    }

    downloadLogoByDomain(domain,file) {
        let orgId = userStore.getOrganizationId()
        let url = API.STATIC_FILE_SERVER.DOWNLOAD_FILE_BY_DOMAIN({domain,type: file.fileType});
        console.log("URL: ", url)
        /*return new Promise((resolve, reject) => {
            StaticFileService.requests[url] = StaticFileService.requests[url] || [];
            StaticFileService.requests[url].push({resolve, reject});

            if (StaticFileService.requests[url].length === 1) {
                this.fetchFileGet(url)
                    .then(res => res.blob())
                    .then((blob) => {
                        saveAs(blob, file.templateName);
                        delete StaticFileService.requests[url];
                    })
                    .catch((err) => {
                        console.error(err, 'Fetch failed');
                        delete StaticFileService.requests[url];
                    });
            }
        });*/
        window.open(url, "_self");
    }


    async deleteFile(data) {
        let headers={
            'Accept-Language': getLangInStorage(),
            'X-TimeZone': moment.tz.guess(),
            'content-type': 'application/json'
        }
        let token = localStorage.getItem("token")
        if(token!==null)
            set(headers, 'Authorization', `Bearer ${token}`)
        return await fetch(API.STATIC_FILE_SERVER.DELETE_FILE, {
            method: "POST",
            headers,
            body: JSON.stringify(data),
        });
    }

    async checkExistLogo(domain,type){
        return await this.http.get(API.STATIC_FILE_SERVER.CHECK_EXIST_LOGO_BY_DOMAIN({domain,type}));
    }
}

export default new StaticFileService();