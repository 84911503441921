import { action, computed, observable } from 'mobx';


class DialogStore {
  @observable __message = '';
  @observable __confirmVisible = false;
  @observable __className = '';

  __confirmCallback = null;

  @computed get message() {
    return this.__message;
  }

  @computed get confirmVisible() {
    return this.__confirmVisible;
  }

  @computed get className() {
    return this.__className;
  }

  @action
  confirm(message, callback) {
    this.__message = message;
    this.__confirmVisible = true;
    this.__className = '';
    this.__confirmCallback = callback;
  }

  @action
  confirmStyled(message, className, callback) {
    this.__message = message;
    this.__confirmVisible = true;
    this.__className = className;
    this.__confirmCallback = callback;
  }

  @action
  hideConfirm() {
    this.__confirmVisible = false;
  }

  @action
  doneConfirm() {
    this.hideConfirm();

    if (this.__confirmCallback) {
      this.__confirmCallback.call();
      this.__confirmCallback = null;
    }
  }
}

export default new DialogStore();
