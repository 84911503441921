import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'rc-switch';
import '../../../assets/styles/ui/rc-switch.css';

const Switcher = ({ checked, disabled, className, autoFocus, onChange, onClick }) => {
  return (
    <Switch
      checked={checked}
      disabled={disabled}
      className={className}
      autoFocus={autoFocus}
      onChange={onChange}
      onClick={onClick}
    />
  );
};

Switcher.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default Switcher;
