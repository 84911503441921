/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, InputGroupAddon, InputGroup } from 'reactstrap';
import { uniqueId } from 'lodash';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AppStore from './../../../stores/appStore';
import { Label, Error, Note } from './common';
import { translate } from '../../../utils/utils';
import { DATE_FORMAT } from '../../../constants/global';
import '../../../assets/styles/ui/datePicker.css';
import 'moment/locale/zh-cn'
import {LANG_CODE_FULL} from "../../../constants/global";

class DateInput extends Component {
  state = {
    id: '',
  };

  UNSAFE_componentWillMount() {
    if (!this.props.id) {
      this.setState({
        id: uniqueId('f-element-text-'),
      });
    }
  }

  render() {
    const {
      readOnly,
      type,
      note,
      id,
      className,
      placeholder,
      label,
      value,
      name,
      isEmptyValue,
      onChange,
      onBlur,
      leftAddon,
      rightAddon,
      disabled,
      dateFormat,
      icon,
      iconClass,
      error,
      onChangeOnDelete
    } = this.props;

    function getMobileOperatingSystem() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera;

      if (/android/i.test(userAgent)) {
        return true;
      }
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return true;
      }
      return false;
    }

    const dFormat = dateFormat || DATE_FORMAT[AppStore.userLang];
    let mValue = null;
    if (!isEmptyValue) {
      mValue = value ? moment(value, dFormat) : '';
    }

    const onChangeValue = (val) => {
      if (val) {
        if(Array.isArray(dFormat)){
          onChange(val.format(dFormat[0]));
        } else {
          onChange(val.format(dFormat));
        }
      }

      if (onChangeOnDelete && !val) {
        onChange(val);
      }
    };

    let input = (
      <DatePicker
          readOnly={readOnly}
          locale={LANG_CODE_FULL[AppStore.userLang]}
        type={type}
        id={id || this.state.id}
        name={name}
        onChange={onChangeValue}
        placeholderText={placeholder ? translate(placeholder) : ''}
        onBlur={onBlur}
          onFocus={(e) =>{
            if(getMobileOperatingSystem()===true)
              e.target.readOnly = true
          }}
        disabled={disabled}
        selected={mValue}
        dateFormat={dFormat}
        autoComplete={'off'}
      />
    );

    if (leftAddon || rightAddon || icon) {
      input = (
        <InputGroup>
          {leftAddon && <InputGroupAddon addonType="prepend"><span className="input-group-text">{leftAddon}</span></InputGroupAddon>}
          {input}
          {icon && <InputGroupAddon className={iconClass} addonType="prepend" />}
          {rightAddon && <InputGroupAddon addonType="append"><span className="input-group-text">{rightAddon}</span></InputGroupAddon>}
        </InputGroup>
      );
    }

    return (
      <FormGroup className={`f-element f-element-date ${error ? 'has-danger' : ''} ${note ? 'has-warning' : ''} ${className}`}>
        <Label elementId={id || this.state.id}>{label}</Label>
        {input}
        <Note>{(note === '-') ? '': note }</Note>
        <Error>{(error === '-') ? '': error }</Error>
      </FormGroup>
    );
  }
}

DateInput.defaultProps = {
  type: 'text',
  placeholder: '',
  className: '',
  name: '',
  startDate: moment(),
  iconClass: 'calendar-icon',
  isEmptyValue: false,
};

DateInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  note: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, null]),
  isEmptyValue: PropTypes.bool,
  onChangeOnDelete: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  dateFormat: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  iconClass: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default DateInput;
