export const DATE_FORMAT = {
  ru: ['YYYY-MM-DD', 'YYYY.MM.DD', 'YYYY/MM/DD'],
  en: ['YYYY-MM-DD', 'YYYY.MM.DD', 'YYYY/MM/DD'],
  zh: ['YYYY-MM-DD', 'YYYY.MM.DD', 'YYYY/MM/DD']
};

export const APP_TITLE = 'Agorafreight';

export const DEFAULT_DELAY = 2000;
export const REQUEST_DEADLINE = 4000;

export const FILTER_EDITORS = {
  DATE: 'DATE',
  DATE_RANGE_EDITOR: 'DATE_RANGE_EDITOR',
  SELECT_FLAG: 'SELECT_FLAG',
  SELECT_FLAG_REVERSE: 'SELECT_FLAG_REVERSE',
  SELECT_SEA_LINE: 'SELECT_SEA_LINE',
  SELECT_CONSOLIDATOR: 'SELECT_CONSOLIDATOR',
  SELECT_LINE_CONSOLIDATOR: 'SELECT_LINE_CONSOLIDATOR',
  SELECT_CURRENCY_CODE: 'SELECT_CURRENCY_CODE',
  SELECT_SURCHARGE_TYPE: 'SELECT_SURCHARGE_TYPE',
  SELECT_TARIFICATION: 'SELECT_TARIFICATION',
  SELECT_NDS_VALUE: 'SELECT_NDS_VALUE',
  SELECT_ORDER_STATUS: 'SELECT_ORDER_STATUS',
  SELECT_PORT: 'SELECT_PORT',
  SELECT_AIR_PORT: 'SELECT_AIR_PORT',
  SELECT_AIR_PORT_NO_TERMINAL: 'SELECT_AIR_PORT_NO_TERMINAL',
  SELECT_HUB: 'SELECT_HUB',
  SELECT_RAILWAY: 'SELECT_RAILWAY',
  SELECT_CONTAINER_TYPE: 'SELECT_CONTAINER_TYPE',
  SELECT_SERVICE_CATEGORY: 'SELECT_SERVICE_CATEGORY',
  SELECT_SERVICE_PROFILE: 'SELECT_SERVICE_PROFILE',
  SELECT_ORGANIZATION_PROFILE_STATUS: 'SELECT_ORGANIZATION_PROFILE_STATUS',
  SELECT_COUNTRY: 'SELECT_COUNTRY',
  SELECT_ORGANIZATION: 'SELECT_ORGANIZATION',
  SELECT_ORGANIZATION_CLIENT: 'SELECT_ORGANIZATION_CLIENT',
  SELECT_ORGANIZATION_TARIFFS: 'SELECT_ORGANIZATION_TARIFFS',
  SELECT_REGIONS: 'SELECT_REGIONS',
  SELECT_CITIES: 'SELECT_CITIES',
  SELECT_MODIFIER: 'SELECT_MODIFIER',
  SELECT_CHARGING: 'SELECT_CHARGING',
  SELECT_EVENT_TYPE: 'SELECT_EVENT_TYPE',
  SELECT_OBJECT_TYPE: 'SELECT_OBJECT_TYPE',
  SELECT_USER: 'SELECT_USER',
  SELECT_TRANSPORTATION_TYPE: 'SELECT_TRANSPORTATION_TYPE',
  SELECT_INSURANCE_TRANSPORTATION: 'SELECT_INSURANCE_TRANSPORTATION',
  SELECT_STATUS_ACT:'SELECT_STATUS_ACT',
  SELECT_ORDER_LOCATION:'SELECT_ORDER_LOCATION',
  SELECT_CARGO_TYPE: 'SELECT_CARGO_TYPE',
};

export const EXPIRED_TARIFFS_FILTER_NAME = 'expiredTariffs';

export const FILTER_FIELD_TYPES = {
  SIMPLE: 'SIMPLE',
  CONTAINS: 'CONTAINS',
  STARTS_WITH: 'STARTS_WITH',
  ENDS_WITH: 'ENDS_WITH',
  INT_RANGE: 'INT_RANGE',
  FLOAT_RANGE: 'FLOAT_RANGE',
  DATE_RANGE: 'DATE_RANGE',
  EXISTS: 'EXISTS',
  FLAG: 'FLAG',
};

export const PARTNER_PROFILE_TYPES = {
  INTEGRATOR: 'Integrator',
  PORT_AGENT: 'Port_agent',
  ROAD_TRANSPORTATION: 'Road_transportation',
  RAILWAY_TRANSPORTATION: 'Railway_transportation',
  CUSTOMS_ROAD_TRANSPORTATION: 'Customs_Road_transportation',
  SEA_TRANSPORTATION: 'Sea_transportation',
  AIR_CARRIER: 'Air_carrier',
  FORWARDER: 'Forwarder',
  PALLET: 'Pallet',
};

export const BULK_OPERATIONS_OPTIONS = {
  ADD_SURCHARGES: 'ADD_SURCHARGES',
  DELETE_SURCHARGES: 'DELETE_SURCHARGES',
  DELETE: 'DELETE',
  PROLONG: 'PROLONG',
  CHANGE_OWNER: 'CHANGE_OWNER',
};

export const CALC_STATUSES = {
  START: 'START',
  PROGRESS: 'PROGRESS',
  END: 'END',
};

export const CARGO_TYPES = {
  GENERAL: 'GENERAL_CARGO',
  CONTAINER: 'CONTAINER',
  BILL: 'BILL',
  BY_HOUR: 'BY_HOUR',
  FORWARDING: 'FORWARDING',
  VEHICLE: 'VEHICLE',
  FROM_AIRPORT: 'FROM_AIRPORT',
  ZIP: 'ZIP',
};

export const SERVICE_CATEGORIES = {
  TRANSPORTATION: 'Transportation',
  TRANSPORTATION_: 'cargo_auto_transportation',
  AIR_TRANSPORTATION: 'air_transportation',
  AIR_SERVICE: 'airport_service',
  FREIGHT: 'freight',
  COMPLEX: 'complex',
  FREIGHT_DEP: 'services_departure',
  FREIGHT_DEST: 'services_destination',
  FREIGHT_FORWARD: 'forwarding_services',
  RAILWAY: 'railway_services',
  RAILWAY_DEP: 'railway_station_departure',
  RAILWAY_DEST: 'railway_station_destination',
  SURCHARGE: 'surcharge', //virtual
  VEHICLE:'main_line_haulage'
};

export const ORGANIZATIONS_PROFILES_STATUSES = {
  NEW: 'NEW',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const CANCELED = 'CANCELED';

/*
deliveryOrders.status.NEW=Заказ получен
deliveryOrders.status.CONFIRMED=Заказ подтвержден отправителем
deliveryOrders.status.PICKED_UP=Груз забрали
deliveryOrders.status.IN_TRANSIT=В пути
deliveryOrders.status.DELIVERED=Доставлен
deliveryOrders.status.STANDBY=Приостановлен

 */
export const ACT_STATUS_FILTER=[
  {
    code:"PAID",
    title:"constants.PAID"
  },
  {
    code:"NOT_PAID",
    title:"constants.PAID"
  }
]
// статусы заказов, которые можно выставить в ручную
export const ORDER_DELIVERY_STATUS_MANUAL = new Map([
  ['COMPLETED', { key: 'COMPLETED', title: 'deliveryOrders.status.COMPLETED'}],
  ['CANCELLED', { key: 'CANCELLED', title: 'deliveryOrders.status.CANCELLED'}],
]);

// статусы заказов, по которым можно установить фильтр
export const ORDER_DELIVERY_STATUS_FILTER = new Map([
  ['NEW', { key: 'NEW', title: 'deliveryOrders.status.NEW' }],
  ['PLANNING', { key: 'PLANNING', title: 'deliveryOrders.status.PLANNING' }],
  ['IN_TRANSIT', { key: 'IN_TRANSIT', title: 'deliveryOrders.status.IN_TRANSIT' }],
  ['ARRIVED_TO_DEPARTURE_HUB', { key: 'ARRIVED_TO_DEPARTURE_HUB', title: 'deliveryOrders.status.ARRIVED_TO_DEPARTURE_HUB' }],
  ['ARRIVED_TO_DESTINATION_HUB', { key: 'ARRIVED_TO_DESTINATION_HUB', title: 'deliveryOrders.status.ARRIVED_TO_DESTINATION_HUB' }],
  ['CUSTOMS_CLEARANCE', { key: 'CUSTOMS_CLEARANCE', title: 'deliveryOrders.status.CUSTOMS_CLEARANCE'}],
  ['AT_DESTINATION', { key: 'AT_DESTINATION', title: 'deliveryOrders.status.AT_DESTINATION'}],
  ['DELIVERED', { key: 'DELIVERED', title: 'deliveryOrders.status.DELIVERED' }],
  ['COMPLETED', { key: 'COMPLETED', title: 'deliveryOrders.status.COMPLETED'}],
  ['CANCELLED', { key: 'CANCELLED', title: 'deliveryOrders.status.CANCELLED'}],
]);

// все статусы заказов
export const ORDER_DELIVERY_STATUS = new Map([
  ['NEW', { key: 'NEW', title: 'deliveryOrders.status.NEW' }],
  ['PLANNING', { key: 'PLANNING', title: 'deliveryOrders.status.PLANNING' }],
  ['IN_TRANSIT', { key: 'IN_TRANSIT', title: 'deliveryOrders.status.IN_TRANSIT' }],
  ['ARRIVED_TO_DEPARTURE_HUB', { key: 'ARRIVED_TO_DEPARTURE_HUB', title: 'deliveryOrders.status.ARRIVED_TO_DEPARTURE_HUB' }],
  ['ARRIVED_TO_DESTINATION_HUB', { key: 'ARRIVED_TO_DESTINATION_HUB', title: 'deliveryOrders.status.ARRIVED_TO_DESTINATION_HUB' }],
  ['CUSTOMS_CLEARANCE', { key: 'CUSTOMS_CLEARANCE', title: 'deliveryOrders.status.CUSTOMS_CLEARANCE'}],
  ['AT_DESTINATION', { key: 'AT_DESTINATION', title: 'deliveryOrders.status.AT_DESTINATION'}],
  ['DELIVERED', { key: 'DELIVERED', title: 'deliveryOrders.status.DELIVERED' }],
  ['COMPLETED', { key: 'COMPLETED', title: 'deliveryOrders.status.COMPLETED'}],
  ['CANCELLED', { key: 'CANCELLED', title: 'deliveryOrders.status.CANCELLED'}],

  //старые статусы оставлены для обратной совместимости
  ['ARRIVED_TO_HUB', { key: 'ARRIVED_TO_HUB', title: 'deliveryOrders.status.ARRIVED_TO_HUB' }],
  ['CONFIRMED', { key: 'CONFIRMED', title: 'deliveryOrders.status.CONFIRMED' }],
  ['PICKED_UP', { key: 'PICKED_UP', title: 'deliveryOrders.status.PICKED_UP' }],
  ['STANDBY', { key: 'STANDBY', title: 'deliveryOrders.status.STANDBY' }],

]);

export const TYPE_OF_LOCATIONS = {
  ADDRESS: 'ADDRESS',
  SEA_PORT: 'SEA_PORT',
  AIRPORT_HUB: 'AIRPORT_HUB',
  RAILWAY_HUB: 'RAILWAY_HUB',
};

export const ICON_NAME_BY_LOCATION = {
  SEA_PORT: 'sea-icon',
  ADDRESS: 'truck-icon',
  TRANSPORT: 'truck-icon',
  TRANSPORT_HUB: 'truck-icon',
  AIRPORT: 'air-icon',
  AIRPORT_HUB: 'air-icon',
  RAILWAY_HUB: 'train-icon',
};

export const SORT_ICON_NAME = {
  ASC: 'sort-asc',
  DESC: 'sort-desc',
};

export const USER_LANG = 'userLang';

export const LANG_CODE = {
  RU: 'RU',
  EN: 'EN',
  CN: 'CN',
  ZH: 'ZH',
  UA: 'UA',
  HK: 'HK',
};

export const LANG_CODE_FULL = {
  ru:'ru-RU',
  en:'en-US',
  zh:'zh-CN'
}

export const TYPE_TO_LOCATION_FIELD_NAME = {
  [TYPE_OF_LOCATIONS.ADDRESS]: 'cityId',
  [TYPE_OF_LOCATIONS.SEA_PORT]: 'seaPortId',
  [TYPE_OF_LOCATIONS.AIRPORT_HUB]: 'airportId',
  [TYPE_OF_LOCATIONS.RAILWAY_HUB]: 'stationId',
};

export const SORT_TYPES = {
  BEST_PRICE: 'BEST_PRICE',
  BEST_TIME: 'BEST_TIME',
};

export const MAX_CONTAINER_WEIGHT = {
  US: 17300,
  default: 20000,
};

export const MAX_CARGO_SIZE ={
  LENGTH: 1203,
  WIDTH: 235,
  HEIGHT: 269,
  WEIGHT:20000
};
export const DIMENSIONS = {
  "CM": 1,
  "M": 100,
  "IN": 2.54,
  "FT": 30.48,
  "KG": 1,
  "LB":0.453592,
  "FT3": 30.48,
  "M3": 1000000.0,
}
export const nameTariffPage ={
  SEA_TARIFFS: "partner.rates.sea",
  AIR_TARIFFS: "partner.rates.air",
}

