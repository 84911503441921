import HttpProvider from "./httpProvider";

class TableTariffsService{
    constructor() {
        this.http = HttpProvider;
    }

    async uploadTariffs(endpoint, data){
       return await this.http.post(endpoint, data)
    }

    async updateTariffs(endpoint, data){
        return await this.http.put(endpoint, data)
    }

    async deleteTariffs(endpoint, data){
        return await this.http.post(endpoint+"/delete", data)
    }
}

export default new TableTariffsService()