import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {Modal} from "../ui/Modal";
import subscriptionService from "../../services/subscriptionService";
import {useHistory} from "react-router";
import {Button, Card, CardBody, CardText, Col, Row} from "reactstrap";
import {getExistLangValue, translate} from "../../utils/utils";
import {FormattedMessage} from "react-intl";

const TariffSubscriptionModal = (props) => {
    const [tariffs, setTariffs] = useState([])
    const history = useHistory()
    const [showSuccess, setShowSuccess] = useState(false)

    useEffect(() => {
        subscriptionService.getTariffs().then(res => {
            if (res.code === 401 || res.code === 403) {
                history.push("/auth/login")
            }
            setTariffs(res.data)
        })
    }, [])

    const onCreateRequest = (tariff) => {
        let data = {
            subscriptionTariffPlan: tariff,
            comment: translate("requestStatus.switch"),
            status: "NEW"
        }
        subscriptionService.createRequest(data).then(res => {
            props.onCreateRequest(res);
            props.onClose();
            setShowSuccess(true)
        })
    }

    const getBody = () => {
        return (
            <div className="d-flex flex-wrap">
                {tariffs.map(tariff => {
                    return (
                        <Col xs={12} md={6} lg={4}
                        >
                            <Card>
                                <CardBody>
                                    <CardText className={"title-card"}>
                                        {getExistLangValue(tariff.name)}
                                    </CardText>
                                    <CardText className={"description-card"}>
                                        {getExistLangValue(tariff.description)}
                                    </CardText>

                                    <CardText className={"price-card"}>
                                        {tariff.price.value} {tariff.price.currency} <FormattedMessage
                                        id={"subscription.tariff.period"}/>
                                    </CardText>

                                    <CardText className={"actions-card"}>
                                        {tariff.id !== props.currentTariffId ?
                                            <Button className={"btn-primary-new"}
                                                    onClick={() => onCreateRequest(tariff)}><FormattedMessage
                                                id={"subscription.tariff.create.request"}/></Button>
                                            :
                                            <Button className={"btn-outline-primary-new"}><FormattedMessage
                                                id={"subscription.tariff.current"}/></Button>
                                        }
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    );
                })}
            </div>
        );
    }

    return (
        <div>
            <Modal
                title={<FormattedMessage id={"subscription.tariff.create.request.confirm"}/>}
                isShow={showSuccess}
                onClose={() => {
                    setShowSuccess(false)
                }}
            >
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id={"subscription.tariff.create.request.confirm.text"}/>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} className={"d-flex justify-content-center mt-3"}>
                        <Button onClick={() => {
                            setShowSuccess(false)
                        }} className={"btn-primary-new"}><FormattedMessage id={"common.close"}/></Button>
                    </Col>
                </Row>
            </Modal>
            <Modal
                className={"select-subscription-tariff-modal"}
                title={<FormattedMessage id={"subscription.tariff.create.modal.title"}/>}
                isShow={props.isShow}
                onClose={props.onClose}
            >
                {tariffs.length > 0 &&
                    <div>
                        <div className={"d-none d-md-block row"}>
                            {getBody()}
                        </div>
                        <div className={"d-block d-md-none row"}>
                            {getBody()}
                        </div>
                    </div>
                }
            </Modal>
        </div>
    );
};

TariffSubscriptionModal.defaultProps = {
    isShow: false,
    currentTariffId: -1
}

TariffSubscriptionModal.propTypes = {
    isShow: PropTypes.bool,
    onClose: PropTypes.func,
    onCreateRequest: PropTypes.func,
    currentTariffId: PropTypes.number
}

export default TariffSubscriptionModal;