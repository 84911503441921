import React from 'react';
import PropTypes from 'prop-types';
import { Switcher } from '../ui/form-control';

function ToggleDropdown(props) {
  return (
    <div className="toggleDropdown">
      <div className="toggleDropdown__header">
        <Switcher
          onChange={props.onChange}
          checked={props.isShown}
          className="mr-2"
        />
        <span className="toggleDropdown__label">{props.label}</span>
      </div>
      { props.isShown &&
        <div className="toggleDropdown__body">
          {props.children}
        </div>
      }
    </div>
  );
}

ToggleDropdown.propTypes = {
  children: PropTypes.node,
  isShown: PropTypes.bool.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  onChange: PropTypes.func.isRequired,
};

ToggleDropdown.defaultProps = {
  children: null,
};

export default ToggleDropdown;
