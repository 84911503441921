export const ORDER_STATUSES = [
  { code: 'NEW' },
  { code: 'IN_WORK' },
  { code: 'CLOSED' },
  { code: 'CANCELED' },
];

export const TYPE_OF_CARGO = [
  {
    id: 'container',
    message: 'typeOfCargo.fcl',
  },
  {
    id: 'general',
    message: 'typeOfCargo.lcl',
  },
];

export const TYPE_OF_CARGO_FULL = [
  {
    cargoType: 'CONTAINER',
    message: 'typeOfCargoFull.fcl',
  },
  {
    cargoType: 'GENERAL_CARGO',
    message: 'typeOfCargoFull.lcl',
  },
];

export const ALL_TYPE_OF_CARGO = [
  {
    cargoType: 'CONTAINER',
    message: 'typeOfCargoFull.fcl',
  },
  {
    cargoType: 'GENERAL_CARGO',
    message: 'typeOfCargoFull.lcl',
  },
  {
    cargoType: 'VEHICLE',
    message: 'typeOfCargoFull.vehicle',
  },
];

export const ALL_PLACE_TYPES = [
  {
    type: 'ADDRESS',
    message: 'placeTypes.address',
  },
  {
    type: 'SEA_PORT',
    message: 'placeTypes.seaPort',
  },
  {
    type: 'AIRPORT_HUB',
    message: 'placeTypes.airport',
  },
  {
    type: 'RAILWAY_HUB',
    message: 'placeTypes.railwayHub',
  },
];

export const PLACE_TYPES = [
  {
    id: 'ADDRESS',
    name: {
      en: 'Address',
      ru: 'Адрес',
    },
  },
  {
    id: 'SEA_PORT',
    name: {
      en: 'Sea port',
      ru: 'Морской порт',
    },
  },
  {
    id: 'AIRPORT_HUB',
    name: {
      en: 'Airport',
      ru: 'Аэропорт',
    },
  },
];

export const DOC_TYPES_MAPPINGS = {
  AWB: 'AWB',
  HOME_AWB: 'HOUSE_AWB',
  BOL: 'BILL_OF_LADING',
  RAILWAY: 'RAILWAY_BILL',
  HOME_BOL: 'HOUSE_BILL_OF_LADING',
  HOME_RAILWAY: 'HOUSE_RAILWAY_BILL',
  CMR: 'CMR',
  TTH: 'TTH',
};

export const REPORTS_KIND = [
  {
    code: 'USER_COUNT',
    name: {
      en: 'Number of registered users',
      ru: 'Количество зарегистрированных пользователей',
    },
  },
  {
    code: 'ORDER_COUNT',
    name: {
      en: 'Number of requested calculations',
      ru: 'Количество запрошенных расчетов',
    },
  },
  {
    code: 'ORDER_REQUEST_COUNT',
    name: {
      en: 'Number of orders issued, grouped',
      ru: 'Количество оформленных заказов, сгруппированных',
    },
  },
  {
    code: 'ORGANIZATION_COUNT',
    name: {
      en: 'Number of partners with given tariffs, grouped by profiles',
      ru: 'Количество партнеров с заданными тарифами, сгруппированных по профилям',
    },
  },
  {
    code: 'TARIFF_COUNT',
    name: {
      en: 'The number of current tariffs, grouped by profiles',
      ru: 'Количество действующих тарифов, сгруппированных по профилям',
    },
  },
  {
    code: 'DICTIONARY_COUNT',
    name: {
      en: 'The number of system objects of the following types',
      ru: 'Количество объектов системы следующих типов',
    },
  },
];

export const MONTH = [
  {
    id: 1,
    message: 'month.january',
  },
  {
    id: 2,
    message: 'month.february',
  },
  {
    id: 3,
    message: 'month.march',
  },
  {
    id: 4,
    message: 'month.april',
  },
  {
    id: 5,
    message: 'month.may',
  },
  {
    id: 6,
    message: 'month.june',
  },
  {
    id: 7,
    message: 'month.july',
  },
  {
    id: 8,
    message: 'month.august',
  },
  {
    id: 9,
    message: 'month.september',
  },
  {
    id: 10,
    message: 'month.october',
  },
  {
    id: 11,
    message: 'month.november',
  },
  {
    id: 12,
    message: 'month.december',
  },

];

export const MODIFIER = [
  {
    id: 'PUBLIC',
    message: 'modifier.public',
  },
  {
    id: 'PRIVATE',
    message: 'modifier.private',
  },
];
export const TYPES_OF_COMPLEX_GENERAL_CARGO =[
  {
  code: 'WM',
  message: 'typeOfRoadGeneral.wm',
},
  {
    code: 'RC',
    message: 'typeOfRoadGeneral.rc',
  },

];
export const TYPES_OF_ROAD_GENERAL_CARGO = [
  {
    code: 'WM',
    message: 'typeOfRoadGeneral.wm',
  },
  {
    code: 'RC',
    message: 'typeOfRoadGeneral.rc',
  },
  {
    code: 'LDM',
    message: 'typeOfRoadGeneral.ldm',
  },

  {
    code: 'RAD',
    message: 'typeOfRoadGeneral.radius',
  },
];

export const FREIGHT = [
  {
    formatName: 'FIFO',
  },
  {
    formatName: 'FILO',
  },
  {
    formatName: 'LILO',
  },
  {
    formatName: 'LIFO',
  },
];

export const NDS = [
  {
    value: 'NONE',
    message: 'nds.none',
  },
  {
    value: 'ZERO',
    message: 'nds.zero',
  },
  {
    value: 'CURRENT_RATE',
    message: 'nds.currentRate',
  },
];

export const SURCHARGE_TYPES = [
  {
    type: 'ON_CARGO',
    message: 'surchargeTypes.onCargo',
  },
  {
    type: 'ON_FREIGHT',
    message: 'surchargeTypes.onFreight',
  },
];

export const TARIFICATIONS = [
  {
    tarificationType: 'ABSOLUTE',
    message: 'tarifications.absolute',
  },
  {
    tarificationType: 'RELATIVE',
    message: 'tarifications.private',
  },
];

export const VEHICLE_CHARGING=[
  {
    charging: 'BETWEEN_CITIES',
    message: 'charging.betweenCities',
  },{
    charging: 'BETWEEN_CITIES_PER_DISTANCE',
    message: 'charging.betweenCitiesPerDistance',
  },
  {
    charging: 'DISTANCE',
    message: 'charging.distance',
  },
]

export const CHARGING = [
  {
    charging: 'BETWEEN_CITIES',
    message: 'charging.betweenCities',
  },
  {
    charging: 'BETWEEN_CITIES_PER_DISTANCE',
    message: 'charging.betweenTheCityPerDistance',
  },
  {
    charging: 'DISTANCE',
    message: 'charging.distance',
  },
  {
    charging: 'RATE',
    message: 'charging.rate',
  },
  {
    charging: 'HOURLY',
    message: 'charging.hourly',
  },
  {
    charging: 'FROM_AIRPORT',
    message: 'charging.fromAirport',
  },
  {
    charging: 'PORT_ADR',
    message: 'charging.PORT_ADR'
  },
  {
    charging: 'AIR_ADR',
    message: 'charging.AIR_ADR'
  },
  {
    charging: 'PORT_RAILWAY',
    message: 'charging.PORT_RAILWAY'
  },
  {
    charging: 'ADR_RAILWAY',
    message: 'charging.ADR_RAILWAY'
  },
  {
    charging: 'ADR_ADR',
    message: 'charging.ADR_ADR'
  },
];

export const ORDERS_SORT_FILTER = [
  {
    code: 'BEST_PRICE',
    message: 'ordersSortFilter.bestPrice',
    fieldName: 'multiTotalCostAfterConversion.USD.value',
  },
  {
    code: 'BEST_TIME',
    message: 'ordersSortFilter.bestTime',
    fieldName: 'totalTransitTime',
  },
];

export const ORDERS_SORT_FILTER_CURRENCY = [
  {
    code: 'USD',
    message: 'ordersSortFilterCurrency.usd',
  },
  {
    code: 'RUB',
    message: 'ordersSortFilterCurrency.rub',
  },
  {
    code: 'EUR',
    message: 'ordersSortFilterCurrency.eur',
  },
  {
    code: 'CNY',
    message: 'ordersSortFilterCurrency.cny',
  },
  {
    code: 'DEFAULT',
    message: 'ordersSortFilterCurrency.default',
  },
];

export const CUSTOM_HOUSE = [
  {
    id: 'IN_PORT',
    message: 'customHouse.inPort',
  },
  {
    id: 'IN_DESTINATION_PLACE',
    message: 'customHouse.inDestinationPlace',
  },
  // {
  //   id: 'CHOOSE_PLATFORM',
  //   name: {
  //     en: 'At our discretion',
  //     ru: 'По выбору платформы',
  //   },
  // },
];

export const CUSTOM_HOUSE_AIR = [
  {
    id: 'IN_PORT',
    message: 'customHouseAir.inPort',
  },
  {
    id: 'IN_DESTINATION_PLACE',
    message: 'customHouseAir.inDestinationPlace',
  },
];

export const ROUTE_TYPE = [
  {
    value: 'IMPORT',
    message: 'routeType.import',
  },
  {
    value: 'EXPORT',
    message: 'routeType.export',
  },
  {
    value: 'LOCAL_ARRIVAL',
    message: 'routeType.localArrival',
  },
  {
    value: 'LOCAL_DEPARTURE',
    message: 'routeType.localDeparture',
  },
];

export const INCOTERMS = [
  { name: 'EXW' },
  { name: 'FCA' },
  { name: 'CPT' },
  { name: 'CIP' },
  { name: 'DAP' },
  { name: 'DAT' },
  { name: 'DDP' },
  { name: 'FAS' },
  { name: 'FOB' },
  { name: 'CFR' },
  { name: 'CIF' },
];

export const VEHICLE_BODY_TYPE = [
  {
    value: 'TENT',
    message: 'vehicleBodyType.tent',
  },
  {
    value: 'TERM',
    message: 'vehicleBodyType.term',
  },
  {
    value: 'REFRIGERATOR',
    message: 'vehicleBodyType.refrigerator',
  },
];

export const VEHICLE_DISTANCE_RANGE = [
  {
    value: 'UNDER_50',
    message: 'vehicleDistanceRange.UNDER_50'
  },
  {
    value: 'UNDER_100',
    message: 'vehicleDistanceRange.UNDER_100'
  },
  {
    value: 'OVER_100',
    message: 'vehicleDistanceRange.OVER_100'
  },
]

export const FORWARDER_TEMPLATES = [
  { name: 'AGORAFREIGHT' },
  { name: 'EMPTY' },
];

export const COMMISSION_TYPES = [
  {
    id: 'PERCENT_FROM_SALES',
    message: 'commissionTypes.percent',
  },
  {
    id: 'PRICE_RANGE',
    message: 'commissionTypes.priceRange',
  },
];

export const INSURANCE_CONDITIONS = [
  { value: 'A' },
  { value: 'B' },
  { value: 'C' },
  { value: 'D' },
  { value: 'E' },
  { value: 'F' },
];

export const INSURANCE_TRANSPORTATION = [
  {
    transportationType: 'LAND',
    id: 'LAND',
    message: 'insuranceTransportType.land',
  },
  {
    transportationType: 'SEA',
    id: 'SEA',
    message: 'insuranceTransportType.sea',
  },
  {
    transportationType: 'AIR',
    id: 'AIR',
    message: 'insuranceTransportType.air',
  },
];

export const FRANCHISE_TYPES = [
  {
    value: 'FIXED_VALUE',
    message: 'franchiseTypes.fixed',
  },
  {
    value: 'PERCENT',
    message: 'franchiseTypes.percent',
  },
];

export const WEIGHT_UNITS = [
  {
    code: 'KG',
    message: 'weightUnits.kg',
  },
  {
    code: 'LB',
    message: 'weightUnits.lb',
  },
];

export const ALL_TYPE_OF_CARGO_FOR_FILTERS = [
  {
    cargoType: 'cargo.containers',
    message: 'typeOfCargoFull.fcl',
  },
  {
    cargoType: 'cargo.generals',
    message: 'typeOfCargoFull.lcl',
  },
  {
    cargoType: 'cargo.vehicle',
    message: 'typeOfCargoFull.vehicle',
  },
];
