import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Row, Col } from 'reactstrap';

import { CargoGeneralsItem } from './CargoGeneralsItem';

import { addFormatName } from './../../utils/utils';

export const CargoGeneralsForm = (props) => {
  const { generalTypes, packings, onChange, weightUnits, sizeUnits, palletTypes, onAdd, onRemove, errors, errorsRefs, onErrorsRefs } = props;
  const preparePackingTypes = addFormatName(generalTypes);

  const rows = packings.map((packing, index) => {
    const handleRemoveItem = () => {
      onRemove(index);
    };

    return (
      <CargoGeneralsItem
        key={`${packing.typeId}_${index}`} //eslint-disable-line
        // className={index > 0 ? 'mt-3' : ''}
        pathToField={`cargo.generals[${index}]`}
        fields={packing}
        weightUnits={weightUnits}
        sizeUnits={sizeUnits}
        packingTypes={preparePackingTypes}
        palletTypes={palletTypes}
        onChange={onChange}
        errorsRefs = {errorsRefs}
        onErrorsRefs = {onErrorsRefs}
        onRemove={handleRemoveItem}
        isShowControl={packings.length > 1}
        errors={errors}
        index={index + 1}
      />
    );
  });

  return (
    <div className="wizard__cargo-general-block px-2 w-100">
      {rows}
      <div className="container  mt-3">
        <Row>
          <Col xs={12}>
            <Button className="btn-sm add-container-btn" color="link" onClick={onAdd}>
              <span>+ {' '} </span>
              <FormattedMessage id="wizard.cargo.form.consolidatedCargo.add" />
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

CargoGeneralsForm.propTypes = {
  generalTypes: PropTypes.array,
  palletTypes: PropTypes.array,
  weightUnits: PropTypes.array,
  sizeUnits: PropTypes.array,
  packings: PropTypes.array,
  onChange: PropTypes.func,
  onAdd: PropTypes.func,
  errorsRefs: PropTypes.array,
  onErrorsRefs: PropTypes.func,
  onRemove: PropTypes.func,
  errors: PropTypes.object,
};
