import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {isNumber, map} from 'lodash';

import DictionaryService from '../../../services/dictionaryService';
import { setName } from '../../../utils/utils';

import Select from './Select';

export default class SelectCountry extends Component {
  state = {
    values: [],
  };

  async loadOptions(input) {
    const res = await DictionaryService.getCountries({ name: input });

    const values = map(res, (country) => {
      return { value: country.id, name: setName(country) };
    });

    return { options: values };
  }

  render() {
    const { value, leftAddon, error, label, onChange, multi, disabled, isDisclaimer } = this.props;
    if(value!==null && value !==undefined && !isNumber(value) && isDisclaimer===true)
      value.name = setName(value)
    return (
      <Select
        async
        disabled={disabled}
        multi={multi}
        label={label}
        leftAddon={leftAddon}
        values={this.state.values}
        labelKey="name"
        value={value}
        error={error}
        onChange={onChange}
        searchable
        loadOptions={input => this.loadOptions(input)}
      />
    );
  }
}

SelectCountry.defaultProps = {
  className: '',
  classNameGroup: '',
  noResultsText: 'form.select.noResultText',
  searchable: false,
  showErrors: true,
  async: false,
  multi: false,
  isDisclaimer: false,
  //resetValue: null,
};

SelectCountry.propTypes = {
  async: PropTypes.bool, // eslint-disable-line
  multi: PropTypes.bool, // eslint-disable-line
  loadOptions: PropTypes.func, // eslint-disable-line
  className: PropTypes.string, // eslint-disable-line
  classNameGroup: PropTypes.string, // eslint-disable-line
  label: PropTypes.string, // eslint-disable-line
  placeholder: PropTypes.string, // eslint-disable-line
  note: PropTypes.string, // eslint-disable-line
  error: PropTypes.string, // eslint-disable-line
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]), // eslint-disable-line
  noResultsText: PropTypes.string, // eslint-disable-line
  onChange: PropTypes.func, // eslint-disable-line
  optionRenderer: PropTypes.func, // eslint-disable-line
  optionComponent: PropTypes.func, // eslint-disable-line
  valueRenderer: PropTypes.func, // eslint-disable-line
  searchable: PropTypes.bool, // eslint-disable-line
  onInputChange: PropTypes.func, // eslint-disable-line
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.number]), // eslint-disable-line
  disabled: PropTypes.bool, // eslint-disable-line
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // eslint-disable-line
  rightAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // eslint-disable-line
  isButton: PropTypes.bool, // eslint-disable-line
  showErrors: PropTypes.bool, // eslint-disable-line
  isDisclaimer: PropTypes.bool,
  //resetValue: PropTypes.any,
};
