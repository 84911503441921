import React from 'react';
import PropTypes from 'prop-types';

export const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666" fontSize={13} transform="rotate(-15)">{payload.value}</text>
    </g>
  );
};

CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};


export const CustomizedAxisTickPD = (props) => {
  const { x, y, payload } = props;

  return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={20} textAnchor="end" fill="#666" fontSize={13} transform="rotate(-25)" >{payload.value}</text>
      </g>
  );
};

CustomizedAxisTickPD.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

export const CustomizedAxisTickDate = (props) => {
  const { x, y, payload } = props;
  console.log("PAYLOAD: ", payload)

  return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fill="#666" fontSize={13} transform="rotate(-15)">{payload.value}</text>
      </g>
  );
};

CustomizedAxisTickDate.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};



