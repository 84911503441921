import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {Alert, Button, ButtonGroup, Col, Row} from 'reactstrap';
import {get} from 'lodash';
import {useMediaQuery} from 'react-responsive';
import {calcGeneralVolume, getContainerTotalWeight, getGeneralTotalWeight, getTotalUnits} from './utils';
import {CargoGeneralsForm} from './CargoGeneralsForm';
import {CargoContainersForm} from './CargoContainersForm';
import {CargoCarsForm} from './CargoCarsForm';
import {addFormatName, addFormatNameToConstant} from '../../utils/utils';
import {WEIGHT_UNITS} from '../../constants/staticData';
import {Error} from '../ui/form-control/common';
import {TAB_TO_CARGO_TYPE} from './DefaultFields';

export const CargoForm = ({
                              generalTypes,
                              sizeUnits,
                              containerTypes,
                              palletTypes,
                              vehicleTypes,
                              fields,
                              onChange,
                              errorsRef,
                              onErrorsRefs,
                              onAddCargoType,
                              onRemoveCargoType,
                              onChangeContainerAmount,
                              errors,
                              directionErrors,
                              activeTab,
                              setActiveTab,
                              enabledCargos = [],
                              allCargos = [],
                              isHighlight: isHighlight = false,
    isFake=false
                          }) => {
    if (!enabledCargos.length) {
        return null;
    }

    const {cargo} = fields;
    const preparePalletTypes = addFormatName(palletTypes);
    const prepareWeightUnits = addFormatNameToConstant(WEIGHT_UNITS);
    const weightUnitGeneral = prepareWeightUnits.find(item => item.code === 'KG');
    const weightUnitContainer = prepareWeightUnits.find(item => item.code === cargo.containers[0].weightUnit);
    const isUSADirection = get(fields, 'fromLocation.countryCode') === 'US' || get(fields, 'toLocation.countryCode') === 'US';
    const isMobile = useMediaQuery({query: '(max-width: 575px)'});

    const getCargoTypes = () => {
        if (isMobile) {
            return (
                <div className="wizard__cargo-from_header_location_row mt-3">
                    <Row>
                        <Col xs={12} lg={8} className="wizard__cargo-from_header_location col-12 ">
                            <ButtonGroup>
                                {allCargos.slice(0, allCargos.length - 1).sort((a,b)=>{
                                  if(a==="box" && b==='containers') return 1
                                  else return -1
                                }).map(tab => (
                                    <Button
                                        style={{height:"100%"}}
                                        onClick={() => setActiveTab(tab)}
                                        className={tab === activeTab ? 'isSelected' : ''}
                                        disabled={!enabledCargos.includes(tab)}>
                                        <FormattedMessage id={`wizard.cargo.form.${tab}.title`}/>
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={8} className="wizard__cargo-from_header_location col-12">
                            <ButtonGroup>
                                <Button
                                    onClick={() => setActiveTab(allCargos[allCargos.length - 1])}
                                    className={allCargos[allCargos.length - 1] === activeTab ? 'isSelected' : ''}
                                    disabled={!enabledCargos.includes(allCargos[allCargos.length - 1])}>
                                    <FormattedMessage
                                        id={`wizard.cargo.form.${allCargos[allCargos.length - 1]}.title`}/>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </div>
            );
        }

        return (
            <Row>
                <Col xs={12} lg={8} className="wizard__cargo-from_header_location">
                    <ButtonGroup className={ isHighlight ? 'ui-highlight' : ''}>
                        {allCargos.sort((a,b)=>{
                          if(a==="containers" && b==='box') return -1
                          else return 1
                        }).map(tab => (
                            <Button
                                key={`button=${tab}`}
                                onClick={() => setActiveTab(tab)}
                                className={tab === activeTab ? 'isSelected' : ''}
                                disabled={!enabledCargos.includes(tab)}>
                                <FormattedMessage id={`wizard.cargo.form.${tab}.title`}/>
                            </Button>
                        ))}
                    </ButtonGroup>
                </Col>
            </Row>
        );
    };

    const getCommonError = () => {
        const generalError = get(errors, 'cargo.generals');
        const containerError = get(errors, 'cargo.containers');

        return (
            (!generalError && !containerError) ?
                null :
                <Col xs={12} lg={12}>
                    {generalError && <Error>{generalError}</Error>}
                    {containerError && <Error>{containerError}</Error>}
                </Col>
        );
    };

    const getSummaryMobile = () => {
        if (activeTab === TAB_TO_CARGO_TYPE.GENERAL_CARGO) {
            return (
                <div>
                    <Row className="wizard__cargo-from_summary_info_mobile">
                        <Col xs={4}>
                            <FormattedMessage id="wizard.cargo.unit"/>
                        </Col>
                        <Col xs={4}>
                            <FormattedMessage id="wizard.cargo.totalWeight"/>:
                        </Col>
                        <Col xs={4}>
                            <FormattedMessage id="wizard.cargo.totalVolume"/>:
                        </Col>
                    </Row>
                    <Row className="wizard__cargo-from_summary_info_mobile">
                        <Col xs={4}>
                            {getTotalUnits(cargo.generals)}
                        </Col>
                        <Col xs={4}>
                            {getGeneralTotalWeight(cargo.generals)}
                            {' '}
                            {weightUnitGeneral && weightUnitGeneral.formatName}
                        </Col>
                        <Col xs={4}>
                            {calcGeneralVolume(cargo.generals, sizeUnits)}
                            {' '}
                            <FormattedMessage id="wizard.cargo.form.size.M"/> <sup>3</sup>
                        </Col>
                    </Row>
                    <Row className="wizard__cargo-from_summary_info_mobile">
                        <Col xs={12}>
                            {getPromptForGeneralCargo(true)}
                        </Col>
                    </Row>

                </div>
            );
        }
        if (activeTab === TAB_TO_CARGO_TYPE.CONTAINER) {
            return (
                <div>
                    <Row className="wizard__cargo-from_summary_info_mobile">
                        <Col xs={6}>
                            <FormattedMessage id="wizard.cargo.unit"/>
                        </Col>
                        <Col xs={6}>
                            <FormattedMessage id="wizard.cargo.totalWeight"/>:
                        </Col>
                    </Row>
                    <Row className="wizard__cargo-from_summary_info_mobile">
                        <Col xs={6}>
                            {getTotalUnits(cargo.containers)}
                        </Col>
                        <Col xs={6}>
                            {getContainerTotalWeight(cargo.containers)}
                            {' '}
                            {weightUnitContainer && weightUnitContainer.formatName}
                        </Col>
                    </Row>
                </div>
            );
        }
    };

    const getSummary = () => {
        if (activeTab === TAB_TO_CARGO_TYPE.GENERAL_CARGO) {
            return (
                <div className="wizard__cargo-from_header_summary-cargo-info ml-auto text-right">
          <span>
            <FormattedMessage id="wizard.cargo.unit"/>:
              {' '}
              {getTotalUnits(cargo.generals)}
              {' '}{' '}
              <FormattedMessage id="wizard.cargo.totalWeight"/>:
              {' '}
              {getGeneralTotalWeight(cargo.generals)}
              {' '}
              {weightUnitGeneral && weightUnitGeneral.formatName}
              {' '}{' '}
              <FormattedMessage id="wizard.cargo.totalVolume"/>:
              {' '}
              {calcGeneralVolume(cargo.generals, sizeUnits)}
              {' '}
              <FormattedMessage id="wizard.cargo.form.size.M"/> <sup>3</sup>
          </span>
                </div>
            )
        }
        if (activeTab === TAB_TO_CARGO_TYPE.CONTAINER) {
            return (
                <div className="wizard__cargo-from_header_summary-cargo-info ml-auto text-right">
            <span>
              <FormattedMessage id="wizard.cargo.unit"/>:
                {' '}
                {getTotalUnits(cargo.containers)}
                {' '}{' '}
                <FormattedMessage id="wizard.cargo.totalWeight"/>:
                {' '}
                {getContainerTotalWeight(cargo.containers)}
                {' '}
                {weightUnitContainer && weightUnitContainer.formatName}
            </span>
                </div>
            )
        }
    }

    const  getPromptForGeneralCargo = (isM=false) => {
        let generalCargo = cargo.generals

        function getBodyPrompt(getVariantPrompt) {
            return <div className="text-left pb-0">
                {getVariantPrompt === 20 &&
                    <span onClick={() => {
                        setActiveTab(TAB_TO_CARGO_TYPE.CONTAINER)
                        setTimeout(() => {
                            const elem = document.getElementById("btnContainer0")
                            if (elem)
                                elem.click()
                        }, 600)
                    }}><FormattedMessage id={"wizard.tooltip.use20Container"}/></span>
                }
                {getVariantPrompt === 40 &&
                    <span onClick={() => {
                        setActiveTab(TAB_TO_CARGO_TYPE.CONTAINER)
                        setTimeout(() => {
                            const elem = document.getElementById("btnContainer1")
                            if (elem)
                                elem.click()
                        }, 600)
                    }}><FormattedMessage id={"wizard.tooltip.use40Container"}/></span>
                }
                {getVariantPrompt === 1 &&
                    <span onClick={() => {
                        setActiveTab(TAB_TO_CARGO_TYPE.CONTAINER)
                    }}><FormattedMessage id={"wizard.tooltip.useOtherContainer"}/></span>
                }
                 
                <span className="containerPromptBtn" onClick={() => {
                    setActiveTab(TAB_TO_CARGO_TYPE.CONTAINER)
                    if (getVariantPrompt === 20) {
                        setTimeout(() => {
                            const elem = document.getElementById("btnContainer0")
                            if (elem)
                                elem.click()
                        }, 600)
                    } else if (getVariantPrompt === 40) {
                        setTimeout(() => {
                            const elem = document.getElementById("btnContainer1")
                            if (elem)
                                elem.click()
                        }, 600)
                    }
                }}>
                                            {getVariantPrompt === 20 &&
                                                <FormattedMessage id={"wizard.tooltip.selectContainer20"}/>
                                            }
                    {getVariantPrompt === 40 &&
                        <FormattedMessage id={"wizard.tooltip.selectContainer40"}/>
                    }
                    {getVariantPrompt === 1 &&
                        <FormattedMessage id={"wizard.tooltip.selectContainerOther"}/>
                    }
                                        </span>
            </div>;
        }

        if (generalCargo.length > 0) {
            const checkShowPrompt = () => {
                if (enabledCargos.includes(TAB_TO_CARGO_TYPE.CONTAINER)) {
                    let summaryInfoCargo = {
                        volume: 0,
                        weight: 0
                    }
                    summaryInfoCargo.volume = calcGeneralVolume(generalCargo, sizeUnits)
                    summaryInfoCargo.weight = getGeneralTotalWeight(generalCargo)

                    for(let item of generalCargo){
                        if(item.errorOverload && item.errorOverload===true)
                           return 0;
                    }

                    const controlValue = 2 / 3
                    const cont20 = containerTypes[0];
                    const cont20V = cont20.size.width * cont20.size.length * cont20.size.height;
                    const cont40 = containerTypes[1];
                    const cont40V = cont40.size.width * cont40.size.length * cont40.size.height;
                    const volumeCargo = summaryInfoCargo.volume;

                    if (volumeCargo > cont20V *0.000001 * controlValue && summaryInfoCargo.weight <= cont20.maxWeight.value &&
                        volumeCargo <= cont20V *0.000001){
                        return 20;
                    }else if(volumeCargo > cont20V *0.000001 && summaryInfoCargo.weight <= cont40.maxWeight.value &&
                        volumeCargo < cont40V *0.000001){
                        return 40;
                    }else if(volumeCargo > cont40V *0.000001){
                        return 1;
                    }
                }

                return 0
            }

            const getVariantPrompt = checkShowPrompt()

            if(isM===true){
                return (
                    getVariantPrompt > 0 &&
                                    <Alert style={{marginLeft: "8px", marginRight: "8px"}} className={"mt-2"}
                                           color={"warning"}>
                                        {getBodyPrompt(getVariantPrompt)}
                                    </Alert>
                )
            }

            return (
                getVariantPrompt > 0 &&
                <Row>
                    <Col xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Alert style={isMobile?{marginLeft:"24px",marginRight:"15px"}:{marginLeft:"8px",marginRight:"8px"}} className={"mt-2"} color={"warning"}>
                                    {getBodyPrompt(getVariantPrompt)}
                                </Alert>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            )
        }
    }

    return (
      <div className="container block-margin-top" style={{boxShadow:"0px 4px 8px rgba(51, 51, 51, 0.05)"}} >
              <Row className="wizard__cargo-from">
                <Col xs={12}>
                  <Row className="wizard__cargo-from_header align-items-center">
                    <Col xs={12} lg={1} className="wizard__cargo-from_header_title">
                      <FormattedMessage id="form.wizard.title.cargo"/>
                    </Col>
                    {getSummary()}
                  </Row>
                  {getCargoTypes()}
                  {/* <Row>
                                      <div className="inv_show_pc">
                                          {getCommonError()}
                                      </div>
                   </Row>*/}
                </Col>
                <Col xs={12} className="px-0">
                    {directionErrors.cargoType && (
                        <div className="errorMessage px-4"><FormattedMessage id={directionErrors.cargoType}/></div>
                    )}
                    {activeTab === TAB_TO_CARGO_TYPE.GENERAL_CARGO &&
                        getPromptForGeneralCargo()
                    }
                    {activeTab === TAB_TO_CARGO_TYPE.GENERAL_CARGO &&
                    <CargoGeneralsForm
                        generalTypes={generalTypes}
                        palletTypes={preparePalletTypes}
                        onChange={onChange}
                        packings={cargo.generals}
                        weightUnits={prepareWeightUnits}
                        sizeUnits={sizeUnits}
                        errorsRefs = {errorsRef}
                        onErrorsRefs = {onErrorsRefs}
                        onAdd={() => onAddCargoType('generals')}
                        onRemove={i => onRemoveCargoType('generals', i)}
                        errors={errors}
                    />
                    }
                    {activeTab === TAB_TO_CARGO_TYPE.CONTAINER &&
                    <CargoContainersForm
                        containerTypes={containerTypes}
                        onChange={onChange}
                        onChangeAmount={onChangeContainerAmount}
                        containers={cargo.containers}
                        onAdd={() => onAddCargoType('containers')}
                        onRemove={i => onRemoveCargoType('containers', i)}
                        errors={errors}
                        weightUnits={prepareWeightUnits}
                        isUSADirection={isUSADirection}
                        errorsRef = {errorsRef}
                        onErrorsRefs = {onErrorsRefs}
                        isFake={isFake}
                    />
                    }
                    {activeTab === TAB_TO_CARGO_TYPE.VEHICLE &&
                    <CargoCarsForm
                        vehicleTypes={vehicleTypes}
                        onChange={onChange}
                        vehicle={cargo.vehicle}
                        isShowVehicleType={true}
                        /*isShowVehicleType={get(fields, 'fromLocation.countryCode') === 'RU' && get(fields, 'toLocation.countryCode') === 'RU'}*/
                        errors={errors}
                        errorsRef = {errorsRef}
                        onErrorsRefs = {onErrorsRefs}
                    />
                    }
                </Col>
            </Row>
            {getSummaryMobile()}
        </div>
    );
};

CargoForm.propTypes = {
    generalTypes: PropTypes.array,
    containerTypes: PropTypes.array,
    vehicleTypes: PropTypes.array,
    palletTypes: PropTypes.array,
    sizeUnits: PropTypes.array,
    fields: PropTypes.object,
    onChange: PropTypes.func,
    onAddCargoType: PropTypes.func,
    onRemoveCargoType: PropTypes.func,
    activeTab: PropTypes.string,
    setActiveTab: PropTypes.func,
    onChangeContainerAmount: PropTypes.func,
    errors: PropTypes.object,
    directionErrors: PropTypes.object,
    enabledCargos: PropTypes.array,
    allCargos: PropTypes.array,
    errorsRef: PropTypes.array,
    onErrorsRefs: PropTypes.func,
    isHighlight: PropTypes.bool,
};
