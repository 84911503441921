/* eslint-disable */
import React, { Fragment, Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { get, cloneDeep } from 'lodash';
import {AgoraDocActions} from './OrderCommon';
import {Button, Col, Row, Label, Alert} from 'reactstrap';
import './orders.css';
import { isEmpty,  } from 'lodash';
import DictionaryService from '../../services/dictionaryService';
import { Modal } from '../ui/Modal';
import moment from 'moment';
import {translate, addFormatName, getExistLangValue} from "../../utils/utils";
import { Select, TextInput } from '../../components/ui/form-control';

const testData = [
    {doctypeId: 1, name: 'Инвойс', creationData: null, authorName: null, authorPost: null},
    {doctypeId: 2, name: 'Упаковочный лист', creationData: null, authorName: null, authorPost: null},
    {doctypeId: 3, name: 'Товарно-транспортная накладная', creationData: null, authorName: null, authorPost: null},
    {doctypeId: 4, name: 'Счет на фрахт', creationData: '2020-08-21T00:00:00.00', authorName: 'Петров Сергей', authorPost: 'Менеджер'},
    {doctypeId: 5, name: 'Сертификат', creationData: '2020-08-21T00:00:00.00', authorName: 'Осокин Григорий', authorPost: 'Администратор'},
    {doctypeId: 6, name: 'Поручение логисту', creationData: '2020-08-21T00:00:00.00', authorName: 'Петров Сергей', authorPost: 'Менеджер'},
];

const DATE_TIME_FORMAT = 'DD.MM.YYYY';

class OrderDocs extends Component {
    state = {
        documentTypes: [],
        documentType: '',
        documentTitle: '',
        isCreateModalOpen:false,
        file: null,
    };


  componentDidUpdate(prevProps) {
    if (this.props.data.length !== prevProps.data.length) {
      this.updateDocumentTypes();
    }
  }

  updateDocumentTypes = () =>{
    DictionaryService.getDocumentTypes()
    .then((response) => {
      const { trackingInfo, data } = this.props;
      if (isEmpty(response)) {
        return;
      }

      let documentTypes = response;
      if (!isEmpty(data)) {
          let uids = [];
          data.map((item, index) => {
              if (item.doctypeId) {
                  uids.push(item.doctypeId);
              }
          });

          documentTypes = response.filter(item => (uids.indexOf(item.uid) <0 ))
      }

      if (trackingInfo && trackingInfo.pointOfArrival ) {
          documentTypes  = documentTypes.filter(item => ( item.locationTypes.indexOf(trackingInfo.pointOfArrival.locationType) > -1 ||
          item.locationTypes.length === 0 ));
      }

      this.setState({
        documentTypes: addFormatName(documentTypes),
        documentType: (documentTypes && documentTypes.length >0) ? documentTypes[0].uid : '',
      });
    });
  }

    async UNSAFE_componentWillMount() {
        this.updateDocumentTypes();
    }

    setModalOpen = (isOpen) => {
        if (!isOpen) {
            this.setState({
                isCreateModalOpen: isOpen,
                file: null,
                documentTitle: '',
                documentType: '',
            });
        } else {
            this.setState({
            isCreateModalOpen: isOpen,
        });
        }
    }

    prepareActions = (hasDoc, item, adminMode, fileName) => {
        if (!hasDoc) {
            if (adminMode){
                return [
                    {type: 'upload', onClick: null, onCreateDoc: this.props.onCreateDoc, fileKey: item.fileKey, formatName: item.name},
                ];
            }

            return [];
        }

        const actions4uploaded = [
            {
                type: 'send-email',
                onClick: () => {
                    if (item.onSend && typeof item.onSend === 'function'){
                        item.onSend(item.id);
                    }
                }
            }
        ];
        if (fileName) {
            actions4uploaded.push({
                type: 'download',
                onClick: () => {
                    if (item.onDownload && typeof item.onDownload === 'function'){
                        item.onDownload();
                    }
                }
            });
        }



        if (adminMode) {
         /*   actions4uploaded.push({
                type: 'rename',
                onClick: () => {
                    if (item.onRename && typeof item.onRename === 'function'){
                        item.onRename();
                    }
                }
            }); */

            actions4uploaded.push({
                type: 'remove',
                onClick: () => {
                    if (item.onDelete && typeof item.onDelete === 'function'){
                        item.onDelete();
                    }
                }
            });
        }

        return actions4uploaded;
    };

    getButtons = () => {
        return (
            <div className={'btns'}>
                <div className="crud-list__inner_actions">
                    <Button onClick={() => this.setModalOpen(true)}>
                        <span className={'btn-add-logo'}>+</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {translate({ id: 'common.documents.addBtnTitle'})}
                    </Button>
                </div>
            </div>
        );
    };

    onChangeDocumentType = (value) => {
        if (value) {
            this.setState({
                documentType: value.uid,
            });
        }
    };

    onChange = (value) => {
        this.setState({
            documentTitle: value,
        });
    };

    onFileUploaded = (e) => {
        this.setState({
            file: get(e, 'target.files[0]', null),
        });
    }

    onSave = () => {
        const {file, documentType,documentTitle} = this.state;

        if(this.props.onCreateDoc && typeof this.props.onCreateDoc === 'function'){
            this.props.onCreateDoc(file, documentType, documentTitle );
        }

        this.setModalOpen(false);
    }

    checkPossibilityAddOrderDoc = (documentType) => {

        const { arrivalPlace, departurePlace } = this.props.contacts;
        if (documentType == "ORDER_TO_LOGISTICIAN" && (isEmpty(departurePlace) || isEmpty(arrivalPlace))) {
            return false;
        } else {
            return true;
        }
    }

    renderModalContent = () => {
        const {documentTypes, documentType,documentTitle} = this.state;
        const isPossibilityAddOrderDoc = !this.checkPossibilityAddOrderDoc(documentType);
        return (
            <Fragment>
                {isPossibilityAddOrderDoc &&
                    <Alert className={"mt-0"} color={"warning"}>
                        <FormattedMessage id={"crudForm.field.common.documents.logisticDoc.warning"}/>
                    </Alert>}
            <Row className="document-modal">
                <Col xs={12}>
                <Select
                    placeholder="crudForm.field.common.documents.typeOfDocument"
                    labelKey="formatName"
                    valueKey="uid"
                    values={documentTypes}
                    value={documentType}
                    className="crud-select"
                    onChange={value => this.onChangeDocumentType(value)}
                  />
                <TextInput
                    placeholder={'common.documents.numberOfDocument'}
                    disabled={isPossibilityAddOrderDoc}
                    onChange={ev => this.onChange(ev.target.value)}
                    type="text"
                    />
                <input
                    ref={(ref) => { this.fileInput = ref; }}
                    id={'load-file-private-another'}
                    name="file"
                    type="file"
                    disabled={isPossibilityAddOrderDoc}
                    onChange={(e) => this.onFileUploaded(e)}
                />
                <div className="order-modal-buttons">
                    <Button onClick={() => { this.setModalOpen(false); }}>
                    <FormattedMessage id="deliveryOrders.cancel" />
                    </Button>
                    <Button color="primary"
                            onClick={() => this.onSave()}
                            disabled={isPossibilityAddOrderDoc}>
                    <FormattedMessage id="detailsOfCalculation.save" />
                    </Button>
                </div>
                </Col>

            </Row>
            <Row className="document-modal">
                <Col>

                </Col>
            </Row>
            </Fragment>
        );
    };

    getMobileDocuments = () => {
        const{ data, adminMode} = this.props;
        return (
        <div className="order-doc-mobile">

        {data.filter(x=>x.creationData).map((item, index) => {
          return (
            <div className={'mobile-card doc-card'} key={item.id}>
              <AgoraDocActions actions={this.prepareActions( item.creationData, item, adminMode, item.fileName)}/>
              <div className={'pale-font'}>{translate({ id: 'crudForm.field.common.documents.typeOfDocument' })}</div>
              <div>{item.documentType}</div>
              <div className={'pale-font'}>{translate({ id: 'common.documents.numberOfDocument' })}</div>
              <div>{item.name}</div>
              <div className={'pale-font'}>{translate({ id: 'common.documents.fileName' })}</div>
              <td>{item.fileName}</td>
              <div className={'pale-font'}>{translate({ id: 'common.documents.creationDate' })}</div>
              <div>{item.creationData? moment(item.creationData).format(DATE_TIME_FORMAT): ''}</div>
              <div className={'pale-font'}>{translate({ id: 'common.documents.userName' })}</div>
              <div>{item.authorName}</div>
            </div>
          );
        }
        )}
      </div>);
    }

    getTable = (doneDocAmount) => {
        const {data, adminMode} = this.props;

        return (
            <Fragment>
            <table className={'order-doc-table striped-table-with-head'}>
            <thead>
                <tr className={'pale-font'}>
                    <th></th>
                    <th>
                        {translate({ id: 'crudForm.field.common.documents.typeOfDocument'})}
                    </th>
                    <th>
                        {translate({ id: 'common.documents.numberOfDocument'})}
                    </th>
                    <th>
                        {translate({ id: 'common.documents.fileName'})}
                    </th>
                    <th>
                        {translate({ id: 'common.documents.creationDate'})}
                    </th>
                    <th>
                        {translate({ id: 'common.documents.userName'})}
                    </th>
                    <th>
                        {translate({ id: 'common.documents.actionsTitle'})}
                    </th>
                </tr>
            </thead>
            <tbody>
                {data.map((x, ind) => {
                    const hasDoc = x.creationData;

                    return <tr key={ind} className={hasDoc? '': 'pale-font'}>
                        <td><div className={x.fileName? 'clip-icon': ''}></div></td>
                        <td>{x.documentType}</td>
                        <td>{x.name}</td>
                        <td>{x.fileName}</td>
                        <td>{hasDoc? moment(x.creationData).format(DATE_TIME_FORMAT): ''}</td>
                        <td>{x.authorName}</td>
                        <td>
                            <AgoraDocActions actions={this.prepareActions(hasDoc, x, adminMode, x.fileName)} />
                        </td>
                    </tr>
                })}
            </tbody>
        </table>
            </Fragment>
        );
    };

    render() {
        const {adminMode, data} = this.props;
        const doneDocAmount = data.reduce((prev, item) => !item.creationData ? prev : ++prev, 0);

        return (
            <Fragment>
                <div className={'doc-amount-header'}>
                    <span className={'pale-font'}>{translate({ id: 'common.documents.attachedDocumentsTitle'})}: </span>{doneDocAmount}
                </div>

                {this.getTable(doneDocAmount)}
                {this.getMobileDocuments()}
                {adminMode && this.getButtons()}

                <Modal
                    isShow={this.state.isCreateModalOpen}
                    style={{ maxWidth: '780px' }}
                    title={translate({ id: 'common.documents.addDocumentOrder' })}
                    onClose={() => { this.setModalOpen(false); }}
                    top
                >
                    { this.renderModalContent() }
                </Modal>
            </Fragment>
        );
    }
};


OrderDocs.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    trackingInfo: PropTypes.arrayOf(PropTypes.object),
    onCreateDoc: PropTypes.func,
    fileInput: PropTypes.object,
    adminMode: PropTypes.object,
    documentModalClosed: PropTypes.bool,
    contacts: PropTypes.object,
};

export default OrderDocs;
