import HttpProvider from "./httpProvider";
import {API} from "../constants/api";

class ResourceReportService {
    constructor() {
        this.http = HttpProvider;
    }

    async getReport(from, to, organization=-1){
        return this.http.get(API.RESOURCE_REPORT.REPORT({from, to, organization}), false)
    }

    async getReport4User(userId, period){
        return this.http.post(API.RESOURCE_REPORT.REPORT4USER(), {userId, period})
    }
    async getReportCountry4User(userId, period){
        return this.http.post(API.RESOURCE_REPORT.REPORT_COUNTRY4USER(), {userId, period})
    }
    async getCalculationsReport4User(userId, period){
        return this.http.post(API.RESOURCE_REPORT.REPORT_CALCULATIONS4USER(), {userId, period})
    }

}
export default new ResourceReportService();
