/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {setName, translate} from '../../../utils/utils';

import Select from './Select';
import DictionaryService from "../../../services/dictionaryService";
import FontAwesome from "react-fontawesome";
import {isNumber} from "lodash";

export default class SelectHub extends Component {
    state = {
        values: [],
    };

    async UNSAFE_componentWillMount() {
        const data = await DictionaryService.getAllHubs();

        const values = data.map((x) => {
            return {
                value: x.id,
                name: setName(x),
                type: x.type,
            };
        });

        this.setState({ values });
    }

    customComponentForSelect = (option) => {
        const typeToIconName = {
            SEA_PORT: 'anchor',
            TRANSPORT: 'truck',
            AIRPORT_HUB: 'plane',
            RAILWAY_HUB: 'train',
        };
        const icon = typeToIconName[option.type] || '';

        return (
            <div>
                <FontAwesome name={icon} style={{ paddingRight: '10px' }} />{option.name}
            </div>
        );
    };

    render() {
        const { value, leftAddon, error, label, onChange, readonly} = this.props;
        if (value!==null && value !== undefined && !isNumber(value))
            value.name = setName(value)
        return (
            <Select
                label={label}
                leftAddon={leftAddon}
                values={this.state.values}
                labelKey="name"
                value={value}
                error={error}
                onChange={onChange}
                disabled={readonly}
                searchable
                optionRenderer={this.customComponentForSelect}
                valueRenderer={this.customComponentForSelect}
            />
        );
    }
}

SelectHub.defaultProps = {
    className: '',
    classNameGroup: '',
    noResultsText: 'form.select.noResultText',
    searchable: false,
    showErrors: true,
    async: false,
    multi: false,
    readonly: false,
};

SelectHub.propTypes = {
    async: PropTypes.bool, // eslint-disable-line
    multi: PropTypes.bool, // eslint-disable-line
    loadOptions: PropTypes.func, // eslint-disable-line
    className: PropTypes.string, // eslint-disable-line
    classNameGroup: PropTypes.string, // eslint-disable-line
    label: PropTypes.string, // eslint-disable-line
    placeholder: PropTypes.string, // eslint-disable-line
    note: PropTypes.string, // eslint-disable-line
    error: PropTypes.string, // eslint-disable-line
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]), // eslint-disable-line
    noResultsText: PropTypes.string, // eslint-disable-line
    onChange: PropTypes.func, // eslint-disable-line
    optionRenderer: PropTypes.func, // eslint-disable-line
    optionComponent: PropTypes.func, // eslint-disable-line
    valueRenderer: PropTypes.func, // eslint-disable-line
    searchable: PropTypes.bool, // eslint-disable-line
    onInputChange: PropTypes.func, // eslint-disable-line
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.number]), // eslint-disable-line
    disabled: PropTypes.bool, // eslint-disable-line
    leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // eslint-disable-line
    rightAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // eslint-disable-line
    isButton: PropTypes.bool, // eslint-disable-line
    showErrors: PropTypes.bool, // eslint-disable-line
    readonly: PropTypes.bool, // eslint-disable-line
};
