import AppStore from "../../../stores/appStore";
import React, {useEffect, useState} from "react";
import {cloneDeep, debounce, get, set} from "lodash";
import orderService from "../../../services/orderService";
import {useDebouncedCallback} from "use-debounce";
import {Button, Col, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";
import {PriceField} from "./PriceField";
import {addFormatName, formatCost, getExistLangValue, translate} from "../../../utils/utils";
import {Checkbox, NumberInput} from "../form-control";
import dictionaryService from "../../../services/dictionaryService";
import {VEHICLE_BODY_TYPE} from "../../../constants/staticData";

const LoadingComponent = () => {
    return (
        <span className="fa-1x text-right inline-block">
            <i className="fa fa-spinner fa-spin"/>
        </span>
    )
}

const FormatPriceComponent = ({loadingPrice, priceRUB, priceUSD}) => {
    const isRUB = AppStore.userLang === "ru"
    return (
        <div className={"text-right font-weight-bold"}>
            {isRUB &&
                <span>{loadingPrice ?
                    <LoadingComponent/> : formatCost(Math.round(priceRUB))}{" "}
                    <span className={"primary-color"}>RUB</span>
            </span>
            }
            {isRUB && " ("}
            <span>{loadingPrice ?
                <LoadingComponent/> : formatCost(parseFloat(priceUSD))}{" "}
                <span className={"primary-color"}>USD</span>
                                </span>
            {isRUB && ")"}
        </div>
    )
}

export const FakeOrderPriceBuildComponent = ({
                                                 hasAccess = false,
                                                 readonly = false,
                                                 setReadonly = () => {
                                                 },
                                                 typeCargo,
                                                 currencies = [],
                                                 prices = [],
                                                 setPrices = () => {
                                                 },
                                                 errors = {},
                                                 onErrorDelete = (name) => {
                                                 },
                                                 onSave,
                                                 onCancel,
                                                 cargoArray = [],
                                             }) => {
    const defaultCurrency = AppStore.userLang === "ru" ? "RUB" : "USD"
    const [summaryPrice, setSummaryPrice] = useState()
    const [summaryPriceAll, setSummaryPriceAll] = useState()
    const [loadingPrice, setLoadingPrice] = useState(false)
    const [loadingPriceAll, setLoadingPriceAll] = useState(false)
    const [services, setServices] = useState([])
    const [autoCalculateVat, setAutoCalculateVat] = useState(false)
    const [vatValue, setVatValue] = useState(20)
    const [activeTabIndex, setActiveTabIndex] = useState(0)
    const [vehicleTypes, setVehicleTypes] = useState([])

    useEffect(() => {
        dictionaryService.getVehicleTypes().then(res=>{
            setVehicleTypes(addFormatName(res.data))
        })
    }, []);

    const _debounceChangeCargoArray =(cargoArray)=>{
        setActiveTabIndex(0)
        let newPrices = cloneDeep(prices)
        cargoArray.forEach((cargo, index) => {
            if (prices.filter(price => price.idCargo === cargo.idCargo).length === 0) {
                newPrices.push({
                    idCargo: cargo.idCargo,
                    typeService: null,
                    enabledVAT: false,
                    nds: {
                        value: 0,
                        currency: "RUB"
                    },
                    price: {
                        value: 0,
                        currency: defaultCurrency
                    }
                })
            }
        })

        newPrices = newPrices.filter((price, i) => cargoArray.map(v => v.idCargo).includes(price.idCargo))
        setPrices(newPrices)
    }

    const debounceChangeCargoArray = useDebouncedCallback(_debounceChangeCargoArray, 1000)

    useEffect(() => {
        debounceChangeCargoArray(cargoArray)
    }, [cargoArray])

    const formatNameCargo = (cargoArray, index, cargoType, vehicleTypes=[], fullCargoArray)=>{
        const cargo = cargoArray[index];
        let amount = fullCargoArray.filter(v=>v.idCargo===cargo?.idCargo)?.length;
        if(cargo?.type){
            if(cargoType==="containers")
                return `${translate({id:`cargoType.${cargoType}`})} - ${amount} ${translate({id:"detailsOfCalculation.pieces"})} - ${cargo?.type?.formatName}`
            if(cargoType==="car"){
                console.log("VEHICLE: ", cargo, cargo.type)
                const vehicleType = cargo.type
                const bodyType = VEHICLE_BODY_TYPE.find(v=>v.value===cargo.bodyType)?.message
                return `${translate({id:`cargoType.${cargoType}`})} - ${vehicleType?.name ? getExistLangValue(vehicleType?.name):''} - ${translate({id:`constants.${bodyType}`})}`
            }
        }

        if(cargo?.typeId !== undefined){
            if(cargoType==="containers"){
                const typeId={
                    1:"20'",
                    2:"40'",
                    3:"40'HC",
                    "":""
                }
                return `${translate({id:`cargoType.${cargoType}`})} - ${cargo?.amount} ${translate({id:"detailsOfCalculation.pieces"})} - ${typeId[cargo?.typeId]}`
            }
            if(cargoType==="box"){
                const typeId={
                    1:"cargoType.box",
                    2:"cargoType.pallet",
                    3:"cargoType.cube"
                }
                let formatSize = `${cargo?.length ? cargo?.length:'-'} x ${cargo?.width ? cargo?.width : '-'} x ${cargo?.height ? cargo?.height :'-'} (${cargo?.sizeUnit}) - ${cargo?.weight} ${cargo?.weightUnit}`
                if(cargo.typeId===3){
                    formatSize = `${cargo.cubeVolume ? cargo.cubeVolume : "-"} ${cargo.sizeUnit} - ${cargo?.weight} ${cargo?.weightUnit}`
                }
                return `${translate({id:`${typeId[cargo.typeId]}`})} - ${cargo?.amount} ${translate({id:"detailsOfCalculation.pieces"})} - ${formatSize}`
            }
            if(cargoType==="car"){

                const vehicleType = vehicleTypes.find(v=>v.id === cargo.typeId)
                const bodyType = VEHICLE_BODY_TYPE.find(v=>v.value===cargo.bodyType)?.message
                return `${translate({id:`cargoType.${cargoType}`})} - ${vehicleType?.formatName ? vehicleType?.formatName:''} - ${translate({id:`constants.${bodyType}`})}`
            }
        }
        if(cargoType){
            return `${translate({id:`cargoType.${cargoType}`})} - `
        }
    }

    const onChange = (name, value, idx) => {
        let index = idx
        if (prices.filter(v => get(v, "emptyRow", null) !== null).length !== 0) {
            index += 1;
        }
        let newData = cloneDeep(prices)

        if (name === null && value === null) {
            newData = newData.filter((item, i) => i !== index)
            onErrorDelete(`prices[${index}].typeService`)
            setTimeout(() => {
                onErrorDelete(`prices[${index}].price`)
            }, 150)
        } else {
            set(newData, `[${index}].${name}`, value)
            const item = get(newData, `[${index}]`)
            if (name === "price.value" && item.price.currency === "RUB" && autoCalculateVat && vatValue > 0) {
                set(newData, `[${index}].nds.value`, (value * vatValue / 100))
            }
            if (name === "price.currency") {
                if (value !== "RUB")
                    set(newData, `[${index}].nds.value`, 0)
                else if (autoCalculateVat && vatValue > 0)
                    set(newData, `[${index}].nds.value`, (item.price.value * vatValue / 100))
            }
            let nameToDeleteError = cloneDeep(name)
            if (nameToDeleteError.includes("price") === true)
                nameToDeleteError = nameToDeleteError.replace(".value", "")
            onErrorDelete(`prices[${index}].${nameToDeleteError}`)
        }

        setPrices(newData)
    }

    useEffect(() => {
        if (vatValue > 0 && autoCalculateVat) {
            let newData = cloneDeep(prices)
            newData.filter(v => get(v, "emptyRow", null) === null).forEach(item => {
                if (item.idCargo === cargoArray[activeTabIndex]?.idCargo)
                    if (item.price.currency === "RUB") {
                        item.nds.value = (item.price.value * vatValue / 100)
                    }
            })
            setPrices(newData)
        }
    }, [autoCalculateVat, vatValue])

    const filterServicesByTypeCargo = (services) => {
        const cloneServices = cloneDeep(services)
        setServices(cloneServices.filter(service => {
            switch (typeCargo) {
                case "containers":
                    return [
                        "cargo_auto_transportation",
                        "sea_trans",
                        "railway_services",
                        "complex_trans"].includes(service.uid)
                case "box":
                    return ["cargo_auto_transportation",
                        "sea_trans",
                        "air_trans",
                        "railway_services",
                        "complex_trans"].includes(service.uid)
                case "car":
                    return ["main_line_haulage"].includes(service.uid)
                default:
                    return false
            }
        }))
    }

    useEffect(() => {
        if (typeCargo)
            orderService.getAllowedServices4FakeOrder().then(res => {
                if (res.code === 200) {
                    filterServicesByTypeCargo(addFormatName(res.data))
                }
            })
    }, [typeCargo])

    const findAmount =(v, cargoArray=[])=>{
        let cargoById = cargoArray.filter(value => value.idCargo === v.idCargo);
        let amount = 0
        if(cargoById.length===1) {
            amount = cargoById[0]?.amount ? cargoById[0]?.amount : 1
        }
        else if(cargoById.length>1){
            amount = cargoById.length;
        }
        return amount;
    }

    const _recalculationPrice = async (prices = [], setPrices, loadingPrice) => {
        const pricesArr = prices?.map(v => {
            return {value: findAmount(v, cargoArray)*v?.price?.value, currency: v?.price?.currency}
        }).filter(value => value?.value > 0)
        const ndsArr = prices?.filter(price => price.enabledVAT).map(v => {
            return {value: findAmount(v, cargoArray)*v?.nds?.value, currency: v?.nds?.currency}
        }).filter(value => value.value > 0)
        return orderService.recalculateFakePrices({priceList: pricesArr, ndsList: ndsArr}).then(res => {
            setPrices(res.data)
            loadingPrice(false)
        })
    }

    const recalculationPrice = useDebouncedCallback(_recalculationPrice, 1000)
    const recalculationPriceAll = useDebouncedCallback(_recalculationPrice, 1000)

    useEffect(() => {
        if (prices?.length > 0) {
            setLoadingPrice(true)
            setLoadingPriceAll(true)
            recalculationPrice(prices.filter(v => v.idCargo === reduceOneIdCargoItems(cargoArray)[activeTabIndex]?.idCargo), setSummaryPrice, (bool) => setLoadingPrice(bool))
            recalculationPriceAll(prices, setSummaryPriceAll, (bool) => setLoadingPriceAll(bool))
        } else {
            setSummaryPrice({
                nds: {
                    USD: {value: 0, currency: "USD"},
                    RUB: {value: 0, currency: "RUB"},
                },
                totalCost: {
                    USD: {value: 0, currency: "USD"},
                    RUB: {value: 0, currency: "RUB"},
                },
            })
            setSummaryPriceAll({
                nds: {
                    USD: {value: 0, currency: "USD"},
                    RUB: {value: 0, currency: "RUB"},
                },
                totalCost: {
                    USD: {value: 0, currency: "USD"},
                    RUB: {value: 0, currency: "RUB"},
                },
            })
        }
    }, [prices, activeTabIndex])

    const hasError = (i) => {
        try {
            const cargoServicesWithErrors = prices
                .filter((price, index) => Object.keys(errors).filter(error => error.includes(`prices[${index}]`)).length > 0)
                .filter(price => price?.idCargo === cargoArray[i]?.idCargo)
            return cargoServicesWithErrors.length > 0
        } catch (e) {
            return false;
        }
    }

    const reduceOneIdCargoItems=(cargo)=>{
        const useCargoId = []
        const filteredCargo = []
        for(let cargoItem of cargo){
            if(!useCargoId.includes(cargoItem.idCargo)){
                useCargoId.push(cargoItem.idCargo)
                filteredCargo.push(cargoItem)
            }
        }
        return filteredCargo;
    }
    const getAmountCargoItems=(cargoArray, price)=>{
        const result =cargoArray.filter(cargo=>cargo.idCargo===price.idCargo);
        if(result.length===1){
            return result[0]?.amount ? result[0]?.amount : 1
        }else{
            return result.length
        }
    }


    return (
        <div>
            <Row className={"d-block d-md-flex"}>
                {reduceOneIdCargoItems(cargoArray).filter((value, index, self) => self.indexOf(value) === index).map((v, i) => {
                    return (
                        <Col xs={12} md={"auto"} key={i} className={`pl-0 mt-2 mb-md-0`}>
                            <Button
                                onClick={() => setActiveTabIndex(i)}
                                className={`btn-outline-primary-new w-100 d-flex align-items-center justify-content-center no-focus ${i === activeTabIndex ? "active" : ""}`}
                            >
                                {formatNameCargo(reduceOneIdCargoItems(cargoArray),i, typeCargo, vehicleTypes, cargoArray)}
                                {hasError(i) &&
                                    <span className={"error-tab"}><FormattedMessage id={"common.error"}/></span>}
                            </Button>
                        </Col>
                    )
                })}
            </Row>
            <div className={`table-custom-prices d-none d-md-block ${cargoArray?.length > 0 ? 'mt-3' : ''}`}>
                <Row className={"d-none d-md-flex row-table table-header"}>
                    <Col xs={12} md={3} className={"row-cell"}><FormattedMessage id={"priceField.typeService"}/></Col>
                    <Col xs={12} md={4} className={"row-cell"}><FormattedMessage id={"priceField.cost"}/></Col>
                    <Col xs={12} md={4} className={"row-cell"}><FormattedMessage id={"priceField.vat"}/></Col>
                    <Col xs={12} md={1} className={"d-none d-md-flex row-cell"}></Col>
                </Row>
                {prices.filter(v => get(v, "emptyRow", null) === null).map((item, index) => {
                    if (item.idCargo === reduceOneIdCargoItems(cargoArray)[activeTabIndex]?.idCargo)
                        return (
                            <PriceField
                                readonly={readonly}
                                size={prices?.filter(item=>item.idCargo === reduceOneIdCargoItems(cargoArray)[activeTabIndex]?.idCargo).length}
                                amountCargo={getAmountCargoItems(cargoArray, item)}
                                services={services}
                                currencies={currencies}
                                item={item}
                                index={index}
                                onChange={onChange}
                                errors={errors}
                                onErrorDelete={onErrorDelete}

                            />
                        )
                })}
                {/*Итог по конкретному грузу*/}
                <Row className={"d-none d-md-flex row-table table-header"}>
                    <Col xs={12} md={3} className={"row-cell"}><FormattedMessage id={"priceField.total"}/>:</Col>
                    <Col xs={12} md={4} className={"row-cell pr-0"}>
                        <FormatPriceComponent
                            priceRUB={get(summaryPrice?.totalCost, "RUB.value", 0)}
                            priceUSD={get(summaryPrice?.totalCost, "USD.value", 0)}
                            loadingPrice={loadingPrice}
                        />
                    </Col>
                    <Col xs={12} md={4} className={"row-cell pr-0"}>
                        <FormatPriceComponent
                            priceRUB={get(summaryPrice?.nds, "RUB.value", 0)}
                            priceUSD={get(summaryPrice?.nds, "USD.value", 0)}
                            loadingPrice={loadingPrice}
                        />
                    </Col>
                    <Col xs={12} md={1} className={"d-none d-md-flex row-cell"}></Col>
                    <Col md={3}></Col>
                    <Col md={8} className={"pr-0"}>
                        <hr/>
                    </Col>
                    <Col md={1}></Col>
                    <Col xs={12} md={7} className={"row-cell d-flex justify-content-end pr-0"}>
                        <span><FormattedMessage id={"priceField.costWithVat"}/>:</span>
                        <span className={"ml-2"}>
                            <FormatPriceComponent
                                priceRUB={get(summaryPrice?.costWithNDS, "RUB.value", 0)}
                                priceUSD={get(summaryPrice?.costWithNDS, "USD.value", 0)}
                                loadingPrice={loadingPrice}
                            />
                        </span>
                    </Col>
                    <Col xs={12} md={4} className={"d-none d-md-flex row-cell"}></Col>

                </Row>
            </div>
            {!readonly &&
                <Row className={"mt-3"}>
                    <Col xs={12} md={4} className={"pl-0"}>
                        <Button className={"btn-primary-new d-none d-md-block"}
                                onClick={() => setPrices([...prices, {
                                    idCargo: reduceOneIdCargoItems(cargoArray)[activeTabIndex]?.idCargo,
                                    typeService: null,
                                    price: {value: 0, currency: defaultCurrency},
                                    nds: {value: 0, currency: defaultCurrency},
                                    enabledVAT: false,
                                }])}>
                            <FormattedMessage id={"priceField.add"}/>
                        </Button>
                    </Col>
                    {AppStore.userLang === "ru" &&
                        <Col xs={12} md={8} className={"d-flex align-items-center justify-content-end pr-0"}>
                            <Checkbox
                                label={"priceField.autoIncludeVat"}
                                checked={autoCalculateVat}
                                onChange={e => setAutoCalculateVat(e.target.checked)}
                            />
                            {autoCalculateVat &&
                                <div className={"ml-3 align-items-center d-flex"}>
                                    <NumberInput style={{height: 38, width: 90}}
                                                 className={"mb-0"}
                                                 value={vatValue}
                                                 step={1}
                                                 onChange={e => setVatValue(e)}
                                    />
                                    <span style={{fontSize: 18}} className={"ml-1"}>%</span>
                                </div>

                            }
                        </Col>
                    }
                </Row>
            }
            {/*Итог по всем грузам*/}
            <Row className={"d-none d-md-flex row-table table-header"} style={{marginTop:40}}>
                <Col xs={12} md={3} className={"row-cell"}></Col>
                <Col xs={12} md={4} className={"row-cell text-right pr-0"}><FormattedMessage id={"priceField.cost"}/></Col>
                <Col xs={12} md={4} className={"row-cell text-right pr-0"}><FormattedMessage id={"priceField.vat"}/></Col>
                <Col xs={12} md={1} className={"d-none d-md-flex row-cell"}></Col>
            </Row>
            <Row className={"d-none d-md-flex row-table table-header"} style={{marginTop:25}}>
                <Col xs={12} md={3} className={"row-cell pl-0"}><FormattedMessage id={"priceField.totalAllCargo"}/>:</Col>
                <Col xs={12} md={4} className={"row-cell pr-0"}>
                    <FormatPriceComponent
                        priceRUB={get(summaryPriceAll?.totalCost, "RUB.value", 0)}
                        priceUSD={get(summaryPriceAll?.totalCost, "USD.value", 0)}
                        loadingPrice={loadingPriceAll}
                    />
                </Col>
                <Col xs={12} md={4} className={"row-cell pr-0"}>
                    <FormatPriceComponent
                        priceRUB={get(summaryPriceAll?.nds, "RUB.value", 0)}
                        priceUSD={get(summaryPriceAll?.nds, "USD.value", 0)}
                        loadingPrice={loadingPriceAll}
                    />
                </Col>
                <Col xs={12} md={1} className={"d-none d-md-flex row-cell"}></Col>
                <Col md={3}></Col>
                <Col md={8} className={"pr-0"}>
                    <hr/>
                </Col>
                <Col md={1}></Col>
                <Col xs={12} md={7} className={"row-cell d-flex justify-content-end pr-0 pl-0"}>
                    <span><FormattedMessage id={"priceField.costWithVat"}/>:</span>
                    <span className={"ml-2"}>
                            <FormatPriceComponent
                                priceRUB={get(summaryPriceAll?.costWithNDS, "RUB.value", 0)}
                                priceUSD={get(summaryPriceAll?.costWithNDS, "USD.value", 0)}
                                loadingPrice={loadingPriceAll}
                            />
                        </span>
                </Col>
                <Col xs={12} md={4} className={"d-none d-md-flex row-cell"}></Col>
            </Row>

            <div className={"table-custom-prices-mobile d-md-none d-block"}>
                {prices.filter(v => get(v, "emptyRow", null) === null).map((item, index) => {
                    if (item.idCargo === reduceOneIdCargoItems(cargoArray)[activeTabIndex]?.idCargo)
                        return (
                            <PriceField
                                readonly={readonly}
                                size={prices?.filter(item=>item.idCargo === reduceOneIdCargoItems(cargoArray)[activeTabIndex]?.idCargo).length}
                                amountCargo={getAmountCargoItems(cargoArray, item)}
                                services={services}
                                currencies={currencies}
                                item={item}
                                index={index}
                                onChange={onChange}
                                errors={errors}
                                onErrorDelete={onErrorDelete}
                            />
                        )
                })}
                {!readonly &&
                    <Button className={"btn-primary-new mt-3"}
                            onClick={() => setPrices([...prices, {
                                idCargo: reduceOneIdCargoItems(cargoArray)[activeTabIndex]?.idCargo,
                                typeService: null,
                                price: {value: 0, currency: defaultCurrency},
                                nds: {value: 0, currency: defaultCurrency},
                                enabledVAT: false,
                            }])}>
                        <FormattedMessage id={"priceField.add"}/>
                    </Button>
                }
                <Row className={"d-block row-table pl-0 no-border"}>
                    <Col xs={12} className={"row-cell"}><FormattedMessage id={"priceField.total"}/>:</Col>
                    <Col xs={12} className={"row-cell "}>
                        <div>
                            <FormattedMessage id={"priceField.vat"}/>:
                        </div>
                        <FormatPriceComponent
                            priceRUB={get(summaryPrice?.nds, "RUB.value", 0)}
                            priceUSD={get(summaryPrice?.nds, "USD.value", 0)}
                            loadingPrice={loadingPrice}
                        />
                    </Col>
                    <Col xs={12} className={"row-cell "}>
                        <div>
                            <FormattedMessage id={"priceField.cost"}/>:
                        </div>
                        <FormatPriceComponent
                            priceRUB={get(summaryPrice?.totalCost, "RUB.value", 0)}
                            priceUSD={get(summaryPrice?.totalCost, "USD.value", 0)}
                            loadingPrice={loadingPrice}
                        />
                    </Col>
                    <Col xs={12} className={"row-cell "}>
                        <div>
                            <FormattedMessage id={"priceField.costWithVat"}/>:
                        </div>
                        <FormatPriceComponent
                            priceRUB={get(summaryPrice?.costWithNDS, "RUB.value", 0)}
                            priceUSD={get(summaryPrice?.costWithNDS, "USD.value", 0)}
                            loadingPrice={loadingPrice}
                        />
                    </Col>
                </Row>
                <Row className={"d-block row-table pl-0"}>
                    <Col xs={12} className={"row-cell"}><FormattedMessage id={"priceField.totalAllCargo"}/>:</Col>
                    <Col xs={12} className={"row-cell "}>
                        <div>
                            <FormattedMessage id={"priceField.vat"}/>:
                        </div>
                        <FormatPriceComponent
                            priceRUB={get(summaryPriceAll?.nds, "RUB.value", 0)}
                            priceUSD={get(summaryPriceAll?.nds, "USD.value", 0)}
                            loadingPrice={loadingPriceAll}
                        />
                    </Col>
                    <Col xs={12} className={"row-cell "}>
                        <div>
                            <FormattedMessage id={"priceField.cost"}/>:
                        </div>
                        <FormatPriceComponent
                            priceRUB={get(summaryPriceAll?.totalCost, "RUB.value", 0)}
                            priceUSD={get(summaryPriceAll?.totalCost, "USD.value", 0)}
                            loadingPrice={loadingPriceAll}
                        />
                    </Col>
                    <Col xs={12} className={"row-cell "}>
                        <div>
                            <FormattedMessage id={"priceField.costWithVat"}/>:
                        </div>
                        <FormatPriceComponent
                            priceRUB={get(summaryPriceAll?.costWithNDS, "RUB.value", 0)}
                            priceUSD={get(summaryPriceAll?.costWithNDS, "USD.value", 0)}
                            loadingPrice={loadingPriceAll}
                        />
                    </Col>
                </Row>
            </div>
            <Row className={"mt-3"}>
                {readonly && hasAccess &&
                    <Col xs={12} md={4} className={"pl-0"}>
                        <Button className={"btn-primary-new"}
                                onClick={setReadonly}>
                            <FormattedMessage id={"deliveryOrders.orderBlock.orderActions.edit"}/>
                        </Button>
                    </Col>
                }
                {(!readonly && typeof onSave === 'function' && typeof onCancel === 'function') &&
                    <Col xs={12} md={8} className={"pl-0"}>
                        <Button className={"btn-primary-new"}
                                onClick={onSave}>
                            <FormattedMessage id={"crudForm.button.save"}/>
                        </Button>
                        <Button className={"btn-primary-new ml-2"}
                                onClick={onCancel}>
                            <FormattedMessage id={"crudForm.button.cancel"}/>
                        </Button>
                    </Col>
                }
            </Row>

        </div>
    )
}