import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import DictionaryService from '../../../services/dictionaryService';
import Select from './Select';
import { setName } from '../../../utils/utils';

export default class SelectSeaPortByCountry extends Component {
  state = {
    values: [],
    value: '',
  };

  UNSAFE_componentWillMount() {
    this.setState({ value: this.props.value });
  }

  async UNSAFE_componentWillReceiveProps(newProps) {
    this.setState({ value: newProps.value });

    if (newProps.value) {
      const city = await DictionaryService.getSeaPortHubsById(newProps.value);

      const value = {
        value: city.id,
        name: setName(city),
      };

      const clonedState = cloneDeep(this.state.values) || [];

      clonedState.push(value);

      if (this.state.value === '') {
        clonedState.pop();
      }
      const prepareClone = this.prepareAndFilterHubs(clonedState);

      this.setState({
        values: prepareClone,
      });
    }
  }

  prepareAndFilterHubs(hubs) {
    const used = {};

    return hubs.filter(obj => obj).filter((obj) => {
      if (obj.value in used) {
        return 0;
      }
      used[obj.value] = 1;

      return used[obj.value];
    });
  }

  render() {
    const { leftAddon, error, label, onChange, disabled, values } = this.props;
    const { value } = this.state;

    return (
      <Select
        searchable
        disabled={disabled}
        label={label}
        leftAddon={leftAddon}
        values={values}
        labelKey="name"
        value={value}
        error={error}
        onChange={onChange}
        cache={false}
      />
    );
  }
}

SelectSeaPortByCountry.defaultProps = {
  noResultsText: 'form.select.noResultText',
  countryId: 0,
  showErrors: true,
  disabled: false,
};

SelectSeaPortByCountry.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
  values: PropTypes.array,
};
