import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from './Select';

const LANGUAGE_VALUES = [
  {
    value: 'EN',
    label: 'English',
  },
  {
    value: 'RU',
    label: 'Русский',
  },
];

export default class SelectLanguage extends Component {
  state = {
    values: LANGUAGE_VALUES,
  };

  render() {
    const { leftAddon, value, onChange, disabled, error } = this.props;
    const { values } = this.state;

    return (
      <Select
        searchable
        leftAddon={leftAddon}
        value={value}
        values={values}
        onChange={onChange}
        disabled={disabled}
        error={error}
      />
    );
  }
}

SelectLanguage.defaultProps = {
  disabled: false,
};

SelectLanguage.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
};
