/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {setName, translate} from '../../../utils/utils';

import Select from './Select';
import DictionaryService from "../../../services/dictionaryService";
import {isNumber} from "lodash";

export default class SelectRailwayStation extends Component {
    state = {
        values: [],
    };

    async UNSAFE_componentWillMount() {
        const data = await DictionaryService.getRailwayHubs();

        for(let item of data)
            item.name = setName(item)

        this.setState({ values: data });
    }

    render() {
        const { value, leftAddon, error, label, onChange, readonly } = this.props;

        return (
            <Select
                label={label}
                leftAddon={leftAddon}
                values={this.state.values}
                labelKey="name"
                value={value}
                valueKey={"id"}
                error={error}
                onChange={onChange}
                disabled={readonly}
                searchable
            />
        );
    }
}

SelectRailwayStation.defaultProps = {
    className: '',
    classNameGroup: '',
    noResultsText: 'form.select.noResultText',
    searchable: false,
    showErrors: true,
    async: false,
    multi: false,
    readonly: false,
};

SelectRailwayStation.propTypes = {
    async: PropTypes.bool, // eslint-disable-line
    multi: PropTypes.bool, // eslint-disable-line
    loadOptions: PropTypes.func, // eslint-disable-line
    className: PropTypes.string, // eslint-disable-line
    classNameGroup: PropTypes.string, // eslint-disable-line
    label: PropTypes.string, // eslint-disable-line
    placeholder: PropTypes.string, // eslint-disable-line
    note: PropTypes.string, // eslint-disable-line
    error: PropTypes.string, // eslint-disable-line
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]), // eslint-disable-line
    noResultsText: PropTypes.string, // eslint-disable-line
    onChange: PropTypes.func, // eslint-disable-line
    optionRenderer: PropTypes.func, // eslint-disable-line
    optionComponent: PropTypes.func, // eslint-disable-line
    valueRenderer: PropTypes.func, // eslint-disable-line
    searchable: PropTypes.bool, // eslint-disable-line
    onInputChange: PropTypes.func, // eslint-disable-line
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.number]), // eslint-disable-line
    disabled: PropTypes.bool, // eslint-disable-line
    leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // eslint-disable-line
    rightAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // eslint-disable-line
    isButton: PropTypes.bool, // eslint-disable-line
    showErrors: PropTypes.bool, // eslint-disable-line
    readonly: PropTypes.bool, // eslint-disable-line
};
