import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, ModalFooter,
  ModalHeader, ModalBody
} from 'reactstrap';
import AppStore from '../../stores/appStore';

function WidgetFooter({ terms = '' }) {
  const [modal, setModal] = React.useState(false);
  const [termsUrl, setTermsUrl] = React.useState(AppStore.terms);

  const toggle = () => {
    setModal(!modal);
    return false;
  };

  // trr receive terms url from AppStore
  useEffect(() => {
    if (!termsUrl) {
      setTermsUrl(AppStore.terms);
    }
  },[AppStore.terms]);

  return (
      <div className="widgetFooter">
        <div className="container">
          <div className={"row"}>
            <div className={"col-12 px-0"}>
              <div className="widgetFooter__text">
                {/*<span>Powered by</span>*/}
                {/*{' '}*/}
                {/*<a className="widgetFooter__link" href="https://cargobar.ru" target="_blank">*/}
                {/*  <div className="widgetFooter__logoImage"/>*/}
                {/*</a>*/}

                {termsUrl &&
                    <div className="widgetFooter__text ml-4">
                      <span className="widgetFooter__link" href="#" style={{cursor: 'pointer'}} onClick={toggle}>Terms and conditions</span>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      <Modal isOpen={modal} toggle={toggle} modalTransition={{ timeout: 200 }} centered className="modal__no-shadow">
        <ModalHeader>
          Terms and conditions
        </ModalHeader>
        <ModalBody>
          <iframe src={termsUrl} frameBorder="0" style={{ width: '100%', height: '400px' }} title="Terms and conditions"></iframe>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

WidgetFooter.propTypes = {
  terms: PropTypes.string,
};

WidgetFooter.defaultProps = {
  terms: '',
};


export default WidgetFooter;
