import React, {Fragment} from 'react';
import {Col, Row} from "reactstrap";

const OrderIndicator = ({indicator = [], onPopupEditClick}) => {
    const lastIndicator = _.last(indicator.filter(i => i.id !== 'indicator.invoicePaymentDate'));
    const orderPaymentIndicator = _.last(indicator.filter(i => i.id === 'indicator.invoicePaymentDate'));

    if (lastIndicator || orderPaymentIndicator) {
        return (
                    <div className={`indicator mb-2`} onClick={onPopupEditClick}>
                        {(orderPaymentIndicator)
                            ? <div className={`indicator__item indicator--${orderPaymentIndicator.color}`}>
                                <span className='indicator__description'>{orderPaymentIndicator.description}</span>
                                {/*<div className={`indicator__${orderPaymentIndicator.color}`}></div>*/}
                            </div>
                            : null
                        }
                        {(lastIndicator)
                            ? <div className={`indicator__item indicator--${lastIndicator.color}`}>
                                <span className='indicator__description'>{lastIndicator.description}</span>
                                {/* <div className={`indicator__${lastIndicator.color}`}></div>*/}
                            </div>
                            : null
                        }
                    </div>
        );
    } else {
        return null;
    }

}

export default OrderIndicator;
