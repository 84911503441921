import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, map } from 'lodash';
import DictionaryService from '../../../services/dictionaryService';
import Select from './Select';

export default class SelectForwarder extends Component {
  state = {
    values: [],
  };

  async UNSAFE_componentWillMount() {
    const data = await DictionaryService.getForwarders(true);
    if (data) {
      const values = map(data.items, (item) => {
        const organization = get(item, 'organization.name', '-');
        return { value: item.id, label: `${organization} (${item.contactPerson})` };
      });

      this.setState({ values });
    }
  }

  render() {
    const { leftAddon, value, onChange, disabled, error } = this.props;
    const { values } = this.state;

    return (
      <Select
        searchable
        leftAddon={leftAddon}
        value={value}
        values={values}
        onChange={onChange}
        disabled={disabled}
        error={error}
      />
    );
  }
}

SelectForwarder.defaultProps = {
  disabled: false,
};

SelectForwarder.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
};
