/* eslint-disable */
import { observable, computed, action } from 'mobx';
import moment from 'moment';
import { cloneDeep, find } from 'lodash';
import 'moment/locale/ru';

// import rest from '../services/restService';
import L10nService from '../services/l10nService';
import UserStore from './userStore';
import AppService from '../services/appService';
import UserService from '../services/userService';

/**
 * Глобальный стор всего сайта, в нем хранится текущий язык пользователя, языковые переменные и глобальные статусы.
 */
class AppStore {
  @observable _userLang;
  @observable _langMessages = null;
  @observable _loading = true;
  @observable _isVisibleAuthModalDialog = false;
  @observable _isVisibleAccessDeniedMessage = false;
  @observable _isLeftMenuOpen = false;
  @observable _isGoogleMapsApiMount = false;
  @observable _history = {};
  @observable _isServerAvailable = true;
  @observable _iconsColorClassName = '';
  @observable _companyLogo = '';
  @observable _appId = '';
  @observable _terms = '';
  @observable _isModal = true;
  @observable languages = [];
  @observable _hintAuthModalDialog = ""
  appBrowserHistory = [];
  translate = () => {

  };

  pushRouteInHistory(route) {
    this.appBrowserHistory.push(route);
  }

  hasUrlHistory() {
    return !!this.appBrowserHistory.length;
  }

  @action
  setHistory(history) {
    this._history = history;
  }

  @action
  setProperties(data) {
    const { lang = '' } = data;

    if (lang) {
      const langByCode = this.getLanguageByCode(lang.toLowerCase());

      if (langByCode) {
        this.changeLang(langByCode.code);
      }
    }
  }

  @computed get history() {
    return this._history;
  }

  @computed get isVisibleAuthModalDialog() {
    return this._isVisibleAuthModalDialog;
  }
  @computed get getHintAuthModalDialog() {
    return this._hintAuthModalDialog;
  }


  @computed get isLeftMenuOpen() {
    localStorage.getItem('isLeftMenuOpen');
    return this._isLeftMenuOpen;
  }

  @action changeStatusLeftMenu(status) {
    if (status !== undefined) {
      this._isLeftMenuOpen = status;
    } else {
      this._isLeftMenuOpen = !this._isLeftMenuOpen;
    }
    localStorage.setItem('isLeftMenuOpen', this._isLeftMenuOpen);
  }

  @action getScrollPositionLeftMenu() {
    localStorage.getItem('scrollPositionLeftMenu');
    return this._scrollPositionLeftMenu;
  }

  @action changeScrollPositionLeftMenu(status) {
    if (status !== undefined) {
      this._scrollPositionLeftMenu = status;
    } else {
      this._scrollPositionLeftMenu = 0;
    }
    localStorage.setItem('scrollPositionLeftMenu', this._scrollPositionLeftMenu);
  }

  @action closeAuthModalDialog() {
    this._isVisibleAuthModalDialog = false;
    this._hintAuthModalDialog="";
  }

  @action openAuthModalDialog(title="") {
    this._isVisibleAuthModalDialog = true;
    this._hintAuthModalDialog=title;
  }

  @computed get isVisibleAccessDeniedMessage() {
    return this._isVisibleAccessDeniedMessage;
  }

  @action hideAccessDeniedMessage() {
    this._isVisibleAccessDeniedMessage = false;
  }

  @action showAccessDeniedMessage() {
    this._isVisibleAccessDeniedMessage = true;
  }

  @action setIconsColorClassName(className) {
    this._iconsColorClassName = className;
  }

  @action setCompanyLogo(src) {
    this._companyLogo = src;
  }

  @action setAppId(appId) {
    this._appId = appId;
  }

  @action setTerms(terms) {
    this._terms = terms;
  }

  @action setIsModal(isModal) {
    this._isModal = isModal;
  }

  @computed get userLang() {
    return this._userLang;
  }

  @computed get isGoogleMapsApiMount() {
    return this._isGoogleMapsApiMount;
  }

  @computed get langMessages() {
    return this._langMessages;
  }

  @computed get isLoading() {
    return this._loading;
  }

  @computed get isLoaded() {
    return !this._loading;
  }

  @computed get isServerAvailable() {
    return this._isServerAvailable;
  }

  @computed get iconsColorClassName() {
    return this._iconsColorClassName;
  }

  @computed get appId() {
    return this._appId;
  }

  @computed get terms() {
    return this._terms;
  }

  @computed get isModal() {
    return this._isModal;
  }

  @computed get companyLogo() {
    return this._companyLogo;
  }

  @action
  setServerAvailable(value) {
    this._isServerAvailable = value;
  }

  @action finishLoading() {
    this._loading = false;
  }

  getLanguageByCode(code) {
    return find(this.languages, o => o.code === code);
  }

  @action
  setGoogleApiMount(status) {
    this._isGoogleMapsApiMount = status;
  }

  @action
  async changeLang(langCode) {
    let cloneCode = cloneDeep(langCode);
    if(UserStore.user?.organization?.international){

      if(langCode==="ru")
        return this.changeLang("en")
    }

    if (this.userLang === cloneCode) {
      return;
    }

    const responses = await Promise.all([
      /*L10nService.messages(langCode), */L10nService.languages(),
    ]);
    this._langMessages = (await L10nService.messages(cloneCode)).data;
    this._userLang = cloneCode;
    this.languages = (await L10nService.languages()).data;


    localStorage.setItem('userLang', cloneCode);
    moment.locale(cloneCode);
  }

  @action
  async checkTokenValidation() {
    console.log("check validation")
    const token = localStorage.getItem("token");
    const param = new URLSearchParams(window.location.search)
    if(param.has('token'))
      return;
    if (token) {
      const response = await UserService.testToken();
      const json = await response.json();
      console.log("response", response, json)
      if(response.status===401 || json?.data === false) {
        localStorage.removeItem("token")
        localStorage.removeItem("refreshToken")
        localStorage.removeItem("intervalUpdateToken");
        localStorage.setItem("redirectTo", window.location.pathname);
        window.location = "/auth/login?redirectTo="+window.location.pathname;
      }
    }
  }

  @action
  async init() {
    //await this.checkTokenValidation();
    let lang = localStorage.getItem('userLang');
    if (lang === 'null') {
      lang = await AppService.getLanguageByCountry();
    }
    if(lang==='false' && window.location.host.includes("localhost")){
      lang="ru"
    }
    const param = new URLSearchParams(window.location.search)
    await this.changeLang(lang);
    if(param.has("token"))
      localStorage.setItem("token", param.get("token"));
    await UserStore.update(param.has('token'));
    if(UserStore.user?.organization?.international && lang==="ru"){
      this.changeLang("en");
    }
    this.finishLoading();

    console.info('App init done.');
  }
}

const store = new AppStore();

// singleton.
export default store;
