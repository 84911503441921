import { observable, action } from 'mobx';
import { DEFAULT_DELAY } from '../constants/global';

/**
 * Стор для хранения временных (flash) сообщений,
 * обычно необходим тогда, когда надо показать сообщение после перехода на другую страницу.
 *
 * Для того чтобы в UI вывести flash сообщения используйте компонент <FlashStatus />.
 *
 * Если необходимо сразу отобразить flash сообщение недожидаясь смены URL, воспользуйтесь методами с постфиксом **now().
 */
class FlashStore {
  @observable message = {
    error: null,
    success: null,
    warning: null,
    info: null,
    unavailable: false,
  };

  _message = { error: null, success: null, warning: null, info: null, unavailable: false };

  @action success(message) {
    this._message.success = message;
  }

  @action successNow(message, temporary = false) {
    this.clearNow();
    this.message.success = message;

    if (temporary) {
      setTimeout(() => this.clearNow(), Math.min(Math.max(DEFAULT_DELAY, message.length * 200), 6000));
    }
  }

  @action error(message) {
    this._message.error = message;
  }

  @action errorNow(message, temporary = false) {
    this.clearNow();
    this.message.error = message;

    if (temporary) {
      setTimeout(() => this.clearNow(), Math.min(Math.max(DEFAULT_DELAY, message.length * 200), 6000));
    }
  }

  @action warning(message) {
    this._message.warning = message;
  }

  @action warningNow(message) {
    this.clearNow();
    this.message.warning = message;
  }

  @action info(message) {
    this._message.info = message;
  }

  @action infoNow(message) {
    this.clearNow();
    this.message.info = message;
  }

  @action unavailable() {
    this._message.unavailable = true;
  }

  @action unavailableNow() {
    this.clearNow();
    this.message.unavailable = true;
  }

  @action clear() {
    this._message = {
      error: null,
      success: null,
      warning: null,
      info: null,
      unavailable: false,
    };
  }

  clearNow() {
    this.message = {
      error: null,
      success: null,
      warning: null,
      info: null,
      unavailable: false,
    };
  }
}

export default new FlashStore();
