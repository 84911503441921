import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { map, cloneDeep } from 'lodash';

import DictionaryService from '../../../services/dictionaryService';

import Select from './Select';
import { addFormatName, setName } from '../../../utils/utils';

export default class SelectCountry extends Component {
  state = {
    values: [],
  };

  async UNSAFE_componentWillMount() {
    const regions = await DictionaryService.getWorldRegions();

    const values = map(regions.items, (region) => {
      const formatRegion = cloneDeep(region);
      formatRegion.formatName = setName(formatRegion);
      return formatRegion;
    });

    this.setState({ values });
  }

  render() {
    const { value, leftAddon, error, label, onChange, multi } = this.props;

    return (
      <Select
        multi={multi}
        label={label}
        leftAddon={leftAddon}
        values={this.state.values}
        labelKey="formatName"
        valueKey="id"
        value={value ? addFormatName(value) : []}
        error={error}
        onChange={onChange}
        searchable
      />
    );
  }
}

SelectCountry.defaultProps = {
  className: '',
  classNameGroup: '',
  noResultsText: 'form.select.noResultText',
  searchable: false,
  showErrors: true,
  async: false,
  multi: false,
  //resetValue: null,
};

SelectCountry.propTypes = {
  async: PropTypes.bool, // eslint-disable-line
  multi: PropTypes.bool, // eslint-disable-line
  loadOptions: PropTypes.func, // eslint-disable-line
  className: PropTypes.string, // eslint-disable-line
  classNameGroup: PropTypes.string, // eslint-disable-line
  label: PropTypes.string, // eslint-disable-line
  placeholder: PropTypes.string, // eslint-disable-line
  note: PropTypes.string, // eslint-disable-line
  error: PropTypes.string, // eslint-disable-line
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]), // eslint-disable-line
  noResultsText: PropTypes.string, // eslint-disable-line
  onChange: PropTypes.func, // eslint-disable-line
  optionRenderer: PropTypes.func, // eslint-disable-line
  optionComponent: PropTypes.func, // eslint-disable-line
  valueRenderer: PropTypes.func, // eslint-disable-line
  searchable: PropTypes.bool, // eslint-disable-line
  onInputChange: PropTypes.func, // eslint-disable-line
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.number]), // eslint-disable-line
  disabled: PropTypes.bool, // eslint-disable-line
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // eslint-disable-line
  rightAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // eslint-disable-line
  isButton: PropTypes.bool, // eslint-disable-line
  showErrors: PropTypes.bool, // eslint-disable-line
  //resetValue: PropTypes.any,
};
