import React, {useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl";
import {Button, Col, Row} from "reactstrap";
import {Modal} from "../Modal";
import Wizard from "../../../pages/wizard/Wizard";
import {useHistory, useLocation} from "react-router";
import userStore from "../../../stores/userStore";
import {ADMIN_DELIVERY_ORDERS, CLIENT_DELIVERY_ORDERS, PARTNER_DELIVERY_ORDERS} from "../../../constants/routes";
import UserStore from "../../../stores/userStore";

const CreateFakeOrderForm = ({onCreate}) => {
    const location = useLocation();
    const history = useHistory();
    const [showForm, setShowForm] = useState(false)

    const onChangeShowForm=()=>{
        setShowForm(!showForm)
    }
    const getLink = () => {
        if (userStore.isClient()) {
            return CLIENT_DELIVERY_ORDERS;
        }
        if (userStore.isPartner()) {
            return PARTNER_DELIVERY_ORDERS;
        }
        return ADMIN_DELIVERY_ORDERS;
    };

    const onCardClick = (id) => {
        history.push(`${getLink()}/${id}`);
    };

    const onSave=(data)=>{
        onCardClick(data.id)
    }

    const getBody=()=>{
        return(<div >
            <Wizard
                onSave={onSave}
                location={location}
                history={history}
                isModalElement={true}
            />
        </div>)
    }


    return (
            <div>
                {(!!UserStore.isManagerOrGreater() && !UserStore.isClientOfPartnerSaas()) &&
                    <Button
                        className="btn-primary-new"
                        onClick={onChangeShowForm}
                    >
                        <FormattedMessage id={"common.add"}/>
                    </Button>
                }
                <Modal
                    isModal={false}
                    top
                    title={<FormattedMessage id={"order.createNewManual.title"}/>}
                    isShow={showForm}
                    onClose={onChangeShowForm}
                    className={"modal-maxwidth-990px wizard-modal"}
                >
                    {getBody()}
                </Modal>
            </div>
    );
};

CreateFakeOrderForm.propTypes = {

};

export default CreateFakeOrderForm;
