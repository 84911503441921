import React from 'react';
import CrudListDesktop from './CrudListDesktop';
import CrudListMobile from './CrudListMobile';

const CrudList = (props) => {
  return (
    <div>
      <div className="d-none d-lg-block">
        <CrudListDesktop {...props} />
      </div>
      <div className="d-block d-lg-none">
        <CrudListMobile {...props} />
      </div>
    </div>
  );
};

export default CrudList;
