import {Button, Col, Label, Row} from "reactstrap";
import {Checkbox, Select, TextInput} from "../form-control";
import {addFormatName, translate} from "../../../utils/utils";
import NumberInput from "../form-control/NumberInput";
import FontAwesome from "react-fontawesome";
import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {get} from "lodash";

export const PriceField = ({
                               readonly = false,
                               size,
                               services = [],
                               currencies = [],
                               item = {
                                   typeService: null,
                                   price: {value: 0, currency: "USD"},
                                   nds: {value: 0, currency: "RUB"}
                               },
                               index = 0,
                               onChange = (name, value, index) => {
                               },
                               errors = {},
                               onErrorDelete = (name) => {
                               },
                               amountCargo=0
                           }) => {

    const [isError, setIsError] = useState(false)

    useEffect(() => {
        Object.keys(errors).forEach(value => {
            if (value.includes("prices")) setIsError(true)
        })
    }, [errors]);

    return (
        <Row className={`row-table d-block d-md-flex align-items-start ${isError ? "is-error" : ""}`}>
            <Col xs={12} md={3} className={"row-cell pr-0"}>
                <div className={"d-flex d-md-none align-items-center justify-content-between"}>
                    <div><FormattedMessage id={"priceField.service.title"}/>{" "}</div>
                    <div>{size !== 1 && <Button className={"btn-outline-danger"}
                                                onClick={() => onChange(null, null, index)}><FontAwesome
                        name={"trash"}/></Button>}</div>
                </div>
                <div className={"select-wrapper mt-3 mt-md-0"}>
                    <Select
                        disabled={readonly}
                        clearable={false}
                        value={item.typeService}
                        values={services}
                        labelKey={"formatName"}
                        valueKey={"id"}
                        labelClasses={"d-block d-md-none"}
                        label={<span className={"f-label flag-required d-block d-md-none"}><FormattedMessage
                            id={"priceField.typeService"}/></span>}
                        onChange={value => onChange(`typeService`, value.id, index)}
                        error={get(errors, `prices[${index}].typeService`)}
                    />
                </div>
            </Col>
            <Col xs={12} className={"d-block d-md-none px-0"}>
                <TextInput
                    disabled={true}
                    value={amountCargo}
                    label={<span className={" flag-required d-block d-md-none"}>
                        <FormattedMessage id={"deliveryOrders.cargoInformation.cargoAmountTitle"}/>
                        <span className={"ml-1"}><FormattedMessage id={"detailsOfCalculation.pieces"}/></span></span>}
                />
            </Col>
            <Col xs={12} md={4} className={"row-cell pr-0 mt-3 mt-md-0"}>
                <div className={"d-flex w-100 align-items-center"}>
                    <div className={"d-none d-md-flex align-items-center"}><span>{amountCargo}</span><span>{" × "}</span></div>
                    <div className={"w-100 ml-0 ml-md-1"}>
                        <NumberInput
                            disabled={readonly}
                            step={1}
                            min={0}
                            value={item.price.value}
                            labelClasses={"f-label d-block d-md-none"}
                            label={<span className={" flag-required d-block d-md-none"}><FormattedMessage
                                id={"priceField.cost"}/></span>}
                            onChange={value => onChange(`price.value`, value, index)}
                            error={get(errors, `prices[${index}].price`)}
                        />
                    </div>
                    <div style={{minWidth: 90}} className={"ml-2 select-wrapper"}>
                        <Select
                            disabled={readonly}
                            clearable={false}
                            value={item.price.currency}
                            values={addFormatName(currencies)}
                            labelKey={"code"}
                            valueKey={"code"}
                            labelClasses={"d-block d-md-none"}
                            label={<span className={" d-block d-md-none"}
                                         style={{height: 17, color: "transparent"}}>{"0"}</span>}
                            onChange={value => onChange(`price.currency`, value.code, index)}
                        />
                    </div>
                </div>
            </Col>
            <Col xs={12} md={4} className={"row-cell pr-0"}>
                {item.price.currency === "RUB" ?
                    <div className={"d-block d-md-flex h-100 w-100 align-items-center form-group"}>
                        <Checkbox
                            disabled={readonly}
                            caption={<Label className={"f-label d-block d-md-none"}><span className={"flag-required"}><FormattedMessage
                                id={"priceField.vat"}/></span></Label>}
                            checked={item.enabledVAT}
                            onChange={value => onChange(`enabledVAT`, value.target.checked, index)}
                        />
                        <div className={"d-flex mt-2 mt-md-0"}>
                            {item.enabledVAT===true &&
                                <div className={"w-100"}>
                                    <NumberInput
                                        disabled={readonly}
                                        step={1}
                                        min={0}
                                        value={item.nds.value}
                                        labelClasses={"f-label d-none"}
                                        onChange={value => onChange(`nds.value`, value, index)}
                                        label={<span className={" d-block d-md-none"}
                                                     style={{height: 17, color: "transparent"}}>{"0"}</span>}
                                    />
                                </div>
                            }
                            {item.enabledVAT===true &&
                                <div style={{minWidth: 90}} className={"ml-2 select-wrapper"}>
                                    <Select
                                        clearable={false}
                                        value={item.nds.currency}
                                        values={addFormatName(currencies)}
                                        labelKey={"code"}
                                        valueKey={"code"}
                                        disabled={true}
                                        labelClasses={"d-none"}
                                        label={<span className={" d-block d-md-none"}
                                                     style={{height: 17, color: "transparent"}}>{"0"}</span>}
                                        onChange={value => onChange(`nds.currency`, value.code, index)}
                                    />
                                </div>
                            }
                        </div>

                    </div>
                    :
                    <div className={"d-none d-md-flex"}>-</div>
                }
            </Col>

            <Col xs={12} md={1} className={"d-none d-md-flex row-cell pr-0"}>
                {!readonly && size>1 &&
                    <Button className={"btn-outline-danger"} onClick={() => onChange(null, null, index)}><FontAwesome
                        name={"trash"}/></Button>
                }
            </Col>
            <Col xs={12} md={11} className={" pr-0 mt-2 row-cell textarea"}>
                <TextInput
                    disabled={readonly}
                    label={<span><FormattedMessage id={"priceField.comment"}/></span>}
                    placeholder={translate({id:"priceField.comment"})}
                    type={"textarea"}
                    value={item.comment}
                    onChange={value => onChange(`comment`, value.target.value, index)}
                />
            </Col>
        </Row>
    )

}