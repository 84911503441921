import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { InputGroup, InputGroupAddon, FormGroup, FormFeedback, InputGroupText } from 'reactstrap';
import { translate } from '../../utils/utils';

const Input = (props) => {
  const { label, type, defaultValue, placeholder, onChange, readOnly, error } = props;
  const color = error ? 'danger' : '';
  const translatePlaceholder = translate({ id: placeholder });

  return (
    <FormGroup color={color}>
      <InputGroup className="mt-4">
        <InputGroupAddon addonType="prepend"><InputGroupText><FormattedMessage id={label} /></InputGroupText></InputGroupAddon>
        <input
          type={type}
          defaultValue={defaultValue}
          placeholder={translatePlaceholder}
          onChange={onChange}
          className="form-control"
          readOnly={readOnly}
        />
      </InputGroup>
      {
        error && <FormFeedback><FormattedMessage id={`${error}`} /></FormFeedback>
      }
    </FormGroup>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  readOnly: PropTypes.bool,
};

export default Input;
