import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { InterlayerAsyncSelect } from './';
import '../../../assets/styles/ui/geosuggest.css';
import DictionaryService from '../../../services/dictionaryService';
import { addFormatName } from '../../../utils/utils';

export default class AddressInput extends Component {
  state = {
    countries: [],
  };

  async UNSAFE_componentWillMount() {
    await this.setRequest(this.props.country);
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    await this.setRequest(nextProps.country);
  }

  async setRequest(country) {
    const res = await DictionaryService.getCitiesExtended({ code: country });

    if (!isEmpty(res)) {
      this.setState({
        countries: addFormatName(res),
      });
    }
  }

  render() {
    const {
      initialValue,
      country,
      onChange,
      error,
      label,
    } = this.props;
    const { countries } = this.state;

    const val = isEmpty(initialValue) ? { formatName: initialValue } : '';

    return (
      <div className="f-control">
        <InterlayerAsyncSelect
          async
          name="autocompleteCity"
          countryCode={country}
          searchable
          label={label}
          value={val}
          values={countries}
          labelKey="formatName"
          valueKey="id"
          onChange={value => onChange(value)}
          error={error}
          classNameGroup="f-element-address"
          filterOption={() => { return true; }}
        />
      </div>
    );
  }
}

AddressInput.defaultProps = {
  className: '',
};

AddressInput.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  country: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};
