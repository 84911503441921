import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import { isFunction, isEmpty, includes } from 'lodash';
import { Button, Form, Row, Col } from 'reactstrap';
import { TextInput } from '../ui/form-control';
import {
  ErrorStatus, SuccessStatus,
  UnavailableServerStatus, WaitingStatus,
} from '../common/Statuses';
import UserStore from '../../stores/userStore';
import AppStore from '../../stores/appStore';
import { sleep, translate, startPageRedirect } from './../../utils/utils';
import { AUTH_PASSWORD_RECOVERY, AUTH_REGISTER, ADMIN_ORDERS, PARTNER_ORDERS, CLIENT_ORDERS, AUTH_LOGIN, AUTH_LOGIN_FACEBOOK, AUTH_LOGIN_VK } from '../../constants/routes';
import {metricsEvents, pushAnalyticalEvent} from "../../utils/analyticsMetrics";

class AuthForm extends Component {
  state = {
    login: '',
    password: '',
    status: null,
    authFlashStatus: '',
  };

  UNSAFE_componentWillMount() {
    UserStore.update(true).then(() => {
      if (!UserStore.isGuest() && !isEmpty(UserStore.user)) {
        this.setState({
          authFlashStatus: (
            <SuccessStatus>
              {this.props.intl.formatMessage({ id: 'auth.alreadySuccess.message' })}
            </SuccessStatus>
          ),
        });
      }
    });
  }

  async componentDidMount() {
    if (!isEmpty(UserStore.user) && !UserStore.isGuest()) {
      this.redirectToPrevPage();
    }
  }

  togglePasswordVisibility() {
    this.setState(prevState => ({
      isPasswordVisible: !prevState.isPasswordVisible,
    }));

    const eyeIcon = document.querySelector('.pass-icon.eye');
    if (eyeIcon) {
      eyeIcon.classList.toggle('eye-disable');
    }
  }


  redirectToPrevPage(orderId = '') {
    window.parent.postMessage('[wizard] error', '*');

    const appHistory = AppStore.appBrowserHistory;

    if (orderId) {
      let url = '';
      if (UserStore.isClient()) {
        url = CLIENT_ORDERS;
      } else if (UserStore.isPartner()) {
        url = PARTNER_ORDERS;
      } else if (UserStore.isOperator()) {
        url = ADMIN_ORDERS;
      }

      if (!isEmpty(url)) {
        let domainV3 = ""
        const user = UserStore.user;
        UserStore.addCalcToBeShowAll(orderId);
        if(user.organization && user.organization.domain){
          let host = window.location.host;
          let arrayHosts = host.split(".")
          if(arrayHosts.length===3){
            host = `${arrayHosts[1]}.${arrayHosts[2]}`
          }else{
            host = `${arrayHosts[0]}.${arrayHosts[1]}`
          }
          domainV3 = `${user.organization.domain}.${host}`
          if(window.location.host.includes("localhost")===false && window.location.host.includes(domainV3)===false)
            this.props.history.push(`${url}/${orderId}`);
            //window.location.assign(`https://${domainV3}${url}/${orderId}`);
          else
            this.props.history.push(`${url}/${orderId}`);
        }else {
          this.props.history.push(`${url}/${orderId}`);
        }
        return;
      }
    }

    const excludedUrl = [
      AUTH_REGISTER,
      AUTH_LOGIN,
      AUTH_PASSWORD_RECOVERY,
    ];

    if ((appHistory.length > 0) && !includes(excludedUrl, appHistory[appHistory.length - 1])) {
      this.props.history.push(appHistory[appHistory.length - 1]);
    } else {
      this.redirectToStartPage();
    }
  }

  redirectToStartPage() {

    // AppStore.changeStatusLeftMenu(false);
    // setTimeout(() => {
    startPageRedirect(UserStore.user.generalRole, this.props.history);
    // }, 500);
  }

  handleChangeLogin(event) {
    this.setState({ ...this.state, login: event.target.value });
  }

  handleChangePassword(event) {
    this.setState({ ...this.state, password: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { intl } = this.props;

    this.setState({
      status: 'processing',
      authFlashStatus: (
        <WaitingStatus />
      ),
    });

    try {
      const orderId = UserStore.guestOrder;
      UserStore.auth(this.state.login.toLowerCase(), this.state.password).then(user=>{
        console.log("USER: ", user)
        if(user) {
          if (user.status !== undefined) {
            if(user.status===403){
              this.setState({
                status: 'fail',
                authFlashStatus: (
                    <ErrorStatus>
                      {intl.formatMessage({ id: 'auth.banned.message' })}
                    </ErrorStatus>
                ),
              });
            }else {
              this.setState({
                status: 'fail',
                authFlashStatus: (
                    <ErrorStatus>
                      {intl.formatMessage({id: 'auth.fail.message'})}
                    </ErrorStatus>
                ),
              });
            }
          } else {
            this.setState({
              status: 'success',
              authFlashStatus: (
                  <SuccessStatus>
                    {intl.formatMessage({ id: 'auth.success.message' })}
                  </SuccessStatus>
              ),
            });
            // eslint-disable-next-line quote-props
            pushAnalyticalEvent(metricsEvents.ButtonAuthorization);
            AppStore.closeAuthModalDialog();
            UserStore.setGuestOrder(null);

            setTimeout(() => {
              if (isFunction(this.props.onSuccessLogin)) {
                this.props.onSuccessLogin();
                this.setState({ authFlashStatus: '' });
              }
              this.redirectToPrevPage(orderId);
              //
              // const lang = localStorage.getItem('userLang') === 'ru' ? '' : '/en';
              // if (window.location.hostname === 'localhost' || window.location.origin === TEST) {
              //   this.props.history.push('/');
              // } else if (window.location.origin === TEST_CARGOBAR) {
              //   window.location.replace(`${TEST_LANDING}${lang}`);
              // } else if (window.location.origin === PROD_CARGOBAR) {
              //   window.location.replace(`${PROD_LANDING}${lang}`);
              // }
            }, 1000);
          }
        }
      }).catch(err=>{
        console.log("ERR: ",err)
        if(err.status===403){
          console.log("BANNED")
        }
        this.setState({
          status: 'fail',
          authFlashStatus: (
              <ErrorStatus>
                {intl.formatMessage({ id: 'auth.fail.message' })}
              </ErrorStatus>
          ),
        });
      });
    } catch (error) {
      this.setState({
        status: 'unavailable',
        authFlashStatus: (
          <UnavailableServerStatus />
        ),
      });
    }
  }

  render() {
    const linkStyle = {
      display: 'inline-block',
      verticalAlign: 'middle',
      paddingLeft: 0,
    };

    const buttonStyle = {
      borderColor: 'transparent',
      display: 'inline-block',
      verticalAlign: 'middle',
      paddingLeft: 0,
    };

    const buttonFacebookStyle = {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      marginTop: '10px',
      border: '1px solid transparent',
      borderRadius: '7px',
      borderColor: '#1a25ab',
      padding: '0.4rem 0.75rem',
      color: '#313131',
      backgroundColor: 'transparent',
      textDecoration: 'none',
    };

    const fbLink = (`${window.location.pathname.split('/')[1]}/${window.location.pathname.split('/')[2]}` === 'guest/orders') ? `${AUTH_LOGIN_FACEBOOK}&redirectAfterLoginTo=${window.location.href}` : AUTH_LOGIN_FACEBOOK;
    const vkLink = (`${window.location.pathname.split('/')[1]}/${window.location.pathname.split('/')[2]}` === 'guest/orders') ? `${AUTH_LOGIN_VK}?redirectAfterLoginTo=${window.location.href}` : AUTH_LOGIN_VK;

    return (
      <div className="pane pane-primary pane-bg">
        <div className="pane-header">
          {/*<span className="close-btn" />*/}
          <h2><FormattedMessage id="login.submit" /></h2>
        </div>
        <Form className="login-form" onSubmit={event => this.handleSubmit(event)}>
          {this.state.authFlashStatus}
          <i className={`input-icon email ${AppStore.iconsColorClassName}`} /><TextInput
            value={this.state.login}
            name="email"
            placeholder="form.field.email.placeholder"
            onChange={event => this.handleChangeLogin(event)}
          />
          <div className="pass-container">
            <i
              className={`pass-icon eye ${AppStore.iconsColorClassName}`}
              onClick={() => this.togglePasswordVisibility()}
            />
          </div>
          <i className={`input-icon lock ${AppStore.iconsColorClassName}`} />
          <TextInput
            type={this.state.isPasswordVisible ? 'text' : 'password'}
            value={this.state.password}
            name="password"
            placeholder="form.field.password.placeholder"
            className="form-group-last"
            onChange={event => this.handleChangePassword(event)}
          />

          <Row className="buttons" style={{ marginBottom: 13 }}>
            <Col md={12}>
              <small>
                <span style={{ verticalAlign: 'middle' }}>{translate('passwordRecovery.hint')}</span>
                {' '}
                {this.props.isShowHeader ?
                  <Link
                    style={linkStyle}
                    className="btn btn-link"
                    to={AUTH_PASSWORD_RECOVERY}
                  >
                    <FormattedMessage id="passwordRecovery.link" />
                  </Link>
                  :
                  <Button
                    style={buttonStyle}
                    className="btn btn-link"
                    onClick={() => this.props.choosePasswordRecovery()}
                  >
                    <FormattedMessage id="passwordRecovery.link" />
                  </Button>
                }
                {/*{' / '}
                {this.props.isShowHeader ?
                  <Link
                    style={linkStyle}
                    className="btn btn-link"
                    to={AUTH_REGISTER}
                  >
                    <FormattedMessage id="register.title" />
                  </Link>
                  :
                  <Button
                    style={buttonStyle}
                    className="btn btn-link"
                    onClick={() => this.props.chooseRegister()}
                  >
                    <FormattedMessage id="register.title" />
                  </Button>
                }*/}
              </small>
            </Col>
          </Row>
          <Button
            type="submit"
            color="primary"
            className="btn-full"
            /*onClick={this.handleSubmit}*/
            disabled={this.state.status === 'processing'}
          >
            <FormattedMessage id="login.submit" />
          </Button>
        </Form>
        {/*{AppStore.userLang==="ru" &&
          <a
            style={buttonFacebookStyle}
            href={vkLink}
            onClick={() => {
              // eslint-disable-next-line quote-props
              pushAnalyticalEvent(metricsEvents.ButtonAuthorization);
            }}
          >
            <FontAwesome name="vk" className="fb-btn" />
            <FormattedMessage id="login.submit.vk" />
          </a>
        }*/}
        {/*<a
          style={buttonFacebookStyle}
          href={fbLink}
          onClick={() => {
            // eslint-disable-next-line quote-props
            pushAnalyticalEvent(metricsEvents.ButtonAuthorization);
          }}
        >
          <FontAwesome name="facebook-square" className="fb-btn" />
          <FormattedMessage id="login.submit.facebook" />
        </a>*/}
      </div>
    );
  }
}

AuthForm.propTypes = {
  history: PropTypes.object, // eslint-disable-line
  onSuccessLogin: PropTypes.func, // eslint-disable-line
  isShowHeader: PropTypes.bool,
  chooseRegister: PropTypes.func,
  choosePasswordRecovery: PropTypes.func,
  intl: PropTypes.object,
};

export default injectIntl(AuthForm);

