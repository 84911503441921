import HttpProvider from './httpProvider';

import { API } from '../constants/api';

class ServicesService {
  constructor() {
    this.http = HttpProvider;
  }

  async getAllOrganizationProfiles() {
    return this.http.get(API.SERVICES.ALL_ORGANIZATION_PROFILES());
  }
}

export default new ServicesService();
