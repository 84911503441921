export const LOCALHOST = 'http://localhost:3030';
export const LOCALHOST_BACK = 'http://localhost:8080';
export const TEST = 'http://test-cargobar';
export const TEST_CARGOBAR = 'http://test-cargobar.reksoft.ru';
export const PRE_PROD_CARGOBAR = 'https://test.agorafreight.com';
export const PROD_CARGOBAR = 'https://agorafreight.com';

export const TEST_LANDING = 'http://test-cargobar.reksoft.ru';
export const PRE_PROD_LANDING = 'https://test.agorafreight.com';
export const PROD_LANDING = 'https://agorafreight.com';
export const WIZARD = '/book/wizard';
export const AGREEMENT = '/agreement';
export const PROFILE = '/profile';

export const AUTH_LOGIN = '/auth/login';
export const AUTH_LOGIN_FACEBOOK = (window.location.hostname === 'localhost') ? `${LOCALHOST_BACK}/auth/facebook?scope=email` : `${window.location.origin}/auth/facebook?scope=email`;
export const AUTH_LOGIN_VK = (window.location.hostname === 'localhost') ? `${LOCALHOST_BACK}/auth/vkontakte` : `${window.location.origin}/auth/vkontakte`;

export const AUTH_PASSWORD_RECOVERY = '/auth/password-recovery';
export const AUTH_REGISTER = '/auth/register';

export const WIDGET_WIZARD = '/widget/wizard';
export const WIDGET_ORDERS = '/widget/orders';

export const ERROR = '/errors';

export const CITIES_CITIES = '/book';

export const ADMIN = '/admin';
export const ADMIN_USERS = '/admin/users';
export const ADMIN_ORDERS = '/admin/orders';
export const ADMIN_ORDER_TEMPLATE = '/admin/orders-template';
export const ADMIN_PERSONAL_ORDERS = '/admin/personal-orders';
// export const ADMIN_CLIENT_USERS = '/admin/users/partners';
// export const ADMIN_PARTNER_USERS = '/admin/users/clients';
export const ADMIN_ORGANIZATIONS = '/admin/organizations';
export const ADMIN_LANGUAGES = '/admin/dictionaries/languages';
export const ADMIN_CURRENCIES = '/admin/dictionaries/currencies';
export const ADMIN_CONTAINER_TYPES = '/admin/dictionaries/container-types';
export const ADMIN_GENERAL_TYPES = '/admin/dictionaries/general-types';
export const ADMIN_LOGISTIC_DOCUMENT_KINDS_CRUD = '/admin/dictionaries/logistic-document-kind';
export const ADMIN_COMMON_CONTAINER_TYPES = '/admin/dictionaries/container-types/common';
export const ADMIN_AUTO_CONTAINER_TYPES = '/admin/dictionaries/container-types/auto';
export const ADMIN_AIR_CONTAINER_TYPES = '/admin/dictionaries/container-types/air';
export const ADMIN_CUSTOMS_CLEARANCE = '/admin/dictionaries/customs-clearance';
export const ADMIN_SEA_LINES = '/admin/dictionaries/sea-lines';
export const ADMIN_CONSOLIDATORS = '/admin/dictionaries/consolidators';
export const ADMIN_RAILWAY_OPERATORS = '/admin/dictionaries/railway-operators';
export const ADMIN_SEA_PORT_HUBS = '/admin/dictionaries/sea-port-hubs';
export const ADMIN_AIR_HUBS = '/admin/dictionaries/airport-hubs';
export const ADMIN_SURCHARGES = '/admin/dictionaries/freight-surcharges';
export const ADMIN_AIRLINE_DISCLAIMERS = '/admin/dictionaries/airline-disclaimers';
export const ADMIN_RAILWAY_DISCLAIMERS = '/admin/dictionaries/railway-disclaimers';
export const ADMIN_VEHICLE_DISCLAIMERS = '/admin/dictionaries/vehicle-disclaimers';
export const ADMIN_SEA_CONDITIONS = '/admin/dictionaries/sea-line-conditions';
export const ADMIN_TRANSPORT_HUBS = '/admin/dictionaries/transport-hubs';
export const ADMIN_SERVICES = '/admin/dictionaries/services';
export const ADMIN_GEOGRAPHY = '/admin/dictionaries/geography';
export const ADMIN_DELIVERY_ORDERS = '/admin/delivery-orders';
export const ADMIN_EMPTY_ORDERS = '/admin/empty-orders';
export const ADMIN_AIRLINES = '/admin/dictionaries/air-company';
export const ADMIN_RAILWAY_HUBS = '/admin/dictionaries/railway-hubs';
export const ADMIN_GEOGRAPHY_OF_PRESENCE = '/admin/geography-presence';
export const ADMIN_DOCUMENTS = '/admin/documents';
export const ADMIN_RATES_SEA = '/admin/rates/sea';
export const ADMIN_PORT_SERVICES = '/admin/rates/port-services';
export const ADMIN_AIRPORT_SERVICES = '/admin/rates/airport-services';
export const ADMIN_ROAD_TRANSPORTATION = '/admin/rates/road-transportation';
export const ADMIN_RAILWAY_TRANSPORTATION = '/admin/rates/railway-transportation';
export const ADMIN_RAILWAY_SERVICES = '/admin/rates/railway-services';
export const ADMIN_FREIGHT_SURCHARGES = '/admin/rates/freight-surcharges';
export const ADMIN_AIR_TRANSPORTATION = '/admin/rates/air-transportation';
export const ADMIN_TRUNKING = '/admin/rates/trucking';
export const ADMIN_TRUNKING_INTERNATIONAL = '/admin/rates/trucking-international';
export const ADMIN_COMPLEX_TARIFF = '/admin/rates/intermodal-rates';
export const ADMIN_PALLET = '/admin/rates/pallet';
export const ADMIN_COMMISSION = '/admin/commission';
export const ADMIN_TRANSPORTATION_RULES = '/admin/transportation-rules';
export const ADMIN_ORGANIZATION_PROFILES = '/admin/organization-profiles';
export const ADMIN_EXTERNAL_APIS = '/admin/external-apis';
export const ADMIN_EXTERNAL_APIS_AGORA = '/admin/external-apis-agora';
export const ADMIN_REPORTS = '/admin/reports';
export const ADMIN_STATISTICS = '/admin/popular-destinations';
export const ADMIN_AUDIT = '/admin/audit';
export const ADMIN_FORWARDERS = '/admin/forwarders';
export const ADMIN_CUSTOMS = '/admin/customs';
export const ADMIN_COUNTRY_CATEGORIES = '/admin/country-categories';
export const ADMIN_INTEREST_TYPES = '/admin/interest-types';
export const ADMIN_INSURANCE_RATES = '/admin/insurance-rates';
export const ADMIN_EVENTS = '/admin/events';
export const ADMIN_STATUSES = '/admin/statuses';
export const ADMIN_CHATBOT = '/admin/chatbots';
export const ADMIN_TEMPLATES_DOCUMENTS = '/admin/document-templates';

export const ADMIN_SUBSCRIPTION_CONFIG = '/admin/subscription/config'

export const ADMIN_SUBSCRIPTION_USERS = '/admin/subscription/users'
export const ADMIN_RATING_USERS = '/admin/rating/users'
export const ADMIN_SUBSCRIPTION_TARIFFS = '/admin/subscription/tariffs'

export const ADMIN_SUBSCRIPTION_MANAGE = "/admin/subscription/manage"
export const ADMIN_SUBSCRIPTION_REQUESTS = "/admin/subscription/requests"

export const ADMIN_RESOURCE_REPORT = "/admin/resource/report"

export const ADMIN_FILES = '/admin/files';

export const ADMIN_PROFILE = '/admin/profile';

export const PARTNER = '/partner';
export const ADMIN_ORGANIZATION_ACTS="/admin/organization/acts"
export const ADMIN_TEMPLATES_DESCR_TARIFFS="/admin/templates/description-tariffs"
export const PARTNER_PROFILE = '/partner/profile';
export const PARTNER_ORDERS = '/partner/orders';
export const PARTNER_ORDER_TEMPLATE = '/partner/orders-template';
export const PARTNER_GEOGRAPHY_OF_PRESENCE = '/partner/geography-presence';
export const PARTNER_DOCUMENTS = '/partner/documents';
export const PARTNER_COMMISSION = '/partner/commission';
//export const PARTNER_RATES = '/partner/rates';
export const PARTNER_USERS = '/partner/users';
export const PARTNER_ORGANIZATIONS = '/partner/organizations';
export const PARTNER_RATES_SEA = '/partner/rates/sea';
export const PARTNER_PORT_SERVICES = '/partner/rates/port-services';
export const PARTNER_AIRPORT_SERVICES = '/partner/rates/airport-services';
export const PARTNER_ROAD_TRANSPORTATION = '/partner/rates/road-transportation';
export const PARTNER_RAILWAY_TRANSPORTATION = '/partner/rates/railway-transportation';
export const PARTNER_RAILWAY_SERVICES = '/partner/rates/railway-services';
export const PARTNER_FREIGHT_SURCHARGES = '/partner/rates/freight-surcharges';
export const PARTNER_AIR_TRANSPORTATION = '/partner/rates/air-transportation';
export const PARTNER_TRUNKING = '/partner/rates/trucking';
export const PARTNER_TRUNKING_INTERNATIONAL = '/partner/rates/trucking-international';
export const PARTNER_COMPLEX_TARIFF = '/partner/rates/intermodal-rates';
export const PARTNER_PALLET = '/partner/rates/pallet';
export const PARTNER_DELIVERY_ORDERS = '/partner/delivery-orders';
export const PARTNER_DELIVERY_GROUP_ORDERS = '/partner/delivery-group-orders';
export const PARTNER_PERSONAL_ORDERS = '/partner/personal-orders';
export const PARTNER_CUSTOMS = '/partner/customs';
export const PARTNER_TEMPLATES_DOCUMENTS = '/partner/document-templates';
export const PARTNER_FILES = '/partner/files';
export const PARTNER_SUBSCRIPTION_MANAGE = "/partner/subscription"
export const PARTNER_TEMPLATES_DESCR_TARIFFS="/partner/templates/description-tariffs"
export const PARTNER_REPORTS = '/partner/reports';
export const PARTNER_STATISTICS = '/partner/popular-destinations';
export const PARTNER_AIRLINE_DISCLAIMERS = '/partner/dictionaries/airline-disclaimers';
export const PARTNER_RAILWAY_DISCLAIMERS = '/partner/dictionaries/railway-disclaimers';
export const PARTNER_VEHICLE_DISCLAIMERS = '/partner/dictionaries/vehicle-disclaimers';
export const PARTNER_SEA_CONDITIONS = '/partner/dictionaries/sea-line-conditions';
export const PARTNER_CUSTOMS_CLEARANCE = '/partner/dictionaries/customs-clearance';
export const PARTNER_EMPTY_ORDERS = '/partner/empty-orders';

export const GUEST_ORDERS = '/guest/orders';

export const IN_PROGRESS = '/in-progress';

export const CLIENT = '/client';
export const CLIENT_ORDERS = '/client/orders';
export const CLIENT_DELIVERY_ORDERS = '/client/delivery-orders';
export const CLIENT_CUSTOMS = '/client/customs';
export const CLIENT_DOCUMENTS = '/client/documents';
export const CLIENT_SUBSCRIPTION_MANAGE = "/client/subscription"

export const PUBLIC_ORDER_CALCULATION = '/calculation/public';

export const name_routes = [
    {
        name: {
            "ru": "site.leftMenu.link.personalArea",
            "en": "site.leftMenu.link.personalArea",
            "zh": "site.leftMenu.link.personalArea"
        },
        path: ["main", "admin", "client", "partner"],
        href: "/delivery-orders",
        isIntermediate: false
    },
    {
        name: {
            "ru": "site.leftMenu.link.personalArea",
            "en": "site.leftMenu.link.personalArea",
            "zh": "site.leftMenu.link.personalArea"
        },
        href: "/delivery-orders",
        path: ["book"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "form.wizard.title",
            "en": "form.wizard.title",
            "zh": "form.wizard.title"
        },
        path: ["wizard"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "",
            "en": "",
            "zh": ""
        },
        path: ["auth"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "deliveryOrders.orderList.header",
            "en": "deliveryOrders.orderList.header",
            "zh": "deliveryOrders.orderList.header"
        },
        path: ["delivery-orders"],
        href: "/delivery-orders",
        isIntermediate: false
    },
    {
        name: {
            "ru": "person.calculations",
            "en": "person.calculations",
            "zh": "person.calculations"
        },
        path: ["orders"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "person.order.number",
            "en": "person.order.number",
            "zh": "person.order.number"
        },
        path: ["delivery-orders/number"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "person.quotation.reference",
            "en": "person.quotation.reference",
            "zh": "person.quotation.reference"
        },
        path: ["orders/number"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "partner.rates",
            "en": "partner.rates",
            "zh": "partner.rates"
        },
        path: ["rates"],
        isIntermediate: true
    },
    {
        name: {
            "ru": "partner.rates.sea",
            "en": "partner.rates.sea",
            "zh": "partner.rates.sea"
        },
        path: ["sea"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "partner.freightSurcharges",
            "en": "partner.freightSurcharges",
            "zh": "partner.freightSurcharges"
        },
        path: ["freight-surcharges"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "partner.rates.portServices",
            "en": "partner.rates.portServices",
            "zh": "partner.rates.portServices"
        },
        path: ["port-services"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "partner.rates.roadTransportation",
            "en": "partner.rates.roadTransportation",
            "zh": "partner.rates.roadTransportation"
        },
        path: ["road-transportation"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "partner.rates.complexTariff",
            "en": "partner.rates.complexTariff",
            "zh": "partner.rates.complexTariff"
        },
        path: ["intermodal-rates"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "partner.rates.trunking",
            "en": "partner.rates.trunking",
            "zh": "partner.rates.trunking"
        },
        path: ["trunking"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "partner.rates.air",
            "en": "partner.rates.air",
            "zh": "partner.rates.air"
        },
        path: ["air-transportation"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "partner.rates.airportServices",
            "en": "partner.rates.airportServices",
            "zh": "partner.rates.airportServices"
        },
        path: ["airport-services"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "partner.rates.railwayTransportation",
            "en": "partner.rates.railwayTransportation",
            "zh": "partner.rates.railwayTransportation"
        },
        path: ["railway-transportation"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "partner.rates.railwayServices",
            "en": "partner.rates.railwayServices",
            "zh": "partner.rates.railwayServices"
        },
        path: ["railway-services"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "partner.rates.excel.tariffs",
            "en": "partner.rates.excel.tariffs",
            "zh": "partner.rates.excel.tariffs"
        },
        path: ["pallet"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "operatorAdmin.users",
            "en": "operatorAdmin.users",
            "zh": "operatorAdmin.users"
        },
        path: ["users"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "organizationsProfiles.title",
            "en": "organizationsProfiles.title",
            "zh": "organizationsProfiles.title"
        },
        path: ["organization-profiles"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "operatorAdmin.organizations",
            "en": "operatorAdmin.organizations",
            "zh": "operatorAdmin.organizations"
        },
        path: ["organizations"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "operatorAdmin.transportationRules",
            "en": "operatorAdmin.transportationRules",
            "zh": "operatorAdmin.transportationRules"
        },
        path: ["transportation-rules"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "partner.salesRules",
            "en": "partner.salesRules",
            "zh": "partner.salesRules"
        },
        path: ["commission"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "partner.geography",
            "en": "partner.geography",
            "zh": "partner.geography"
        },
        path: ["geography-presence"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "operatorAdmin.insuranceRates",
            "en": "operatorAdmin.insuranceRates",
            "zh": "operatorAdmin.insuranceRates"
        },
        path: ["insurance-rates"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "operatorAdmin.reports",
            "en": "operatorAdmin.reports",
            "zh": "operatorAdmin.reports"
        },
        path: ["reports"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "operatorAdmin.listOfAPIs",
            "en": "operatorAdmin.listOfAPIs",
            "zh": "operatorAdmin.listOfAPIs"
        },
        path: ["external-apis"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "operatorAdmin.audit",
            "en": "operatorAdmin.audit",
            "zh": "operatorAdmin.audit"
        },
        path: ["audit"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "operatorAdmin.directories",
            "en": "operatorAdmin.directories",
            "zh": ""
        },
        path: ["dictionaries"],
        isIntermediate: true
    },
    {
        name: {
            "ru": "operatorAdmin.directories.languages",
            "en": "operatorAdmin.directories.languages",
            "zh": "operatorAdmin.directories.languages"
        },
        path: ["languages"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "operatorAdmin.directories.currencies",
            "en": "operatorAdmin.directories.currencies",
            "zh": "operatorAdmin.directories.currencies"
        },
        path: ["currencies"],
        isIntermediate: false
    }, {
        name: {
            "ru": "",
            "en": "",
            "zh": ""
        },
        path: ["container-types"],
        isIntermediate: true
    }, {
        name: {
            "ru": "operatorAdmin.directories.typesOfGoods",
            "en": "operatorAdmin.directories.typesOfGoods",
            "zh": "operatorAdmin.directories.typesOfGoods"
        },
        path: ["common"],
        isIntermediate: false
    }, {
        name: {
            "ru": "operatorAdmin.directories.typesOfGeneralCargo",
            "en": "operatorAdmin.directories.typesOfGeneralCargo",
            "zh": "operatorAdmin.directories.typesOfGeneralCargo"
        },
        path: ["general-types"],
        isIntermediate: false
    }, {
        name: {
            "ru": "operatorAdmin.directories.trucks",
            "en": "operatorAdmin.directories.trucks",
            "zh": "operatorAdmin.directories.trucks"
        },
        path: ["auto"],
        isIntermediate: false
    }, {
        name: {
            "ru": "operatorAdmin.directories.airs",
            "en": "operatorAdmin.directories.airs",
            "zh": "operatorAdmin.directories.airs"
        },
        path: ["air"],
        isIntermediate: false
    }, {
        name: {
            "ru": "operatorAdmin.directories.seaLines",
            "en": "operatorAdmin.directories.seaLines",
            "zh": "operatorAdmin.directories.seaLines"
        },
        path: ["sea-lines"],
        isIntermediate: false
    }, {
        name: {
            "ru": "operatorAdmin.directories.consolidators",
            "en": "operatorAdmin.directories.consolidators",
            "zh": "operatorAdmin.directories.consolidators"
        },
        path: ["consolidators"],
        isIntermediate: false
    }, {
        name: {
            "ru": "operatorAdmin.directories.seaPortHubs",
            "en": "operatorAdmin.directories.seaPortHubs",
            "zh": "operatorAdmin.directories.seaPortHubs"
        },
        path: ["sea-port-hubs"],
        isIntermediate: false
    }, {
        name: {
            "ru": "operatorAdmin.directories.transportHubs",
            "en": "operatorAdmin.directories.transportHubs",
            "zh": "operatorAdmin.directories.transportHubs"
        },
        path: ["transport-hubs"],
        isIntermediate: false
    }, {
        name: {
            "ru": "operatorAdmin.directories.airportsHubs",
            "en": "operatorAdmin.directories.airportsHubs",
            "zh": "operatorAdmin.directories.airportsHubs"
        },
        path: ["airport-hubs"],
        isIntermediate: false
    }, {
        name: {
            "ru": "partner.geography",
            "en": "partner.geography",
            "zh": ""
        },
        path: ["geography"],
        isIntermediate: false
    }, {
        name: {
            "ru": "operatorAdmin.directories.services",
            "en": "operatorAdmin.directories.services",
            "zh": "operatorAdmin.directories.services"
        },
        path: ["services"],
        isIntermediate: false
    }, {
        name: {
            "ru": "operatorAdmin.directories.airlines",
            "en": "operatorAdmin.directories.airlines",
            "zh": "operatorAdmin.directories.airlines"
        },
        path: ["air-company"],
        isIntermediate: false
    }, {
        name: {
            "ru": "operatorAdmin.directories.railwayHubs",
            "en": "operatorAdmin.directories.railwayHubs",
            "zh": "operatorAdmin.directories.railwayHubs"
        },
        path: ["railway-hubs"],
        isIntermediate: false
    }, {
        name: {
            "ru": "operatorAdmin.directories.seaConditions",
            "en": "operatorAdmin.directories.seaConditions",
            "zh": "operatorAdmin.directories.seaConditions"
        },
        path: ["sea-line-conditions"],
        isIntermediate: false
    }, {
        name: {
            "ru": "operatorAdmin.directories.airlineDisclaimers",
            "en": "operatorAdmin.directories.airlineDisclaimers",
            "zh": "operatorAdmin.directories.airlineDisclaimers"
        },
        path: ["airline-disclaimers"],
        isIntermediate: false
    }, {
        name: {
            "ru": "operatorAdmin.directories.railwayDisclaimers",
            "en": "operatorAdmin.directories.railwayDisclaimers",
            "zh": "operatorAdmin.directories.railwayDisclaimers"
        },
        path: ["railway-disclaimers"],
        isIntermediate: false
    }, {
        name: {
            "ru": "operatorAdmin.directories.vehicleDisclaimers",
            "en": "operatorAdmin.directories.vehicleDisclaimers",
            "zh": "operatorAdmin.directories.vehicleDisclaimers"
        },
        path: ["vehicle-disclaimers"],
        isIntermediate: false
    }, {
        name: {
            "ru": "operatorAdmin.directories.forwarders",
            "en": "operatorAdmin.directories.forwarders",
            "zh": "operatorAdmin.directories.forwarders"
        },
        path: ["forwarders"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "operatorAdmin.directories.countryCategories",
            "en": "operatorAdmin.directories.countryCategories",
            "zh": "operatorAdmin.directories.countryCategories"
        },
        path: ["interest-types"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "operatorAdmin.countryCategories",
            "en": "operatorAdmin.countryCategories",
            "zh": "operatorAdmin.countryCategories"
        },
        path: ["country-categories"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "operatorAdmin.directories.events",
            "en": "operatorAdmin.directories.events",
            "zh": "operatorAdmin.directories.events"
        },
        path: ["events"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "operatorAdmin.directories.statuses",
            "en": "operatorAdmin.directories.statuses",
            "zh": "operatorAdmin.directories.statuses"
        },
        path: ["statuses"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "operatorAdmin.emptyOrders",
            "en": "operatorAdmin.emptyOrders",
            "zh": "operatorAdmin.emptyOrders"
        },
        path: ["empty-orders"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "operatorAdmin.customsCalculator",
            "en": "operatorAdmin.customsCalculator",
            "zh": "operatorAdmin.customsCalculator"
        },
        path: ["customs"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "operatorAdmin.directories.chatbot",
            "en": "operatorAdmin.directories.chatbot",
            "zh": "operatorAdmin.directories.chatbot"
        },
        path: ["chatbots"],
        isIntermediate: false
    },
    {
        name: {
            "ru": "operatorAdmin.directories.customsClearance",
            "en": "operatorAdmin.directories.customsClearance",
            "zh": "operatorAdmin.directories.customsClearance"
        },
        path: ["customs-clearance"],
        isIntermediate: false
    },

]
