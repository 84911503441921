import { action, observable } from 'mobx';

/**
 * Стор массовых операций в тарифах.
 */

class BulkOperationsStore {
  @observable isBulkOperations = false;
  @observable bulkEditItems = [];
  @observable filterId = null;
  @observable isBulkForFilter = false;
  @observable data = {};
  @observable items = null;

  @action
  setItemsNumber(items = null) {
    this.items = items;
  }

  @action
  setFilterId(id = null) {
    this.filterId = id;
  }

  @action
  setBulkForFilter(status) {
    this.isBulkForFilter = status;
  }

  @action
  startBulkOperations() {
    this.isBulkOperations = true;
  }

  @action
  cancelBulkOperations() {
    this.isBulkOperations = false;
    this.data = {};
  }

  @action
  setEditItems(items) {
    this.bulkEditItems = items;
  }

  @action
  setData(name, value) {
    this.data = {
      ...this.data,
      [name]: value,
    };

    if (value.length === 0) {
      delete this.data[name];
    }
  }

  @action
  setObjectData(data) {
    this.data = {
      ...data,
    };
  }

  @action
  resetAll() {
    this.bulkEditItems = [];
    this.data = {};
  }
}

export default BulkOperationsStore;
