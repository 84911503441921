/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {translate} from '../../../utils/utils';

import Select from './Select';

export default class SelectChargingType extends Component {
    data4general = [{
        value: 'RATE',
        name: translate({id: 'constants.charging.rate'})
    },{
        value: 'DISTANCE',
        name: translate({id: 'constants.charging.distance'})
    },{
        value: 'HOURLY',
        name: translate({id: 'constants.charging.hourly'})
    }];

    data4containers = [{
        value: 'RATE',
        name: translate({id: 'constants.charging.rate'})
    },{
        value: 'DISTANCE',
        name: translate({id: 'constants.charging.distance'})
    }];

     getValues = (cargoType) => {
         switch (cargoType) {
             case 'CONTAINERS':
                 return this.data4containers;
             case 'GENERAL':
                 return this.data4general;
         }
    };

    render() {
        const { value, leftAddon, error, label, onChange, readonly, cargoType } = this.props;

        return (
            <Select
                label={label}
                leftAddon={leftAddon}
                values={this.getValues(cargoType)}
                labelKey="name"
                value={value}
                error={error}
                onChange={onChange}
                disabled={readonly}
                searchable
            />
        );
    }
}

SelectChargingType.defaultProps = {
    className: '',
    classNameGroup: '',
    noResultsText: 'form.select.noResultText',
    searchable: false,
    showErrors: true,
    async: false,
    multi: false,
    readonly: false,
};

SelectChargingType.propTypes = {
    async: PropTypes.bool, // eslint-disable-line
    multi: PropTypes.bool, // eslint-disable-line
    loadOptions: PropTypes.func, // eslint-disable-line
    className: PropTypes.string, // eslint-disable-line
    classNameGroup: PropTypes.string, // eslint-disable-line
    label: PropTypes.string, // eslint-disable-line
    placeholder: PropTypes.string, // eslint-disable-line
    note: PropTypes.string, // eslint-disable-line
    error: PropTypes.string, // eslint-disable-line
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]), // eslint-disable-line
    noResultsText: PropTypes.string, // eslint-disable-line
    onChange: PropTypes.func, // eslint-disable-line
    optionRenderer: PropTypes.func, // eslint-disable-line
    optionComponent: PropTypes.func, // eslint-disable-line
    valueRenderer: PropTypes.func, // eslint-disable-line
    searchable: PropTypes.bool, // eslint-disable-line
    onInputChange: PropTypes.func, // eslint-disable-line
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.number]), // eslint-disable-line
    disabled: PropTypes.bool, // eslint-disable-line
    leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // eslint-disable-line
    rightAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // eslint-disable-line
    isButton: PropTypes.bool, // eslint-disable-line
    showErrors: PropTypes.bool, // eslint-disable-line
    readonly: PropTypes.bool, // eslint-disable-line
    cargoType: PropTypes.string, // eslint-disable-line
};
