import HttpProvider from './httpProvider';
import { API } from '../constants/api';

class FilterService {
  constructor() {
    this.http = HttpProvider;
  }

  async getFiltersByContext(context, force = false) {
    return await this.http.get(API.FILTERS.FILTERS_BY_CONTEXT({ context }), force)
      .then(({ data = [] }) => data);
  }
}

export default new FilterService();
