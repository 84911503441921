import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { FormGroup, InputGroupAddon, InputGroup } from 'reactstrap';
import { uniqueId } from 'lodash';
import { Label, Note } from './common';
import { NumberInput } from '../../ui/form-control';

export default class CoordinatesInput extends React.Component {
  state = {
    id: '',
    value: {
      latitude: '',
      longitude: '',
    },
  };

  UNSAFE_componentWillMount() {
    if (!this.props.id) {
      this.setState({
        id: uniqueId('f-element-text-'),
      });
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const value = toJS(props.value);
    if (!value) {
      return;
    }

    this.setState({ value: { longitude: value.longitude, latitude: value.latitude } });
  }

  handleChangeField(name, value) {
    const { onChange } = this.props;

    const sValue = this.state.value;
    sValue[name] = value;

    this.setState({ value: sValue });

    onChange(sValue);
  }

  renderInput() {
    const { disabled, error } = this.props;
    const { value } = this.state;

    return (
      <div className="f-group">
        <NumberInput
          allowNegative
          precision={6}
          value={value.latitude}
          onChange={v => this.handleChangeField('latitude', v)}
          disabled={disabled}
          placeholder="crudForm.dictionary.field.coordinates.latitude.placeholder"
          min={-90}
          max={90}
          error={error.dictionaryErrors[`${error.name}.latitude`] || error.dictionaryErrors[`${error.name}`]}
        />
        <NumberInput
          allowNegative
          precision={6}
          value={value.longitude}
          onChange={v => this.handleChangeField('longitude', v)}
          disabled={disabled}
          placeholder="crudForm.dictionary.field.coordinates.longitude.placeholder"
          min={-180}
          max={180}
          error={error.dictionaryErrors[`${error.name}.longitude`] || error.dictionaryErrors[`${error.name}`]}
        />
      </div>
    );
  }

  render() {
    const { leftAddon, rightAddon, error, className, label, note, id } = this.props;

    let input = this.renderInput();

    if (leftAddon || rightAddon) {
      input = (
        <InputGroup>
          {leftAddon && <InputGroupAddon className="left-addon" addonType="prepend"><span className="input-group-text">{leftAddon}</span></InputGroupAddon>}
          {input}
          {rightAddon && <InputGroupAddon className="right-addon" addonType="append"><span className="input-group-text">{rightAddon}</span></InputGroupAddon>}
        </InputGroup>
      );
    }

    return (
      <FormGroup className={`f-element coordinate-input f-element-text ${error ? 'has-danger1' : ''} ${className}`}>
        <Label elementId={id || this.state.id}>{label}</Label>
        {input}
        <Note>{note}</Note>

        {/*<Error>{error}</Error>*/}
      </FormGroup>
    );
  }
}

CoordinatesInput.defaultProps = {
  type: 'coordinates',
  className: '',
  name: '',
  disabled: false,
};

CoordinatesInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  note: PropTypes.string,
  //value: PropTypes.any,
  onChange: PropTypes.func,
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
};

