import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal} from "../Modal";
import HeaderModalTableTariffs from "./HeaderModalTableTariffs";
import TableTariffs from "./TableTariffs";
import {FormattedMessage} from "react-intl";
import {Button, Col, Row} from "reactstrap";
import AppStore from "../../../stores/appStore";
import {NumberInput} from "../form-control";
import {cloneDeep, get, set} from "lodash";
import FontAwesome from "react-fontawesome";
import {nameTariffPage} from "../../../constants/global";

const ModalTableTariffInput = ({tableTariffsType, bulkEditItems, tableTariffsEndpoint, items, onAfterSave}) => {
    const [isShow, setIsShow] = useState(false);
    const [isShowAirTariffs, setIsShowAirTariffs] = useState(false);
    const [isShowConfirm, setIsShowConfirm] = useState(false);
    const [airWeightRanges, setAirWeightRanges] = useState(
        {
            rate_0: null,
            rate_45: null,
            rate_100: null,
            rate_250: null,
            rate_500: null,
            rate_1000: null,
        });

    const onCancel = () => {
        setIsShowConfirm(true)
    }

    const onApplyWeightRanges = () => {
        setIsShow(false)
        setIsShowAirTariffs(true)
    }

    const onAddWeightRange = () => {
        const newWeightRange = cloneDeep(airWeightRanges);
        let newValue = Object.keys(airWeightRanges)[Object.keys(airWeightRanges).length - 1];
        const valueWeight = parseInt(newValue.replace("rate_", ""));
        newWeightRange[`rate_${valueWeight + 1}`]=null;
        setAirWeightRanges(newWeightRange)
    }

    const onRemoveWeightRange = (key) => {
        const newWeightRange = cloneDeep(airWeightRanges);
        delete newWeightRange[key];
        setAirWeightRanges(newWeightRange)
    }

    const onChangeWeightRange = (value, keyOld, index) => {
        let arrayWeights = Object.keys(airWeightRanges).map(v=>parseInt(v.replace("rate_", "")));
        let weightChange = parseInt(keyOld.replace("rate_", ""));
        arrayWeights[index] = value;
        if(index!==0 && arrayWeights[index-1]===value){
            arrayWeights[index] = value+1
        }
        for(let i=index+1; i<arrayWeights.length; i++){
            const difference = arrayWeights[i] - arrayWeights[i-1];
            if(difference <= 0){
                arrayWeights[i] = arrayWeights[i-1]+ 1;
            }
        }
        const newObj = {};
        for(let weight of arrayWeights){
            newObj[`rate_${weight}`] = null;
        }
        setAirWeightRanges(newObj)
    }

    const onBlurInputWeightRange=(key, arr, index)=>{
        const condition = (index===0?0:parseInt(arr[index-1].replace("rate_", "")))>=parseInt(arr[index].replace("rate_", ""))
        const value = parseInt(key.replace("rate_", ""));
        if(condition || isNaN(value)){
            onChangeWeightRange(index===0?0:parseInt(arr[index-1].replace("rate_", ""))+1, key, index)
        }
    }

    useEffect(() => {
        console.log(airWeightRanges)
    }, [airWeightRanges]);

    return (
        <div style={{display: 'contents'}}>
            <Modal
                className={"confirm-cancel-modal"}
                isShow={isShowConfirm}
                onClose={()=>setIsShowConfirm(false)}
                isModal={true}
                title={<FormattedMessage id={"dialog.confirm.default.question"} />}
            >
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id={"tableComponent.confirm.cancelChages"}/>
                    </Col>
                </Row>
                <Row className="btn-controls mt-3" style={{justifyContent: 'flex-start'}}>
                    <Col xs={12}>
                        <Button className={"btn-primary-new"} onClick={() => {
                            setIsShowConfirm(false);
                            setIsShow(false);
                            setIsShowAirTariffs(false)
                            onAfterSave();
                        }}>
                            <FormattedMessage id="dialog.confirm.yes"/>
                        </Button>
                        <Button className={"btn-outline-primary-new"} onClick={() => setIsShowConfirm(false)}>
                            <FormattedMessage id="dialog.confirm.no"/>
                        </Button>
                    </Col>
                </Row>
            </Modal>
            <Modal
                isShow={isShow}
                isModal={true}
                onClose={()=>onCancel()}
                title={<Row>
                    <Col xs={12}>
                        <div>
                            <FormattedMessage id={`crud.form.add.table.title`}/>{" — "}
                            <FormattedMessage id={nameTariffPage[tableTariffsType]}/>
                        </div>
                        <FormattedMessage id={"airTariffWeights.modal.title"}/>
                    </Col>
                </Row>
                }
            >
                <div>
                    {Object.keys(airWeightRanges).map((key, index, arr) => {
                        return(
                            <Row className={"mt-1"}>
                                <Col xs={12}>
                                    <div className={"d-flex align-items-end"}>
                                        <NumberInput
                                            label={<span>≥</span>}
                                            className={"w-100 overflow-auto mb-0 react-numeric-input-no-hidden"}
                                            min={index===0?0:parseInt(arr[index-1].replace("rate_", ""))}
                                            value={parseInt(key.replace("rate_", ""))}
                                            onChange={(v)=>onChangeWeightRange(v, key, index)}
                                            onBlur={()=>onBlurInputWeightRange(key, arr, index)}

                                        />
                                        {arr.length!==1&&
                                            <Button style={{height:"fit-content"}} onClick={()=>onRemoveWeightRange(key)} className={"btn-outline-danger ml-2"}><FontAwesome name={"trash"}/></Button>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        )
                    })}
                    <Row className={"mt-3"}>
                        <Col xs={12} className={"d-flex justify-content-between"}>
                            <Button
                                className={"btn-outline-primary-new px-3"}
                                onClick={onAddWeightRange}
                            >
                                <FontAwesome name={"plus"}/>{" "}<FormattedMessage className={"ml-1"} id={"common.add"}/>
                            </Button>
                            <Button
                                className={"btn-outline-primary-new px-3 ml-2"}
                                onClick={()=>setAirWeightRanges({
                                    rate_0: null,
                                    rate_45: null,
                                    rate_100: null,
                                    rate_250: null,
                                    rate_500: null,
                                    rate_1000: null,
                                })}
                            >
                                <FontAwesome name={"undo"}/>{" "}<FormattedMessage className={"ml-1"} id={"common.reset"}/>
                            </Button>
                            <Button
                                onClick={()=>setAirWeightRanges({
                                    rate_0: null,
                                })}
                                className={"btn-outline-primary-new px-3 ml-2"}
                            >
                                <FontAwesome name={"trash"}/>{" "}<FormattedMessage className={"ml-1"} id={"common.removeAll"}/>
                            </Button>
                        </Col>
                    </Row>
                    <Row className={"mt-3"}>
                        <Col xs={12} lg={6}>
                            <Button className={"btn-primary-new"} onClick={onApplyWeightRanges}><FormattedMessage id={"form.button.next"}/></Button>
                        </Col>
                    </Row>

                </div>
            </Modal>
            <Modal className={'modal-resource-report-crud modal-minheight-700px modal-table-tariffs'}
                   title={
                       <HeaderModalTableTariffs
                           tariffType={tableTariffsType}
                           isEdit={bulkEditItems.length !== 0}
                       />
                   }
                   isModal={true}
                   isShow={tableTariffsType === 'AIR_TARIFFS' ? isShowAirTariffs : isShow}
                   onClose={onCancel}>
                <div className="mx-auto text-center">
                    <TableTariffs endpoint={tableTariffsEndpoint}
                                  dataTariffsEdit={items.filter(v => bulkEditItems.includes(v.id))}
                                  onAfterSave={() => {
                                      setIsShow(false);
                                      setIsShowAirTariffs(false)
                                      onAfterSave();
                                  }}
                                  onCancel={onCancel}
                                  type={tableTariffsType}
                                  airWeightRanges={airWeightRanges}
                    />
                </div>
            </Modal>
            <Button onClick={() => setIsShow(true)}
                    className="btn btn-primary"
                    style={{minWidth:"130px",marginTop: `${tableTariffsType === 'SEA_TARIFFS'? '4px': '0'}`}}
                    disabled={tableTariffsType === 'AIR_TARIFFS' &&  bulkEditItems.length !== 0}
            >
                {bulkEditItems.length === 0 ?
                    <FormattedMessage id="crud.form.table.add"/> : <FormattedMessage id="crud.form.table.edit"/>}
            </Button>
        </div>
    );
};

ModalTableTariffInput.propTypes = {};

export default ModalTableTariffInput;