class CrudService {
  constructor(urlPrefix, crudActions) {
    this.urlPrefix = urlPrefix;
    this.crudActions = crudActions;
  }

  get = async orderDeliveryRequestGroupId => this.crudActions.read(orderDeliveryRequestGroupId);

  list = async (offset = 0, limit = 20, params = {}) => {
    let query = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
    if (query !== '') {
      query = `&${query}`;
    }
    return this.crudActions.list(offset, limit, query);
  };

  create = async data => this.crudActions.create(data);

  update = async (id, data, filename = null) => this.crudActions.update(id, data, filename);

  updateFile = async (id, file) => this.crudActions.updateFile(id, file);

  sendEmail = async id => this.crudActions.sendEmail(id);
  generateAndSendEmail = async id => this.crudActions.generateAndSendEmail(id);

  remove = async id => this.crudActions.delete(id);
}

export default CrudService;
