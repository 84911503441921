import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { isArray, isEmpty } from 'lodash';
import { DynamicDataSheetGrid, keyColumn, textColumn } from 'react-datasheet-grid';
import { FormattedMessage } from 'react-intl';
import { createAddRowsComponent } from './TableContainersUtils';
import Select from 'react-select';
import userStore from '../../../stores/userStore';
import DictionaryService from '../../../services/dictionaryService';
import dictionaryService from '../../../services/dictionaryService';
import { formatValues, isError, onCopyValueSelect, onPasteValueSelect } from '../TableTariffs/TableTariffsUtils';
import { API } from '../../../constants/api';
import { getExistLangValue, translate } from '../../../utils/utils';
import UserStore from '../../../stores/userStore';
import { Input, Popover, PopoverBody, UncontrolledPopover } from 'reactstrap';
import { NumberInput, TextInput } from '../form-control';
import Link from './Link';

const TableContainers = (props) => {

  const {items, orderDelivery} = props;
  const [data, setData] = useState([])
  const [containersTypes, setContainersTypes] = useState([])


  // Структура строки
  // containerId - Номер контейнера
  // type.formatName - Тип контейнера
  // weight - Значение веса
  // weightUnit - Единица веса
  // item.deliveryTruckDetail.truckNumber - Гос. номер грузовика
  // item.deliveryTruckDetail.driverName - ФИО Водителя
  // item.deliveryTruckDetail.driverMobile - Телефон водителя
  // status - Статус

  const formatValues = (values = []) => {
    let result = []
    for (let val of values) {
      result.push({value: val.id, label: getExistLangValue(val.name)})
    }
    return result;
  }

  const isShowLinkTrackingPage = (index) => {
    const {items} = props;
    if (items && items[index].trackingPageUrl) {
      return true;
    } else return false;
  }
  const openTrackingPage = (index) => {
    const {items} = props;
    const url = items[index].trackingPageUrl;
    window.open(url, '_blank');
  }

  const SelectComponent = (data) => {

    const {
      rowData,
      setRowData,
      focus,
      stopEditing,
      active,
      defaultValue= null,
      rowIndex
    } = data

    const [values, setValues] = useState([])


    useEffect(() => {
      DictionaryService.getCargoContainerTypes('COMMON')
        .then((response) => {
          if (isEmpty(response)) {
            return;
          }
          setValues(formatValues(response));
        })
    }, [containersTypes]);

    const value = {
      value: rowData.id,
      label: rowData.formatName,
    }

    const {onChange} = props;

    return (
      <Select
        disabled={(orderDelivery?.fake !== true)}
        placeholder={<FormattedMessage id={"crud.filter.cargoContainerType"}/>}
        noOptionsMessage={() => <FormattedMessage id={"form.select.noResultText"}/>}
        value={value}
        options={values}
        isClearable={false}
        classNamePrefix={"Select5V"}
        className={"w-100 text-left Select5V no-border small"}
        onChange={value => onChange(value.value, 'type', rowIndex, 'containers')}
        onMenuClose={() => setTimeout(e => stopEditing({nextRow: false}), 200)}
        menuIsOpen={focus}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: base => ({...base, zIndex: 9999}),
          control: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: 'transparent',
          }),
        }}
      />
    )
  }

  const TextInputColumn = (data) => {
    const {
      active,
      addId,
      columnData,
      rowData,
      setRowData,
      placeholder = '',
      rowIndex,
      name,
      className,
      focus,
      stopEditing,
      disabled
    } = data
    const [idField, setIdField] = useState(`element-table-${rowIndex}-${name}`)
    const [error, setError] = useState(false)
    const inputRef = useRef(null)
    const {onChange} = props;

    useEffect(() => {
      setIdField(`element-table-${rowIndex}-${name}`)
    }, [name])

    useEffect(()=>{
      if(focus){
        inputRef?.current?.focus()
      }else{
        inputRef?.current?.blur()
      }
    }, [focus])

    return <div>
    <div id={idField} className={"f-element f-element-text w-100"}>
      <TextInput
        style={{border:"none"}}
        //innerRef={inputRef}
        type="text"
        onChange={ev=>{
          onChange(ev.target.value, name, rowIndex, 'containers')}}
        value={rowData}
        disabled={false}
      />
    </div>
      {name === "containerId" && document.getElementById(idField) && rowData && isShowLinkTrackingPage(rowIndex) &&
        <UncontrolledPopover
          className={"z-index-9999"}
          placement="left"
          target={idField}
          trigger="legacy click"
        >
          <PopoverBody>
            <Link items={items} index={rowIndex} />
          </PopoverBody>
        </UncontrolledPopover>}
    </div>

  }

  const GetStatusColumn = (data) => {
    const {
      active,
      addId,
      columnData,
      rowData,
      setRowData,
      placeholder = '',
      rowIndex,
      name,
      className,
      focus,
      stopEditing,
      disabled
    } = data
    const [idField, setIdField] = useState(`element-table-${rowIndex}-${name}`)
    const [error, setError] = useState(false)
    const inputRef = useRef(null)
    const {onChange} = props;

    return(<div>
      <div id={idField} className={"f-element f-element-text w-100"} style={{border:"none",whiteSpace: "nowrap"}}>
        { rowData ?
        <a>{rowData.substring(0, 35)}{rowData.length > 35 ? "..." : ""}</a> : " - "}
    </div>
    {document.getElementById(idField) && rowData &&
    <Popover
      className={"z-index-9999"}
      placement="left"
      isOpen={active || focus}
      target={idField}>
      <PopoverBody>{rowData}</PopoverBody>
    </Popover>}
      </div>
    );
  }

  const IntegerColumnInput = (data) => {
    const {
      columnData,
      rowData,
      setRowData,
      placeholder = '',
      rowIndex,
      name,
      className,
      focus,
      disabled
    } = data

    const {onChange} = props;

    return(
    <NumberInput
      style={{border:"none"}}
      disabled={(orderDelivery?.fake !== true)}
      placeholder="orderForm.modal.cargoWeight"
      onChange={ev => onChange(ev, 'weight', rowIndex, 'containers')}
      value={rowData}
    />)
  }

  const [columns, setColumns] = useState([]);
  useMemo(() => {
    setColumns([
      keyColumn('containerId',
        {
          component: (p) => <TextInputColumn {...p} name={"containerId"} />,
          title: <FormattedMessage id="orderForm.idContainer"/>,
          minWidth: 200,
          disabled: false,
        }),
        keyColumn('statusTracking',
          {
            component: (p)=><GetStatusColumn {...p} name={'statusTracking'} />,
            title: <FormattedMessage id="orderForm.currentStatus"/>,
            minWidth: 300,
            disabled: true,
      }),

      keyColumn('type',
        {
          component: SelectComponent,
          title: <FormattedMessage id="crud.filter.cargoContainerType"/>,
          headerClassName: 'cell-required',
          minWidth: 300,
          disabled:orderDelivery?.fake !== true
        }),

        keyColumn('weight',
          {
            component: IntegerColumnInput,
            title: <FormattedMessage id="orderForm.modal.cargoWeight"/>,
            minWidth: 100,
            disabled:orderDelivery?.fake !== true
      }),
      {
        ...keyColumn('weightUnit', textColumn),
        title: <FormattedMessage id="orderForm.modal.cargoWeight"/>,
        minWidth: 100,
        disabled:orderDelivery?.fake !== true
      },

      keyColumn(
        'truckNumber',
        {
          component: (p)=><TextInputColumn {...p} name={'deliveryTruckDetail.truckNumber'}/>,
          title: <FormattedMessage id="orderForm.modal.stateTruckNumber"/>,
          minWidth: 150,
        },
      ),
      keyColumn('driverName',
        {
          component: (p)=><TextInputColumn {...p} name={'deliveryTruckDetail.driverName'}/>,
        title: <FormattedMessage id="orderForm.modal.driver"/>,
        minWidth: 200,
      }),
        keyColumn('driverMobile',
          {
            component: (p)=><TextInputColumn {...p} name={'deliveryTruckDetail.driverMobile'}/>,
        title: <FormattedMessage id="orderForm.modal.driverPhone"/>,
        minWidth: 200,
      })])
  }, [containersTypes]);

  useEffect(() => {
    if (items && isArray(items))
      setData(prepareItems(items))

    DictionaryService.getCargoContainerTypes('COMMON')
      .then((response) => {
        if (!isEmpty(response)) {
          setContainersTypes(formatValues(response));
        }
      })
  }, [items]);

  const prepareItems = (items) => {
    const blocks = items.map((item, index) => {
      return {
        ...item,
        truckNumber: item?.deliveryTruckDetail?.truckNumber,
        driverName: item?.deliveryTruckDetail?.driverName,
        driverMobile: item.deliveryTruckDetail?.driverMobile,
      };
    });

    return blocks;
  };

  return (
    <DynamicDataSheetGrid
      value={data}
      onChange={setData}
      columns={columns}
      addRowsComponent={createAddRowsComponent()}
    />
  )
}

export default TableContainers;
