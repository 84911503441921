import PropTypes from 'prop-types';

const CrudColumn = () => {
  return '';
};

CrudColumn.defaultProps = {
  isShow: true,
};

CrudColumn.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  unsortable: PropTypes.bool,
  format: PropTypes.func,
  width: PropTypes.number,
  virtual: PropTypes.bool,
  needStore: PropTypes.bool,
  align: PropTypes.string,
  isShow: PropTypes.bool,

  // Events:
  onRowClick: PropTypes.func,
};

export default CrudColumn;

