/* eslint-disable react/no-unused-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'lodash';
import FontAwesome from 'react-fontawesome';
import { InterlayerAsyncSelect } from './';

export default class AllHubsSelect extends Component {
  state = {
    item: null,
  };

  UNSAFE_componentWillMount() {
    this.setState({
      item: this.props.item,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.item) {
      this.setState({
        item: nextProps.item,
      });
    }
  }

  onChange = (value) => {
    let newValue = value;

    if (isArray(value) && value.length === 0) {
      newValue = []; // null
    }

    this.props.onChange(newValue);
  };

  // Для картинок в options
  customComponentForSelect = (option) => {
    const { labelKey } = this.props;
    const typeToIconName = {
      SEA_PORT: 'anchor',
      TRANSPORT: 'truck',
      AIRPORT_HUB: 'plane',
      RAILWAY_HUB: 'train',
    };
    const icon = typeToIconName[option.type] || '';

    return (
      <div>
        <FontAwesome name={icon} style={{ paddingRight: '10px' }} />
        {option[labelKey]}
      </div>
    );
  };

  render() {
    const {
      async,
      multi,
      labelKey,
      valueKey,
      values,
      value,
      name,
      onChange,
      error,
      searchable,
      leftAddon,
      placeholder,
      disabled,
      label,
      countryCode,
    } = this.props;

    return (
      <InterlayerAsyncSelect
        item={this.state.item}
        name={name}
        async={async}
        multi={multi}
        searchable={searchable}
        values={values}
        valueKey={valueKey}
        labelKey={labelKey}
        value={value}
        onChange={onChange}
        leftAddon={leftAddon}
        label={label}
        className="crud-select"
        error={error}
        optionRenderer={this.customComponentForSelect}
        valueRenderer={this.customComponentForSelect}
        placeholder={placeholder}
        disabled={disabled}
        countryCode={countryCode}
      />
    );
  }
}

AllHubsSelect.defaultProps = {
  valueKey: 'value',
  labelKey: 'label',
  className: '',
  classNameGroup: '',
  noResultsText: 'form.select.noResultText',
  searchable: false,
  showErrors: true,
  async: false,
  multi: false,
  backspaceRemoves: true,
  //resetValue: null,
};

AllHubsSelect.propTypes = {
  item: PropTypes.object,
  name: PropTypes.string,
  async: PropTypes.bool,
  filterOption: PropTypes.func,
  multi: PropTypes.bool,
  loadOptions: PropTypes.func,
  clearable: PropTypes.bool,
  className: PropTypes.string,
  classNameGroup: PropTypes.string,
  label: PropTypes.oneOfType(PropTypes.string, PropTypes.object),
  placeholder: PropTypes.string,
  note: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
  values: PropTypes.arrayOf(PropTypes.object),
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  noResultsText: PropTypes.string,
  onChange: PropTypes.func,
  optionComponent: PropTypes.func,
  valueComponent: PropTypes.func,
  valueRenderer: PropTypes.func,
  searchable: PropTypes.bool,
  onInputChange: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isButton: PropTypes.bool,
  showErrors: PropTypes.bool,
  backspaceRemoves: PropTypes.bool,
  countryCode: PropTypes.string,
};
