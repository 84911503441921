import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import FontAwesome from 'react-fontawesome';
import './../../assets/styles/blocks/preloader.css';

export const Preloader = (props) => {
  const { isShow, isFixed, isTop, isSpinner, noPaddings } = props;
  console.log('Preloader', props);
  // let preloaderContent = children || 'Загрузка';

  const preloaderContent = (<div className="lds-default">
    <div> </div>
    <div> </div>
    <div> </div>
    <div> </div>
    <div> </div>
    <div> </div>
    <div> </div>
    <div> </div>
    <div> </div>
    <div> </div>
    <div> </div>
    <div> </div>
  </div>);

  const classNames = [
    isFixed ? 'fixed' : '',
    isTop ? 'top' : '',
    noPaddings ? 'no-paddings' : '',
  ]
    .join(' ')
    .trim();

  if (isSpinner && isShow) {
    return (
      <div className={`preloader ${classNames}`}>
        <div className="preloader-inner">
          <FontAwesome name={'spinner'} className="text-primary" spin />
        </div>
      </div>
    );
  }

  const preloader = (
    <div className={`preloader ${classNames}`}>
      <div className="preloader-inner">
        {preloaderContent}
      </div>
    </div>
  );

  return (
    <Modal isOpen={isShow} modalClassName="preloader__modal">
      { preloader }
    </Modal>
  );
};

Preloader.propTypes = {
  isSpinner: PropTypes.bool,
  noPaddings: PropTypes.bool,
  isShow: PropTypes.bool,
  isFixed: PropTypes.bool,
  isTop: PropTypes.bool,
  children: PropTypes.element, //eslint-disable-line
};
