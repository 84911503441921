import React, {useState} from 'react';
import DictionaryService from "../../../../services/dictionaryService";
import {Button, FormGroup, InputGroup, InputGroupAddon, Label} from "reactstrap";
import {FormattedMessage} from "react-intl";
import FontAwesome from "react-fontawesome";

const ResetUsageLimitExternalApi = (props) => {

    const [isReseted, setIsReseted] = useState(false)
    const resetFn = () => {
        DictionaryService.resetUsageLimit(props.id).then(res => {
            if (res.code === 200) {
                setIsReseted(true)
                props.onReset()
            }
        })
    }
    return (
        <FormGroup>
            <InputGroup className={"d-none d-lg-flex"}>
                <InputGroupAddon className="left-addon d-none d-lg-block"
                                 style={{height: "auto", marginRight:"15px"}}
                                 addonType="prepend"><span
                    className="input-group-text nowrap-input-left-addon"><FormattedMessage id={"crud.field.resetUsageLimit.title"}/></span>
                </InputGroupAddon>
                <Button
                    className="btn-primary-new"
                    onClick={resetFn}
                >
                    {isReseted === false ?
                        <FormattedMessage id={"crud.field.resetUsageLimitBtn.title"}/>
                        :
                        <FormattedMessage id={"crud.field.resetedUsageLimit.title"}/>
                    }
                </Button>
            </InputGroup>
            <div className={"d-block d-lg-none"}>
                <Label className={"f-label"}><FormattedMessage id={"crud.field.resetUsageLimit.title"}/></Label><br/>
                <Button
                    className="btn-primary-new"
                    onClick={resetFn}
                >
                    {isReseted === false ?
                        <FormattedMessage id={"crud.field.resetUsageLimitBtn.title"}/>
                        :
                        <FormattedMessage id={"crud.field.resetedUsageLimit.title"}/>
                    }
                </Button>
            </div>
        </FormGroup>
    )
};

export default ResetUsageLimitExternalApi;