import React, {useState} from 'react';
import {formatCost, translate} from "../../../../utils/utils";
import {Popover, PopoverBody} from "reactstrap";
import {FormattedMessage} from "react-intl";

const FormatOrderCost = ({item}) => {

    const [openPopoverCost, setOpenPopoverCost] = useState(false)

    if (!item.minCost || !item.maxCost) {
        return ' - ';
    }

    if (item.minCost.value !== item.maxCost.value) {
        return <span className={"price-with-tooltip"} style={{cursor:"pointer"}}>
        <span id={`calc-price-${item.id}`}
              onPointerEnter={() => {if(window.innerWidth>=768)setOpenPopoverCost(true)}}
              onPointerLeave={() => {if(window.innerWidth>=768)setOpenPopoverCost(false)}}
              onClick={()=>{if(window.innerWidth<768) setOpenPopoverCost(true)}}
              style={item.needUpdate === true ? {color: "red"} : {color: "initial"}}>
          {item.calculationsCount} {translate({id: 'common.field.quotes'})}{" "}
            {translate({id: 'common.field.from'})}{" "}
            {formatCost(item.minCost.value)}{" "}
            {translate({id: 'common.field.before'})}{" "}
            {formatCost(item.maxCost.value)}{" "}
            {item.minCost.currency}{" "}
            {item.needUpdate === true ? <sup>{" "}?</sup> : ''}
        </span>
            {item.needUpdate === true &&
                <span>
                    <div className={"d-none d-md-block"}>
                        {document.getElementById(`calc-price-${item.id}`) &&
                        <Popover
                            placement="left"
                            isOpen={openPopoverCost}
                            toggle={()=>setOpenPopoverCost(!openPopoverCost)}
                            className={"fix-arrow no-mobile"}
                            target={`calc-price-${item.id}`}>
                            <PopoverBody>{translate({id: "detailsOfCalculation.outdatedCalculationMsg"})}</PopoverBody>
                        </Popover>
                        }
                    </div>
                    <div className="tooltip-mobile d-block d-md-none">
                        {openPopoverCost &&
                            <div className="tooltip-btn-order">
                                <div className={"outer"} onClick={()=>{setOpenPopoverCost(false)}}></div>
                                <div className="inner">
                                    <div className="body">
                                        <FormattedMessage id="detailsOfCalculation.outdatedCalculationMsg" />
                                    </div>
                                </div>
                                <span className="arrow"
                                      style={{position: "absolute", left: "20px"}}>
                                </span>
                            </div>
                        }
                    </div>
                </span>
            }
      </span>;
    }

    if (item.minCost.value === item.maxCost.value) {
        return <span style={{cursor:"pointer"}}>
                <span
                    id={`calc-price-${item.id}`}
                    onPointerEnter={() => {if(window.innerWidth>=768)setOpenPopoverCost(true)}}
                    onPointerLeave={() => {if(window.innerWidth>=768)setOpenPopoverCost(false)}}
                    onClick={()=>{if(window.innerWidth<768) setOpenPopoverCost(true)}}
                    style={item.needUpdate === true ? {color: "red"} : {color: "initial"}}>
                  {formatCost(item.minCost.value)}{" "}{item.minCost.currency}{item.needUpdate === true ?
                    <sup>{" "}?</sup> : ''}
                </span>
            {item.needUpdate === true &&
                <span>
                    <div className={"d-none d-md-block"}>
                        {document.getElementById(`calc-price-${item.id}`) &&
                        <Popover
                            placement="left"
                            isOpen={openPopoverCost}
                            className={"fix-arrow no-mobile"}
                            target={`calc-price-${item.id}`}>
                            <PopoverBody>{translate({id: "detailsOfCalculation.outdatedCalculationMsg"})}</PopoverBody>
                        </Popover>
                        }
                    </div>
                    <div className="tooltip-mobile d-block d-md-none">
                        {openPopoverCost &&
                            <div className="tooltip-btn-order">
                                <div className={"outer"} onClick={()=>{setOpenPopoverCost(false)}}></div>
                                <div className="inner">
                                    <div className="body">
                                        <FormattedMessage id="detailsOfCalculation.outdatedCalculationMsg" />
                                    </div>
                                </div>
                                <span className="arrow"
                                      style={{position: "absolute", left: "20px"}}>
                                </span>
                            </div>
                        }
                    </div>
                </span>
            }
        </span>;
    }

    return ' - ';
};

export default FormatOrderCost;
