import React, {useEffect, useState} from 'react';
import {Col, Container, Dropdown, DropdownMenu, DropdownToggle, Row} from "reactstrap";
import NotificationItem from "./NotificationItem";
import UserStore from "../../../stores/userStore";
import {webSocketApi} from "../../../constants/api";
import FlashStore from "../../../stores/flashStore";
import NotificationService from "../../../services/notificationService";
import {FormattedMessage} from "react-intl";
import {translate} from "../../../utils/utils";
import Stomp from "stompjs";
import SockJS from "sockjs-client";

const NotificationsModalWindow = ({isNotificationMenuOpen, toggleNotificationMenu}) => {
    const [notificationList, setNotificationList] = useState([])
    const [visibleNotificationArray, setVisibleNotificationArray] = useState(true)
    const [hasNewNotification, setHasNewNotification] = useState(false)
    const [styleNotificationBtn, setStyleNotificationBtn] = useState("")
    const [userData, setUserData] = useState({
        userId: UserStore.user ? UserStore.user.id : 0,
        sessionId: (Date.now().toString()+(UserStore.user ? UserStore.user.id.toString() : "0")),
        statusUser: "NEW",
    })
    let stompClient = null;

    const loadData = ()=>{
        const lastId = notificationList.length===0?0:notificationList.at(-1).id
        NotificationService.getOldNotifications(lastId).then(res=>{
            if(res && res.length>0){
                for(let value of res) {
                    if(notificationList.findIndex(v=>v.id === value.id)===-1) {
                        notificationList.push(value)
                        if(value.statusNotificationMessage==="UNREAD"){
                            setStyleNotificationBtn("new-notification")
                        }
                    }
                }
                setNotificationList([...notificationList])
            }
        })
    }

    useEffect(() => {
        if (!UserStore.isGuest()) {
            loadData()
            let socket = new SockJS(webSocketApi());
            stompClient = Stomp.over(socket);
            stompClient.debug = null
            stompClient.connect({}, function (frame) {
                stompClient.subscribe(
                    "/notification/" + userData.sessionId + "/user-data",
                    onMessageUserData
                );
                stompClient.subscribe(
                    "/notification/" + userData.sessionId + "/queue/messages",
                    onMessageNotification
                );
                registrationNewSession()
            });

            function registrationNewSession() {
                stompClient.send("/app/user", {}, JSON.stringify(userData));
            }

            function onMessageNotification(newMessage) {
                let notification = JSON.parse(newMessage.body);
                FlashStore.successNow(translate({id:"notifications.new"}), true)
                setHasNewNotification(true)
                if(isNotificationMenuOpen===false)
                    setStyleNotificationBtn("new-notification")
                notificationList.push(notification)
                setNotificationList(notificationList.sort((a, b)=>b.timeAsLong-a.timeAsLong))
            }

            function onMessageUserData() {
                setUserData(userData)
            }
        }
    }, [])

    useEffect(() => () => {
        if (stompClient != null) {
            try {
                userData.statusUser = "DISCONNECTED"
                stompClient.send("/app/user", {}, JSON.stringify(userData));
                stompClient.disconnect();
            }catch (e){
                console.error(e.name, e.message)
            }
        }
    }, []);

    const onToggle = ()=>{
        if(isNotificationMenuOpen===false){
            setStyleNotificationBtn("active")
        }
        if(isNotificationMenuOpen===false && hasNewNotification === true){
            setHasNewNotification(false)
        }
        if(isNotificationMenuOpen===true){
            setStyleNotificationBtn("")
        }
        if(isNotificationMenuOpen===true && hasNewNotification===true) {
            setStyleNotificationBtn("new-notification")
        }
        toggleNotificationMenu()
    }

    const readAll=() => {
        const arrayOfData = []
        const newArray = []
        for (let value of notificationList) {
            value.statusNotificationMessage = "READIED"
            newArray.push(value)
            const data = {
                id: value.id,
                action: "READIED"
            }
            arrayOfData.push(data)
        }
        NotificationService.updateStatusNotifications(arrayOfData).then(res=>{
            setNotificationList([])
            setNotificationList([...newArray])
        })
    }

    const onScroll=(e)=>{
        if (e.target.offsetHeight + e.target.scrollTop === e.target.scrollHeight) {
            loadData()
        }
    }

    const onRead = (notification, newStatus)=>{
        notification.statusNotificationMessage = newStatus;
        setNotificationList(notificationList);
    }


    return (
        <div>
            <div >
                <Dropdown
                    isOpen={isNotificationMenuOpen}
                    toggle={onToggle}
                    className="header__notification_pull-right"
                >
                    <DropdownToggle className={`btn-default notification-btn ${styleNotificationBtn}`} caret>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <Container className={"notification-modal-window"}>
                            <div className={"header-block"}>
                                <Row>
                                    <Col xs={12}>
                                        <div className={"d-flex"}>
                                            <div className={"title-header"}><FormattedMessage id={"notifications.title"}/></div>
                                            <div onClick={readAll}
                                                 className={"header-read-all-btn"}>
                                                <FormattedMessage id={"notifications.readAll"}/>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <hr/>
                            </div>
                            {visibleNotificationArray === true &&
                                <div className={"body-block"} onScroll={onScroll}>
                                    {notificationList.length === 0 &&
                                        <div><FormattedMessage id={"notifications.noNotifications"}/></div>
                                    }
                                    {notificationList.map((notification,index) => {
                                        return (<NotificationItem key={`nl-ni-${index}`}
                                            notificationValue={notification}
                                            onRead={onRead}
                                        />)
                                    })
                                    }
                                </div>
                            }
                        </Container>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    );
};

export default NotificationsModalWindow;
