import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { Modal } from '../ui/Modal';
import DialogStore from '../../stores/dialogStore';
import closeModal from '../../assets/img/icons/close-modal.svg';

@observer
class Dialogs extends Component {
  renderCloseIcon () {
    if (DialogStore.className !== '') {
      return <img src={closeModal} alt="" onClick={() => DialogStore.hideConfirm()}/>;
    }

    return null;
  }

  renderConfirm() {
    return (<Modal
      isShow={DialogStore.confirmVisible}
      className={DialogStore.className}
      title={<FormattedMessage id="dialog.confirm.title" />}
      isModal
    >
      {this.renderCloseIcon()}
      <h6 style={{ textAlign: 'center' }}>{DialogStore.message}</h6>
      <hr />
      <div className="buttons" style={{ textAlign: 'center' }}>
        <Button color="primary" onClick={() => DialogStore.doneConfirm()} style={{ marginRight: 10 }}><FormattedMessage id="dialog.confirm.yes" /></Button>
        <Button onClick={() => DialogStore.hideConfirm()}><FormattedMessage id="dialog.confirm.no" /></Button>
      </div>
    </Modal>
    );
  }

  render() {
    return (
      <div>
        {this.renderConfirm()}
      </div>
    );
  }
}

export default Dialogs;
