/* eslint-disable */
import React, {Component, Children} from 'react';
import PropTypes from 'prop-types';
import {toJS} from 'mobx';
import {FormattedMessage} from 'react-intl';
import {cloneDeep, set, get, keys, omit, each, map, isEmpty, find, compact, isPlainObject, isArray} from 'lodash';
import {
    Form, FormGroup, Row, Col, Button, InputGroupAddon, InputGroup,
    Nav, NavItem, NavLink, TabContent, TabPane, Label
} from 'reactstrap';
import {
    SizeInput,
    HatchSizeInput,
    CoordinatesInput,
    TextInput,
    Select,
    SelectCountry,
    SelectForwarder,
    SelectCountries,
    SelectCity,
    SelectServiceCategory,
    SelectWorldRegion,
    SelectServiceProfile,
    SelectCountryMulti,
    SelectRegion,
    SelectSeaPort,
    SelectSeaLine,
    SelectLanguage,
    SelectCargoType,
    SelectRailwayCompany,
    SelectRailwayStation,
    SelectAirline,
    SelectAirport,
    SelectChargingType,
    SelectHub,
    SelectDirectionOfTransportation
} from '../../form-control';
import FlashStore from '../../../../stores/flashStore';
import TranslationText from '../fields/TranslationText';
import AppStore from '../../../../stores/appStore';
import {
    addFormatName, camelCaseToTile, translate,
    convertErrorsArrayToObject, addFormatNameToConstant, setName, getExistLangValue,
} from '../../../../utils/utils';
import Checkbox from '../../form-control/Checkbox';
import NumberInput from '../../form-control/NumberInput';
import {
    SURCHARGE_TYPES,
    TARIFICATIONS,
    TYPE_OF_CARGO_FULL,
    FORWARDER_TEMPLATES,
    INSURANCE_TRANSPORTATION
} from '../../../../constants/staticData';
import {Error} from '../../form-control/common';
import SelectSubordinateRole from '../../form-control/SelectSubordinateRole';
import UserService from '../../../../services/userService';
import {SuccessStatus} from '../../../common/Statuses';
import {DEFAULT_DELAY} from '../../../../constants/global';
import OrganizationProfilesStore from '../../../../stores/organizationProfilesStore';
import OrganizationService from '../../../../services/organizationService';
import DictionaryService from '../../../../services/dictionaryService';
import * as ROLES from '../../../../constants/userRoles';
import subscriptionService from "../../../../services/subscriptionService";
import HistorySubscription from "../../../subscriptions/HistorySubscription";
import AddSubscription from "../../../subscriptions/AddSubscription";
import ResetUsageLimitExternalApi from "../fields/ResetUsageLimitExternalApi";
import FontAwesome from "react-fontawesome";

const COMPARTMENT_FIELDS = {
    name: {},
    maxLoad: {
        value: '',
        unit: 'KG',
    },
    size: {
        length: '',
        width: '',
        height: '',
        unit: 'M',
    },
    hatchSize: {
        length: '',
        width: '',
        unit: 'M',
    },
    volume: '',
    floorSize: '',
    maxFloorPressure: '',
};

const LANGAGE_PROPS_NAME = 'language';
const LANGAGE_DEFALUT_VALUE = 'English';
const LANGAGE_DEFAULT_SHORTCUR = 'EN';

class DictionaryForm extends Component {
    state = {
        isWaiting: false,
        item: null,
        type: [],
        tarificationType: [],
        typeOfCargo: [],
        tabIndex: 1,
        countryId: '',
        noRegion: false,
        dictionaryFields: this.props.item ? this.props.item : {id: ''},
        resetPasswordErrors: {},
        dictionaryErrors: {},
        successMessage: '',
        roles: [],
        countries: [],
        searchText: {
            startRegion: '',
            endRegion: ''
        },
        isLoadingSave: false
    };

    async UNSAFE_componentWillMount() {
        const prepareTypeSurcharges = addFormatNameToConstant(SURCHARGE_TYPES);
        const prepareTarifications = addFormatNameToConstant(TARIFICATIONS);
        const prepareTypeOfCargo = addFormatNameToConstant(TYPE_OF_CARGO_FULL);
        const prepareTransportationType = addFormatNameToConstant(INSURANCE_TRANSPORTATION);

        const response = await UserService.getSubordinateRoles();
        const roles = isArray(response.data) ? response.data.map((item) => {
            return item.id;
        }) : [];
        const hasPermission = roles.includes(ROLES.OPERATOR_ADMIN);
        const fields = cloneDeep(this.state.dictionaryFields);
        if (!hasPermission) {
            set(fields, LANGAGE_PROPS_NAME, LANGAGE_DEFAULT_SHORTCUR);
        }

        this.setState({
            type: prepareTypeSurcharges,
            tarificationType: prepareTarifications,
            cargoType: prepareTypeOfCargo,
            forwarderTemplate: FORWARDER_TEMPLATES,
            transportationType: prepareTransportationType,
            roles,
            dictionaryFields: fields,
        });

        const promises = [
            DictionaryService.getCurrencies(),
        ];
        const regions = await DictionaryService.getWorldRegions();

        const c = []
        const noneCountry = {
            code: "NONE_C",
            name: {
                "ru": "Выберите страну",
                "en": "Select country"
            }
        }
        const offRegion = {
            code: "NONE",
            name: {
                "ru": "Данный вид груза не будет доступен к выбору",
                "en": "This type of cargo will not be available for selection"
            }
        }
        c.push({value: noneCountry.code, name: setName(noneCountry)})
        c.push({value: offRegion.code, name: setName(offRegion)})
        const res = await DictionaryService.getCountries();
        const values = map(res, (country) => {
            c.push({value: country.code, name: setName(country)});
        });
        const europe = {
            code: "EU",
            name: {
                "ru": "Европа",
                "en": "Europe"
            }

        }
        c.push({value: europe.code, name: setName(europe)})

        this.setState({countries: c});

        this.setState({
            worldRegions: addFormatName(regions.items),
        });

        Promise.all(promises).then((res) => {
            this.setState({
                currencies: addFormatName(res[0]),
            });
        });

        this.setNewState(this.props);
    }

    isInitFieldArrivalPlace = false;
    isInitFieldDeparturePlace = false;

    UNSAFE_componentWillReceiveProps(newProps) {
        try {
            const {children} = newProps;
            if (newProps.item)
                if (newProps.item.arrivalPlace || newProps.item.departurePlace) {
                    Children.map(compact(children), (child) => {
                        const {props} = child;
                        if (newProps.item.arrivalPlace && props.name === 'arrivalPlace' && props.isShow === false && this.isInitFieldArrivalPlace === false) {
                            this.isInitFieldArrivalPlace = true
                            props.setVisible(true)
                        }
                        if (newProps.item.departurePlace && props.name === 'departurePlace' && props.isShow === false && this.isInitFieldDeparturePlace === false) {
                            this.isInitFieldDeparturePlace = true
                            props.setVisible(true)
                        }
                    })
                }
        }catch (e){
            console.error(e)
        }


        if (!newProps.item) {
            return;
        }

        this.setNewState(newProps);
        this.getOrganizationProfiles(newProps.item);
    }

    componentWillUnmount() {
        try {
            this.props.closeAddArrivalPlace()
            this.props.closeAddDeparturePlace()
        }catch (e) {

        }
    }

    setNewState(newProps) {
        const fields = cloneDeep(this.state.dictionaryFields);

        if (newProps.item && newProps.item.parent) {
            if (newProps.item.parent.type === 'COUNTRY') {
                this.setState({
                    countryId: get(newProps.item.parent, 'parent.id', newProps.item.parent.id),
                    noRegion: true,
                });
            } else {
                this.setState({
                    countryId: get(newProps.item.parent, 'parent.id', newProps.item.parent.id),
                });
            }
        }

        if (newProps.kind === 'AIR') {
            let compartments = [];

            if (fields[newProps.name]) {
                compartments = fields[newProps.name];
            }

            if (compartments.length === 0) {
                compartments[0] = {...COMPARTMENT_FIELDS};

                set(fields, 'compartments', compartments);
            }
        }

        this.setState({
            item: toJS(newProps.item),
            dictionaryFields: {
                ...fields,
                ...toJS(newProps.item),
            },
        });
    }

    getOrganizationProfiles = (organization) => {
        const {fetchInitialOptionsFor = []} = this.props;
        const organizationId = get(organization, 'id');

        if (isEmpty(fetchInitialOptionsFor) || !organizationId) {
            return;
        }

        each(fetchInitialOptionsFor, async (fieldName) => {
            if (fieldName === 'organizationProfiles' && this.state.isLoadingSave === false) {
                const profiles = await OrganizationProfilesStore.getOrganizationProfiles(organizationId);

                const organizationProfiles = map(
                    addFormatName(map(profiles, 'value')),
                    profile => ({
                        ...profile,
                        value: {
                            id: profile.id,
                        },
                    })
                );

                this.setState({
                    savedOrganizationProfiles: [].concat(organizationProfiles),
                    dictionaryFields: {
                        ...this.state.dictionaryFields,
                        [fieldName]: [].concat(organizationProfiles),
                    },
                });
            }
        });
    };

    sendRecoveryInstructionsOnEmail = async () => {
        const email = this.props.item.email;

        if (!email) {
            return;
        }

        const response = await UserService.sendRecoveryInstructionsOnEmail({
            email,
        });

        if (response.status !== 'success') {
            const errors = convertErrorsArrayToObject(response.data.errors);

            this.setState({
                resetPasswordErrors: errors,
                dictionaryErrors: {
                    ...this.state.dictionaryErrors,
                    ...errors,
                },
            });

            return;
        }

        const fieldNamesWithErrors = keys(this.state.resetPasswordErrors);

        this.setState({
            successMessage: translate(response.message),
            resetPasswordErrors: {},
            dictionaryErrors: omit(this.state.dictionaryErrors, fieldNamesWithErrors),
        });

        setTimeout(() => {
            this.setState({successMessage: ''});
        }, DEFAULT_DELAY);
    };

    setTab(index) {
        this.setState({
            tabIndex: index,
        });
    }

    addCompartments() {
        const data = {...COMPARTMENT_FIELDS};

        if (!this.state.dictionaryFields.compartments) {
            this.setState({
                ...this.state,
                tabIndex: this.state.tabIndex + 1,
                dictionaryFields: {
                    ...this.state.dictionaryFields,
                    compartments: [data],
                },
            });
        } else {
            const clone = cloneDeep(this.state.dictionaryFields.compartments);
            clone.push(data);

            this.setState({
                ...this.state,
                tabIndex: this.state.tabIndex + 1,
                dictionaryFields: {
                    ...this.state.dictionaryFields,
                    compartments: clone,
                },
            });
        }
    }

    removeCompartments(index) {
        const clone = cloneDeep(this.state.dictionaryFields.compartments);
        clone.splice(index, 1);

        this.setState({
            ...this.state,
            tabIndex: 1,
            dictionaryFields: {
                ...this.state.dictionaryFields,
                compartments: clone,
            },
        });
    }

    getBody() {
        const {children} = this.props;
        const {dictionaryErrors} = this.state;

        const dictionaryFields = this.state.dictionaryFields;

        return Children.map(compact(children), (child) => {
            const {props} = child;
            let placeholder = props.placeholder;
            let label = props.label ? translate({id: props.label, defaultMessage: props.label}) : null;

            if (props.disableOnAdd && !this.props.item) {
                return null;
            }

            if (props.disableOnEdit && this.props.item) {
                return null;
            }

            if (props.dictionaryType) {
                if (!placeholder) {
                    placeholder = {
                        id: `crudForm.dictionary.field.${props.name}_${props.dictionaryType}.placeholder`,
                        defaultMessage: camelCaseToTile(props.name),
                    };
                }

                if (!label) {
                    label = (<FormattedMessage
                        id={`crudForm.dictionary.field.${props.name}_${props.dictionaryType}`}
                        defaultMessage={translate({
                            id: `crud.field.${props.name}.title`,
                            defaultMessage: camelCaseToTile(props.name),
                        })}
                    />);
                }
            } else {
                if (!placeholder) {
                    placeholder = {
                        id: `crudForm.dictionary.field.${props.name}.placeholder`,
                        defaultMessage: translate({
                            id: `crud.field.${props.name}.title`,
                            defaultMessage: camelCaseToTile(props.name)
                        }),
                    };
                }

                if (!label) {
                    let propsName = props.name;
                    if ((props.name === 'seaLineId') && this.state.dictionaryFields.cargoType === 'GENERAL_CARGO') {
                        propsName = 'consolidator';
                    }

                    label = (<FormattedMessage
                        id={`crudForm.dictionary.field.${props.name}`}
                        defaultMessage={translate({
                            id: `crud.field.${propsName}.title`,
                            defaultMessage: camelCaseToTile(props.name),
                        })}
                    />);
                }
            }

            if (props.required) {
                label = <span className="flag-required">{label}</span>;
            }

            let fieldValue = dictionaryFields[props.name] || dictionaryFields[`${props.name}Id`];
            const readOnlyValue = props.readonly;
            const showVirtualAirportsValue = props.showVirtual;

            if (!fieldValue && props.getterName) {
                fieldValue = get(dictionaryFields, props.getterName);
                dictionaryFields[props.name] = fieldValue;
            }

            if (!fieldValue && props.needToFindCountryCode) {
                if (get(dictionaryFields, 'city.parent.type') === 'REGION') {
                    fieldValue = dictionaryFields.city.parent.parent.id;
                } else if (get(dictionaryFields, 'city.parent.type') === 'COUNTRY') {
                    fieldValue = dictionaryFields.city.parent.id;
                }

                dictionaryFields[props.name] = fieldValue;
            }

            if (props.name === 'parentId' && props.editor === 'select-region') {
                if (get(dictionaryFields, 'parent.type') === 'COUNTRY' && dictionaryFields.parentId === dictionaryFields.parent.id) {
                    fieldValue = '';
                }
            }

            if (props.name === 'countries' && dictionaryFields.countries) {
                fieldValue = addFormatName(dictionaryFields.countries);
            }

            const seaReadOnly = props.readonly;

            if (React.isValidElement(props.editor)) {
                return React.cloneElement(props.editor, {
                    label,
                    placeholder,
                    fieldValue,
                    fieldName: props.name,
                    fields: dictionaryFields,
                    disable: props.readonly,
                    error: dictionaryErrors[props.name],
                    onChange: value => this.onChangeFields(value, props.name),
                    store: this.props.store,
                });
            }

            switch (props.editor) {

                case 'tariffSubscriptions':{
                    if(fieldValue){

                        return (
                            <FormGroup>
                                <InputGroup>
                                    <HistorySubscription idSubscription={fieldValue}/>
                                </InputGroup>
                            </FormGroup>
                        );
                    }
                    return null;
                }

                case 'statusRequestSubscription':{
                    const statuses = [
                        {
                            label: translate("subscription.request.status.NEW"),
                            value: "NEW"
                        },{
                            label: translate("subscription.request.status.PROCESSED"),
                            value: "PROCESSED"
                        },{
                            label: translate("subscription.request.status.ACCEPT"),
                            value: "ACCEPT"
                        },{
                            label: translate("subscription.request.status.DECLINE"),
                            value: "DECLINE"
                        }
                    ]
                    if(fieldValue){
                        return (
                            <FormGroup>
                                <div className={"d-block d-lg-none"}>
                                    <Select
                                        value={fieldValue}
                                        values={statuses}
                                        disabled={props.readonly}
                                        labelKey={"label"}
                                        valueKey={"value"}
                                        onChange={(value)=>this.onChangeFields(value.value, props.name)}
                                        label={<Label className={"f-label"}>{label}</Label>}
                                    />
                                    </div>
                                <div className={"d-none d-lg-block"}>
                                    <Select
                                        className={"d-none d-lg-block"}
                                        value={fieldValue}
                                        values={statuses}
                                        disabled={props.readonly}
                                        labelKey={"label"}
                                        valueKey={"value"}
                                        onChange={(value)=>this.onChangeFields(value.value, props.name)}
                                        leftAddon={<FormattedMessage id={"crudForm.field.status.text"}/>}
                                    />
                                </div>

                            </FormGroup>
                        );
                    }
                    return null;
                }

                case 'tariffPlanNameOnly': {
                    if(fieldValue){
                        return (
                            <FormGroup>
                                <FormGroup className={"d-none d-lg-block"}>
                                    <InputGroup>
                                        <InputGroupAddon className="left-addon" style={{marginRight: "15px"}}
                                                         addonType="prepend"><span
                                            className="input-group-text"><FormattedMessage id={"crud.field.subscriptionTariffPlan.title"}/></span>
                                        </InputGroupAddon>
                                        <span
                                            style={{lineHeight: "42px"}}>
                                            {getExistLangValue(fieldValue.name)}{" | "}{fieldValue.limitCalculations} расчетов{" | "}{fieldValue.price.value}{" "}{fieldValue.price.currency} / мес
                                        </span>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup className={"d-block d-lg-none"}>
                                        <div>
                                            <Label className={"f-label"}>{label}</Label>
                                        </div>
                                        <div>
                                            {getExistLangValue(fieldValue.name)}{" | "}{fieldValue.limitCalculations} расчетов{" | "}{fieldValue.price.value}{" "}{fieldValue.price.currency} <FormattedMessage id={"subscription.tariff.period"}/>
                                        </div>
                                </FormGroup>
                            </FormGroup>
                        );
                    }
                    return null;
                }

                case 'user': {
                    if (fieldValue)
                        return (
                            <FormGroup>
                                <FormGroup className={"d-none d-lg-block"}>
                                    <InputGroup>
                                        <InputGroupAddon className="left-addon" style={{marginRight: "15px"}}
                                                         addonType="prepend"><span
                                            className="input-group-text"><FormattedMessage id={"crud.field.client.title"}/></span></InputGroupAddon>
                                        <span
                                            style={{lineHeight: "42px"}}>
                                        {fieldValue.lastName}{" "}{fieldValue.firstName}{" / "}{fieldValue.email}{fieldValue.phone?" / ":""}{fieldValue.phone}
                                    </span>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup className={"d-block d-lg-none"}>
                                    <div>
                                        <Label className={"f-label"}><FormattedMessage id={"crud.field.client.title"}/></Label>
                                    </div>
                                    <div>
                                        {fieldValue.lastName}{" "}{fieldValue.firstName}<br/>{fieldValue.email}{fieldValue.phone?<br/>:""}{fieldValue.phone}
                                    </div>
                                </FormGroup>
                            </FormGroup>
                        );
                    break;
                }

                case 'translationText': {
                    return (
                        <span>
                            <TranslationText
                                required={props.required}
                                fields={dictionaryFields}
                                fieldName={props.name}
                                fieldNameLabel={props.nameField ? props.nameField : props.name}
                                onChange={this.onChangeTranslate}
                                disabled={props.readonly}
                                error={dictionaryErrors[props.name]}
                                type={props.type}
                            />
                        </span>
                    );
                }

                case 'size': {
                    return (
                        <FormGroup>
                            <SizeInput
                                value={fieldValue}
                                leftAddon={label}
                                onChange={value => this.onChangeFields(value, props.name)}
                                error={dictionaryErrors[props.name]}
                                disabled={props.readonly}
                            />
                        </FormGroup>
                    );
                }

                case 'hatchSize': {
                    let err = (!isEmpty(dictionaryErrors) && dictionaryErrors.hatchSize) ? 'common.required' : '';
                    if (!isEmpty(dictionaryErrors) && !dictionaryErrors.hatchSize) {
                        err = dictionaryErrors['hatchSize.length'] || dictionaryErrors['hatchSize.width'] ? 'common.required' : '';
                    }

                    return (
                        <FormGroup>
                            <HatchSizeInput
                                value={fieldValue}
                                leftAddon={label}
                                onChange={value => this.onChangeFields(value, props.name)}
                                error={err}
                                disabled={props.readonly || (dictionaryFields.kind !== 'AIR' && true)}
                            />
                        </FormGroup>
                    );
                }

                case 'coordinates': {
                    return (
                        <FormGroup>
                            <CoordinatesInput
                                value={toJS(fieldValue)}
                                leftAddon={label}
                                onChange={value => this.onChangeCoordinatesFields(value, props.name)}
                                error={{dictionaryErrors, name: props.name}}
                                disabled={props.readonly}
                            />
                        </FormGroup>
                    );
                }

                case 'checkbox': {
                    return (
                        <FormGroup
                            className={`f-element f-element-checkbox ${props.className?props.className:''} ${dictionaryErrors[props.name] ? 'has-danger' : ''}`}>
                            <InputGroup className={"d-none d-lg-flex"}>
                                <InputGroupAddon className="left-addon" style={{marginRight: 15}}
                                                 addonType="prepend"><span
                                    className={`input-group-text ${props.className?props.className:''}`}>{label}</span></InputGroupAddon>
                                <Checkbox
                                    style={{marginTop: 8}}
                                    checked={!!fieldValue}
                                    disabled={props.readonly}
                                    onChange={ev => this.onChangeFields(ev.target.checked, props.name)}
                                    label={translate({id: 'crud.label.yes', defaultMessage: 'Yes'})}
                                />
                            </InputGroup>
                            <FormGroup className={"d-block d-lg-none"}>
                                <Label className={"f-label"}>
                                    {label}
                                </Label>
                                <Checkbox
                                    checked={!!fieldValue}
                                    disabled={props.readonly}
                                    onChange={ev => this.onChangeFields(ev.target.checked, props.name)}
                                    label={translate({id: 'crud.label.yes', defaultMessage: 'Yes'})}
                                />
                            </FormGroup>
                            <Error>{dictionaryErrors[props.name]}</Error>
                        </FormGroup>
                    );
                }

                case 'select-subordinate-role': {
                    return (
                        <SelectSubordinateRole
                            multi
                            leftAddon={label}
                            error={dictionaryErrors[props.name]}
                            disabled={props.readonly}
                            onChange={roles => this.onChangeFields(roles, props.name)}
                            value={fieldValue}
                        />
                    );
                }

                case 'password-reset': {
                    return (
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon
                                    className="left-addon"
                                    style={{marginRight: 15}}
                                    addonType="prepend"
                                >
                                    <FormattedMessage id="crud.field.passwordReset.label"/>
                                </InputGroupAddon>
                                <Button
                                    type="button"
                                    color="info"
                                    onClick={this.sendRecoveryInstructionsOnEmail}
                                >
                                    <FormattedMessage id="crud.field.passwordReset.title"/>
                                </Button>
                                <Error>
                                    {dictionaryErrors[props.name]}
                                </Error>
                            </InputGroup>
                        </FormGroup>
                    );
                }

                case 'select-country': {
                    return (
                        <FormGroup>
                            <SelectCountry
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                disabled={props.readonly}
                                onChange={el => this.onChangeFields(el.value, props.name)}
                                value={fieldValue}
                            />
                        </FormGroup>
                    );
                }

                case 'select-country-disclaimers': {
                    return (
                        <FormGroup>
                            <SelectCountry
                                isDisclaimer
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                disabled={props.readonly}
                                onChange={el => this.onChangeFields(el.value, props.name)}
                                value={fieldValue}
                            />
                        </FormGroup>
                    );
                }

                case 'select-country-select-region': {
                    return (
                        <FormGroup>
                            <SelectCountry
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                disabled={props.readonly}
                                onChange={el => this.onChangeCountry(el.value, props.name)}
                                value={this.state.countryId}
                            />
                        </FormGroup>
                    );
                }

                case 'select-region': {
                    return (
                        <FormGroup>
                            <Row className="align-items-center">
                                <Col md={8}>
                                    <SelectRegion
                                        key={`select-region__${this.state.countryId}`}
                                        disabled={this.state.countryId === '' || this.state.noRegion}
                                        parent={this.state.countryId || ''}
                                        leftAddon={label}
                                        error={dictionaryErrors[props.name]}
                                        labelKey={`name.${AppStore.userLang}`}
                                        onChange={el => this.onChangeFields(el.value, props.name)}
                                        value={!this.state.noRegion ? fieldValue : ''}
                                    />
                                </Col>
                                <Col md={4}>
                                    <Checkbox
                                        checked={this.state.noRegion}
                                        onChange={this.onChangeNoRegion}
                                        label="crudForm.field.regionAbsent"
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    );
                }


                case 'select-countries': {
                    return (
                        <FormGroup>
                            <SelectCountries
                                multi
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                disabled={props.readonly}
                                onChange={el => this.onChangeFields(el, props.name)}
                                value={fieldValue}
                            />
                        </FormGroup>
                    );
                }

                case 'select-sea-port': {
                    return (
                        <FormGroup>
                            <SelectSeaPort
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                onChange={el => this.onChangeFields(el.value, props.name)}
                                value={fieldValue}
                                disabled={seaReadOnly}
                            />
                        </FormGroup>
                    );
                }

                case 'select-sea-line': {
                    return (
                        <FormGroup>
                            <SelectSeaLine
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                onChange={el => this.onChangeFields(el.value, props.name)}
                                value={fieldValue}
                                disabled={seaReadOnly}
                                isConsolidator={this.state.dictionaryFields.cargoType === 'GENERAL_CARGO'}
                            />
                        </FormGroup>
                    );
                }

                case 'select-country-multi': {
                    return (
                        <FormGroup>
                            <SelectCountryMulti
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                disabled={props.readonly}
                                onChange={el => this.onChangeFields(el, props.name)}
                                value={toJS(fieldValue)}
                            />
                        </FormGroup>
                    );
                }

                case 'select-world-region-multi': {
                    return (
                        <FormGroup>
                            <SelectWorldRegion
                                multi
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                disabled={props.readonly}
                                onChange={el => this.onChangeFields(el, props.name)}
                                value={toJS(fieldValue)}
                            />
                        </FormGroup>
                    );
                }

                case 'select-world-region-single': {

                    const offRegion = {
                        code: "NONE",
                        name: {
                            "ru": "Данный вид груза не будет доступен к выбору",
                            "en": "This type of cargo will not be available for selection"
                        }
                    }
                    const offRegionFormatName = setName(offRegion)
                    const search = {
                        name: {
                            "ru": "Поиск",
                            "en": "Search"
                        }
                    }
                    const loadOptionsCountries = async (input) => {
                        const res = await DictionaryService.getCountries({name: input});
                        const europe = {
                            code: "EU",
                            name: {
                                "ru": "Европа",
                                "en": "Europe"
                            }
                        }
                        const values = map(res, (country) => {
                            return {value: country.code, name: setName(country)};
                        });
                        values.push({value: europe.code, name: setName(europe)})

                        return {options: values};
                    }
                    const applySearch = (element_search) => {
                        let searchData = cloneDeep(this.state.searchText)
                        let c_start = []
                        if (searchData.startRegion.length > 0)
                            c_start = this.state.countries.filter((element) => {
                                return element.name.toLowerCase().includes(searchData.startRegion.toLowerCase());
                            })
                        let c_end = []
                        if (searchData.endRegion.length > 0)
                            c_end = this.state.countries.filter((element) => {
                                return element.name.toLowerCase().includes(searchData.endRegion.toLowerCase());
                            })

                        if (searchData.startRegion.length === 0 && searchData.endRegion.length === 0) {
                            return true
                        }
                        let isFind = false
                        if (searchData.startRegion.length !== 0 && searchData.endRegion.length === 0) {
                            c_start.map((value) => {
                                if (value.value.toString() === element_search.split("-")[0])
                                    isFind = true
                            })
                        } else if (searchData.startRegion.length !== 0 && searchData.endRegion.length !== 0) {
                            let isFindLeft = false
                            let isFindRight = false
                            c_start.map((value) => {
                                if (value.value.toString() === element_search.split("-")[0])
                                    isFindLeft = true
                            })
                            c_end.map((value) => {
                                if (value.value.toString() === element_search.split("-")[1])
                                    isFindRight = true
                            })
                            if (isFindLeft === true && isFindRight === true)
                                isFind = true
                        } else if (searchData.startRegion.length === 0 && searchData.endRegion.length !== 0) {
                            c_end.map((value) => {
                                if (value.value.toString() === element_search.split("-")[1])
                                    isFind = true
                            })
                        }


                        return isFind
                    }
                    const renderRows = (elem, index) => {
                        return (
                            <Row>
                                <Col xs={12} lg={4} className="px-0">
                                    <Select
                                        values={this.state.countries}
                                        labelKey={`name`}
                                        value={elem.split("-")[0]}
                                        searchable
                                        loadOptions={input => loadOptionsCountries(input)}
                                        onChange={el => this.onChangeRegionTypeContainerType(el, props.name, 0, index)}
                                    />
                                </Col>
                                <Col xs={12} lg={1}
                                     className="px-0 d-flex align-items-center">
                                    <div className="mx-auto" style={{paddingBottom: "7px"}}>
                                        <svg
                                            className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1 DateRangePickerInput_arrow_svg__small DateRangePickerInput_arrow_svg__small_2"
                                            viewBox="0 0 1000 1000">
                                            <path
                                                d="M694.4 242.4l249.1 249.1c11 11 11 21 0 32L694.4 772.7c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210.1-210.1H67.1c-13 0-23-10-23-23s10-23 23-23h805.4L662.4 274.5c-21-21.1 11-53.1 32-32.1z"></path>
                                        </svg>
                                    </div>
                                </Col>
                                <Col xs={12} lg={4} className={"px-0"}>
                                    <Select
                                        values={this.state.countries}
                                        labelKey={`name`}
                                        value={elem.split("-")[1]}
                                        searchable
                                        loadOptions={input => loadOptionsCountries(input)}
                                        onChange={el => this.onChangeRegionTypeContainerType(el, props.name, 1, index)}
                                    />
                                </Col>
                                <Col xs={12} lg={3} className={"px-0"}>
                                    <button
                                        onClick={() => this.deleteRegionContainerType(props.name, index)}
                                        type="button" className="ml-2 btn btn-danger"><span
                                        aria-hidden="true" className="fa fa-trash"></span>
                                    </button>
                                </Col>
                            </Row>
                        )
                    }

                    return (
                        <FormGroup>
                            <Row>
                                <Col className={"px-0 mb-2 mt-2"}>
                                    {label}
                                    <button type="button" onClick={() => this.addNewRegion(props.name)}
                                            className="ml-2 btn btn-info">
                                        <span aria-hidden="true" className="fa fa-plus"></span></button>
                                </Col>
                            </Row>
                            <Row className="mb-2" style={{borderBottom: "1px solid #D3D8E0"}}>
                                <Col xs={12} className={"px-0"}>{setName(search)}</Col>
                                <Col xs={12} lg={4} className="px-0">
                                    <TextInput onChange={(el) => this.searchRegions(el.target.value, 0)}/>
                                </Col>
                                <Col xs={12} lg={1} className="px-0 d-flex align-items-center">
                                    <div className="mx-auto" style={{paddingBottom: "7px"}}>
                                        <svg
                                            className="DateRangePickerInput_arrow_svg DateRangePickerInput_arrow_svg_1 DateRangePickerInput_arrow_svg__small DateRangePickerInput_arrow_svg__small_2"
                                            viewBox="0 0 1000 1000">
                                            <path
                                                d="M694.4 242.4l249.1 249.1c11 11 11 21 0 32L694.4 772.7c-5 5-10 7-16 7s-11-2-16-7c-11-11-11-21 0-32l210.1-210.1H67.1c-13 0-23-10-23-23s10-23 23-23h805.4L662.4 274.5c-21-21.1 11-53.1 32-32.1z"></path>
                                        </svg>
                                    </div>
                                </Col>
                                <Col xs={12} lg={4} className="px-0">
                                    <TextInput onChange={el => this.searchRegions(el.target.value, 1)}/>
                                </Col>
                            </Row>
                            {fieldValue !== undefined &&
                                fieldValue.map((elem, index) => {
                                    return (
                                        <div>
                                            {elem !== "NONE-NONE" ?
                                                <div>
                                                    {elem.includes("NONE_C") &&
                                                        renderRows(elem, index)
                                                    }
                                                    {applySearch(elem) === true && elem.includes("NONE_C")===false &&
                                                        renderRows(elem, index)
                                                    }
                                                </div>
                                                :
                                                <Row>
                                                    {offRegionFormatName}
                                                </Row>
                                            }
                                        </div>

                                    )
                                })}
                            {/*<Select
                    multi={true}
                    leftAddon={label}
                    values={valReg}
                    labelKey="formatName"
                    valueKey="value"
                    value={fieldValue}
                    error={dictionaryErrors[props.name]}
                    onChange={el => this.onChangeRegionTypeContainerType(el, props.name)}
                    searchable
                />*/}
                        </FormGroup>
                    );
                }
                case 'select-type-chatbot': {
                    var valChatbotTypes = [{
                        id: 1,
                        value: "Telegram",
                        name: {
                            ru: "Telegram",
                            en: "Telegram"
                        },
                        formatName: "Telegram"
                    }];
                    return (
                        <FormGroup>
                            <Select
                                multi={false}
                                leftAddon={label}
                                values={valChatbotTypes}
                                labelKey="formatName"
                                valueKey="value"
                                value={fieldValue}
                                error={dictionaryErrors[props.name]}
                                onChange={el => this.onChangeChatbotType(el, props.name)}
                                searchable
                            />
                        </FormGroup>
                    );
                }


                case 'select-city': {
                    return (
                        <FormGroup>
                            <SelectCity
                                disabled={!get(dictionaryFields.regionId, 'id', dictionaryFields.regionId)}
                                parent={get(dictionaryFields.regionId, 'id', dictionaryFields.regionId) || ''}
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                onChange={el => this.onChangeFields(el.value, props.name)}
                                value={fieldValue}
                            />
                        </FormGroup>
                    );
                }

                case 'select-city-parent-country': {
                    return (
                        <FormGroup>
                            <SelectCity
                                disabled={!this.state.dictionaryFields.countryId}
                                parent={this.state.dictionaryFields.countryId || ''}
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                onChange={el => this.onChangeCity(el, props)}
                                value={fieldValue}
                            />
                        </FormGroup>
                    );
                }

                case 'select-service-category': {
                    return (
                        <FormGroup>
                            <SelectServiceCategory
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                onChange={el => this.onChangeFields(el.value, props.name)}
                                value={fieldValue}
                            />
                        </FormGroup>
                    );
                }

                case 'select-service-profile': {
                    return (
                        <FormGroup>
                            <SelectServiceProfile
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                onChange={el => this.onChangeFields(el.value, props.name)}
                                value={fieldValue}
                            />
                        </FormGroup>
                    );
                }

                case 'select-cargo-type': {
                    const value = fieldValue || props.value;
                    return (
                        <FormGroup>
                            <SelectCargoType
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                onChange={el => {
                                    this.onChangeFields(el.value, props.name);
                                    if (props.onChange && typeof props.onChange === 'function') {
                                        props.onChange(el.value);
                                    }
                                }}
                                value={value}
                                readonly={readOnlyValue}
                            />
                        </FormGroup>
                    );
                }

                case 'select-sea-port-type': {
                    const value = fieldValue || props.value;
                    return (
                        <FormGroup>
                            <SelectDirectionOfTransportation
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                onChange={el => {
                                    this.onChangeFields(el.value, props.name);
                                    if (props.onChange && typeof props.onChange === 'function') {
                                        props.onChange(el.value);
                                    }
                                }}
                                value={value}
                                readonly={readOnlyValue}
                                    />
                        </FormGroup>
                    );
                }

                case 'select-transportation-direction': {
                    const value = fieldValue || props.value;
                    return (
                        <FormGroup>
                            <SelectDirectionOfTransportation
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                onChange={el => {
                                    this.onChangeFields(el.value, props.name);
                                    if (props.onChange && typeof props.onChange === 'function') {
                                        props.onChange(el.value);
                                    }
                                }}
                                value={value}
                                readonly={readOnlyValue}
                            />
                        </FormGroup>
                    );
                }

                case 'select-charging-type': {
                    const value = fieldValue || props.value;
                    return (
                        <FormGroup>
                            <SelectChargingType
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                onChange={el => {
                                    this.onChangeFields(el.value, props.name);
                                    if (props.onChange && typeof props.onChange === 'function') {
                                        props.onChange(el.value);
                                    }
                                }}
                                value={value}
                                readonly={readOnlyValue}
                                cargoType={props.cargoType}
                            />
                        </FormGroup>
                    );
                }

                case 'select-hub': {
                    return (
                        <FormGroup>
                            <SelectHub
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                onChange={el => this.onChangeFields(el.value, props.name)}
                                value={fieldValue}
                            />
                        </FormGroup>
                    );
                }

                case 'select-railway-company': {
                    return (
                        <FormGroup>
                            <SelectRailwayCompany
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                onChange={el => this.onChangeFields(el, props.name)}
                                value={fieldValue}
                                readonly={readOnlyValue}
                            />
                        </FormGroup>
                    );
                }

                case 'select-railway-station': {
                    return (
                        <FormGroup>
                            <SelectRailwayStation
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                onChange={el => this.onChangeFields(el, props.name)}
                                value={fieldValue}
                                readonly={readOnlyValue}
                            />
                        </FormGroup>
                    );
                }

                case 'select-airline': {
                    return (
                        <FormGroup>
                            <SelectAirline
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                onChange={el => this.onChangeFields(el, props.name)}
                                value={fieldValue}
                                readonly={readOnlyValue}
                            />
                        </FormGroup>
                    );
                }

                case 'select-airport': {
                    return (
                        <FormGroup>
                            <SelectAirport
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={`name.${AppStore.userLang}`}
                                onChange={el => this.onChangeFields(el, props.name)}
                                value={fieldValue}
                                readonly={readOnlyValue}
                                showVirtual={showVirtualAirportsValue}
                            />
                        </FormGroup>
                    );
                }

                case 'select-organization': {
                    return (
                        <FormGroup>
                            <Select
                                async
                                searchable
                                loadOptions={() => {
                                    return OrganizationService.getOrganizationsAll()
                                        .then((res) => {
                                            return ({options: get(res, 'data', [])});
                                        });
                                }}
                                value={dictionaryFields[props.name]}
                                error={dictionaryErrors[props.name]}
                                labelKey="name"
                                valueKey="id"
                                disabled={props.readonly}
                                leftAddon={label}
                                onChange={value => this.onChangeOrganization(value, props.name, props.type)}
                            />
                        </FormGroup>
                    );
                }

                case 'select-organization-profile': {
                    return (
                        <FormGroup>
                            <Select
                                async
                                searchable
                                multi
                                loadOptions={() => {
                                    return OrganizationProfilesStore.getAllOrganizationProfiles()
                                        .then(profiles => ({
                                            options: addFormatName(profiles),
                                        }));
                                }}
                                value={dictionaryFields[props.name]}
                                error={dictionaryErrors[props.name]}
                                labelKey="formatName"
                                valueKey="id"
                                disabled={props.readonly}
                                leftAddon={label}
                                onChange={(nextProfiles) => {
                                    this.onChangeFields(
                                        map(nextProfiles, profile => ({
                                            ...profile,
                                            value: {
                                                id: profile.id,
                                            },
                                        })),
                                        props.name
                                    );
                                }}
                            />
                        </FormGroup>
                    );
                }

                case 'select-forwarder-template': {
                    return (
                        <FormGroup>
                            <Select
                                value={dictionaryFields[props.name]}
                                values={this.state[props.name]}
                                valueKey="name"
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey="name"
                                disabled={props.readonly}
                                onChange={value => this.onChangeFields(value.name, props.name)}
                            />
                        </FormGroup>
                    );
                }

                case 'select-forwarder': {
                    return (
                        <FormGroup>
                            <SelectForwarder
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey={'name'}
                                onChange={el => this.onChangeFields(el.value, props.name)}
                                value={fieldValue}
                            />
                        </FormGroup>
                    );
                }

                case 'select': {
                    return (
                        <FormGroup>
                            <Select
                                value={dictionaryFields[props.name]}
                                values={this.state[props.name]}
                                valueKey={props.name}
                                leftAddon={label}
                                error={dictionaryErrors[props.name]}
                                labelKey="formatName"
                                disabled={props.readonly}
                                onChange={value => this.onChangeFields(value[props.name], props.name)}
                            />
                        </FormGroup>
                    );
                }

                case 'price': {
                    const value = dictionaryFields[props.name] ? dictionaryFields[props.name].value : '';
                    const currency = dictionaryFields[props.name] ? dictionaryFields[props.name].currency : '';
                    const classNames = props.bordered ? 'border-top border-dark' : '';
                    return (
                        <FormGroup className={classNames}>
                            <NumberInput
                                value={value}
                                placeholder={placeholder}
                                leftAddon={translate(props.labelName)}
                                onChange={value => this.onChangePrice(value, props.name, 'value')}
                                error={dictionaryErrors[props.name]}
                                disabled={props.readonly}
                            />
                            <Select
                                multi={false}
                                leftAddon={translate('common.currency')}
                                values={this.state.currencies}
                                labelKey="code"
                                valueKey="code"
                                value={currency}
                                error={dictionaryErrors[props.name]}
                                onChange={el => this.onChangePrice(el.code, props.name, 'currency')}
                                searchable
                            />
                        </FormGroup>
                    );
                }

                case 'price-new': {
                    const value = dictionaryFields[props.name] ? dictionaryFields[props.name].value : '';
                    const currency = dictionaryFields[props.name] ? dictionaryFields[props.name].currency : '';
                    const classNames = props.bordered ? 'border-top border-dark' : '';
                    return (
                        <FormGroup className={`${classNames} input-42px`}>
                            <InputGroup className={"d-none d-lg-flex"}>
                                <InputGroupAddon className="left-addon d-none d-lg-block"
                                                 style={{height: "42px"}}
                                                 addonType="prepend"><span
                                    className="input-group-text">{label}</span>
                                </InputGroupAddon>
                                <NumberInput
                                    value={value}
                                    placeholder={placeholder}
                                    leftAddon={translate(props.labelName)}
                                    onChange={value => this.onChangePrice(value, props.name, 'value')}
                                    error={dictionaryErrors[props.name]}
                                    disabled={props.readonly}
                                />
                                <Select
                                    multi={false}
                                    values={this.state.currencies}
                                    labelKey="code"
                                    valueKey="code"
                                    value={currency}
                                    error={dictionaryErrors[props.name]}
                                    onChange={el => this.onChangePrice(el.code, props.name, 'currency')}
                                />
                            </InputGroup>
                            <FormGroup className={"d-block d-lg-none"}>
                                <Label className={"f-label"}>{label}</Label>
                                <NumberInput
                                    value={value}
                                    placeholder={placeholder}
                                    leftAddon={translate(props.labelName)}
                                    onChange={value => this.onChangePrice(value, props.name, 'value')}
                                    error={dictionaryErrors[props.name]}
                                    disabled={props.readonly}
                                />
                                <Select
                                    multi={false}
                                    values={this.state.currencies}
                                    labelKey="code"
                                    valueKey="code"
                                    value={currency}
                                    error={dictionaryErrors[props.name]}
                                    onChange={el => this.onChangePrice(el.code, props.name, 'currency')}
                                />
                            </FormGroup>
                        </FormGroup>
                    );
                }

                case 'select-type-data': {
                    let values = addFormatName(props.data)
                    return (
                        <FormGroup>
                            <Select
                                multi={false}
                                leftAddon={label}
                                values={values}
                                labelKey="formatName"
                                valueKey="value"
                                value={fieldValue}
                                error={dictionaryErrors[props.name]}
                                onChange={el => this.onChangeFields(el.value, props.name)}
                                searchable
                            />
                        </FormGroup>
                    );
                }

                case 'select-region-from': {
                    return (
                        <FormGroup>
                            <Select
                                multi={false}
                                leftAddon={translate('crud.field.region.title')}
                                values={this.state.worldRegions}
                                labelKey="formatName"
                                valueKey="id"
                                value={fieldValue}
                                error={dictionaryErrors[props.name]}
                                onChange={el => this.onChangePrice(el.code, props.name, 'currency')}
                                searchable
                            />
                        </FormGroup>
                    )
                }

                case 'number': {
                    if (props.step) {
                        return (
                            <FormGroup>
                                <FormGroup className={"d-none d-lg-block"}>
                                    <NumberInput
                                        value={fieldValue}
                                        placeholder={placeholder}
                                        leftAddon={label}
                                        className={props.className?props.className:''}
                                        step={props.step}
                                        onChange={value => this.onChangeFields(value, props.name)}
                                        error={dictionaryErrors[props.name]}
                                        disabled={props.readonly}
                                    />
                                </FormGroup>
                                <FormGroup className={"d-block d-lg-none"}>
                                    <NumberInput
                                        value={fieldValue}
                                        placeholder={placeholder}
                                        label={<Label className={"f-label"}>{label}</Label>}
                                        step={props.step}
                                        onChange={value => this.onChangeFields(value, props.name)}
                                        error={dictionaryErrors[props.name]}
                                        disabled={props.readonly}
                                    />
                                </FormGroup>
                            </FormGroup>
                        );
                    } else {
                        return (
                            <FormGroup>
                                <FormGroup className={"d-none d-lg-block"}>
                                    <NumberInput
                                        value={fieldValue}
                                        placeholder={placeholder}
                                        leftAddon={label}
                                        onChange={value => this.onChangeFields(value, props.name)}
                                        error={dictionaryErrors[props.name]}
                                        disabled={props.readonly}
                                    />
                                </FormGroup>
                                <FormGroup className={"d-block d-lg-none"}>
                                    <NumberInput
                                        value={fieldValue}
                                        placeholder={placeholder}
                                        label={<Label className={"f-label"}>{label}</Label>}
                                        onChange={value => this.onChangeFields(value, props.name)}
                                        error={dictionaryErrors[props.name]}
                                        disabled={props.readonly}
                                    />
                                </FormGroup>
                            </FormGroup>
                        );

                    }
                }

                case 'weightInput': {
                    const value = dictionaryFields[props.name] ? dictionaryFields[props.name].value : '';
                    // const unit = dictionaryFields[props.name] ? dictionaryFields[props.name].unit : '';
                    return (
                        <FormGroup>
                            <NumberInput
                                value={value}
                                placeholder={placeholder}
                                leftAddon={label}
                                onChange={_value => this.onChangeFields(_value, 'maxWeight.value')}
                                error={dictionaryErrors['maxWeight.value'] || dictionaryErrors.maxWeight}
                                disabled={props.readonly}
                            />
                            {/*<Select*/}
                            {/*values={this.state.weightUnits}*/}
                            {/*leftAddon={<span className="flag-required">{<FormattedMessage id="crudForm.field.weightUnit" />}</span>}*/}
                            {/*labelKey="code"*/}
                            {/*valueKey="code"*/}
                            {/*value={unit}*/}
                            {/*onChange={_value => this.onChangeFields(_value.code, 'maxWeight.unit')}*/}
                            {/*error={dictionaryErrors['maxWeight.unit'] || dictionaryErrors.maxWeight}*/}
                            {/*disabled={props.readonly}*/}
                            {/*/>*/}
                        </FormGroup>
                    );
                }

                case 'templateError':{
                    return (dictionaryErrors[`${props.name}`] &&
                        <FormGroup>
                            <h4 style={{color:"#f45d5d"}}>{translate({id:"common.errorNullTemplate"})}</h4>
                        </FormGroup>
                    )
                }

                case 'placeTemplate':{
                    return (
                        <FormGroup>
                            <Button
                                onClick={props.onChange}
                            >
                                {props.isShow===true?translate({id:`crud.field.${props.name}.button.delete`}):translate({id:`crud.field.${props.name}.button.add`})}
                            </Button>
                            {props.isShow === true &&
                                <FormGroup className="mt-2">
                                    <TextInput
                                        value={dictionaryFields[`${props.name}`] ? dictionaryFields[`${props.name}`].companyName : ""}
                                        placeholder={translate({id: 'crud.field.companyName.title'})}
                                        leftAddon={<span
                                            className="flag-required">{translate({id: 'crud.field.companyName.title'})}</span>}
                                        onChange={ev => this.onChangeFields(ev.target.value, `${props.name}.companyName`)}
                                        error={dictionaryErrors[`${props.name}.companyName`]}
                                        disabled={props.readonly}
                                        type={'text'}
                                    />
                                    <TextInput
                                        value={dictionaryFields[`${props.name}`] ? dictionaryFields[`${props.name}`].address : ""}
                                        placeholder={translate({id: 'wizard.directionForm.address'})}
                                        leftAddon={<span
                                            className="flag-required">{translate({id: 'wizard.directionForm.address'})}</span>}
                                        onChange={ev => this.onChangeFields(ev.target.value, `${props.name}.address`)}
                                        error={dictionaryErrors[`${props.name}.address`]}
                                        disabled={props.readonly}
                                        type={'text'}
                                    />
                                    <TextInput
                                        value={dictionaryFields[`${props.name}`] ? dictionaryFields[`${props.name}`].contactPerson : ""}
                                        placeholder={translate({id: 'crud.field.contactPerson.title'})}
                                        leftAddon={<span
                                            className="flag-required">{translate({id: 'crud.field.contactPerson.title'})}</span>}
                                        onChange={ev => this.onChangeFields(ev.target.value, `${props.name}.contactPerson`)}
                                        error={dictionaryErrors[`${props.name}.contactPerson`]}
                                        disabled={props.readonly}
                                        type={'text'}
                                    />
                                    <TextInput
                                        value={dictionaryFields[`${props.name}`] ? dictionaryFields[`${props.name}`].phone : ""}
                                        placeholder={translate({id: 'form.field.phone'})}
                                        leftAddon={<span
                                            className="flag-required">{translate({id: 'form.field.phone'})}</span>}
                                        onChange={ev => this.onChangeFields(ev.target.value, `${props.name}.phone`)}
                                        error={dictionaryErrors[`${props.name}.phone`]}
                                        disabled={props.readonly}
                                        type={'text'}
                                    />
                                    <TextInput
                                        value={dictionaryFields[`${props.name}`] ? dictionaryFields[`${props.name}`].email : ""}
                                        placeholder={translate({id: 'form.order.email'})}
                                        leftAddon={<span
                                            className="flag-required">{translate({id: 'form.order.email'})}</span>}
                                        onChange={ev => this.onChangeFields(ev.target.value, `${props.name}.email`)}
                                        error={dictionaryErrors[`${props.name}.email`]}
                                        disabled={props.readonly}
                                        type={'email'}
                                    />
                                    <TextInput
                                        value={dictionaryFields[`${props.name}`] ? dictionaryFields[`${props.name}`].zipCode : ""}
                                        placeholder={translate({id: 'crud.field.zipCode.title'})}
                                        leftAddon={<span>{translate({id: 'crud.field.zipCode.title'})}</span>}
                                        onChange={ev => this.onChangeFields(ev.target.value, `${props.name}.zipCode`)}
                                        error={dictionaryErrors[`${props.name}.zipCode`]}
                                        disabled={props.readonly}
                                        type={'text'}
                                    />
                                </FormGroup>
                            }
                        </FormGroup>
                    )
                }


                case 'compartments': {
                    const compartments = get(dictionaryFields, 'compartments', []);

                    const rows = compartments.map((item, index) => {
                        const idx = index;
                        return (
                            <TabPane tabId={index + 1} key={`${item.id}_${idx}`}>
                                <Row className="mt-3">
                                    <Col xs={12}>
                                        {/*название отсека*/}
                                        <TranslationText
                                            required
                                            fields={item}
                                            fieldName="name"
                                            onChange={(value, name, error) => this.onChangeTranslateCompartments(value, name, error, index)}
                                            deleteTranslate={i => this.deleteTranslate(i)}
                                            addTranslate={value => this.addTranslate(value)}
                                            error={dictionaryErrors[`compartments[${index}].name`]}
                                        />
                                        {/*Предельная загрузка*/}
                                        <NumberInput
                                            value={get(item.maxLoad, 'value', '')}
                                            placeholder="crudForm.dictionary.field.maxLoad"
                                            leftAddon={translate({id: 'crudForm.dictionary.field.maxLoad'})}
                                            onChange={value => this.onChangeFields(value, `compartments[${index}].maxLoad.value`)}
                                            error={dictionaryErrors[`compartments[${index}].maxLoad.value`]}
                                        />
                                        {/*Размеры отсека*/}
                                        <SizeInput
                                            value={item.size}
                                            leftAddon={translate({id: 'crudForm.dictionary.field.compartmentSize'})}
                                            onChange={value => this.onChangeFields(value, `compartments[${index}].size`, index)}
                                            error={dictionaryErrors[`compartments[${index}].size.width`] || dictionaryErrors[`compartments[${index}].size.height`] ||
                                                dictionaryErrors[`compartments[${index}].size.length`]}
                                        />
                                        {/*Объем*/}
                                        <NumberInput
                                            value={item.volume}
                                            placeholder="crudForm.dictionary.field.volume"
                                            leftAddon={translate({id: 'crudForm.dictionary.field.volume'})}
                                            onChange={value => this.onChangeFields(value, `compartments[${index}].volume`)}
                                            error={dictionaryErrors[`compartments[${index}].volume`]}
                                        />
                                        {/*Площадь пола*/}
                                        <NumberInput
                                            value={item.floorSize}
                                            placeholder="crudForm.dictionary.field.floorSize"
                                            leftAddon={translate({id: 'crudForm.dictionary.field.floorSize'})}
                                            onChange={value => this.onChangeFields(value, `compartments[${index}].floorSize`)}
                                            error={dictionaryErrors[`compartments[${index}].floorSize`]}
                                        />
                                        {/*Грузовой люк (ВхШ)*/}
                                        <HatchSizeInput
                                            value={item.hatchSize}
                                            leftAddon={<span
                                                className="flag-required">{translate({id: 'crudForm.dictionary.field.mainHatchSize'})}</span>}
                                            onChange={value => this.onChangeFields(value, `compartments[${index}].hatchSize`, index)}
                                            error={get(dictionaryErrors, `compartments[${index}].hatchSize.length`, '') || get(dictionaryErrors, `compartments[${index}].hatchSize.width`, '')}
                                        />
                                        {/*Допустимое давление на пол*/}
                                        <NumberInput
                                            value={item.maxFloorPressure}
                                            placeholder="crudForm.dictionary.field.maxFloorPressure"
                                            leftAddon={translate({id: 'crudForm.dictionary.field.maxFloorPressure'})}
                                            onChange={value => this.onChangeFields(value, `compartments[${index}].maxFloorPressure`)}
                                            error={dictionaryErrors[`compartments[${index}].maxFloorPressure`]}
                                        />
                                        <Button
                                            onClick={() => this.removeCompartments(index)}
                                            color="danger"
                                            disabled={compartments.length === 1}
                                        >
                                            {translate({id: 'crudForm.dictionary.btn.removeHatch'})}
                                        </Button>
                                    </Col>
                                </Row>
                            </TabPane>
                        );
                    });

                    const navs = compartments.map((item, index) => {
                        const idx = index;
                        const TAB_ERRORS = [
                            `compartments[${index}].size.width`,
                            `compartments[${index}].size.height`,
                            `compartments[${index}].size.length`,
                            `compartments[${index}].maxLoad.value`,
                            `compartments[${index}].floorSize`,
                            `compartments[${index}].name`,
                            `compartments[${index}].volume`,
                        ];

                        return (
                            <NavItem key={`${item.id}_navs_${idx}`}>
                                <NavLink
                                    className={`${this.state.tabIndex === index + 1 ? 'active' : ''}`}
                                    onClick={() => this.setTab(index + 1)}
                                >
                                    {this.renderTabName('crudForm.dictionary.tab.tabHatch', index, TAB_ERRORS)}
                                </NavLink>
                            </NavItem>
                        );
                    });

                    return (
                        <div>
                            <Nav tabs>
                                {navs}
                            </Nav>
                            <TabContent activeTab={this.state.tabIndex}>
                                {rows}
                            </TabContent>
                            {compartments.length !== 0 && <hr/>}
                            <Button
                                onClick={() => this.addCompartments()}
                                color="primary"
                            >
                                {translate({id: 'crudForm.dictionary.btn.addHatch'})}
                            </Button>
                            <hr/>
                        </div>
                    );
                }

                case 'select-email-language': {
                    return (
                        this.renderLanguageField(label, dictionaryErrors, props, fieldValue)
                    );
                }
                case 'select-email-language-user': {
                    return (
                        this.renderLanguageField4User(label, dictionaryErrors, props, fieldValue)
                    );
                }

                case 'resetUsageLimit':{
                    return (
                        <ResetUsageLimitExternalApi id={fieldValue} onReset={props.onReset}/>
                    )
                }
                case 'enabledApiBtn':{
                    const buttonStyle = { height: 38, marginLeft:"15px", display:"flex", alignItems:"center" };
                    return (
                        <FormGroup>
                            <InputGroup className={"d-none d-lg-flex"}>
                                <InputGroupAddon className="left-addon d-none d-lg-block"
                                                 style={{height: "42px"}}
                                                 addonType="prepend"><span
                                    className="input-group-text">{label}</span>
                                </InputGroupAddon>
                                <Button
                                    size="sm"
                                    color={fieldValue ? 'danger' : 'success'}
                                    style={buttonStyle}
                                    onClick={ev => this.onChangeFields(!fieldValue, props.name)}
                                >
                                    <FontAwesome name="power-off" className="mr-2" />
                                    <FormattedMessage
                                        id={`operatorAdmin.listOfAPIs.${fieldValue ? 'disconnect' : 'connect'}`}
                                    />
                                </Button>
                            </InputGroup>
                        </FormGroup>
                    )
                }

                case 'button':
                case 'none':
                    return null;

                case 'hr':
                    return <hr/>;

                case 'domain':
                    return (
                      <FormGroup>
                          <div className={"d-none d-lg-block"}>
                              <TextInput
                                value={dictionaryFields[props.name] || ''}
                                maxLength={10}
                                placeholder={placeholder}
                                leftAddon={label}
                                onChange={ev => this.onChangeFields(ev.target.value?ev.target.value.toLowerCase():ev.target.value, props.name)}
                                error={dictionaryErrors[props.name]}
                                disabled={props.readonly}
                                type={'text'}
                              />
                          </div>
                          <div className={"d-block d-lg-none"}>
                              <TextInput
                                maxLength={10}
                                value={dictionaryFields[props.name] || ''}
                                placeholder={placeholder}
                                label={label}
                                onChange={ev => this.onChangeFields(ev.target.value?ev.target.value.toLowerCase():ev.target.value, props.name)}
                                error={dictionaryErrors[props.name]}
                                disabled={props.readonly}
                                type={'text'}
                              />
                          </div>
                      </FormGroup>
                    )
                case 'textarea': {
                    return (
                      <FormGroup>
                          <div className={"d-none d-lg-block"}>
                              <TextInput
                                value={dictionaryFields[props.name] || ''}
                                placeholder={placeholder}
                                leftAddon={label}
                                onChange={ev => this.onChangeFields(ev.target.value, props.name)}
                                error={dictionaryErrors[props.name]}
                                disabled={props.readonly}
                                type={'textarea'}
                              />
                          </div>
                          <div className={"d-block d-lg-none"}>
                              <TextInput
                                value={dictionaryFields[props.name] || ''}
                                placeholder={placeholder}
                                label={label}
                                onChange={ev => this.onChangeFields(ev.target.value, props.name)}
                                error={dictionaryErrors[props.name]}
                                disabled={props.readonly}
                                type={'textarea'}
                              />
                          </div>

                      </FormGroup>
                    );
                }
                case 'text':
                default: {
                    return (
                        <FormGroup>
                            <div className={"d-none d-lg-block"}>
                                <TextInput
                                    value={dictionaryFields[props.name] || ''}
                                    placeholder={placeholder}
                                    leftAddon={label}
                                    onChange={ev => this.onChangeFields(ev.target.value, props.name)}
                                    error={dictionaryErrors[props.name]}
                                    disabled={props.readonly}
                                    type={props.name === 'password' ? 'password' : 'text'}
                                />
                            </div>
                            <div className={"d-block d-lg-none"}>
                                <TextInput
                                    value={dictionaryFields[props.name] || ''}
                                    placeholder={placeholder}
                                    label={label}
                                    onChange={ev => this.onChangeFields(ev.target.value, props.name)}
                                    error={dictionaryErrors[props.name]}
                                    disabled={props.readonly}
                                    type={props.name === 'password' ? 'password' : 'text'}
                                />
                            </div>

                        </FormGroup>
                    );
                }
            }
        });
    }

    renderTabName(tabTitle, index, errors) {
        let _error = false;

        // Проверка для всех полей
        Object.keys(this.state.dictionaryErrors).forEach((key) => {
            if (find(errors, er => er === key)) {
                _error = true;
            }
        });

        if (_error) {
            return (
                <div style={{color: 'red'}}>
                    <FormattedMessage id={tabTitle}/>{' '}{`# ${index + 1}`}{' '}<span
                    className="badge badge-danger">{translate({id: 'common.error'})}</span>
                </div>
            );
        }

        return <span><FormattedMessage id={tabTitle}/>{' '}{`#${index + 1}`}</span>;
    }

    setFields() {
        const fields = this.getBody();
        const {successMessage} = this.state;

        return (
            <Form>
                {successMessage &&
                    <SuccessStatus>
                        {successMessage}
                    </SuccessStatus>
                }
                {fields}
                <FormGroup>
                    {this.props.readonlyForm===false &&
                        <Row className="no-gutters btn-controls" style={{justifyContent: 'flex-start'}}>
                            <Button
                              disabled={this.state.isLoadingSave}
                              color="primary" onClick={() => this.onSave()}
                            >
                                <div className={"d-flex"}>
                                    {this.state.isLoadingSave && <div className="fa-1x mr-2">
                                        <i className="fa fa-spinner fa-spin" style={{ color: '#fff' }} />
                                    </div>}<span className={this.state.isLoadingSave?"ml-1":""}><FormattedMessage id="crudForm.button.save" defaultMessage={camelCaseToTile(name)}/></span>
                                </div>

                            </Button>
                            <Button
                              disabled={this.state.isLoadingSave}
                              color="secondary" onClick={() => this.onCancel()}
                            >
                                <FormattedMessage id="crudForm.button.cancel" defaultMessage={camelCaseToTile(name)}/>
                            </Button>
                        </Row>
                    }
                </FormGroup>
            </Form>
        );
    }

    async onSave() {
        const {store, item, excludedFields} = this.props;
        this.setState({
            isLoadingSave:true
        })
        let createdOrUpdatedItem = {};

        const newState = {
            dictionaryErrors: {},
        };

        let dictionaryFields = omit({...this.state.dictionaryFields}, excludedFields);

        if(this.props.isAddArrivalPlace===false && dictionaryFields.arrivalPlace){
            delete dictionaryFields.arrivalPlace
        }
        if(this.props.isAddDeparturePlace===false && dictionaryFields.departurePlace){
            delete dictionaryFields.departurePlace
        }

        // удаляем свойство size в справочнике самолетов
        if (get(dictionaryFields, 'compartments[0].size')) {
            const fields = dictionaryFields.compartments.map((el) => {
                const copy = cloneDeep(el);
                if (copy.size.height === '' &&
                    copy.size.length === '' &&
                    copy.size.width === '') {
                    delete copy.size;
                    return copy;
                }
                return el;
            });
            set(dictionaryFields, 'compartments', fields);
        }

        if (this.state.noRegion) {
            set(dictionaryFields, 'parentId', this.state.countryId);
        }
        if (item) {
            createdOrUpdatedItem = await store.update(this.props.item.id, dictionaryFields);
        } else {
            createdOrUpdatedItem = await store.create(dictionaryFields);
        }

        if (store.hasClientError) {
            const pugeMsg = store.statusMessage;
            let msg = translate(pugeMsg);
            if (msg === pugeMsg) {
                msg = translate('reports.alert.fail');
            }

            FlashStore.errorNow(msg, true);
            return;
        }

        if (!store.hasErrors) {
            this.setState({
                dictionaryErrors: {},
            });

            const profilesRequests = [];

            const {
                savedOrganizationProfiles = [],
                dictionaryFields: {
                    organizationProfiles,
                },
            } = this.state;

            if (organizationProfiles) {
                for(let savedOrganizationProfile of savedOrganizationProfiles){
                    const profileIsRemoved = !find(organizationProfiles, {value: {id: savedOrganizationProfile.id}});
                    if (profileIsRemoved) {
                        profilesRequests.push({
                            id: createdOrUpdatedItem.id,
                            profileId: savedOrganizationProfile.id,
                            action:"delete"
                        });
                    }
                }
                for(let organizationProfile of organizationProfiles){

                    const profileIsAdded = !find(savedOrganizationProfiles, {value: {id:organizationProfile.id}});

                    if (profileIsAdded) {
                        profilesRequests.push({
                            id: createdOrUpdatedItem.id,
                            profileId: organizationProfile.id,
                            action:"create"
                        });
                    }
                }

                console.log("profiles: ", profilesRequests);

                if (!profilesRequests.length) {
                    FlashStore.successNow(translate('crud.save.success'), true);
                }
                if(profilesRequests.length>0){
                    await OrganizationService.updateOrganizationProfiles(profilesRequests,createdOrUpdatedItem.id)
                }
            } else {
                FlashStore.successNow(translate('crud.save.success'), true);
            }
            if(this.props.onAfterSave && typeof this.props.onAfterSave === 'function') {
                this.props.onAfterSave();
            }
            await store.reloadList();
            store.cancelEdit();
        } else {
            const errors = {};

            store.errors.forEach((error) => {
                errors[error.fieldName] = error.errorText;
            });

            newState.dictionaryErrors = errors;
        }
        newState.isLoadingSave=false;

        this.setState(newState);
    }

    onCancel() {
        this.props.store.cancelEdit();
    }

    onChangeCoordinatesFields = (value, name) => {
        const fields = (this.state.dictionaryFields);
        const errors = cloneDeep(this.state.dictionaryErrors);

        delete errors[name];
        delete errors[`${name}.latitude`];
        delete errors[`${name}.longitude`];

        set(fields, name, value);

        this.setState({
            dictionaryFields: fields,
            dictionaryErrors: errors,
        });
    };

    onChangeCountry = (value, name) => {
        const fields = cloneDeep(this.state.dictionaryFields);
        const errors = cloneDeep(this.state.dictionaryErrors);

        if (this.state.countryId !== value) {
            fields.parentId = null;
            delete fields.parent;
        }
        delete errors[name];

        this.setState({
            countryId: value,
            dictionaryFields: fields,
            dictionaryErrors: errors,
        });
    };

    onChangeCity = async (el, name) => {
        this.onChangeFields(el.value, name.name);
        const c = await DictionaryService.getGeoObjectById(el.value);
        this.onChangeCoordinatesFields(c.coordinates, 'geoCoordinates');
    };

    onChangeOrganization = (value, name, type = '') => {
        const fields = (this.state.dictionaryFields);
        const errors = cloneDeep(this.state.dictionaryErrors);

        if (type === 'nameChanger') {
            set(fields, 'organizationName', value.name);
        }
        set(fields, name, value.id);
        delete errors[name];

        this.setState({
            dictionaryFields: fields,
            dictionaryErrors: errors,
        });
    };

    onChangeFields = (value, name, index) => {
        const fields = cloneDeep(this.state.dictionaryFields);
        const errors = cloneDeep(this.state.dictionaryErrors);

        delete errors[name];
        const old = get(fields, name)
        set(fields, name, value);

        if (fields.regionId && isPlainObject(fields.regionId)) {
            set(fields, 'regionId', fields.regionId.id);
        }

        if (name === 'hatchSize') {
            delete errors['hatchSize.length'];
            delete errors['hatchSize.width'];
        }

        if (name === `compartments[${index}].hatchSize`) {
            delete errors[`compartments[${index}].hatchSize.width`];
            delete errors[`compartments[${index}].hatchSize.length`];
        }

        if (index !== undefined && name === `compartments[${index}].size`) {
            delete errors[`compartments[${index}].size.width`];
            delete errors[`compartments[${index}].size.height`];
            delete errors[`compartments[${index}].size.length`];
        }

        if (name === 'maxWeight.value') {
            set(fields, 'maxWeight', {value, unit: 'KG'});
            delete errors.maxWeight;
        }

        if (name === 'size') {
            if (fields.size.height === '' &&
                fields.size.length === '' &&
                fields.size.width === '') {
                delete fields.size;
            }
        }

        if (name === 'cargoType') {
            set(fields, 'seaPortId', null);
            delete fields.seaPort;
            set(fields, 'seaLineId', null);
            delete fields.seaLine;
        }

        if (name === 'countryId' && fields.countryId !== this.state.dictionaryFields.countryId) {
            set(fields, 'cityId', '');
            set(fields, 'city', null);
        }

        if(name==="domain"){
            //Только англ буквы и - (дефис) или пустая строка
            const regex = new RegExp('^\\s*$|[A-Za-z-]+$');
            if (regex.test(value) === true) {
                set(fields, name, value);
            } else {
                set(fields, name, old)
            }
        }

        this.setState({
            dictionaryFields: fields,
            dictionaryErrors: errors,
        });
    };
    deleteRegionContainerType = (name, index) => {
        let fields = cloneDeep(this.state.dictionaryFields);
        const errors = cloneDeep(this.state.dictionaryErrors);
        delete errors[name];
        if (name === 'region') {
            const currRegions = fields[name]
            let newFields = [];
            for (let i = 0; i < currRegions.length; i++) {
                if (i !== index)
                    newFields.push(currRegions[i]);
            }
            if (newFields.length === 0) {
                newFields.push("NONE-NONE")
            }
            set(fields, name, newFields);
        }

        this.setState({
            dictionaryFields: fields,
            dictionaryErrors: errors,
        });
    }

    addNewRegion = (name) => {
        let fields = cloneDeep(this.state.dictionaryFields);
        const errors = cloneDeep(this.state.dictionaryErrors);
        delete errors[name];
        if (name === 'region') {
            const currRegions = fields[name]
            let newFields = [];
            newFields.push("NONE_C-NONE_C")
            for (let i = 0; i < currRegions.length; i++) {
                if (currRegions[i] !== "NONE-NONE")
                    newFields.push(currRegions[i]);
            }
            set(fields, name, newFields);
        }
        this.setState({
            dictionaryFields: fields,
            dictionaryErrors: errors,
        });
    }
    searchRegions = (value, index) => {
        let searchData = cloneDeep(this.state.searchText)
        if (index === 0) {
            searchData.startRegion = value
        } else {
            searchData.endRegion = value
        }
        this.setState({searchText: searchData})
    }


    onChangeRegionTypeContainerType = (value, name, indexPos, index) => {
        let fields = cloneDeep(this.state.dictionaryFields);
        const errors = cloneDeep(this.state.dictionaryErrors);
        delete errors[name];
        if (name === 'region') {
            const currRegions = fields[name]
            let notChangeRegion = currRegions[index].split("-")[indexPos === 1 ? 0 : 1]
            let newRegion = ""
            if (indexPos === 0) {
                newRegion = value.value + "-" + notChangeRegion
            } else {
                newRegion = notChangeRegion + "-" + value.value
            }
            let newFields = [];
            for (let i = 0; i < currRegions.length; i++) {
                if (i === index)
                    newFields.push(newRegion)
                else
                    newFields.push(currRegions[i]);
            }
            set(fields, name, newFields);
        } else {
            set(fields, name, value);
        }
        this.setState({
            dictionaryFields: fields,
            dictionaryErrors: errors,
        });
    }

    onChangePrice = (value, name, param, index) => {
        let fields = cloneDeep(this.state.dictionaryFields);
        const errors = cloneDeep(this.state.dictionaryErrors);
        delete errors[name];
        set(fields, name + '.' + param, value)
        this.setState({
            dictionaryFields: fields,
            dictionaryErrors: errors,
        });
    }
    onChangeSingleValue = (value, name, index) => {
        let fields = cloneDeep(this.state.dictionaryFields);
        const errors = cloneDeep(this.state.dictionaryErrors);
        delete errors[name];
        set(fields, name.value, value)
        this.setState({
            dictionaryFields: fields,
            dictionaryErrors: errors,
        });
    }

    onChangeChatbotType = (value, name, index) => {
        let fields = cloneDeep(this.state.dictionaryFields);
        const errors = cloneDeep(this.state.dictionaryErrors);
        delete errors[name];
        if (name === 'typeBot') {
            let newField = '';
            newField = value.value
            set(fields, name, newField);
        } else {
            set(fields, name, value);
        }
        this.setState({
            dictionaryFields: fields,
            dictionaryErrors: errors,
        });
    }

    onChangeNoRegion = () => {
        const errors = cloneDeep(this.state.dictionaryErrors);
        if (!this.state.noRegion) {
            delete errors.parentId;
        }
        this.setState({noRegion: !this.state.noRegion, dictionaryErrors: errors});
    };

    onChangeTranslate = (value, name, error) => {
        const fields = cloneDeep(this.state.dictionaryFields);
        const errors = cloneDeep(this.state.dictionaryErrors);

        delete errors[error];
        set(fields, name, value);

        this.setState({
            dictionaryFields: fields,
            dictionaryErrors: errors,
        });
    };

    onChangeTranslateCompartments = (value, name, error, index) => {
        const fields = cloneDeep(toJS(this.state.dictionaryFields.compartments));
        const errors = cloneDeep(this.state.dictionaryErrors);

        delete errors[`compartments[${index}].name`];
        set(fields[index], name, value);

        this.setState({
            dictionaryFields: {
                ...this.state.dictionaryFields,
                compartments: fields,
            },
            dictionaryErrors: errors,
        });
    };

    render() {
        if (this.props.children) {
            return this.setFields();
        }

        return <div>default form</div>;
    }

    renderLanguageField(label, dictionaryErrors, props, fieldValue) {
        const hasPermission = this.state.roles.includes(ROLES.OPERATOR_ADMIN);
        const inputValue = hasPermission ? fieldValue : LANGAGE_DEFALUT_VALUE;

        return hasPermission ?
            <FormGroup>
                <SelectLanguage
                    leftAddon={label}
                    error={dictionaryErrors[props.name]}
                    labelKey={'name'}
                    onChange={val => this.onChangeFields(val.value, props.name)}
                    value={inputValue}
                />
            </FormGroup> : null;
    }
    renderLanguageField4User(label, dictionaryErrors, props, fieldValue) {
        let inputValue ="";
        if(fieldValue!==null && fieldValue!==undefined)
            inputValue = fieldValue.toUpperCase()

        return(
            <FormGroup>
                <SelectLanguage
                    leftAddon={label}
                    error={dictionaryErrors[props.name]}
                    labelKey={'name'}
                    onChange={val => this.onChangeFields(val.value, props.name)}
                    value={inputValue}
                />
            </FormGroup>);
    }
}
DictionaryForm.defaultProps={
    readonlyForm: false
}
DictionaryForm.propTypes = {
    store: PropTypes.object,
    item: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    fetchInitialOptionsFor: PropTypes.array,
    excludedFields: PropTypes.array,
    readonlyForm: PropTypes.bool
};

export default DictionaryForm;
