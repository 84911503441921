import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Form } from 'reactstrap';
import { Link } from 'react-router-dom';
import Page from '../../layouts/main';
import { TextInput } from '../../components/ui/form-control';
import { translate, convertErrorsArrayToObject } from '../../utils/utils';
import UserService from '../../services/userService';
import { DEFAULT_DELAY } from '../../constants/global';
import { AUTH_LOGIN } from '../../constants/routes';
import { SuccessStatus } from '../../components/common/Statuses';
import AppStore from '../../stores/appStore';

class PasswordRecovery extends Component {
  initialState = {
    email: '',
    errors: {},
    passwordRecoveryFlashStatus: null,
    showPasswordBlock: false,
    showConfirmationCodeField: true,
    showAllFields: true,
    passwordData: {
      password: '',
      passwordConfirmation: '',
      confirmationCode: '',
    },
  };

  state = {
    ...this.initialState,
  };

  showPasswordFieldsIfConfirmationCodeInHash = (hash = '') => {
    if (!hash.includes('confirmation_code')) {
      return;
    }

    const confirmationCode = hash.split('=')[1];

    if (!confirmationCode) {
      return;
    }

    this.setState({
      showPasswordBlock: true,
      showConfirmationCodeField: false,
      passwordData: {
        ...this.state.passwordData,
        confirmationCode,
      },
    });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.showPasswordFieldsIfConfirmationCodeInHash(
      nextProps.location.hash,
    );
  }

  UNSAFE_componentWillMount() {
    this.showPasswordFieldsIfConfirmationCodeInHash(
      this.props.location.hash,
    );
  }

  sendRecoveryInstructionsOnEmail = async () => {
    const response = await UserService.sendRecoveryInstructionsOnEmail({
      email: this.state.email,
    });

    if (response.status !== 'success') {
      this.setState({
        errors: convertErrorsArrayToObject(response.data.errors),
      });

      return;
    }

    this.setState({
      ...this.initialState,
      showPasswordBlock: true,
      passwordRecoveryFlashStatus: (
        <SuccessStatus>
          {translate(response.message)}
        </SuccessStatus>
      ),
    });

    setTimeout(() => {
      this.setState({
        passwordRecoveryFlashStatus: null,
      });
    }, DEFAULT_DELAY);
  };

  setNewPassword = async () => {
    const response = await UserService.restorePassword(this.state.passwordData);

    if (response.status !== 'success') {
      this.setState({
        errors: convertErrorsArrayToObject(response.data.errors),
      });

      return;
    }

    this.setState({
      showAllFields: false,
      passwordRecoveryFlashStatus: (
        <SuccessStatus>
          {translate(response.message)}
        </SuccessStatus>
      ),
    });

    setTimeout(() => {
      this.props.history.push(AUTH_LOGIN);
    }, DEFAULT_DELAY);
  };

  updateEmail = event => this.setState({ email: event.target.value });

  updatePasswordData = ({ target: { name, value } }) => {
    this.setState({
      passwordData: {
        ...this.state.passwordData,
        [name]: value,
      },
    });
  };

  render() {
    const { passwordData, email, errors } = this.state;

    const emailBlock = this.state.showAllFields ? (
      <div>
        <div className="pane-header">
          <h2><FormattedMessage id="passwordRecovery.submit" /></h2>
        </div>
        <i className={`input-icon email ${AppStore.iconsColorClassName}`} /><TextInput
          type="email"
          value={email}
          onChange={this.updateEmail}
          className="form-group-last"
          placeholder="form.field.email.placeholder"
          error={errors.email}
        />
        {this.props.isShowHeader ?
          <div style={{ marginBottom: 13 }}><small>
            <Link className="btn btn-link" to={AUTH_LOGIN}>
              <FormattedMessage id="login.submit" />
            </Link>
          </small></div>
          :
          <div style={{ marginBottom: 13 }}><small>
            <Button className="btn btn-link" style={{ borderColor: 'transparent' }} onClick={() => this.props.chooseLogin()}>
              <FormattedMessage id="login.submit" />
            </Button>
          </small></div>
        }
        <Button
          className="mt-1 btn-full"
          type="button"
          color="primary"
          onClick={this.sendRecoveryInstructionsOnEmail}
        >
          <FormattedMessage id="passwordRecovery.link" />
        </Button>
      </div>
    ) : null;

    const passwordBlock = this.state.showAllFields ? (
      <div>
        {this.state.showConfirmationCodeField ? (
          <TextInput
            type="text"
            name="confirmationCode"
            value={passwordData.confirmationCode}
            onChange={this.updatePasswordData}
            placeholder="form.field.confirmCode.placeholder"
            leftAddon={<FormattedMessage id="form.field.confirmCode" />}
            error={errors.confirmationCode}
          />
        ) : ''}
        <TextInput
          type="password"
          name="password"
          value={passwordData.password}
          onChange={this.updatePasswordData}
          placeholder="form.field.password.placeholder"
          leftAddon={<FormattedMessage id="form.field.password" />}
          error={errors.password}
        />
        <TextInput
          type="password"
          name="passwordConfirmation"
          value={passwordData.passwordConfirmation}
          onChange={this.updatePasswordData}
          placeholder="form.field.confirmPassword.placeholder"
          leftAddon={<FormattedMessage id="form.field.confirmPassword" />}
          error={errors.passwordConfirmation}
        />
        <Button
          className="mt-1"
          type="button"
          color="primary"
          onClick={this.setNewPassword}
        >
          <FormattedMessage id="passwordRecovery.submit" />
        </Button>
      </div>
    ) : null;

    // const passwordRecoveryForm = () => {
    //   return (
    //     <div className="pane pane-primary password-recovery-wr" >
    //       <Form className="password-recovery-form">
    //         {this.state.passwordRecoveryFlashStatus}
    //         {this.state.showPasswordBlock ? passwordBlock : emailBlock}
    //       </Form>
    //     </div>
    //   );
    // };

    return (
      this.props.isShowHeader ?
        <Page
          {...this.props}
          scope="guest"
        >
          <div className="pane pane-primary password-recovery-wr" >
            <Form className="password-recovery-form">
              {this.state.passwordRecoveryFlashStatus}
              {this.state.showPasswordBlock ? passwordBlock : emailBlock}
            </Form>
          </div>
        </Page>
        :
        <div className="pane pane-primary password-recovery-wr" >
          <Form className="password-recovery-form">
            {this.state.passwordRecoveryFlashStatus}
            {this.state.showPasswordBlock ? passwordBlock : emailBlock}
          </Form>
        </div>
    );
  }
}

PasswordRecovery.defaultProps = {
  isShowHeader: true,
};

PasswordRecovery.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  isShowHeader: PropTypes.bool,
  chooseLogin: PropTypes.func,
};

export default PasswordRecovery;
