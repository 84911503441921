import 'intl';
import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router as ReactRouter } from 'react-router';
import TagManager from 'react-gtm-module';
// import { YMInitializer } from 'react-yandex-metrika';
import { createRoot } from 'react-dom/client';
import history from './history';
import App from './App';
import {getTagManagerArgs} from './utils/analyticsMetrics';

const Main = () => (
  <div>
    {/*<YMInitializer accounts={[48882707]} />*/}
    <ReactRouter history={history}>
      <Route component={App} />
    </ReactRouter>
  </div>
);

TagManager.initialize(getTagManagerArgs());

const rootEl = document.getElementById('root');
const root = createRoot(rootEl);
root.render(<Main />);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default; // eslint-disable-line

    const NextMain = () => (
      <ReactRouter history={history}>
        <Route component={NextApp} />
      </ReactRouter>
    );
    root.render(<NextMain />);
  });
}
