import moment from 'moment-timezone';
import FlashStore from '../stores/flashStore';
import AppStore from '../stores/appStore';
import { translate } from '../utils/utils';
import { set } from 'lodash';
import { API } from '../constants/api';

// TODO: Привести в порядок все методы получения данных. Сгруппировать по смыслу. Сделать JSdoc к каждому методу.

class HttpProvider {
  /**
   * Состояние запросов для избежания дублирования вызовов к апи.
   * @type {{}}
   */
  static requests = {};
  static get(url, cache = false) {
    //AppStore.checkTokenValidation().then()
    return new Promise((resolve, reject) => {
      if (cache && window.sessionStorage && window.sessionStorage.getItem(url)) {
        resolve(JSON.parse(window.sessionStorage.getItem(url)));
      } else {
        HttpProvider.requests[url] = HttpProvider.requests[url] || [];

        HttpProvider.requests[url].push({
          resolve,
          reject,
        });

        if (HttpProvider.requests[url].length === 1) {
          const timestamp = (new Date()).getTime();
          const urlSuffix = url.indexOf('?') > -1 ? `&v=${timestamp}` : `?v=${timestamp}`;
          const lang = localStorage.getItem('userLang') === 'zh' ? 'zh-CN' : localStorage.getItem('userLang');
          const token = localStorage.getItem("token")
          let headers = {
            'Accept-Language': lang,
            'X-TimeZone': moment.tz.guess(),
          }
          if(token!==null){
            headers = {
              'Accept-Language': lang,
              'Authorization': `Bearer ${token}`
            }
          }
          fetch(encodeURI(url) + urlSuffix, {
            headers: headers,
          }).then((response) => {
            if (response.status === 401) {
              /*localStorage.removeItem("token")
              localStorage.removeItem("refreshToken")
              window.location.reload();*/
              /*if(localStorage.getItem("refreshToken")===null){
                localStorage.removeItem("token")
                localStorage.removeItem("refreshToken")
                window.location.reload();
                return;
              }*/
              //const updatedToken = this.post(API.CLIENTS.REFRESH_TOKEN(), {refreshToken: localStorage.getItem("refreshToken")})
              return this.get(url, cache);
            }

            try {
              if (response.status !== 200) {
                const error = new Error(response.statusText);
                error.response = response;
                throw error;
              }
            } catch (err) {
              if (err.response.status !== 404) {
                console.error(new Error(err));
              }
            }

            const text = response.text();

            return text.then((str) => {
              return JSON.parse(str);
            }).catch((e) => {
              console.error(text, e);
            });

            //return response.json();
          }).then((data) => {
            if (cache && data !== undefined) {
              try {
                window.sessionStorage.setItem(url, JSON.stringify(data));
              }catch (Error) {
                console.error("error in set cache: ", Error);
              }
            }

            HttpProvider.requests[url].forEach((item) => {
              item.resolve(data);
            });
            delete HttpProvider.requests[url];
          }).catch((error) => {
            HttpProvider.requests[url].forEach((item) => {
              item.reject(error);
            });
            delete HttpProvider.requests[url];
          });
        }
      }
    });
  }

  /**
   * Универсальный метод для отправки данных методами POST, PUT, DELETE
   *
   * @param {string} url
   * @param {object} data
   * @param {string} method
   * @return {Promise.<T>}
   */
  static send(url, method = 'POST', data = {}) {
    const lang = localStorage.getItem('userLang') === 'zh' ? 'zh-CN' : localStorage.getItem('userLang');
    const token = localStorage.getItem("token")
    let headers = {
      'Accept-Language': lang,
      'Content-Type': 'application/json',
      'X-TimeZone': moment.tz.guess(),
    }
    if(token!==null){
      headers = {
        'Accept-Language': lang,
        'Content-Type': 'application/json',
        'X-TimeZone': moment.tz.guess(),
        'Authorization': `Bearer ${token}`
      }
    }
    return fetch(url, {
      method,
      headers: headers,
      body: JSON.stringify(data),
    }).then((res) => {
      if (res.status >= 200 && res.status < 500) {
        if (res.status === 401) {
          AppStore.openAuthModalDialog();
        }

        return res.text().then((text) => {
          return text ? JSON.parse(text) : { success: true };
        });
      }

      if (res.status >= 500) {
        FlashStore.errorNow(translate({ id: 'service.serverError' }), 4000);
        Promise.reject(translate({ id: 'service.serverError' }));
        return new Error(translate({ id: 'service.serverError' }));
        // return Promise.reject('Server error');
      }

      const error = new Error(res.statusText);
      error.response = res;
      return null;
    }).catch((error) => {
      console.error(error);
      AppStore.setServerAvailable(false);

      return Promise.reject({
        statusText: error.statusText,
        response: error,
      });
    });
  }

  /**
   * Отправка данных методом POST.
   *
   * @param {string} url
   * @param {object} data
   * @return {Promise.<T>}
   */
  static post(url, data = {}) {
    return this.send(url, 'POST', data);
  }

  /**
   * Обновление данных методом PUT.
   *
   * @param {string} url
   * @param {object} data
   * @return {Promise.<T>}
   */
  static put(url, data = {}) {
    return this.send(url, 'PUT', data);
  }

  /**
   * Запрос методом DELETE.
   *
   * @param {string} url
   * @return {Promise.<T>}
   */
  static remove(url) {
    return this.send(url, 'DELETE');
  }
}

export default HttpProvider;
