import { action, observable, computed } from 'mobx';
import OrganizationService from '../services/organizationService';
import userStore from './userStore';

/**
 * Стор организации.
 */

class OrganizationStore {
  @observable _expiredTariffs = [];

  @computed get expiredAllTariffs() {
    return this._expiredTariffs;
  }

  // Обновление истекающих тарифов
  @action
  async reloadExpiredTariffs() {
    const user = await userStore.user;

    if (!user && !user.organization) {
      return [];
    }

    OrganizationService.getExpiredTariffs(user.organization.id).then(res=>{
      this.setExpiredTariffs(res)
    });
  }

  @action
  setExpiredTariffs(expiredTariffs) {
    this._expiredTariffs = expiredTariffs;
  }
}

export default new OrganizationStore();
