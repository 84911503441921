/* eslint-disable */
import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, TabPane, TabContent } from 'reactstrap';
import { includes, find } from 'lodash';
import CrudTabs from './CrudTabs';
import AppStore from '../../../stores/appStore';
import UserStore from '../../../stores/userStore';
import CrudStore from '../../../stores/crudStore';

class CrudRatesFrame extends Component {
  state = {
    tabIndex: 1,
    storesList: {},
  };

  UNSAFE_componentWillMount() {
    const { isNeedCreateCrudStore, isNeedCheckAccess, children } = this.props;

    if (isNeedCreateCrudStore) {
      const storesList = {};
      let i = 1;

      Children.forEach(children, (child) => {
        const { props } = child;
        const isNeedToShow = isNeedCheckAccess ? UserStore.isRatesTabVisible(props.endpoint) : true;

        if (isNeedToShow) {
          storesList[props.endpoint] = {
            store: new CrudStore(props.endpoint, props.listParams, props.createParams),
            index: i,
          };
          i += 1;
        }
      });

      this.setState({ storesList });
    }
  }

  setTab = (index) => {
    this.setState({
      tabIndex: index,
    });

    const { isNeedCreateCrudStore } = this.props;
    const { storesList } = this.state;

    if (isNeedCreateCrudStore) {
      const item = find(storesList, el => el.index === index);

      if (item) {
        const { page } = item.store;
        AppStore.history.push(`${window.location.pathname}#page-${page}`);
      }
    }
  };

  getNavTabsJSX(allowedNavArr) {
    const { navArray } = this.props;
    const { tabIndex } = this.state;

    return (
      <CrudTabs
        navArray={navArray.filter((nav, i) => includes(allowedNavArr, i))}
        tabIndex={tabIndex}
        setTab={index => this.setTab(index)}
      />
    );
  }

  getContentJSX() {
    const { children, isNeedCheckAccess, isNeedCreateCrudStore } = this.props;
    const { tabIndex, storesList } = this.state;

    if (children.length > 1) {
      const tabArr = [];
      const navIndexArr = [];
      let counter = 1;

      Children.forEach(children, (child, i) => {
        const idx = i;
        const { props } = child;
        const isNeedToShow = isNeedCheckAccess ? UserStore.isRatesTabVisible(props.endpoint) : true;
        const childElement = isNeedCreateCrudStore ? React.cloneElement(child, { store: storesList[props.endpoint].store }) : child;

        if (isNeedToShow) {
          navIndexArr.push(i);
          tabArr.push(
            <TabPane tabId={counter} key={idx}>
              {childElement}
            </TabPane>
          );
        }
        counter += 1;
      });

      return (
        <div>
          { this.getNavTabsJSX(navIndexArr) }
          <TabContent activeTab={includes(navIndexArr, tabIndex - 1) ? tabIndex : (navIndexArr[0] + 1)}>
            { tabArr }
          </TabContent>
        </div>
      );
    }

    return children;
  }

  render() {
    return (
      <div className="rates">
        <Row>
          <Col xs={12} className={'single-col'}>
            { this.getContentJSX() }
          </Col>
        </Row>
      </div>
    );
  }
}

CrudRatesFrame.defaultProps = {
  isNeedCheckAccess: false,
  isNeedCreateCrudStore: true,
};

CrudRatesFrame.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  navArray: PropTypes.array,
  isNeedCheckAccess: PropTypes.bool,
  isNeedCreateCrudStore: PropTypes.bool,
};

export default CrudRatesFrame;
