import config from '../config';
import { set } from 'lodash';

class RestService {
  constructor(endpoint) {
    this.endpoint = endpoint;
  }

  /**
   * @param url
   * @param options
   * @returns {*}
   */
  fetch(url, options) {
    if (!this.endpoint) {
      throw 'endpoint is empty!'; // eslint-disable-line
    }

    const newOptions = Object.assign({
    }, options || {});
    const token = localStorage.getItem("token")
    if(token!==null){
      set(newOptions, "headers.Authorization", `Bearer ${token}`)
    }
    return fetch(this.endpoint + url, newOptions);
  }
}

export default new RestService(config.endpoint);
