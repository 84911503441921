const isProd = process.env.NODE_ENV === 'production';
//const isPreprod = process.env.NODE_ENV === 'preproduction';
//const isDev = process.env.NODE_ENV === 'dev';

const _base={
  endpoint: process.env.REACT_APP_API_URL || '/api/v1',
  endpointUrl: process.env.REACT_APP_API_ENDPOINT_URL || '/',
  endpointUrlV2: process.env.REACT_APP_API_ENDPOINT_URL_V2 || '/',

  endpointSSO : process.env.REACT_APP_ENDPOINT_SSO || '/sso',
  redirectURI: process.env.REACT_APP_REDIRECT_URI || '/book/wizard',
  clientId: process.env.REACT_APP_CLIENTID || 'saas-client-prod',
  clientAuth: process.env.REACT_APP_CLIENTAUTH || 'Basic c2Fhcy1jbGllbnQtcHJvZDpzYWFzLWNsaWVudC1wcm9kLXBhc3N3b3Jk',
  gtmId: process.env.REACT_APP_GTMID ||'GTM-NXGCPKK',
  phoneNumber: '+7 (495) 136-56-33',
};

const production = {
  ..._base,
};

const local = {
  endpoint: 'http://localhost:8080/api/v1',
  endpointUrl: 'http://localhost:8080/',
  endpointUrlV2: 'http://localhost:8080/',
  endpointSSO: 'http://localhost:7777/sso',
  redirectURI: 'http://localhost:3030/book/wizard',
  clientId: 'saas-client-local',
  clientAuth: 'Basic c2Fhcy1jbGllbnQtbG9jYWw6c2Fhcy1jbGllbnQtbG9jYWwtcGFzc3dvcmQ=',
  gtmId: 'GTM-NXGCPKK',
  phoneNumber: '+7 (495) 136-56-33',
};

const env = isProd ? production : local;
env.isProd = () => isProd;

export default env;
