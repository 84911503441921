import HttpProvider from './httpProvider';
import { API } from '../constants/api';

class CollectionViewService {
  constructor() {
    this.http = HttpProvider;
  }

  async getByContext(context) {
    return await this.http.get(API.COLLECTION_VIEWS.BY_CONTEXT({ context }), false);
  }
}

export default new CollectionViewService();
