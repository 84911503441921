import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select-old';
import { isArray } from 'lodash';
import { FormGroup, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import 'react-select-old/dist/react-select.css';

import AppStore from '../../../stores/appStore';
import { Error, Label, Note } from './common';
import { translate } from './../../../utils/utils';
import FontAwesome from "react-fontawesome";


class Select extends Component {
  state = {
    showHint: false
  }

  onChangeShowHint = ()=>{
    this.setState({
      showHint: !this.state.showHint
    })
  }
  onChange = (value) => {
    let newValue = value;

    if (isArray(value) && value.length === 0) {
      newValue = []; // null
    }

    if (!newValue && !this.props.multi) {
      newValue = {};
    }

    this.props.onChange(newValue);
  };

  getControl() {
    const {
      async,
      cache,
      clearable,
      multi,
      loadOptions,
      className,
      labelKey,
      valueKey,
      values,
      value,
      optionRenderer,
      valueRenderer,
      placeholder,
      searchPromptText,
      noResultsText,
      searchable,
      onInputChange,
      disabled,
      leftAddon,
      rightAddon,
      optionComponent,
      valueComponent,
      filterOption,
      backspaceRemoves,
      hint,
      labelClasses
    } = this.props;

    const SelectComponent = async ? ReactSelect.Async : ReactSelect;

    const onInputKeyDown=(e)=>{
      if(e.keyCode === 46){
        e.preventDefault();
        e.stopPropagation()
        return false;
      }
    }


    let control = (
      <div className="f-control">
        <SelectComponent
          multi={multi}
          cache={cache}
          backspaceRemoves={backspaceRemoves}
          filterOption={filterOption}
          loadOptions={loadOptions}
          className={`${className} ${AppStore.iconsColorClassName}`}
          labelKey={labelKey}
          valueKey={valueKey}
          options={!values ? undefined : values}
          onChange={this.onChange}
          value={value}
          clearable={clearable}
          searchable={searchable}
          matchPos="start"
          matchProp="label"
          optionRenderer={optionRenderer}
          valueRenderer={valueRenderer}
          optionComponent={optionComponent}
          valueComponent={valueComponent}
          placeholder={placeholder ? translate(placeholder) : translate({ id: 'form.select.defaultPlaceholder' })}
          searchPromptText={<FormattedMessage id={searchPromptText} />}
          noResultsText={<FormattedMessage id={noResultsText} />}
          onInputChange={onInputChange}
          disabled={disabled}
          loadingPlaceholder={<FormattedMessage id="form.select.loading" />}
          onInputKeyDown={onInputKeyDown}
        />
        {this.props.children}
      </div>
    );

    if (leftAddon || rightAddon) {
      control = (
        <InputGroup>
          {leftAddon && <InputGroupAddon addonType="prepend"><InputGroupText>{leftAddon}</InputGroupText></InputGroupAddon>}
          {control}
          {rightAddon && <InputGroupAddon addonType="append"><InputGroupText>{rightAddon}</InputGroupText></InputGroupAddon>}
        </InputGroup>
      );
    }

    return control;
  }

  render() {
    const { error, value, classNameGroup, label, note, isButton, showErrors,hint,labelClasses } = this.props;
    let isStr = false;
    if(typeof label === 'string')
      isStr = true
    return (
      <FormGroup className={`f-element f-element-select${value ? ' has-value' : ''}${error ? ' has-danger' : ''}${isButton ? ' hide-value' : ''} ${classNameGroup}`}>
        {isStr ?
            <span className={labelClasses}><Label>{label}</Label>{(label && hint !== undefined) && <FontAwesome name="question-circle-o" className="ml-2 cursor-pointer primary-color" onClick={this.onChangeShowHint} /> }</span>
            :
            <span className={labelClasses}><Label>{label}</Label>{(label && hint !== undefined) && <FontAwesome name="question-circle-o" className="ml-2 cursor-pointer primary-color" onClick={this.onChangeShowHint} /> }</span>
        }
        {this.getControl()}
        <Note>{note}</Note>
        {showErrors ? <Error>{error}</Error> : null}
      </FormGroup>
    );
  }
}

Select.defaultProps = {
  valueKey: 'value',
  labelKey: 'label',
  className: '',
  classNameGroup: '',
  noResultsText: 'form.select.noResultText',
  searchPromptText: 'form.select.noResultText',
  searchable: false,
  showErrors: true,
  async: false,
  multi: false,
  backspaceRemoves: false,
  cache: false,
  clearable:true,
  //resetValue: null,
};

Select.propTypes = {
  async: PropTypes.bool,
  cache: PropTypes.bool,
  filterOption: PropTypes.func,
  multi: PropTypes.bool,
  loadOptions: PropTypes.func,
  clearable: PropTypes.bool,
  className: PropTypes.string,
  classNameGroup: PropTypes.string,
  label: PropTypes.oneOfType(PropTypes.string, PropTypes.element),
  placeholder: PropTypes.string,
  note: PropTypes.string,
  error: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
  values: PropTypes.arrayOf(PropTypes.object),
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
  searchPromptText: PropTypes.string,
  noResultsText: PropTypes.string,
  onChange: PropTypes.func,
  optionRenderer: PropTypes.func,
  optionComponent: PropTypes.func,
  valueComponent: PropTypes.func,
  valueRenderer: PropTypes.func,
  searchable: PropTypes.bool,
  onInputChange: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isButton: PropTypes.bool,
  showErrors: PropTypes.bool,
  backspaceRemoves: PropTypes.bool,
  //resetValue: PropTypes.any,
};

export default Select;

