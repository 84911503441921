/* eslint-disable */

import React, {Component} from 'react';
import {isEmpty, isArray, isPlainObject, get, every} from 'lodash';
import {NavLink} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import FontAwesome from 'react-fontawesome';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {Button} from 'reactstrap';
import UserStore from '../../../stores/userStore';
import OrderService from "../../../services/orderService";

class MenuItem extends Component {
    constructor(props) {
        super(props);

        const isOpenOpt = localStorage.getItem(`menuItem@${this.props.name}@isOpen`);
        let isOpen = props.isOpen;

        if (isOpenOpt !== null) {
            isOpen = isOpenOpt === '1';
        }

        this.state = {
            isOpen: isOpen !== undefined ? isOpen : false,
            isShowModal: false,
            expiredOrderCount:null
        };
        if(this.props.showExpiredOrders === true) {
            OrderService.getCountExpiredOrders().then(res => {
                this.setState({expiredOrderCount: res.data})
            })
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.showExpiredOrders === true) {
            OrderService.getCountExpiredOrders().then(res => {
                this.setState({expiredOrderCount: res.data})
            })
        }
    }

    toggle = () => {
        localStorage.setItem(`menuItem@${this.props.name}@isOpen`, this.state.isOpen ? '0' : '1');

        this.setState({
            isOpen: !this.state.isOpen,
        });
    };

    expand = () => {
        localStorage.setItem(`menuItem@${this.props.name}@isOpen`, '1');

        this.setState({
            isOpen: true,
        });
    };

    render() {
        const {
            name,
            icon,
            children,
            url: menuItemUrl = '',
            expiredTariffs,
            isWizard,
            onlyImage = false,
            isModal = false,
            headerModal,
            image,
            isShow = true,
            showExpiredOrders
        } = this.props;
        const {isOpen} = this.state;
        const hasChildren = !isEmpty(children);
        let useIconsForChild = false;

        /*if (children) {
            useIconsForChild = children.some(x => x.props.icon);
        }*/

        if (!isShow) {
            return null;
        }

        if (menuItemUrl && !UserStore.isAccessAllowed(menuItemUrl)) {
            return null;
        }

        if (hasChildren) {
            if (isArray(children) && every(children, ({props}) => !UserStore.isAccessAllowed(props.url))) {
                return null;
            }

            if (isPlainObject(children) && !UserStore.isAccessAllowed(get(children, 'props.url'))) {
                return null;
            }
        }

        let menuItemIcon = null;

        if (icon) {
            menuItemIcon =
                <FontAwesome name={icon} style={{width: 32, fontSize: '18px', textAlign: 'center', marginRight: 8}}/>;
        }

        if (image) {
            menuItemIcon = <div className={image}/>;
        }

        const hasActiveChildren = (childrenItems, currentUrl) => {
            if (isEmpty(childrenItems)) {
                return false;
            }

            if (isArray(childrenItems)) {
                return childrenItems
                    .map(childrenItem => hasActiveChildren(childrenItem, currentUrl))
                    .some(hasActive => hasActive);
            }

            if (get(childrenItems, 'props.url') === currentUrl) {
                return true;
            }

            const grandChildren = get(childrenItems, 'props.children', []);

            if (!isEmpty(grandChildren)) {
                return hasActiveChildren(grandChildren, currentUrl);
            }

            return false;
        };

        const isActive = (match, {pathname: currentUrl}) => {
            const currentUrlWithoutId = UserStore.removeIdAtTheEndOfTheRoute(currentUrl);

            if (currentUrlWithoutId === menuItemUrl) {
                return true;
            }

            return hasActiveChildren(children, currentUrlWithoutId);
        };

        if (isWizard) {
            return (
                <li className="left-menu_nav_list_btn-wizard">
                    <NavLink exact to={menuItemUrl} isActive={isActive}>
                        <Button color="info" className="btn-block mb-3">
                            {menuItemIcon} <FormattedMessage id={name}/>
                        </Button>
                    </NavLink>
                    <hr/>
                </li>
            );
        }

        if (onlyImage) {
            return (
                <li className="left-menu_nav_list_only-image">
                    <NavLink
                        exact
                        to={menuItemUrl}
                        isActive={isActive}
                    >
                        <div className={image}/>
                    </NavLink>
                </li>
            );
        }
        if (isModal) {

            return (
                <li className="left-menu_nav_list_only-image">
                    <div onClick={() => {
                        this.setState({isShowModal: !this.state.isShowModal})
                    }} className={`${image} ${this.state.isShowModal?'bc-dark':''}`}>
                    </div>
                    <div className={`modal-menu ${this.state.isShowModal?'show-m':'close-m'}`}>
                        <div className={'header-modal'}><FormattedMessage id={headerModal}/></div>
                        {children}
                    </div>
                </li>
            );
        }

        return (
            hasChildren ? (
                <li className={image && 'left-menu_nav_list_image-item'}>
                    <div className="parent-item">
                        <NavLink
                            to={menuItemUrl}
                            isActive={isActive}
                            className="parent-link"
                            onClick={(event) => {
                                if (this.props.url) {
                                    this.expand();
                                    //event.preventDefault();
                                    /*const firstChildrenUrl = (isArray(children) ? children[0] : children).props.url;

                                    this.props.history.push(firstChildrenUrl);*/
                                } else {
                                    this.toggle();
                                    event.preventDefault();
                                }
                            }}
                        >
                            {menuItemIcon} <FormattedMessage id={name}/>{' '}
                            {expiredTariffs && expiredTariffs.count !== 0 ?
                                <b style={{color: 'red'}}>({expiredTariffs.count})</b> : null}
                        </NavLink>
                        <span
                            className={`parent-item_icon fa ${isOpen ? 'fa-minus' : 'fa-plus'}`}
                            aria-hidden="true"
                            onClick={this.toggle}
                        />
                    </div>
                    <ul className={`child ${isOpen ? 'show' : 'hidden'} ${useIconsForChild ? 'with-icons' : 'with-icons'}`}>
                        {children}
                    </ul>
                </li>
            ) : (
                <li className={image && 'left-menu_nav_list_image-item'}>
                    <NavLink
                        exact
                        to={menuItemUrl}
                        isActive={isActive}
                    >
                        {menuItemIcon} <span className="left-menu_nav_list_title"><FormattedMessage id={name}/></span>
                        {expiredTariffs && expiredTariffs.count !== 0 ?
                            <span className="label label-danger ml-auto">({expiredTariffs.count})</span> : null}
                        {showExpiredOrders && this.state.expiredOrderCount !== null && this.state.expiredOrderCount > 0 ?
                            <span className="label label-danger ml-auto">({this.state.expiredOrderCount})</span> : null
                        }
                    </NavLink>
                </li>
            )
        );
    }
}


MenuItem.propTypes = {
    name: PropTypes.string,
    icon: PropTypes.string,
    image: PropTypes.string,
    url: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    isOpen: PropTypes.bool,
    isModal: PropTypes.bool,
    headerModal: PropTypes.string,
    //history: PropTypes.object,
    expiredTariffs: PropTypes.object,
    isWizard: PropTypes.bool,
    onlyImage: PropTypes.bool,
    isShow: PropTypes.bool,
    showExpiredOrders: PropTypes.bool,
};

export default withRouter(MenuItem);
