export const zh_lang ={
  "data": {
    "detailsOfCalculation.fields.additions": "附加费",
    "crudForm.field.forHour": "每小时",
    "crud.field.mainPortId.title": "港口码头",
    "form.field.organization.factAddress": "实际地址",
    "form.field.commission.container.price": "每个货柜从",
    "crud.field.billing.relative": "相对",
    "logisticsBlocks.inExcel": "Excel 报告",
    "form.field.iAm": "我是",
    "form.field.addDocument": "Add document",
    "crud.field.organizationClient.title": "Organization (client)",
    "reports.types.ORDER_REQUEST_COUNT": "处理的订单数量",
    "orderCalculation.hidePriceLabelHelp": "系统为您查到多个运输方案，请登录/注册账号查看详细信息。",
    "organizationsProfiles.status.REJECTED": "拒绝",
    "profile.data.title": "您的个人资料",
    "form.order.contactPerson": "联系人",
    "modal.changePassword": "密码修改表",
    "subscription.switchToFree.text": "确认切换到免费订阅。可用报价的未使用余额将被取消。",
    "orderForm.modal.driverName": "司机姓名",
    "crudForm.field.planeType": "飞机类型",
    "crud.field.showDetails": "显示详细信息",
    "protocol.title": "使用条款",
    "crudForm.field.id": "ID",
    "crud.form.view.title": "查看条目",
    "crud.field.departurePlace.button.add": "添加发货人详细信息",
    "crud.field.passwordReset.title": "重置密码",
    "detailsOfCalculation.callManagerResponse.title": "请求已发送",
    "detailsOfCalculation.services_destination": "目的地港口费用",
    "crud.form.add.template.title": "创建新模板",
    "detailsOfCalculation.fields.integratorReward": "货运代理的佣金",
    "crud.field.international.title": "国际",
    "reports.barChart.LANGUAGE": "Languages",
    "reports.statusTest.empty": "缺失数据",
    "logistBlock.departureOrIssueDate": "出发/清关",
    "constants.franchiseTypes.percent": "百分比",
    "constants.typeOfRoadGeneral.zip": "用于邮政编码",
    "crud.field.surchargePerItem.title": "下一单位货物的价格",
    "orderForm.idBolOrAwb": "提单：",
    "crud.field.charge.title": "计费类型",
    "form.error.commission.alreadyExist": "该运输类型的佣金已经设置",
    "form.order.cargoDescription": "货物描述",
    "detailsOfCalculation.fromDoor": "收货点",
    "constants.month.february": "二月",
    "ordersList.button.getMinInfo": "以简短形式显示",
    "operatorAdmin.listOfAPIs": "外部API接口",
    "modal.orderInfo.id": "ID",
    "report.unit.lb": "lb",
    "crud.tab.cargoParams": "报价",
    "crud.field.fromHub.title": "提货点",
    "detailsOfCalculation.customsHouseTitle": "关税和增值税",
    "crud.field.service.title": "服务类型",
    "palletTariff.form.defaultFileName": "运输费用价格",
    "service.serverIsUnavailable": "服务器现在不可用",
    "customsOfCargo.form.calcBtn": "计算",
    "calculation-list.guest.result.registrationMsg": "请注册以获得最佳运费。",
    "common.documents.error.fields": "填写所有字段并上传文件",
    "crudForm.field.rateBilling": "费率",
    "error.numberAccount.size.20": "Enter the correct account number (20 digits)",
    "partner.rates.private": "私人",
    "subscription.tariff.current": "当前",
    "form.field.commission.integrator.percent": "总成本的百分比",
    "crud.field.updateDate.title": "更新时间",
    "orderForm.homeBill": "货代提单：",
    "report.unit.kg": "kg",
    "detailsOfCalculation.filters.sortFor": "排序",
    "crud.add.changeParams": "更改信息。",
    "detailsOfCalculation.railway_station_destination": "目的地车站费用",
    "crudForm.dictionary.field.compartmentSize": "隔间的大小，（L、W、H）",
    "crud.field.departurePlace.button.delete": "删除发货人详细信息",
    "reportResource.title": "资源消耗报告",
    "common.copy.success": "数据复制到剪贴板",
    "form.field.countryFrom": "国家（来自）",
    "crud.field.executionTime.title": "执行时间",
    "form.field.perKM": "每公里的价格",
    "customsOfCargo.rate.both": "关税按海关估价的 {mainRate}% о 加上 {value} {currency}/{unit} 的综合税率征收。增值税 {nds}%",
    "notifications.readAll": "阅读全部",
    "mainMenu.subscriptions.tariff": "订阅计划",
    "crudForm.field.hourly": "每小时",
    "crud.field.tarificationType.title": "计费",
    "reportResource.report.avg.countExternalVariants": "平均计算选项数，包括外部合作伙伴",
    "crudForm.field.documentDeadline": "最晚提供相关文件",
    "person.wizard": "运价计算",
    "crud.field.error.category.serviceExists": "类别不能删除，因为它包含服务",
    "crud.remove.failed": "删除时出错。",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.demurrage": "滞期期无效。Row {0}, column {1}.",
    "general.cargo.compound.generalBtn": "拼箱/零担货物",
    "crudForm.dictionary.field.maxPalletCount": "最大托盘计数",
    "deliveryOrders.orderList.headerTable.departurePoint": "来自到",
    "partner.rates.sea": "海运价格",
    "detailsOfCalculation.dayRP": "天",
    "crud.field.allPorts": "所有港口",
    "common.equalsfortariffAddress": "路徑選擇錯誤，根據所選資費",
    "deliveryOrders.orderInfo.index": "邮政编码",
    "reports.types": "报告类型",
    "customsOfCargo.fields.price": "货物价值",
    "crud.field.documentDeadline.title": "提交文件的截止日期",
    "partner.userInfo.form.email.placeholder": "输入邮件地址",
    "crudForm.field.id.placeholder": "未填写",
    "person.calculationInfo.fail": "没有找到计算",
    "common.documents.position": "位置",
    "crud.field.actions.title": "动作",
    "reports.lineChart.countOfAllClient": "其他数量",
    "report.unit.in": "in",
    "crudForm.field.fromAirport": "来自/到机场",
    "wizard.cargo.form.size.length": "长度",
    "crud.field.geoObjects.title": "地理对象",
    "report.field.month": "月",
    "operatorAdmin.directories.vehicleDisclaimers": "货运条件",
    "indicator.pointOfDestination.arrival.fact": "Point Of Destination, arrival (fact)",
    "modal.contactUs.successBody": "谢谢，我们会尽快与您联系。",
    "orderForm.modal.stateTruckNumber": "卡车车牌号：",
    "crudForm.rates.title": "添加/编辑价格",
    "partner.deliveryForClients": "订单",
    "crud.field.palletType.title": "托盘类型",
    "register.title": "注册",
    "form.field.commissionCurrency": "货运代理的佣金货币",
    "form.field.organization.factAddress.placeholder": "请输入实际地址",
    "detailsOfCalculation.orderTable.typeOfCargo": "货物类型",
    "form.field.customsClearance": "清关",
    "crud.uploadFromExcel.example.body.text": "所有栏都需要填，如有留空报价表是无法上传成功！",
    "userRegistration.passwordDontMatch": "输入的密码不匹配",
    "common.invalidRange": "范围无效",
    "crudForm.field.common.documents.type": "类型",
    "orderForm.actTransitTime": "运输时间/实际：",
    "orderForm.numberOfPositions": "物品：",
    "form.order.truckType": "卡车类型",
    "subscription.history.text": "订阅跟踪记录：",
    "crudForm.field.currency": "货币",
    "orderForm.modal.driver": "驱动程序：",
    "crudList.empty": "列表为空",
    "dialog.confirm.title": "问题",
    "crud.field.dateRange.title": "有效期",
    "indicator.pointOfOrigin.arrival.fact": "Point Of Origin, arrival (fact)",
    "common.roleClient": "用户",
    "agreement.agreeMsg": "You have confirmed that you have read and accepted all the paragraphs of this Agreement ",
    "orderForm.modal.cargoParameters": "货物参数",
    "constants.placeTypes.railwayHub": "铁路枢纽",
    "logistBlock.arrivalOrInnings": "到达/ 车辆已定位以供装载",
    "orderForm.modal.truckNumber": "卡车车牌号：",
    "indicator.pointOfArrival.departure.plan": "Point Of Arrival, departure (plan)",
    "orderForm.companyName": "公司",
    "orderList.StatusColumnTitle": "状态",
    "orderForm.warning.message": "您的订单已创建！您应该填写托运人、收货人和被通知方的详细信息。为此，您可以使用以下链接：",
    "crud.field.sellerRewardMax.title": "最高卖家费用",
    "crudForm.bulkOperations.nameCode.changeOwner": "更改所主",
    "crud.field.error.seaPort.freightTariffExists": "无法删除港口，因为系统包含其海运价格",
    "common.documents": "文件",
    "crud.field.sort.title": "排序编号",
    "modal.save.calculation.title": "Save the quotation",
    "detailsOfCalculation.OrderPopover": "本公司不运送个人物品或私人货物。",
    "report.transportation.avia": "空运",
    "form.wizard.title.from": "发货地",
    "logistBlock.invoicePaymentDate": "发票付款日期",
    "crud.field.cityId.title": "城市",
    "operatorAdmin.directories.seaPortHubs": "海港",
    "report.place.pieces": "pcs",
    "login.exit": "登录",
    "form.wizard.pickup": "上车点",
    "crud.form.view.template.title": "查看模板",
    "modal.orderInfo.cargo": "货物",
    "report.unit.ft": "ft",
    "wizard.cargo.form.containerTypes": "货柜类型",
    "contacts.consignee": "收货人",
    "form.order.remarks": "备注",
    "deliveryOrders.listItem.amount": "数量",
    "constants.month.may": "五月",
    "partner.button.save": "保存更改",
    "common.field.quotes": "引用",
    "detailsOfCalculation.fields.totalCost": "总价格",
    "indicator.customsClearance.departure.plan": "Customs Clearance, departure (plan)",
    "crudForm.field.customsClearance": "清关",
    "orderForm.userName": "名称",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.currency.not.found": "系统中没有货币。Row {0}, column {1}.",
    "crudForm.field.clearing": "清除",
    "detailsOfCalculation.dayMn": "天",
    "operatorAdmin.directories.services": "服务",
    "orderForm.numberOfPlaces": "外包装的总件数：",
    "crud.field.transitHub.title": "交通枢纽",
    "crudForm.dictionary.field.mainHatchSize": "货物舱口的尺寸",
    "operatorAdmin.directories.consolidators": "共同装运公司",
    "orderForm.from": "出发点：",
    "error.inn.size.10": "Enter the correct TIN (10 digits)",
    "operatorAdmin.countryCategories": "国家类别",
    "client.successCreate": "客户的个人资料已创建",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.date.from": "价格日期错误。Row {0}, column {1}.",
    "trunking.form.errorsTitle": "下载资费时出现问题：",
    "reports.field.btn.download": "Download",
    "crudForm.dictionary.field.maxWeight.placeholder": "最大重量",
    "form.order.grossWeight": "毛重",
    "constants.commissionTypes.priceRange": "价格范围",
    "crudForm.field.intraportForwarding.container.to": "到",
    "detailsOfCalculation.weight": "重量",
    "client.passwordChanged": "密码已更改",
    "form.field.notificationOnTariffChange": "订阅价格变更",
    "crud.field.organization.name.title.my": "我的公司",
    "partner.bankDetails.form.field.numberAccount": "Account number",
    "trunking.form.downloadBtn": "下载",
    "crudForm.field.required": "必需的",
    "common.invalidEmail": "邮箱地址地址无效",
    "modal.orderInfo.template": "模板",
    "crud.field.airBillRate": "每个空运提单的成本",
    "customsOfCargo.fields.totalCost": "总计",
    "operatorAdmin.reports": "Quotation statistics",
    "reports.lineChart.countOfNew": "New",
    "partner.bankDetails.form.field.corrAccount.placeholder": "Enter the corresponding account number",
    "detailsOfCalculation.callManager.modalFailBody": "无法发送消息。请稍后再试。",
    "detailsOfCalculation.dayImP": "天",
    "reports.lineChart.countOfAll": "All",
    "crudForm.field.countries": "国家",
    "page.accessDenied.text": "您无权访问此页面",
    "detailsOfCalculation.customsHouseBtn": "海关计算器",
    "user.passwordRestored": "密码恢复成功",
    "crudForm.dictionary.field.maxCommercialDownload": "最大商业下载，（公斤）",
    "report.transportation.express": "快递运输",
    "crud.field.passwordReset.label": "重置密码",
    "operatorAdmin.listOfAPIs.connect": "连接",
    "partner.rates.roadTransportation": "本地卡车运输价格",
    "wizard.cargo.form.size.width.placeholder": "W",
    "partner.orders": "订单",
    "report.cargo.general": "拼箱",
    "crud.filter.modifier": "类型",
    "general.oversized.cargo": "超大/超重货物 –不适合标准设备 – 我们的服务部门会尽快与您联系以讨论最佳选择。 ",
    "orderForm.shipper": "发货人：",
    "crud.field.considerFailedRequests.title": "包括没有结果的回应",
    "crud.field.railwayStation.title": "火车站",
    "crud.field.ratingReportBtn.title": "查看报告",
    "palletTariff.form.uploadDesc": "文件必须是 .xlsx 格式",
    "crudForm.field.ADR_RAILWAY": "地址⇄火車站",
    "detailsOfCalculation.filters.sort.cost": "价格",
    "crud.field.demurrage.title": "滞期费",
    "deliveryOrders.orderList.searchPlaceholder": "输入订舱号或货物编号",
    "deliveryOrders.listItem.orderDate": "订单日期",
    "form.field.companyName.placeholder": "输入公司名称",
    "operatorAdmin.directories.typesOfGoods": "货柜",
    "detailsOfCalculation.fields.commission": "货运代理的佣金",
    "wizard.cargo.form.consolidatedCargo.add": "添加",
    "form.field.commission.sea_transportation.menu": "海运",
    "operatorAdmin.directories.hubs": "中转站",
    "form.field.organizationName.placeholder": "请输入公司名称",
    "crud.field.priceFirst.title": "第一单位货物的价格。",
    "crudForm.field.conversionPercent": "转化百分比",
    "crud.filter.to": "到",
    "HOME_RAILWAY_WITHOUT_SYMBOL": "货代铁路账单",
    "report.unit.сm": "cm",
    "crudForm.field.tariffStatus": "状态",
    "notifications.noNotifications": "没有通知",
    "subscription.page.config.title": "订阅系统配置",
    "reportResource.filter.period": "报告期：",
    "site.footer.menu.personalArea": "个人区域",
    "wizard.cargo.form.palletTypes.placeholder": "托盘",
    "crud.field.billing.absolute": "绝对值",
    "crud.field.categoryId.title": "类别",
    "crud.uploadFromExcel.example.body.btn.download": "下载",
    "importTranslates.alertNotSelectTranslation": "请至少选择一个翻译栏",
    "operatorAdmin.deliveryForClients": "订单",
    "form.field.notificationLimitScan": "警告！ 如果您更改热门目的地的统计周期，更改将在 24 小时内生效。",
    "partner.commission.empty": "缺少所选配置文件的货运代理佣金",
    "person.detailsOfCalculation.fail": "接收订单数据失败",
    "report.transportation.complex": "复杂的运输",
    "constants.modifier.private": "私有",
    "crud.field.error.railwayOperator.railwayTariffExists": "铁路运营商不能被删除，因为他们的火车站成本在系统中",
    "constants.insuranceTransportType.sea": "海",
    "crudForm.bulkOperations.profile.error": "组织没有必要的配置文件",
    "common.required.privateRanges": "请为私人价格添加 1 个范围",
    "crudForm.button.cancel": "取消",
    "crudForm.field.portsOfDeparture": "发货地",
    "form.field.maxKM": "最大距离",
    "constants.month.november": "十一月",
    "detailsOfCalculation.fields.showTariffDetails": "显示详细信息",
    "wizard.tooltip.use40Container": "我们建议根据您的货物大小考虑整箱运输。",
    "common.documents.actionsTitle": "动作",
    "partner.profile": "个人资料",
    "form.field.password.placeholder": "输入密码",
    "directionOfTransportation.departure": "出发",
    "wizard.subscribe.message.first": "订阅来自 Agorafreight.com 的双周通讯，以随时关注新的实际费率和更新的关税。",
    "deliveryOrders.cargoInformation.cargoTypeTitle": "货物信息",
    "crud.field.countVariantsExternalCalculation.title": "包括外部合作伙伴在内的计算选项数",
    "detailsOfCalculation.filters.sort.time": "预计运输时间",
    "crudForm.field.customsPost": "海关邮政",
    "form.order.netWeight": "净重",
    "crudForm.field.maxHeight": "最大高度",
    "crudForm.field.maxWeight": "最大重量",
    "constants.charging.PORT_RAILWAY": "港口⇄火車站",
    "reports.types.ORGANIZATION_COUNT": "提交价格的合作伙伴数量",
    "crudForm.field.toHub": "收货地",
    "auth.success.message": "授权成功",
    "detailsOfCalculation.filters.sort.maxNumber": "最大数量",
    "mainMenu.subscriptions": "订阅",
    "crud.field.error.organization.orderRequestExists": "无法删除系统中有用户的公司",
    "crud.field.name.title": "名称",
    "site.leftMenu.link.contactUs": "联系我们",
    "crudForm.dictionary.currencies.title": "货币",
    "crud.field.order.id.title": "报价单号",
    "form.field.status": "Status",
    "form.field.private": "数据处理",
    "common.documents.sendEmailActionTitle": "发送电子邮件",
    "crud.field.generalCargoRangeRate.title": "价格范围",
    "report.field.count": "数字",
    "crudForm.field.nds.ndsInc": "已包含在价格里",
    "form.order.palletsNumber": "托盘数量",
    "detailsOfCalculation.filters.totalCount": "结果",
    "site.footer.subscribe.placeholder": "电子邮件",
    "HOME_AWB": "货代空运提单：",
    "operatorAdmin.directories.seaLines": "航运线",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.port.to.not.found": "该到达港口在系统中不可用。Row {0}, column {1}.",
    "modal.changePassword.buttonSave": "保存",
    "deliveryOrders.cargoTracking.table.conversion": "转换率",
    "user.invalidOldPassword": "旧密码无效",
    "crud.field.cargoContainerRates.title": "货柜类型和价格",
    "person.calculationInfo.tryWithOtherData": "抱歉，没有找到价格。{br}目前平台在测试模式下运行，功能有限，仅支持中国 - 俄罗斯流量。{br}请将您的询问发送给我们，我们会安排报价单。 ",
    "crudForm.field.period.on.15d": "15 天",
    "partner.bankDetails.form.field.address.placeholder": "Enter legal address in full",
    "deliveryOrders.cargoInformation.cargoTypeBox": "纸箱",
    "orderForm.modal.comment": "评论",
    "importTranslates.titleResult": "翻译导入结果",
    "crud.disclaimers.vehicle.cargoType": "货物类型",
    "form.field.container": "容器",
    "wizard.cargo.form.amount": "数量（个）",
    "form.field.address.to.placeholder": "收件人（地址或邮编）...",
    "crud.filter.expiredTariffsParam": "按过期价格过滤",
    "modal.contactUs.sendBtn": "发送",
    "detailsOfCalculation.logIn": "登录",
    "partner.geography.form.address": "地址",
    "crudForm.field.minTransitPriceCost": "最低运输成本",
    "report.lineChart.count": "数量",
    "customsOfCargo.rate.advalorem": "关税按海关估价的 {mainRate}% 收取。增值税 {nds}%",
    "modal.orderInfo.shippingDate": "发货日期",
    "form.field.middleName": "中间名",
    "login.submit.vk": "使用 VK 登录",
    "crudForm.dictionary.field.floorSize": "楼层面积，（平方米）",
    "detailsOfCalculation.tariffOrganization": "组织",
    "modal.tracking.btn": "跟踪",
    "form.field.countryTo": "国家（至）",
    "reports.field.btn.downloadCsv": "下载为 .csv",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.detension": "无效的滞留期。Row {0}, column {1}.",
    "form.select.noResultText": "未找到任何内容",
    "subscription.tariff.create.request.confirm.text": "您的请求将会尽快处理。",
    "crud.field.arrivalData.title": "收货人",
    "form.field.numberAccount": "Invoice number",
    "crud.field.hasCustoms.title": "清关",
    "subscription.modal.add.btn": "订阅",
    "crud.filter.field": "字段",
    "reports.barChart.TRANSPORT_HUB": "运输中转站",
    "deliveryOrders.status.ARRIVED_TO_DESTINATION_HUB": "到达目的地中心",
    "indicator.pointOfArrival.departure": "Point Of Arrival, departure",
    "operatorAdmin.charts": "Quotation statistics",
    "widget.order.getintouch": "我们团队的一名成员将很快与您联系，他将进行所有必要的安排。",
    "crudForm.field.stations": "站点",
    "agreement.modal.btnAgree": "Accept",
    "crud.field.commission.title": "货运代理的佣金",
    "form.order.shipper": "托运人",
    "report.transportation.sea": "海运",
    "wizard.button.reset": "重置",
    "detailsOfCalculation.successOrder.body": "感谢您的订购！您的订单号 - {orderId}。{br}我们的专业人士会尽快与您联系。通常它会在 15 分钟内发生。",
    "subscription.page.title": "我的订阅",
    "crud.filter.no": "不是",
    "importTranslates.name.code": "代码",
    "modal.changePassword.password.placeholder": "输入新密码",
    "notifications.type.ExpiredCalculation.link.title": "细节",
    "crudForm.field.customsClearance.placeholder": "输入价格",
    "indicator.pointOfPickup.departure.plan": "Point Of Pickup, departure (plan) ",
    "detailsOfCalculation.transitTime": "预计的运输时间",
    "detailsOfCalculation.callManager.checkPriceBtn": "澄清报价",
    "crudForm.dictionary.field.code_currency.placeholder": "输入货币代码",
    "form.field.lastName": "姓",
    "crud.field.country.to.title": "国家",
    "modal.tracking.title": "跟踪",
    "subscriptionRequest.title": "客户端请求订阅",
    "TTH_WITHOUT_SYMBOL": "传输文件：",
    "crud.field.cargo.title": "货物详情",
    "crudForm.field.period.on.12m": "一年",
    "constants.nds.none": "不含增值税",
    "detailsOfCalculation.cargoIsOverSize.bot": "请点击发送，因为我们现在在我们的系统中有所有需要的数据，我们将优先通过电子邮件提供报价。",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.sealine.empty": "无效的航运公司代码。Row {0}, column {1}.。",
    "detailsOfCalculation.updateCalculation": "重新计算",
    "register.partner.title": "注册成为合作伙伴",
    "crudForm.field.fromHub": "发货地",
    "operatorAdmin.directories.ports": "港口",
    "crud.field.ON_FREIGHT": "货运",
    "form.field.confirmPassword.placeholder": "确认密码",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.currency.empty": "没有货币。Row {0}, column {1}.",
    "detailsOfCalculation.createDateNew": "从日期",
    "common.equalsroad": "以同樣的方式選擇",
    "crudForm.field.sum": "金额",
    "deliveryOrders.cargoInformation.cargoTypePackage": "包裹",
    "crud.field.forwardRates.title": "价格范围",
    "organizationsProfiles.title": "公司简介",
    "reportResource.report.avg.countVariants": "平均计算选项数",
    "crud.field.number": "数字",
    "form.field.periodStart": "Period (from)",
    "crud.add.record": "添加价格",
    "partner.generalInformation.form.field.profileStatus.REJECTED": "拒绝",
    "crudForm.field.intraportForwarding.container.price": "每个货柜",
    "crud.disclaimers.vehicle.hubName": "枢纽",
    "detailsOfCalculation.fields.general.air": "空运",
    "partner.geography.geoTypes.city": "城市",
    "subscription.page.config.resetFree.value.DAY": "天",
    "detailsOfCalculation.successOrder.title": "您的订单已创建",
    "indicator.customsClearance.arrival": "Customs Clearance, arrival",
    "detailsOfCalculation.priceOverTime.client": "显示的价格是 ({minHour} {hour}) 的最低价，此后按小时支付",
    "common.error": "错误",
    "orderForm.currentStatus": "当前状态：",
    "crud.field.dateTo.title": "日期到",
    "detailsOfCalculation.fields.cost": "成本",
    "operatorAdmin.directories.vehicleTypes": "车辆类型",
    "detailsOfCalculation.makeOrderConfirmation": "您确定要下订单吗？",
    "detailsOfCalculation.filters.success.text": "数据已成功排序",
    "mainMenu.reportResource": "资源消耗报告",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.freightTerms.wrong": "错误的运费条款值。Row {0}, column {1}.",
    "form.order.address": "公司地址",
    "report.transportation.multimodal.sea": "多式联运（海运+公路）",
    "tracking.fields.date": "日期",
    "detailsOfCalculation.hourImP": "小时",
    "partner.userInfo.form.middleName": "中间名",
    "crudForm.bulkOperations.success": "条目已更改",
    "wizard.general.weight": "重量",
    "crud.field.service": "服务",
    "auth.alreadySuccess.message": "您已经获得授权",
    "crud.field.destinationAirPort.title": "目的地机场",
    "form.order.internalSystemOrderCode": "货物参考",
    "detailsOfCalculation.calcIDNew": "报价单",
    "detailsOfCalculation.fields.conversionPercent2": " ",
    "operatorAdmin.partners": "合作伙伴",
    "detailsOfCalculation.fields.conversionPercent1": "货币换算",
    "indicator.deliveryToRecipientDate": "Delivery To Recipient",
    "common.documents.fileName": "文件名",
    "crud.field.id.title": "№",
    "ordersList.now": "现在：",
    "form.field.includeInInsurance": "添加保险",
    "form.wizard.title.insurance": "货物保险",
    "reports.barChart.SEA_LINE": "运输线",
    "crudForm.field.railwayOperator": "铁路运营商",
    "form.field.address.from.placeholder": "发件人（地址或邮编）...",
    "crud.field.organization.phone.title": "公司电话",
    "LogisticDocType.PACKING_LIST": "装箱单",
    "deliveryOrders.requestId": "子订单号",
    "trunking.form.uploadTitle": "资费上传",
    "operatorAdmin.directories.templateDescriptionTariff": "Description for the tariff",
    "detailsOfCalculation.customs.desc": "描述",
    "crud.field.active.no": "无效",
    "deliveryOrders.orderList.status.notDelivered": "未交付",
    "CMR": "CMR：",
    "common.download": "下载",
    "constants.tarifications.absolute": "绝对",
    "HOME_RAILWAY": "货代铁路账单：",
    "detailsOfCalculation.pieces": "个",
    "detailsOfCalculation.orderInfo.submit": "提交",
    "crud.field.shippingDate.title": "货物准备日期（如果已知）",
    "form.select.loading": "加载中...",
    "customsOfCargo.fields.weight": "重量",
    "wizard.cargo.customsOfCargo": "清关地点",
    "confirm.order.fail": "错误，请确保所有字段都正确填写",
    "orderForm.commentPrice": "价格/评论：",
    "detailsOfCalculation.filters.currency": "货币",
    "form.field.commission.integrator.percent.min": "不少于",
    "subscription.manage.tariffs.section": "管理您的订阅",
    "crud.field.departurePort.title": "发货港",
    "constants.typeOfRoadGeneral.wm": "对于 w/m",
    "crudForm.dictionary.btn.removeHatch": "删除隔间",
    "form.order.shippingDateFact": "货物准备日期，事实",
    "order.createNewManual.detailsOrder.changeOwnerOrg.title": "Company of the owner",
    "agreement.modal.title": "Terms of use",
    "detailsOfCalculation.callManager.modalSendBtn": "发送",
    "trunking.form.counterSecondString": "超出",
    "subscription.main.dateCreated.text": "订阅日期：",
    "constants.placeTypes.transportHub": "交通枢纽",
    "indicator.customsClearance.arrival.fact": "Customs Clearance, arrival (fact)",
    "crud.field.comment.title": "评论",
    "service.serverError": "服务器错误",
    "crud.field.organizationName.title": "公司名称",
    "deliveryOrders.delivery": "发送",
    "detailsOfCalculation.insurance.cargoCost": "货物价值",
    "crudForm.field.surcharges": "附加费",
    "crud.field.error.organization.usersExists": "无法删除已打开交货订单的公司",
    "trunking.form.noLoadedFile": "没有上传文件",
    "detailsOfCalculation.customs.title": "清关",
    "wizard.subscribe.cancelBtnText": "不，谢谢",
    "partner.bankDetails.form.field.ogrn": "Primary State Registration Number",
    "constants.typeOfRoadGeneral.radius": "直径",
    "register.submit": "注册",
    "site.leftMenu.link.news": "新闻",
    "constants.placeTypes.seaPort": "海港",
    "crud.field.transportationType.title": "运输类型",
    "crud.field.orderNumber": "订单号",
    "reports.statusTest.emptyAllReports": "生成报告",
    "form.field.commissionPublic": "公共",
    "constants.quarter": "前3个月",
    "common.documents.orderId": "订单号",
    "orderForm.estTransitTime": "运输时间/估计：",
    "mainMenu.subscriptions.userSubscription": "客户端订阅",
    "form.order.common": "普货",
    "crud.field.size.title": "尺寸（长、宽、高）",
    "site.footer.copyright": "© {thisYear} <a href='https://cargobar.dev/' target='_blank'>CARGOBAR</a>. All rights reserved.",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.transitTime": "Invalid transit time. Row {0}, column {1}.",
    "crud.field.GENERAL_CARGO": "拼箱",
    "modal.save.calculation.checkbox.title": "Save for the client",
    "deliveryOrders.cargoInformation.cargoWeightTitle": "重量",
    "crud.field.freightCondition.title": "运费条款",
    "detailsOfCalculation.documentDeadline": "向航运公司提交文件的截止日期",
    "detailsOfCalculation.outdatedCalculationMsg": "计算已过时，结果可能已更改。",
    "crud.field.direction.title": "方向",
    "deliveryOrders.cargoInformation.title": "货物信息",
    "constants.month.august": "八月",
    "modal.orderInfo.owner": "单主",
    "subscription.switchToFree.btn.yes.text": "确认",
    "form.field.position": "位置",
    "common.status.current": "当前阶段",
    "operatorAdmin.directories.freightSurcharges": "运费附加费",
    "tracking.fields.organization": "组织",
    "form.field.commission.customs_road_transportation.menu": "保税陆运运输公司",
    "partner.rates.railwayTransportation": "铁路运费",
    "orderForm.sidesLimit": "达到的最大通知方数量",
    "crud.field.position.title": "位置",
    "indicator.pointOfPickup.load": "Point Of Pickup, load",
    "operatorAdmin.directories.airportsHubs": "机场",
    "orderForm.notificateConsignee": "通知收货人",
    "common.documents.userName": "文件创建者",
    "subscription.main.tariff.text": "订阅：",
    "customsOfCargo.fields.customsPrice": "海关费用",
    "commentDescription.button.create": "Add new",
    "user.invalidOperatorOrganization": "具有运营商授权的用户的公司无效",
    "indicator.pointOfArrival.arrival.fact": "Point Of Arrival, arrival (fact)",
    "wizard.find": "查找",
    "constants.nds.currentRate": "20",
    "form.field.position.placeholder": "请输入您的位置",
    "deliveryOrders.orderBlock.orderActions.getNotifications": "订阅",
    "crud.field.generalCargoRangeRate.price": "价格",
    "orderForm.actPrice": "价格/实际：",
    "site.leftMenu.link.profile": "个人资料",
    "deliveryOrders.status.CONFIRMED": "确认的",
    "detailsOfCalculation.filters.sort": "排序",
    "form.field.users": "用户",
    "order.location.seaPort.required": "这是一个必填字段",
    "login.submit": "登录",
    "detailsOfCalculation.services_departure": "出发港费用",
    "wizard.tooltip.use20Container": "我们建议根据您的货物大小考虑整箱运输。",
    "crudForm.field.TypeOfTariff": "类型",
    "operatorAdmin.transportationRules": "运输规则",
    "detailsOfCalculation.transitTimeNew": "预计的运输时间",
    "dialog.confirm.default.question": "你确定吗？",
    "crudForm.field.description": "描述",
    "tracking.fields.statuses": "状态",
    "crudForm.button.apply": "应用",
    "indicator.pointOfPickup.ready": "Point Of Pickup, ready",
    "ratingReport.scale": "量表",
    "constants.NOT_PAID": "Not paid",
    "deliveryOrders.listItem.weight": "重量",
    "deliveryOrders.cargoInformation.cargoVolumeTitle": "体积",
    "partner.rates.trunking": "货运费用",
    "crudForm.dictionary.field.size": "大小，（长，宽，高）",
    "crudForm.field.transportationType": "运输类型",
    "wizard.cargo.form.car.title": "卡车",
    "crud.field.departureAirPort.title": "始发机场",
    "rating.page.title": "用户评分",
    "reports.field.month": "Month",
    "deliveryOrders.cargoTracking.table.service": "服务",
    "crud.filter.include": "包含",
    "constants.typeOfRoadGeneral.rc": "区域系数",
    "partner.rates.railwayServices": "铁路杂费",
    "form.field.confirmCode": "确认码",
    "common.delete.success": "数据已删除",
    "crud.label.yes.no": "是/否",
    "orderForm.departureDate": "出发日期：",
    "reports.barChart.ROAD_TRANSPORT_TARIFF": "陆运价格",
    "indicator.pointOfPickup.ready.plan": "Point Of Pickup, ready (plan)",
    "common.applyTemplate": "从模板填充",
    "crud.field.franchise.title": "特许经营权",
    "person.delivery": "预订",
    "LogisticDocType.FREIGHT_INVOICE": "运费发票",
    "deliveryOrders.orderList.headerTable.cargo": "货物",
    "crudForm.field.airTransportation.to": "重量段直到",
    "crudForm.field.pricePerKg": "每公斤价格",
    "form.order.incoterms": "国际商业术语",
    "operatorAdmin.directories.airlineDisclaimers": "航空运输条件",
    "deliveryOrders.orderList.headerTable.orderNumber": "订单号",
    "login.title": "授权",
    "detailsOfCalculation.fields.vatPersent": "增值税 %",
    "crud.field.error.service.tariffExists": "无法删除服务，因为它包含价格",
    "circular.parent.become.child": "You can't use a parent element as a subsidiary one",
    "detailsOfCalculation.RenewBtn": "续订",
    "dialog.confirm.commission.notSaveData.question": "您确定要在保存数据之前退出吗？",
    "operatorAdmin.directories.trucks": "卡车",
    "wizard.customsOfCargo.containerImP": "货柜",
    "crud.filter.hideExpired": "隐藏过期",
    "common.alertWorkJobScan": "目前，正在分析热门目的地的统计数据。 分析完成后即可获得结果。",
    "detailsOfCalculation.fields.includeService": "包括这个服务",
    "form.field.station": "选择火车站",
    "common.unsupported.geo.region": "您没有权限输入报价给这贸易通道",
    "auth.banned.message": "由于多次登录失败，您的帐户已被暂时暂停。请5分钟后再试。",
    "crud.uploadFromExcel.tooltip": "如果您有很多价格想上传，这需要用到我们的上传表格。请在这下载上传表格。",
    "crud.add.newOrder": "新查询",
    "profile.organizationTemplates": "组织模板",
    "form.field.periodEnd": "Period (until)",
    "detailsOfCalculation.to": "到",
    "crudForm.field.billRate": "每提单报价",
    "customsOfCargo.rate.specific": "关税按 1 {unit} 的 {value} {currency} 费率收取。增值税 {nds}%",
    "form.field.commission.integrator.percent.max": "不超过",
    "person.button.edit": "编辑",
    "crud.field.address.title": "地址",
    "subscription.main.datePayNext.text": "即将付款的日期：",
    "form.field.commissionPrivate": "私人",
    "deliveryOrders.cargoTracking.table.cost": "成本",
    "form.order.cargoName": "货物名称",
    "crud.field.error.railway.roadTariffExists": "无法删除火车站，因为它的陆运价格已经上传到系统中",
    "operatorAdmin.directories.currencies": "货币",
    "radius.prices": "费率",
    "crud.field.error.seaPort.portTariffExists": "无法删除港口，因为系统包含其港口价格",
    "crud.field.error.consolidator.freightTariffExists": "无法删除协同装载机，因为其运费在系统中 ",
    "person.button.save": "保存",
    "form.order.bill": "提单详情",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.port.from.not.found": "该出发港在系统中不可用。Row {0}, column {1}.",
    "reports.lineChart.countOfClient": "客户数量",
    "deliveryOrders.status.PLANNING": "规划",
    "partner.generalInformation.form.field.factAddress.placeholder": "输入邮寄地址",
    "crud.remove.request.label": "您确定要取消订阅申请吗？",
    "crudForm.field.costForWM": "每平方米的成本",
    "confirm.help.statusNotConfirm": "Dear user! We noticed that we have already sent a registration confirmation code to your email. Please check your mail, including the spam folder. We have sent a re-verification code to complete the registration.",
    "page.main": "首",
    "form.order.companyName": "公司名称",
    "customsOfCargo.rate.max_of_both": "关税按海关估价的 {mainRate}% 收取，但不低于 {value} {currency}/{unit}。增值税 {nds}%",
    "crud.field.surchargeText": "附加费",
    "partner.userInfo.form.lastName": "姓",
    "detailsOfCalculation.fields.showTariffDetailsNew": "更多的",
    "crud.field.cargoTypeName.title": "货物类型",
    "operatorAdmin.directories.railwayHubs": "火车站",
    "form.order.companyAddress": "公司地址",
    "wizard.cargo.unit": "单位",
    "partner.rates.public": "从 Excel 下载",
    "wizard.directionForm.addressFrom": "提货点",
    "logisticBlocks.inExcel": "Excel report",
    "detailsOfCalculation.tryEnd": ".",
    "crudForm.field.perKm": "每公里",
    "wizard.cargo.form.amount.placeholder": "输入数字",
    "notifications.read": "标记为已读",
    "subscription.tariff.create.request.confirm": "您的订阅申请已获批准。",
    "crud.field.usageLimit.crud.title": "使用的报价数",
    "organizationsProfiles.deleteProfileConfirm": "您确定要从公司中删除配置文件吗？",
    "crud.field.error.railwayOperator.railwayStationTariffExists": "铁路运营商不能被删除，因为他们的公路运输关税在系统中",
    "detailsOfCalculation.insurance.interestType": "兴趣类型",
    "crud.save.success": "元素已保存",
    "crudForm.field.transitCargo.customCleared": "清关完成",
    "form.order.date": "日期",
    "excel.tariff.no.directionTariffType": "无方向 FROM_CITY 或 TO_CITY",
    "crud.field.arrivalPlace.button.add": "添加收货人详细信息",
    "wizard.cargo.form.weightTo": "到",
    "wizard.cargo.form.vehicleBody": "车辆车身",
    "crud.field.country.name.title": "国家",
    "trunking.form.defaultFileName": "运输费用关税",
    "common.documents.downloadActionTitle": "下载",
    "page.expiredTariffs.railway-services": "铁路杂费",
    "common.errorNullTemplate": "模板不能为空！ 填写托运人和/或收货人的详细信息。",
    "form.field.commission.container.to": "去",
    "crudForm.field.airTransportation.from": "重量段从",
    "reports.types.POPULAR_DESTINATIONS_TREND": "热门目的地（与上期相比）",
    "deliveryOrders.cargoTracking.table.total": "总计",
    "crud.pagination.title": "总计",
    "deliveryOrders.cargoTracking.table.taxName": "增值税",
    "crud.field.auditEventType.title": "审核事件类型",
    "confirm.help.status": "我们向您的邮箱发送了一个验证码",
    "crud.field.paid.title": "Paid API",
    "crud.field.createDate.title": "创建于",
    "register.partner.description": "合作伙伴平台描述",
    "organizationsProfiles.rejectProfileConfirm": "您确定要拒绝配置文件吗？",
    "partner.bankDetails.form.field.inn": "TIN",
    "common.unique": "这个值必须是独一的",
    "trunking.form.addFile": "附加文件",
    "LogisticDocType.HOME_BILL": "货代提单/空运提单",
    "constants.vehicleDistanceRange.UNDER_100": "<100 公里",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.freightTerms.empty": "运费条款为空。Row {0}, column {1}.",
    "crudForm.field.service": "服务",
    "detailsOfCalculation.callManager.checkTransitBtn": "发送请求",
    "crud.filter.title": "筛选",
    "wizard.cargo.form.size.height": "高度",
    "importTranslates.resultCountAll": "总进口：",
    "crud.filter.from": "来自",
    "modal.orderInfo.status": "状态",
    "crud.field.additions.title": "附加费",
    "reports.types.TARIFF_COUNT": "有效价格数量",
    "wizard.subscribe.message.mobile": "订阅来自 Agorafreight.com 的双周通讯以保持关注。从不会收到垃圾邮件，只会收到最新的新闻。",
    "trunking.form.uploadError": "上传错误",
    "crud.filter.cargoContainerType": "货柜箱类型",
    "customsOfCargo.fields.price.placeholder": "价值",
    "wizard.cargo.form.weight": "重量",
    "widget.order.thanks": "感谢您向我们预订！",
    "detailsOfCalculation.filters.updated.text": "数据已成功更新",
    "crudForm.field.consolidation": "整合",
    "detailsOfCalculation.fields.vat": "增值税",
    "crud.field.regionId.title": "国家",
    "crudForm.bulkOperations.nameCode.prolong": "扩展",
    "form.field.airport": "选择机场",
    "partner.services.serviceTypes.serviceProfiles": "服务配置文件",
    "form.order.zipCode": "邮政编码",
    "operatorAdmin.users": "用户",
    "indicator.receiptOfDocuments.fact": "Point Of Arrival, receipt of documents (fact)",
    "detailsOfCalculation.customs.price": "成本",
    "site.leftMenu.link.termsOfUse": "使用条款",
    "form.order.personName": "姓名",
    "detailsOfCalculation.changeParams": "更改参数",
    "form.wizard.title": "获取报价",
    "crud.field.railwayOperator.title": "铁路运营商",
    "deliveryOrders.orderList.searchBtnTitle": "搜索",
    "crudForm.field.containerType": "货柜类型",
    "form.button.back": "上一个",
    "crud.field.language.title": "电子邮件通知的语言",
    "partner.bankDetails.form.field.numberAccount.title": "Bank account details",
    "reportResource.report.avg.countInternalVariants": "不包括外部合作伙伴的平均计算选项数",
    "crud.field.error.seaLine.surchargeExists": "航运公司无法删除，因为已为其输入海运费",
    "crud.field.RELATIVE": "相对",
    "partner.userInfo.form.phone.placeholder": "输入电话号码",
    "crud.filter.show": "显示",
    "crud.field.error.seaPort.roadTariffExists": "无法删除枢纽，因为系统包括其公路托运价格",
    "constants.month.march": "三月",
    "subscription.request.title": "申请跟踪记录",
    "crudForm.bulkOperations.form.field.name": "名字",
    "wizard.tooltip.selectContainerOther": "请查看容器选项。",
    "crudForm.field.betweenTheCityPerDistance": "城市之间 (每公里)",
    "notifications.delete": "删除通知",
    "crud.field.locked.title": "锁定",
    "crudForm.field.documentDeadline.placeholder": "填写天数",
    "crud.field.city.name.title": "城市",
    "crud.field.countVariantsInternalCalculation.title": "不包括外部合作伙伴的计算选项数",
    "crud.field.cargoKind.title": "货柜种类",
    "crud.field.region.title": "区域",
    "deliveryOrders.listItem.size": "尺寸",
    "form.field.limit": "限制",
    "form.order.arrival": "原产地",
    "crud.edit.bulkOperation": "编辑选择",
    "crudForm.field.demurrage": "免堆天数",
    "crud.field.error.profile.cantRemove": "无法删除带有公司的配置文件",
    "register.client.description": "客户端平台描述",
    "common.send": "发送",
    "ordersList.left": "左：",
    "crud.field.seaLine.title": "船公司",
    "crudForm.field.typeOfCargo": "货物类型",
    "wizard.customsOfCargo.title": "清关",
    "disclaimers.calculation.label": "附加條款",
    "operatorAdmin.directories.events": "事件",
    "reports.field.count": "Number",
    "excel.tariff.no.organization": "未找到组织",
    "operatorAdmin.emptyOrders": "空价格",
    "crud.field.countryFrom.title": "来自",
    "crud.field.routeType.title": "路线类型",
    "error.bik.size.9": "Enter the correct BIK (9 digits)",
    "crudForm.field.view": "视图",
    "orderForm.cargoVolume": "总体积：",
    "detailsOfCalculation.fields.tariff": "价格",
    "operatorAdmin.listOfAPIs.disconnect": "断开连接",
    "crud.field.cargoTypeCalculation.title": "报价数量（FCL/LCL/FTL）",
    "wizard.cargo.form.size.length.placeholder": "L",
    "form.order.destination": "目的地",
    "crudForm.field.common.documents.nameOfDocument": "文件名",
    "detailsOfCalculation.fields.seaLine": "航运公司",
    "operatorAdmin.directories.template": "订单模板",
    "partner.bankDetails.form.field.inn.placeholder": "Enter TIN  number",
    "OF_DESTINATION": "目的地的",
    "common.documents.numberOfDocument": "文件编号",
    "partner.salesRules": "毛利调整器",
    "crud.field.error.railwayOperator.roadTransportTariffExists": "铁路运营商不能被删除，因为他们的铁路运输关税在系统中",
    "crud.field.toHub.title": "送货地址",
    "crud.field.error.profile.categoryExists": "无法删除具有现有类别的配置文件",
    "crudForm.field.minWeight": "最低重量",
    "circle.parent.become.child": "您不能将父元素用作子元素",
    "crudForm.field.transitCargo.inTransit": "运输中",
    "detailsOfCalculation.hideDetails": "隐藏细节",
    "form.field.email": "电子邮件",
    "mainMenu.mySubscription": "我的订阅",
    "detailsOfCalculation.orderTable.volume": "体积",
    "wizard.directionForm.address": "地址",
    "report.name": "计算编号",
    "constants.typeOfCargoFull.vehicle": "车辆",
    "crudForm.field.country": "国家",
    "crudForm.dictionary.field.parent": "Main organization",
    "orderForm.warning.consigneeInfoMessage": "收货人详细信息",
    "crud.field.totalCost.title": "总成本",
    "wizard.insuranceForm.price": "价格",
    "trunking.form.deleteAll": "删除所有",
    "form.field.password": "密码",
    "subscription.page.config.resetFree.value.MONTH": "Month subscription.page.config.resetFree.value.MONTH=月份",
    "orderForm.to": "目的地：",
    "crudForm.field.PORT_ADR": "港口⇄地址",
    "crud.field.nds.title": "增值税",
    "crud.field.errors.title": "错误",
    "form.field.fullName": "全名",
    "contacts.confirm.message": "如果您删除通知方，数据将无法恢复",
    "reports.lineChart.countOfFreight": "海运",
    "crud.field.aviaCompany.title": "航空公司",
    "constants.charging.PORT_ADR": "港口⇄地址",
    "documents.file.download.success": "文件下载成功",
    "crudForm.field.RequiredServices": "所需服务",
    "modal.orderInfo": "订单信息",
    "detailsOfCalculation.calculationsIsEmpty.bot": "请一键发送您的询问，我们将优先通过电子邮件提供报价。",
    "reports.field.btn.restart": "Regenerate",
    "error.ogrn.size.13": "Enter the correct Primary State Registration Number (13 digits)",
    "customsOfCargo.form.removeArticles": "移除货物",
    "report.barChart.LANGUAGE": "语言",
    "deliveryOrders.cargoTracking.table.quantity": "数量",
    "detailsOfCalculation.railway_station_services": "车站收费",
    "constants.customHouseAir.inPort": "卸货机场清关",
    "crud.field.hub.title": "枢纽",
    "reports.field.btn.search": "Display",
    "detailsOfCalculation.fields.cargo": "货物",
    "crud.field.reportDetail.title": "详细报告",
    "common.documents.nameOfDocument": "文件名",
    "form.field.vehicle": "车辆",
    "reports.lineChart.countOfAllOrganization": "海运+陆运",
    "templates.documents.table.headers.default": "默认模板",
    "BOL_WITHOUT_SYMBOL": "提单",
    "reports.types.POPULAR_DESTINATIONS_COUNTRY": "热门目的地（按国家/地区）",
    "detailsOfCalculation.airport_service": "机场服务",
    "role.user.adminOrganization": "Organization Administrator",
    "detailsOfCalculation.filters.cancel": "重置",
    "partner.generalInformation.form.field.email": "官方邮件地址",
    "detailsOfCalculation.fields.container": "容器类型",
    "crudForm.dictionary.field.name": "名称",
    "reports.lineChart.months": "Months",
    "crud.field.modifier.title": "类型",
    "AWB_WITHOUT_SYMBOL": "空运提单",
    "form.field.organization.name": "名称",
    "protocol.modal.title": "使用条款",
    "form.wizard.seo.title": "在线运费计算器",
    "articles.more.than.cargo": "Gross weights used for customs costs calculation and cost of  transportation should match each other",
    "operatorAdmin.directories.forwarders": "货运代理",
    "site.leftMenu.link.forwarders": "货代",
    "importTranslates.title": "导入中心/地理对象的翻译",
    "modal.contactUs.failBody": "无法发送消息。请稍后再试。",
    "reports.alert.waitRestart": "操作成功完成。需要等待一分钟，直到生成新的图形",
    "detailsOfCalculation.callManager.modalSuccessBody": "您的请求将得到及时处理，报价将发送到您的电子邮件地址。",
    "crud.field.active.yes": "有效",
    "crud.disclaimers.railway.conditionText": "免责声明",
    "crud.field.vehicleRates.distanceRange.title": "距离范围",
    "detailsOfCalculation.fields.containers": "货柜",
    "crud.field.active.title": "现行",
    "requestStatus.switch": "更改订阅计划",
    "crud.field.profile.title": "个人资料",
    "detailsOfCalculation.createDate": "报价日",
    "form.order.customsCode": "HS 编码",
    "indicator.deliveryToRecipientDate.plan": "Delivery To Recipient (plan)",
    "operatorAdmin.directories.transportHubs": "运输中转站",
    "crudForm.bulkOperations.field.selectedItems": "选定的项目",
    "common.contactsTitle": "联系人",
    "crud.field.forwarderTemplate.title": "模板类型",
    "constants.insuranceTransportType.land": "国家",
    "link.back.text": "< 返回",
    "reportResource.report.avg.timeExecution": "平均报价执行时间（秒）",
    "report.transportation.road": "陆运",
    "crud.field.nds.include": "包括",
    "wizard.cargo.totalVolume": "总体积",
    "crud.field.countriesRoute.title": "路线类型",
    "crudForm.dictionary.field.nameTemplate": "模板名称",
    "report.transportation.multimodal.rail": "多式联运（铁路+公路）",
    "form.field.commission.addStr.common": "范围",
    "form.field.commission.consolidator.menu": "共同装运公司",
    "crud.field.createDateTime.title": "创建日期",
    "confirm.order.reset": "您的订单已成功清除",
    "crudForm.dictionary.field.code.placeholder": "输入代码",
    "userRegistration.passwordError": "密码至少为8位字符，至少包含1个数字，大写字母和小写字母。",
    "crud.field.periodStart.title": "Period (from)",
    "crud.field.conditionText.title": "免责声明",
    "indicator.invoiceDate": "Invoice date",
    "crudForm.field.notRequired": "可选",
    "deliveryOrders.orderList.status.onTheWay": "在路上",
    "rate.error.accessDenied": "该组织无权创建此类价格",
    "crudForm.field.transitCargo": "运输",
    "crudForm.field.forWM": "每瓦特/米",
    "form.field.confirmPassword": "密码（确认）",
    "detailsOfCalculation.fields.general": "小于集装箱/卡车货物",
    "crudForm.dictionary.field.organizationName": "组织名称",
    "ordersList.plan": "* 计划",
    "AIRPORT_HUB": "机场",
    "crud.field.legalAddress.title": "合法地址",
    "orderCalculation.hidePriceLabelHover": "登录或注册查看运价",
    "form.field.firstName.placeholder": "输入名字",
    "crudForm.field.weightUnit": "重量单位",
    "reportResource.downloadReportExcelBtn": "Download report (Excel)",
    "common.settingsBtn": "设置",
    "crud.field.objectType.title": "对象类型",
    "partner.rates.cargo": "货物",
    "orderForm.po": "货物参考：",
    "site.footer.developedBy": "Developed by",
    "constants.typeOfRoadGeneral.ldm": "用于 LDM",
    "person.generalInformation": "一般信息",
    "partner.generalInformation.form.field.factAddress": "邮寄地址",
    "crud.field.chargingName.title": "计费类型",
    "crudForm.dictionary.field.rates_currency": "其他货币的汇率：",
    "crudForm.dictionary.field.insuranceRate": "保险费率",
    "wizard.insuranceForm.addRange": "添加",
    "constants.month.december": "十二月",
    "form.field.postcode": "邮政编码",
    "report.transportation.vehicle": "货运",
    "crud.field.companyName.title": "公司名称",
    "deliveryOrders.status.AT_DESTINATION": "在目的地",
    "common.someRequired": "必须为至少一种类型的货柜",
    "modal.changePassword.oldPassword.placeholder": "输入旧密码",
    "partner.rates.public.crud": "关税",
    "crudForm.bulkOperations.nameCode.copyLink": "复制链接",
    "constants.charging.distance": "距离",
    "deliveryOrders.orderBlock.orderActions.details": "详细信息",
    "deliveryOrders.cargoInformation.cargoAmountTitle": "数量",
    "crud.field.error.geoObject.stationExists": "无法删除地理对象，因为在系统中为它输入了机场",
    "detailsOfCalculation.orderTable.vehicle.type": "类型",
    "detailsOfCalculation.fields.billing": "计费",
    "subscription.main.usage.text": "使用的报价数：",
    "wizard.directionForm.airport": "机场",
    "wizard.cargo.form.size.M": "m",
    "operatorAdmin.insuranceRates": "保险价格",
    "orderForm.of": "的",
    "crud.field.fromLocation.title": "来自",
    "templates.documents.edit.form.nofile": "未选择文件",
    "reports.types.POPULAR_DESTINATIONS": "热门目的地",
    "partner.bankDetails.form.field.fullName.placeholder": "Enter the name of legal entity",
    "person.button.changePassword": "更改密码",
    "report.field.btn.download": "下载",
    "crudForm.field.maxSize": "货物的最大尺寸",
    "crud.disclaimers.railway.railwayStationName": "火车站",
    "crud.field.seaPort.title": "港口",
    "operatorAdmin.title": "管理仪表板",
    "crud.field.departureDataNotFill": "发货人数据未填写",
    "crud.filter.deleteFilterConfirm": "您确定要删除过滤器吗？",
    "subscription.main.text": "一般信息：",
    "page.expiredTariffs.road-transportation": "原地卡派报价（满车和散货）",
    "error.corrAccount.size.20": "Enter the correct corresponding account number (20 digits)",
    "crud.field.profileId.title": "个人资料",
    "crudForm.field.intraportForwarding.auto.placeholder": "自动输入",
    "templates.documents.table.headers.name": "姓名",
    "common.documents.addDocumentOrder": "新文件输入",
    "constants.franchiseTypes.fixed": "固定",
    "wizard.subscribe.message.second": "从不会收到垃圾邮件，只会收到最新的新闻。",
    "constants.charging.rate": "价格",
    "report.cargo.hourly": "拼箱/零担",
    "crud.field.error.surcharge.cantremove": "无法删除输入值的附加费",
    "crud.field.confirmationCode.title": "确认码",
    "organizationsProfiles.status.NEW": "新",
    "deliveryOrders.status.ARRIVED_TO_DEPARTURE_HUB": "到达出发中心",
    "report.lineChart.months": "月",
    "crudForm.field.freightConditions": "运费条款",
    "crud.field.seaLineId.title": "航线",
    "constants.month.april": "四月",
    "orderForm.modal.driverPhone": "司机手机号码",
    "crudForm.field.airTransportation.price": "价格",
    "wizard.tooltip.useOtherContainer": "我们建议根据您的货物大小考虑整箱运输。",
    "detailsOfCalculation.toDoor": "送货到门",
    "detailsOfCalculation.priceOverTime": "显示的价格是（{minHour} {hour}）的最低价，此后按小时付款（{priceForHour} in hour）",
    "crudForm.dictionary.field.organizationId.placeholder": "公司 ID",
    "crudForm.field.airTransportation.auto.placeholder": "自动输入",
    "crud.form.add.title": "价格条目",
    "wizard.cargo.form.vehicleType": "车辆类型",
    "documents.replace.doc": "该文档已经上传。换一个新的？",
    "widget.order": "订单",
    "deliveryOrders.ownerInfo": "单主",
    "partner.rates.complexTariff": "複雜的關稅",
    "detailsOfCalculation.details": "显示详细信息",
    "logistBlock.plan": "计划",
    "modal.changePassword.passwordConfirmation": "重复新密码",
    "detailsOfCalculation.insurance.title": "货物保险",
    "crudForm.field.betweenTheCity": "城市之间",
    "common.equalsroadTransit": "中转枢纽不得与始发地或目的地枢纽相同",
    "crudForm.dictionary.tab.tabHatch": "隔间",
    "form.button.next": "下一个",
    "crud.field.error.aviaCompany.airTariffExists": "无法删除机场，因为已经为它上传了公路运输价格",
    "form.wizard.allTerminals": "所有终端",
    "partner.userInfo.form.firstName.placeholder": "输入名字",
    "modal.contactUs.title": "联系我们",
    "detailsOfCalculation.fields.railwayOperator": "铁路运营商",
    "person.title": "客户信息",
    "constants.commissionTypes.percent": "销售额百分比",
    "crudForm.dictionary.languages.title": "语言",
    "wizard.cargo.form.typeOfCargo": "货物类型",
    "reports.barChart.SEA_PORT": "港口",
    "form.field.price": "Amount",
    "partner.geography.geoTypes.country": "国家",
    "deliveryOrders.order": "订单",
    "subscription.page.config.resetFree.value.WEEK": "周",
    "logistBlock.documentsDate": "文件收据",
    "crud.field.sellerRewardPercent.title": "卖家报酬百分比",
    "wizard.directionForm.addressTo": "送货地址",
    "detailsOfCalculation.fields.conversionPercent": "货币换算",
    "operatorAdmin.directories.railwayDisclaimers": "铁路运输条件",
    "form.wizard.title.common": "其他",
    "indicator.pointOfArrival.departure.fact": "Point Of Arrival, departure (fact)",
    "customsOfCargo.form.title": "海关付款计算",
    "deliveryOrders.orderList.excelReport": "Excel 报告",
    "excel.tariff.no.identify.direction": "无法定位主要城市",
    "modal.orderInfo.fromLocation": "来自位置",
    "partner.generalInformation.dictionaries.title": "公司简介",
    "orderForm.cargoDescription": "货物描述：",
    "site.footer.menu.agreement": "使用条款",
    "orderCalculation.hidePriceLabelModal": "要查看运价，您需要登录/注册",
    "templates.documents.btn.downloadTestWord": "在 WORD 中查看",
    "auth.fail.message": "登录名或密码不正确",
    "crud.field.ON_CARGO": "货物",
    "modal.orderInfo.resultLink": "查看报告",
    "OF_PICKUP": "取货的",
    "form.field.cityAndAddress": "城市，地址",
    "button.logout": "退出",
    "orderForm.editSide": "编辑",
    "crudForm.field.airTransportation.placeholder": "输入数字",
    "page.expiredTariffs.air-transportation": "空运",
    "indicator.customsClearance.departure.fact": "Customs Clearance, departure (fact)",
    "partner.generalInformation.form.field.email.placeholder": "输入官方邮箱地址",
    "crud.field.resetedUsageLimit.title": "重置成功",
    "modal.contactUs.email": "邮件地址",
    "crud.field.error.geoObject.portExists": "无法删除地理对象，因为在系统中为它输入了港口",
    "operatorAdmin.directories.airlines": "航空公司",
    "crud.field.type": "服务类型",
    "partner.generalInformation.form.field.name.placeholder": "输入姓名",
    "form.field.phone": "电话号码",
    "mainMenu.subscriptions.conf": "配置",
    "detailsOfCalculation.register": "注册",
    "deliveryOrders.listItem.status": "状态",
    "subscriptionTariffs.title": "订阅",
    "common.changeSelfOrganization": "您不能自行更改组织",
    "crudForm.bulkOperations.form.field.email": "邮箱地址",
    "error.numberAccount": "Enter the correct account number (20 digits)",
    "detailsOfCalculation.fakePartPaths": "这部分的价格需要确认",
    "BOL": "提单：",
    "wizard.cargo.form.insurance.interest": "商品类型",
    "crud.field.externalAPI.title": "External API",
    "indicator.pointOfDestination.arrival": "Point Of Destination, arrival",
    "partner.userInfo.form.middleName.placeholder": "输入中间名",
    "site.footer.subscribe.title": "如果想收到关于亚高拉物流开发的最新消息，请在下面注册您邮箱地址",
    "indicator.pointOfArrival.arrival": "Point Of Arrival, arrival",
    "reports.barChart.COUNTRY": "国家",
    "detailsOfCalculation.ContainerType.Label": "容器类型",
    "orderForm.consignee": "收货人：",
    "page.notFound.tittle": "找不到页面",
    "form.wizard.delivery": "送货地址",
    "crud.field.newVersion.title": "新值",
    "deliveryOrders.orderInfo.title": "订单信息",
    "deliveryOrders.status.STANDBY": "支持",
    "common.documents.order.title": "订单的所有文件列表",
    "modal.contactUs.name": "姓名",
    "register.welcome": "注册",
    "crud.field.nds.notInclude": "不包括",
    "partner.rates.plato": "俄罗斯公路税",
    "operatorAdmin.directories.languages": "语言",
    "form.field.email.placeholder": "输入电子邮件",
    "constants.charging.betweenCities": "城市之间",
    "detailsOfCalculation.filters.error.text": "发生错误，请更改搜索参数",
    "wizard.direction.title": "进行计算",
    "customsOfCargo.fields.quantity": "数量",
    "protocol.modal.btnAgree": "接受",
    "crudForm.field.franchiseType": "特许经营类型",
    "crudForm.field.airBillRate": "每个空运提单的价格",
    "circular.dependence": "You can't link an element to itself",
    "user.sentRestorePassword": "密码恢复说明已通过您的电子邮件发送",
    "orderForm.numberOfFirstContainer": "提单中第一个容器的编号：",
    "detailsOfCalculation.tryTo": "尝试",
    "contact.shipper": "托运人",
    "form.field.exporter": "出口商",
    "crud.filter.showExpired": "显示已过期",
    "crudForm.field.file": "文件",
    "circle.dependence": "您不能将元素链接到自身",
    "partner.generalInformation.form.field.phone.placeholder": "输入电话",
    "templates.documents.edit.form.header.edit": "编辑模板",
    "user.passwordDontMatch": "输入的密码不匹配",
    "indicator.pointOfPickup.load.plan": "Point Of Pickup, load (plan)",
    "crud.field.dateFrom.title": "日期从",
    "constants.routeType.localDeparture": "本地（出发）",
    "logistBlock.cargoTracking": "货物追踪",
    "partner.generalInformation.form.field.phone": "电话",
    "detailsOfCalculation.orderTable.amount": "数量",
    "crud.edit.bulkOperation.element": "el.",
    "crud.label.yes": "是",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.fifo.container.40HC": "40HC FIFO 货柜的成本无效。Row {0}, column {1}.",
    "operatorAdmin.organizations": "公司",
    "crudForm.field.percent": "百分比",
    "form.field.commission.container.from": "来自集货柜数量",
    "form.order.internalId": "货物参考",
    "confirm.regenerate": "发送新代码",
    "templates.documents.edit.form.cancel": "取消",
    "form.field.cost": "成本",
    "crud.field.forwarderId.title": "货代",
    "partner.geography.arrivalRegions": "目的地国家",
    "deliveryOrders.orderInfo.email": "电子邮件",
    "crud.field.createOrderDate.title": "计算日期",
    "detailsOfCalculation.hourRP": "小时",
    "deliveryOrders.cancel": "取消",
    "crudForm.dictionary.field.coordinates.longitude.placeholder": "经度",
    "orderList.NumberColumnTitle": "号码",
    "crudForm.field.period.on.6m": "半年",
    "crud.filter.exclude": "排除",
    "crudForm.dictionary.field.organizationProfiles": "公司简介",
    "importTranslates.notSelect": "不选择",
    "deliveryOrders.orderParameters": "订单参数",
    "crud.field.country.title": "国家",
    "partner.commission": "货运代理的佣金",
    "crud.field.cargoType.title": "货物类型",
    "calculationUpdate.fail": "系统无法使用之前应用的价格来更新计算，麻烦您重新计算这条路线。",
    "logistBlock.cargoReadyDate": "货物准备日期",
    "reports.types.POPULAR_DESTINATIONS_CARGO": "热门目的地（按货物类型）",
    "reports.barChart.SEA_FREIGHT_TARIFF": "海运价格",
    "RAILWAY_HUB": "火车站",
    "crud.field.generalCargo.title": "拼箱/零担货物",
    "logistBlock.invoiceDate": "发票日期",
    "crud.field.historyBillingList.crud.title": "当前订阅计划",
    "crudForm.field.forKg": "每公斤",
    "LogisticDocType.INVOICE": "发票",
    "page.expiredTariffs.airport-services": "机场费用",
    "orderForm.deleteSide": "删除",
    "form.field.typeFrom": "出发点类型",
    "constants.PAID": "Paid",
    "notifications.type.ExpiredCalculation.message": "您保存的计算 № order.Id 已过时。",
    "detailsOfCalculation.cargoIsOverSize.top": "您请求中指定的货物尺寸不适合这条路线上的常规拖车。",
    "modal.changePassword.password": "输入新密码",
    "confirm.modal.title": "确认码",
    "orderList.PriceColumnTitle": "价格",
    "constants.charging.hourly": "每小时",
    "partner.services.serviceTypes.services": "服务",
    "crudForm.button.save": "保存",
    "wizard.field.containersNumber.placeholder": "输入货柜数量",
    "crud.field.container.title": "货柜",
    "indicator.pointOfOrigin.departure.plan": "Point Of Origin, departure (plan)",
    "form.field.cost.placeholder": "输入成本",
    "detailsOfCalculation.calculationInfo": "价格包括：",
    "crudForm.field.AIR_ADR": "機場⇄地址",
    "importTranslates.name.zh": "姓名 (ZH)",
    "crud.field.executionTime.second.title": "处理时间",
    "crud.field.phone.title": "电话",
    "subscription.request.status.NEW": "新",
    "partner.generalInformation.form.field.legalAddress": "法定地址",
    "general.cargo.compound.containerBtn": "整箱",
    "importTranslates.btn.wait": "等待...",
    "crudForm.field.relativeRate": "比率，(%)",
    "crudForm.field.transitHub": "交通枢纽",
    "ratingReport.modal.title": "用户的详细报告:",
    "crud.field.error.geoObject.wrongLatitude": "纬度应在 [-90, 90] 范围内",
    "wizard.cargo.form.weight.placeholder.LB": "磅",
    "crud.field.latitude.title": "纬度",
    "subscription.history.date": "订阅日期",
    "partner.rates.general": "拼箱货",
    "partner.generalInformation.form.title": "公司信息",
    "ratingReport.generateReport": "生成报告",
    "wizard.customsClearance.noTariff": "无法计算这条车道的清关费用",
    "detailsOfCalculation.orderTable.to": "收货地",
    "crud.field.numberAccount.title": "Invoice number",
    "crud.field.resetUsageLimitBtn.title": "重置",
    "crudForm.field.period.on.3m": "三个月",
    "errorLimit": "很抱歉，您的订阅计划中没有可用的报价。",
    "crud.uploadFromExcel.example": "下载模版",
    "crud.field.charging.title": "计费",
    "orderForm.orderName": "预订",
    "crud.field.commentDescription.title": "Description",
    "confirm.already.done": "您已经确认您的注册...",
    "order.location.address.required": "这是一个必填字段",
    "detailsOfCalculation.callTheManager": "请联系我们",
    "wizard.cargo.form.weight.placeholder.KG": "公斤",
    "form.wizard.title.to": "收货地",
    "detailsOfCalculation.integrator": "积分器",
    "crudForm.bulkOperations.field.forItems": "应用",
    "crudForm.field.port": "港口",
    "detailsOfCalculation.hourMn": "小时",
    "crud.field.toHub.name.title": "到枢纽",
    "crud.disclaimers.vehicle.conditionText": "免责声明",
    "page.expiredTariffs.sea": "海运",
    "crudForm.field.period.on.2m": "两个月",
    "common.documents.file": "附加文件",
    "crud.field.transitHub": "Transit hub",
    "OF_ORIGIN": "来源的",
    "crud.field.lastCalculationDateTime.title": "最后计算时间",
    "article.more.than.cargo": "用于计算海关成本和运输成本的总重量应该相互匹配 ",
    "partner.rates.container": "整箱货",
    "operatorAdmin.directories.chatbot": "聊天机器人",
    "subscription.chooseTariff.btn": "选择订阅",
    "crudForm.field.PORT_RAILWAY": "港口⇄火車站",
    "crud.disclaimers.vehicle.countryName": "国家",
    "partner.bankDetails.form.field.bik": "BIK",
    "orderForm.modal.cargoWeight": "货物重量：",
    "form.field.private.placeholder": "我在此确认协议",
    "common.roleIsUnknown": "-",
    "partner.rates.vehicle": "车辆",
    "deliveryOrders.status.CUSTOMS_CLEARANCE": "报关",
    "crudForm.field.airTransportation.transitTime": "运输时间",
    "ADDRESS": "城市",
    "crudForm.field.period.on.1m": "一个月",
    "constants.month.january": "一月",
    "common.documents.creationDate": "创建日期",
    "templates.documents.edit.form.alert.exist": "已经为这些参数创建了一个模板。",
    "crud.field.enabled.title": "模式",
    "crudForm.field.ldmValue": "LDM 值",
    "crudForm.value.placeholder": "值",
    "crud.field.type.title": "类型",
    "form.field.bothSides": "往返",
    "crud.field.vehicleRates.title": "车辆",
    "customsOfCargo.remark": "计算仅供参考",
    "crudForm.field.status.text": "申请状态",
    "form.field.commission.road_transportation.menu": "陆运运输公司",
    "detailsOfCalculation.insurance.franchise": "特许经营",
    "tracking.fields.error.fact": "填写事实日期",
    "detailsOfCalculation.orderTable.vehicle.bodyType": "拖车类型",
    "constants.routeType.export": "导出",
    "common.copy.error": "复制数据失败",
    "LogisticDocType.ANOTHER": "其它",
    "operatorAdmin.directories.customsTerminals": "海关码头",
    "rates.error.accessDenied": "The organization is not authorised to create such tariff",
    "crud.field.fromHub.name.title": "来自枢纽",
    "common.invalidZipCodes": "邮政编码无效",
    "indicator.pointOfPickup.departure.fact": "Point Of Pickup, departure (fact)",
    "partner.generalInformation.form.field.profileStatus.NEW": "新",
    "deliveryOrders.status.IN_TRANSIT": "在途中",
    "page.expiredTariffs.railway-transportation": "铁路运费",
    "clientErrorMsg.disclaimer.exists": "这个解说词已经存在。",
    "crud.field.confirmed.title": "已确认",
    "form.field.locationType": "位置类型",
    "crud.field.rates.title": "货物价值的%",
    "common.documents.error.id.none": "未找到指定的预订编号",
    "partner.geography.geoTypes.regionOfWorld": "大陆",
    "crud.edit.downloadCSV": "下载所有关税",
    "constants.month.june": "六月",
    "report.unit.m": "m",
    "crud.field.station.title": "火车站",
    "operatorAdmin.directories.airs": "飞机",
    "crud.field.transactionId.title": "合约编号",
    "crud.field.error.airport.airportTariffExists": "无法删除机场，因为机场费用已经上传",
    "partner.rates.portServices": "港口费用",
    "constants.ordersSortFilterCurrency.cny": "人民币",
    "detailsOfCalculation.filters.save": "应用",
    "crudForm.field.commissionIncluded": "启用",
    "crud.field.sellerRewardMin.title": "最低卖家费用",
    "constants.surchargeTypes.onCargo": "货物",
    "crudForm.field.minTimeForTransport": "最短时间，（小时）",
    "orderForm.warning.notifyPartyMessage": "添加通知方",
    "operatorAdmin.directories.geography": "地理",
    "detailsOfCalculation.departureDate": "出发日期",
    "deliveryOrders.orderList.headerTable.internalId": "货物参考",
    "constants.month.july": "七月",
    "partner.geography.geoTypes.specialRegion": "特殊区域",
    "crudForm.field.IDschedule.placeholder": "输入 ID 时间表",
    "form.field.commission.general_cargo.to": "W/m 到",
    "indicator.pointOfDestination.arrival.plan": "Point Of Destination, arrival (plan)",
    "crud.field.error.seaPort.portCantBeTerminal": "一个带有港口码头不能是另一个码头的港口",
    "templates.documents.edit.form.default": "默认模板",
    "form.address.defaultPlaceholder": "输入城市名称",
    "crud.field.error.geoObject.parentGeoObjectExists": "无法删除父地理对象",
    "crud.field.password.title": "密码",
    "deliveryOrders.totalCost": "成本",
    "modal.contactUs.phone": "电话",
    "partner.rates.air": "空运价格",
    "deliveryOrders.orderList.status.bookingReceived": "预订已收到",
    "crudForm.field.charging": "计费类型",
    "crudForm.field.minCostForTransport": "最低运价费",
    "form.field.typeTo": "到达点类型",
    "detailsOfCalculation.transportationParameters": "总重量和尺寸",
    "crud.field.personName.title": "人名",
    "crudForm.field.common.documents.typeOfDocument": "文档类型",
    "documents.email.success": "文档已通过电子邮件成功发送",
    "crud.field.error.railway.railwayStationTariffExists": "无法删除火车站，因为它的铁运价格已经上传到系统中",
    "crudForm.field.costForHour": "每小时成本",
    "constants.customHouse.inPort": "卸货港清关",
    "orderForm.numberOfContainers": "货柜：",
    "crudForm.dictionary.btn.addHatch": "添加隔间",
    "wizard.field.containersOverweight": "超重",
    "crud.field.description.title": "描述",
    "crud.filter.selectFilter": "选择过滤器...",
    "partner.generalInformation.form.updateSuccess": "数据已成功保存",
    "modal.changePassword.passwordConfirmation.placeholder": "输入新密码",
    "crudForm.field.hub": "枢纽",
    "wizard.parameters.title": "参数",
    "operatorAdmin.customsCalculator": "海关计算器（俄罗斯）",
    "partner.users": "用户",
    "form.field.lastName.placeholder": "输入您的姓",
    "crudForm.field.customsClearance.included": "包含在价格里",
    "page.expiredTariffs.port-services": "港口费用",
    "importTranslates.alertNotSelectCondition": "选择具有条件值的列",
    "page.expiredTariffs.freight-surcharges": "运输附加费",
    "modal.orderInfo.error": "错误",
    "crudForm.field.interestType": "货物类型",
    "trunking.plato.saveBtn": "保存",
    "site.header.phone": "电话",
    "crud.field.relativeRate.title": "比率, (%)",
    "detailsOfCalculation.from": "来自",
    "indicator.pointOfOrigin.arrival.plan": "Point Of Origin, arrival (plan)",
    "importTranslates.name.ru": "姓名 (RU)",
    "partner.geography.form.country": "国家",
    "customsOfCargo.form.addArticles": "添加货物",
    "reportResource.report.countFail": "计算失败次数",
    "crud.form.edit.title": "编辑条目",
    "deliveryOrders.orderBlock.orderActions.editLogistic": "跟踪",
    "crud.field.allCountries.title": "所有国家",
    "pallet.too.many.directions": "文件包含太多车道",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.port.from.empty": "出发港代码无效。Row {0}, column {1}.。",
    "common.roleAdmin": "公司管理员",
    "crudForm.dictionary.field.maxFloorPressure": "允许的地板压力，(kgf/m2)",
    "TRANSPORT_HUB": "点",
    "detailsOfCalculation.askInputdetailOfOrder": "如果您可以填写下面的字段，请填写，如果不能，我们将在收到您通过电子邮件发送的信息后尽快为您填写。您的数据将保存在系统中，以避免将来发货时重复。",
    "constants.typeOfCargo.fcl": "整箱FCL",
    "crudForm.field.weightFrom": "从（公斤）",
    "crud.field.price.title": "行情",
    "toHomepage": "到主页",
    "form.wizard.title.customsOfCargo": "清关点",
    "confirmationCode.notFound": "Confirmation code not found",
    "subscription.tariff.create.modal.title": "选择订阅选项",
    "modal.orderInfo.toLocation": "到位置",
    "deliveryOrders.order.commonInfoTitle": "一般信息",
    "form.order.notifyParty": "通知方",
    "crud.field.generalCargoRate.title": "小于集装箱/卡车装载的货物",
    "crud.field.limitCalculations.title": "引用次数",
    "common.info": "信息",
    "constants.modifier.public": "公开",
    "detailsOfCalculation.alert.invalid": "计算无效，请联系我们",
    "crudForm.field.forBL": "每提单",
    "crud.field.arrivalDataNotFill": "收货人资料未填写",
    "wizard.insuranceForm.include": "包括",
    "constants.month.september": "九月",
    "form.order.title": "预订",
    "crud.field.counterUsePartnersApi.title": "外部合作伙伴（查询/收到价格）",
    "importTranslates.resultCountSuccess": "导入成功：",
    "crud.field.error.geoObject.seaportExists": "无法删除地理对象，因为在系统中为它输入了火车站",
    "site.footer.subscribe.error": "错误。请稍后再试",
    "operatorAdmin.directories.seaConditions": "海上运输条件",
    "passwordRecovery.title": "密码恢复",
    "partner.bankDetails.form.field.ogrn.placeholder": "Enter Primary State Registration Number",
    "common.currency": "货币",
    "wizard.cargo.form.size.height.placeholder": "H",
    "passwordRecovery.hint": "忘记密码？",
    "operatorAdmin.personalOrders": "预订",
    "form.field.minKM": "最小距离",
    "site.leftMenu.link.personalArea": "个人区域",
    "crud.field.conditions.title": "条件",
    "form.field.terminal": "码头",
    "operatorAdmin.directories.typesOfGeneralCargo": "箱子/拖板",
    "deliveryOrders.orderInfo.phone": "电话",
    "crudForm.field.ADR_ADR": "地址⇄地址",
    "page.accessDenied.prevLinkText": "返回上一页",
    "customsOfCargo.fields.currency": "货币",
    "LogisticDocType.BILL": "提单/空运提单",
    "orderList.companyName": "公司名称",
    "crudForm.field.thresholdWeight": "毛重高达",
    "partner.rates.trunking.international": "国际货运费",
    "customsOfCargo.fields.incoterms": "国际贸易术语",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.fifo.containers": "没有运输成本。字符串 {0}。",
    "detailsOfCalculation.orderTable.kg": "公斤个",
    "wizard.directionForm.railway": "火车站",
    "trunking.form.counterFirstString": "上传成功",
    "crud.remove.confirm.text": "您确定要删除所选项目吗？",
    "crudForm.dictionary.field.translate.placeholder": "输入翻译",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.sealine.not.found": "系统中没有这样的航运公司。Row {0}, column {1}.ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.transitTime=无效的运输时间。Row {0}, column {1}.",
    "partner.bankDetails.form.field.corrAccount": "Corresponding account number",
    "subscription.tariff.create.request": "申请",
    "form.order.email": "邮件地址",
    "detailsOfCalculation.orderCreated.header": "您的预订已创建并保存在我们的系统中。",
    "crud.field.forwarder.title": "货代",
    "crudForm.field.generalCargoUnit": "单位",
    "crud.field.destinationPort.title": "目的港",
    "common.documents.attachedDocumentsTitle": "附加文件",
    "crudForm.dictionary.field.id": "ID",
    "crud.field.historyBillingList.title": "订阅记录",
    "crud.field.portType.title": "类型",
    "crudForm.field.region": "区域",
    "crudForm.field.type": "服务类型",
    "subscription.switchToFree.btn.text": "切换到免费订阅",
    "popupCreateOrder.header": "请注意！",
    "wizard.subscribe.subscribeBtnText": "订阅",
    "deliveryOrders.confirmOfCancelOrder": "您确定要取消此订单吗？",
    "wizard.cargo.form.palletTypes": "托盘",
    "common.documents.removeActionTitle": "移除",
    "report.cargo.container": "货柜",
    "constants.placeTypes.airportHub": "机场",
    "detailsOfCalculation.successOrder.btnOk": "转到订单",
    "orderForm.arrivalDate": "到达日期：",
    "crud.uploadFromExcel.example.header": "警告！",
    "crudForm.field.work": "有效",
    "partner.generalInformation.form.field.conversionPercent.placeholder": "输入转化百分比",
    "deliveryOrders.orderBlock.orderActions.edit": "编辑",
    "crud.field.organization.name.title": "公司名称",
    "crudForm.field.cost": "价格",
    "client.successUpdate": "客户的数据已更新",
    "crud.field.error.geoObject.airportExists": "无法删除地理对象，因为在系统中为它输入了海港",
    "tracking.fields.error.update": "保存错误",
    "crud.field.file.title": "文件",
    "wizard.cargo.form.box.title": "箱子/拖板",
    "crud.field.seaLineOrConsolidator.title": "装运公司/共同装运公司",
    "site.copyright": "保留所有权利",
    "crudForm.field.from": "有效自",
    "common.siteUsesCookie": "亚高拉物流使用小型文本文件使网站更方便。如果您继续使用本网站，您将自动接受本政策。",
    "wizard.cargo.form.size.width": "宽度",
    "partner.freightSurcharges": "海运附加费",
    "subscription.history.idContract": "合约编号",
    "crud.filter.userFilter": "自定义过滤器",
    "crud.field.railwayCompany.title": "铁路运营商",
    "form.field.TNVD": "HS 编码",
    "operatorAdmin.directories.lines": "运输线路",
    "common.required": "这是一个必填字段",
    "crud.field.usageLimit.title": "请求数量(已使用/总数)",
    "role.partner": "合作伙伴",
    "crud.field.middleName.title": "中间名",
    "deliveryOrders.mainInfo.comment": "评论",
    "modal.currentUserInfo": "用户资料",
    "deliveryOrders.calculationInfo": "成本包括：",
    "crud.field.maxWeight.value.title": "最大重量",
    "crud.field.departureData.title": "托运人",
    "container.oversized.cargo": "超大/超重货物 –不适合标准设备 – 我们的服务部门会尽快与您联系以讨论最佳选择。 ",
    "form.error.commission.minComissionInvalid": "最低货代的佣金值不能超过最高1",
    "form.field.general": "普通货物",
    "modal.contactUs.question": "你的问题",
    "common.field.before": "到",
    "crud.field.code.title": "代码",
    "calculations-list.guest.result.registrationMsg": "Please, register for the best freight rates.",
    "form.field.commission.integrator.menu": "积分器",
    "form.field.organization.legalAddress": "注册地址",
    "crud.field.maxPalletCount.title": "最大托盘计数",
    "templates.documents.type.orderkp": "商业报价",
    "CMR_WITHOUT_SYMBOL": "CMR",
    "partner.geography.departureRegions": "发货国家",
    "crudForm.field.nds.placeholder": "输入增值税",
    "form.field.commission.general_cargo.price": "对于 w/m",
    "form.field.companyName": "公司名称",
    "form.field.cargoName": "运输名称",
    "partner.bankDetails.form.field.kpp": "RRC (registration reason code)",
    "deliveryOrders.orderDate": "订单日期",
    "person.calculationInfo.invalid": "计算无效，请点击图标更新",
    "constants.typeOfCargoFull.fcl": "整箱FCL",
    "crud.field.conversionPercent.title": "销售利润率",
    "constants.surchargeTypes.onFreight": "运费",
    "service.dataBaseIsUnavailable": "数据库暂时不可用",
    "crudForm.field.airline": "航空公司",
    "crudForm.field.weightLimit": "最高重量",
    "templates.documents.btn.downloadTestPdf": "以 PDF 格式查看",
    "form.field.organization.name.placeholder": "输入姓名",
    "dialog.confirm.yes": "是",
    "report.vehicle.refrigerator": "冰箱",
    "person.button.enter": "个人区域",
    "trunking.form.uploadDesc": "文件必须是 .xlsx 格式",
    "RAILWAY_NUMBER": "铁路账单：",
    "reports.barChart.SPECIAL_REGION": "特殊区域",
    "deliveryOrders.orderList.status.bookingConfirmed": "发货人确认的预订",
    "logistBlock.deliveryToRecipientDate": "交付给收件人的日期",
    "deliveryOrders.listItem.loadingDate": "加载日期",
    "common.documents.uploadActionTitle": "上传",
    "common.invalidSize": "值太长",
    "crud.field.roles.title": "角色",
    "crudForm.field.zipCodes": "邮政编码",
    "deliveryOrders.orderInfo.contactPerson": "联系人",
    "importTranslates.column": "柱子 №",
    "crud.field.consolidatedCargo.title": "拼箱",
    "mainMenu.subscriptions.userRequests": "客户端应用程序",
    "crud.field.coordinates.title": "纬度/经度",
    "wizard.directionForm.seaPort": "海港",
    "partner.geography.geoTypes.region": "区域",
    "common.add": "添加",
    "constants.insuranceTransportType.air": "阿维亚",
    "indicator.pointOfPickup.ready.fact": "Point Of Pickup, ready (fact)",
    "crudForm.field.franchise": "特许经营权",
    "form.order.user.phone": "您的电话号码",
    "form.wizard.summary.title": "带有货物参数表单",
    "crud.field.nextBillingDate.title": "下次付款日期",
    "order.createNewManual.detailsOrder.title": "Booking Details",
    "detailsOfCalculation.fields.trunking": "货运",
    "crud.disclaimers.airline.airportName": "机场",
    "detailsOfCalculation.transitTimeZero": "24小时之内",
    "crud.field.lastName.title": "姓氏",
    "importTranslates.columnCondition": "条件值",
    "order.customs.already.exists": "该货物的海关支付计算已经可用",
    "crud.field.currency.title": "货币",
    "templates.documents.table.headers.type": "模板类型",
    "constants.charging.fromAirport": "来自/到机场",
    "operatorAdmin.directories": "目录",
    "detailsOfCalculation.fields.priceIncludingVat": "Price including VAT",
    "form.field.cargoName.placeholder": "输入货物名称",
    "crud.field.arrivalPlace.button.delete": "删除收货人详细信息",
    "detailsOfCalculation.callManager.title": "发送请求",
    "ConfirmationCode.notFound": "未找到确认码",
    "orderForm.downloadFrom": "从模板下载",
    "general.cargo.compound.to.container.40": "对于这种数量的货物，使用 20' 整箱 (FCL) 更具成本效益。您要更改为 FCL 吗？",
    "orderForm.cargoWeight": "总重量：",
    "crudForm.field.portsOfArrival": "收货地",
    "crudForm.field.commissionNotIncluded": "未启用",
    "crud.tab.organization.contact": "Full Contact Details",
    "detailsOfCalculation.successOrder.btnClose": "关闭",
    "tracking.fields.events": "事件",
    "report.transportation.rail": "铁运",
    "crudForm.dictionary.field.kind_containerType.placeholder": "货柜种类",
    "deliveryOrders.listItem.totalPrice": "价格",
    "detailsOfCalculation.orderTable.from": "发货地",
    "wizard.button.search": "搜索",
    "crud.field.toLocation.title": "到",
    "crudForm.field.intraportForwarding.addRange": "添加范围",
    "crud.field.contactPerson.title": "联系人",
    "passwordRecovery.setNewPassword": "设置新密码",
    "importTranslates.typeCondtion": "类型条件",
    "crudForm.field.airTransportation.addRange": "添加范围",
    "crud.field.documentAttachments.title": "Attached documents",
    "site.header.dropdown.edit": "编辑",
    "form.order.dateReady": "货物准备日期",
    "subscription.request.status.DECLINE": "拒绝",
    "RAILWAY_WITHOUT_SYMBOL": "铁路账单",
    "detailsOfCalculation.orderTable.weight": "重量",
    "partner.bankDetails.form.field.kpp.placeholder": "Enter RRC (registration reason code)",
    "wizard.wrongDirection": "通过这条路线的价格目前无法在线获取",
    "modal.orderInfo.container": "货柜",
    "partner.title": "公司信息",
    "crud.field.cost.title": "成本",
    "form.wizard.seo.description": " 获取即时报价、比较和在线预订！",
    "person.detailsOfCalculation": "计算详情",
    "crud.field.periodEnd.title": "Period (until)",
    "deliveryOrders.orderList.status.delivered": "已交付",
    "form.field.commission.addStr.private": "私人范围",
    "page.accessDenied.loginLinkText": "进入登录页面",
    "detailsOfCalculation.filters": "筛选",
    "acts.page.title": "Invoices and Statements",
    "crud.field.countryId.title": "国家",
    "confirm.success.text": "注册已成功完成！",
    "crudForm.field.commentDescription.placeholder": "Description",
    "detailsOfCalculation.fields.total": "总计",
    "templates.documents.page.title": "文档模板",
    "partner.rates.pallets": "托盘",
    "deliveryOrders.singleOrderTitle": "送货单",
    "logistBlock.invoice": "付款发票",
    "OF_ARRIVAL": "о 到达的",
    "crudForm.field.minPrice": "最低价格",
    "general.cargo.compound.to.container.20": "对于这种数量的货物，使用 40' 整箱(FCL) 更具成本效益。你是想改成整箱还是留成拼箱？",
    "role.client": "客户",
    "crud.field.costCargo.title": "价格",
    "constants.routeType.localArrival": "本地（到达）",
    "crud.filter.active": "活动",
    "crudForm.field.transitSurcharge": "过境附加费",
    "crud.field.error.geoObject.transportHubsExists": "无法删除地理对象，因为系统中为它输入了枢纽",
    "crud.field.billingDate.title": "订阅日期",
    "dictionary.translation.noSuchField": "目录中不存在字段",
    "crud.field.seaPortId.title": "港口",
    "crud.field.error.airport.airTariffExists": "无法删除机场，因为机场费用已经上传",
    "deliveryOrders.orderId": "订单号",
    "waiting.text": "等待",
    "crud.field.externalAPIAgora.title": "AGORA API",
    "crud.tab.mainParams": "基本信息",
    "AWB": "空运提单：",
    "customsOfCargo.fields.name": "代码/名称",
    "organizationsProfiles.approveProfileConfirm": "您确定要确认配置文件吗？",
    "reportResource.report.countSuccess": "计算成功次数",
    "form.order.truckCount": "卡车数量",
    "crudForm.dictionary.field.translate": "翻译",
    "importTranslates.resultCountError": "导入错误：",
    "common.close": "关闭",
    "crudForm.default.title": "表格",
    "crudForm.field.IDschedule": "ID 时间表",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.port.to.empty": "到达港代码无效。Row {0}, column {1}.",
    "subscription.page.config.resetFree": "免费报价计数器的间隔时间（免费订阅）",
    "partner.bankDetails.form.field.fullName": "Name of your legal entity",
    "crud.field.billRate.title": "提单成本",
    "partner.generalInformation.form.field.conversionPercent": "转化百分比",
    "reports.types.USER_COUNT": "注册用户数",
    "trunking.form.downloadTitle": "查看最近上传的资费",
    "crudForm.bulkOperations.field.filter": "对于所有过滤项目",
    "reports.types.POPULAR_DESTINATIONS_TREND_CARGO": "热门目的地（相对于上期，按货物类型）",
    "crudForm.field.airports": "机场",
    "subscription.tariff.period": "/月",
    "crud.field.airline.title": "航空公司",
    "crud.field.geoCoordinates.title": "纬度/经度",
    "widget.completed": "完成",
    "report.field.btn.search": "显示",
    "reports.types.ORDER_COUNT": "请求计算数量",
    "constants.vehicleDistanceRange.OVER_100": ">100 公里",
    "crud.field.email.title": "电子邮件",
    "user.notFound": "未找到用户",
    "crud.field.requestGroup.id.title": "订单号",
    "subscription.modal.add.btn.label": "订阅",
    "common.documents.renameActionTitle": "改名",
    "constants.typeOfCargo.lcl": "拼箱LCL",
    "crudForm.field.intraportForwarding.placeholder": "输入数据",
    "partner.userInfo.title": "合作伙伴信息",
    "report.barChart.CITY": "城市",
    "crud.field.regions.title": "区域",
    "orderForm.addSide": "添加通知方",
    "crud.field.organizationId.title": "公司名称",
    "crudForm.dictionary.field.maxLoad": "极限载荷，（公斤）",
    "wizard.cargo.shipmentTotal": "装运总量",
    "crud.disclaimers.airline.airlineName": "航空公司",
    "indicator.invoicePaymentDate": "Invoice payment date",
    "detailsOfCalculation.detailInformation": "详细信息",
    "crud.disclaimers.vehicle.cityFromName": "来自",
    "form.field.enter.placeholder": "输入数据",
    "crudForm.field.accessLevel": "访问级别",
    "detailsOfCalculation.fields.service": "服务",
    "wizard.cargo.totalWeight": "总重量",
    "importTranslates.name.en": "姓名 (EN)",
    "notifications.title": "通知",
    "deliveryOrders.status.NEW": "新的",
    "deliveryOrders.orderBlock.orderActions.changeOwner": "更改所主",
    "templates.documents.alert.beforeSave": "为确保文件正确，请在保存前查看模板，方法是单击：",
    "importTranslates.btn.import": "进口",
    "crudForm.error.tariff.invalidDateFrom": "关税不能在其开始有效期之前到期",
    "crudForm.field.pricePerCbm": "每立方米价格",
    "subscription.history.tariff": "订阅",
    "detailsOfCalculation.calculationsIsEmpty.top": "抱歉，虽然我们一直在开发我们系统的地理覆盖范围，但并非每个计算都可以像这种情况一样立即提供。",
    "report.transportation.multimodal.avia": "多式联运（空运+公路）",
    "reportResource.report.countCommon": "请求数量",
    "form.field.country": "国家",
    "trunking.replaceFile": "上传新文件",
    "error.kpp.size.9": "Enter the correct RRC (9 digits)",
    "form.field.commission.general_cargo.from": "W/m 从",
    "form.field.firstName": "名字",
    "detailsOfCalculation.save": "保存",
    "constants.charging.ADR_RAILWAY": "地址⇄火車站",
    "partner.forwarders": "转发器",
    "agreement.title": "Terms of use",
    "report.vehicle.term": "温度",
    "crud.filter.inactive": "无效价格",
    "crudForm.field.calculationType": "报价类型",
    "form.wizard.header": "接收您的报价",
    "modal.orderInfo.result": "结果",
    "login.submit.facebook": "使用 Facebook 登录",
    "customsOfCargo.navTitle": "货物",
    "form.field.commission.port_agent.menu": "港口代理",
    "partner.additions": "附加费",
    "LogisticDocType.CERTIFICATE": "证书",
    "form.order.phone": "电话",
    "partner.rates.billOfLading": "提单",
    "crudForm.field.commissionType": "佣金类型",
    "crudForm.field.rates": "比率",
    "form.order.volume": "货物量",
    "crud.field.ABSOLUTE": "绝对值",
    "crudForm.field.price": "价格",
    "palletTariff.form.addFile": "附加文件",
    "deliveryOrders.listItem.arrivalDate": "到达日期",
    "partner.userInfo.form.firstName": "名字",
    "partner.bankDetails.form.field.bik.placeholder": "Enter BIK number",
    "indicator.pointOfOrigin.arrival": "Point Of Origin, arrival",
    "crud.field.country.from.title": "来自的国家",
    "reports.lineChart.countOfInWork": "进行中",
    "crudForm.field.forCbm": "每立方米",
    "partner.generalInformation": "About Us",
    "constants.vehicleDistanceRange.UNDER_50": "<50 公里",
    "constants.charging.AIR_ADR": "機場⇄地址",
    "crud.disclaimers.vehicle.cityToName": "到",
    "templates.documents.edit.form.btn.file": "附加文件",
    "crud.field.infinite.title": "无限的报价",
    "crud.remove.success": "元素被移除",
    "tracking.fields.eventType": "事件类型",
    "crud.field.error.seaLine.portTariffExists": "无法删除共同装运公司，因为它们的港口费用在系统中 ",
    "form.field.headerModalSettingsScan": "热门目的地搜索选项设置",
    "passwordRecovery.submit": "找回密码",
    "crudForm.field.minSum": "最低金额",
    "reports.field.dateFrom": "来自",
    "wizard.tooltip.selectContainer40": "请查看 40 英尺集装箱选项。",
    "constants.customHouse.inDestinationPlace": "最终目的地的清关",
    "common.documents.title": "系统中所有文档的列表",
    "crud.form.edit.template.title": "编辑模板",
    "crud.field.objectId.title": "对象 ID",
    "constants.vehicleBodyType.term": "热",
    "partner.geography.form.city": "城市",
    "detailsOfCalculation.calculationReportHeader": "计算报告编号",
    "report.field.btn.restart": "重新生成",
    "form.order.shippingDatePlan": "货物准备日期，计划",
    "contacts.shipper": "Shipper",
    "partner.geography": "地理",
    "form.field.commission.load_error": "未指定配置文件",
    "crudForm.field.radiusTariff": "按距离算的价格",
    "importTranslates.importFile": "导入文件",
    "crudForm.field.detention": "免柜天数",
    "partner.rates.zip": "ZIP",
    "crud.filter.nds.label": "增值税（包括/排除）",
    "crudForm.field.period": "期间",
    "form.order.consignee": "收货人",
    "order.createNewManual.title": "Place a new booking",
    "operatorAdmin.directories.countryCategories": "保险。国家类别",
    "crud.disclaimers.railway.railwayCompanyName": "铁路运营商",
    "pallet.not.tariffs": "没有找到价格",
    "indicator.customsClearance.arrival.plan": "Customs Clearance, arrival (plan)",
    "common.save.success": "数据保存成功",
    "crud.field.consolidator.title": "共同装运公司",
    "detailsOfCalculation.download": "下载",
    "disclaimers.title.modal": "附加條款",
    "crudForm.dictionary.field.code_lang.placeholder": "输入语言代码",
    "crudForm.field.commentDescription": "Description",
    "subscription.request.status.PROCESSED": "进行中",
    "orderForm.estPrice": "价格/计划：",
    "crud.field.CONTAINER": "整箱",
    "form.field.user": "用户",
    "LogisticDocType.Offer": "报价",
    "login.welcome": "请登录。",
    "form.field.commission.profile1.menu": "陆运",
    "person.quotation.reference": "报价参考编号",
    "wizard.tooltip.selectContainer20": "请查看 20 英尺集装箱选项。",
    "operatorAdmin.directories.customsClearance": "清关（条件)",
    "report.vehicle.tent": "拉紧衬垫",
    "partner.rates.billOfAir": "航空运单",
    "reports.lineChart.countOfClosed": "关闭",
    "crudForm.field.edit": "编辑",
    "orderForm.modal.numberOfPlaces": "位置数：",
    "operatorAdmin.directories.lineConditions": "班轮条款",
    "common.loading": "加载",
    "constants.typeOfCargoFull.lcl": "拼箱LCL",
    "templates.documents.table.headers.actions": "行动",
    "crud.field.moderationRequired.title": "需要缓和",
    "operatorAdmin.audit": "审计",
    "subscription.request.status.ACCEPT": "批准",
    "crudForm.bulkOperations.form.info.email": "您可以用空格输入多个邮箱地址",
    "excel.tariff.already.exist": "该车道的Excel价格已经存在",
    "crud.field.summary.title": "计算次数",
    "common.documents.addBtnTitle": "添加文档",
    "report.cargo.vehicle": "车辆",
    "common.documents.error.id": "没有指定编号的预订 ",
    "common.greaterThanOne": "值应该大于 1",
    "order.location.airportId.required": "这是必填字段",
    "crud.disclaimers.airline.conditionText": "免责声明",
    "modal.currentUserInfo.success": "配置文件数据已更新",
    "partner.rates.excel.tariffs": "Excel 价格",
    "deliveryOrders.status.COMPLETED": "完全的",
    "crudForm.field.routeType": "路线类型",
    "form.field.importer": "进口商",
    "TTH": "运输文件：",
    "constants.semiannual": "前6个月",
    "crudForm.dictionary.field.code": "代码",
    "partner.rates.intraportForwarding": "在港口处理",
    "deliveryOrders.status.PICKED_UP": "已接",
    "tracking.already.exists": "该货物的追踪已经可用",
    "crudForm.dictionary.field.code_currency": "货币代码",
    "ratingReport.noResult": "没有结果",
    "common.greaterThanZero": "值应该大于 0",
    "indicator.pointOfArrival.arrival.plan": "Point Of Arrival, arrival (plan)",
    "form.field.organization.legalAddress.placeholder": "请输入注册地址",
    "constants.vehicleBodyType.tent": "拉紧衬垫",
    "crud.field.airport.title": "机场",
    "reports.lineChart.count": "Quantity",
    "detailsOfCalculation.alert.containsArtificialCalculation": "引用的某些部分需要澄清。请点击此处了解详情。",
    "detailsOfCalculation.orderDetails.btnName": "附加信息",
    "notifications.type.ExpiredCalculation.title": "计算 № order.Id 过时",
    "constants.ordersSortFilter.bestTime": "交货时间",
    "form.field.includeInCost": "包含在价格里",
    "form.field.phone.placeholder": "请输请入电话号码",
    "crud.disclaimers.railway.cargoTypeName": "货物类型",
    "partner.bankDetails.form.field.bankName": "In the Bank",
    "ordersList.button.getMaxInfo": "以展开形式显示",
    "crud.field.status.title": "状态",
    "reports.barChart.REGION": "Regions",
    "indicator.pointOfPickup.departure": "Point Of Pickup, departure ",
    "detailsOfCalculation.filters.remove.text": "成功的搜索参数已被清除",
    "crud.field.selectTemplate.title": "选择模板",
    "constants.ordersSortFilterCurrency.default": "多种",
    "form.wizard.title.cargo": "货物",
    "userRegistration.uniqueEmail": "电子邮件地址已在系统中注册",
    "person.delivery.status.title": "状态",
    "operatorAdmin.directories.statuses": "状态",
    "constants.vehicleBodyType.refrigerator": "冷藏",
    "common.documents.error.file": "文件大小超过 20 MB",
    "wizard.insuranceForm.value": "价值",
    "crud.field.factAddress.title": "事实地址",
    "orderForm.warning.shipperInfoMessage": "发货人详细信息",
    "passwordRecovery.link": "恢复",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.date.to": "错误的价格到期日。Row {0}, column {1}.",
    "indicator.deliveryToRecipientDate.fact": "Delivery To Recipient (fact)",
    "tracking.fields.place": "城市",
    "partner.rates": "价格",
    "form.field.attachmentDocuments": "Attached documents",
    "operatorAdmin.directories.documentsFlow": "文件流",
    "operatorAdmin.listOfAPIsAgora": "外部API接口 (Agora)",
    "crud.field.resetUsageLimit.title": "重置已使用的请求数",
    "crudForm.dictionary.field.id.placeholder": "ID",
    "reports.lineChart.countOfCanceled": "取消",
    "wizard.cargo.form.vehiclePayload": "拖车类型",
    "detailsOfCalculation.railway_station_departure": "出发站费用",
    "detailsOfCalculation.fields.sellerReward": "卖方的奖励",
    "crudForm.field.schedule": "时间表",
    "templates.documents.table.headers.lang": "语",
    "crud.field.countVariantsCalculations.title": "计算选项数",
    "detailsOfCalculation.calcID": "参考编号",
    "partner.bankDetails.form.field.address": "Legal address",
    "constants.ordersSortFilterCurrency.rub": "俄罗斯卢布",
    "partner.services.serviceTypes.serviceCategories": "服务类别",
    "subscription.main.infinite.text": "无限数量的报价：",
    "crud.field.interestType.title": "货物类型",
    "crud.field.transitTime.title": "预计运输时间",
    "constants.nds.zero": "0",
    "deliveryOrders.listItem.orderNumber": "订单号",
    "crud.field.zipCode.title": "邮政编码",
    "crudForm.field.thresholdPrice": "每公斤成本",
    "customsOfCargo.fields.nds": "增值税，20%",
    "RAILWAY": "铁路账单：",
    "pallet.tariff.not.found.ranges": "无法确定国际运输的范围",
    "crud.field.port.title": "港口",
    "crud.field.uid.title": "用户标识符",
    "crudForm.field.notWork": "无效",
    "form.field.limitDays": "number of days for the report",
    "partner.userInfo.form.email": "邮件地址",
    "crud.uploadFromExcel": "Excel文件上 传",
    "crud.field.languageEmail.title": "电子邮件通知的语言",
    "form.order.bill.cargoDescription": "包裹和货物描述",
    "detailsOfCalculation.insurance.insuranceCost": "保险费",
    "operatorAdmin.directories.logisticDocumentKindTitle": "相关运输文件",
    "dialog.confirm.no": "否，取消",
    "crud.disclaimers.vehicle.chargingName": "计费类型",
    "modal.orderInfo.general": "散柜/散车货物",
    "crud.field.modifier.private": "私有",
    "crud.field.organization.title": "公司名称",
    "common.required.commonRanges": "请为公共费率添加 1 个范围",
    "common.documents.error.fieldsWithoutFile": "填写所有字段",
    "orderForm.trackingNumber": "Tracking number",
    "orderForm.containerNumber.placeholder": "Enter container number",
    "orderForm.aviaNumber.placeholder": "Enter AWB number",
    "orderForm.idContainer": "集装箱编号：",
    "report.barChart.REGION": "区域",
    "radius.delete": "删除",
    "customsOfCargo.form.price.title": "货物清关的总成本",
    "modal.changePassword.oldPassword": "旧密码",
    "tracking.fields.search": "搜索键",
    "constants.month": "月",
    "reports.types.DICTIONARY_COUNT": "系统对象的数量",
    "deliveryOrders.listItem.cargoType": "货物类型",
    "form.field.organizationName": "公司名称",
    "logistBlock.loadingDate": "加载中",
    "confirm.order.success": "您的价格请求已被处理",
    "directionOfTransportation.arrival": "到达",
    "partner.generalInformation.form.field.profileStatus.APPROVED": "已批准",
    "crud.field.countryTo.title": "到",
    "wizard.directionForm.title": "请选择以下选项之一：",
    "constants.routeType.import": "导入",
    "constants.ordersSortFilter.bestPrice": "按价格",
    "operatorAdmin.clients": "客户端",
    "crudForm.bulkOperations.nameCode.addSurcharges": "添加附加费",
    "widget.order.number": "您的订单号",
    "crud.filter.hide": "隐藏",
    "crudForm.field.reverseTariff": "反向价格",
    "trunking.form.counterLastString": "价格",
    "person.calculationInfo.update": "更新",
    "constants.charging.betweenTheCityPerDistance": "城市之间 (每公里)",
    "reports.field.dateTo": "到",
    "deliveryOrders.orderList.status.standby": "待机",
    "user.passwordChanged": "密码修改成功",
    "reports.barChart.CURRENCY": "货币",
    "form.select.defaultPlaceholder": "选择...",
    "form.field.postcode.placeholder": "输入邮政编码",
    "deliveryOrders.orderInfo.address": "地址",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.docdeadline": "提交文件的截止日期不正确。Row {0}, column {1}.",
    "report.lineChart.countOfNew": "新建",
    "ratingReport.noOrderData": "Deleted quotation",
    "report.lineChart.countOfAll": "全部",
    "modal.orderInfo.vehicle": "车辆",
    "person.order.number": "订单号。",
    "crudForm.field.to": "有效于",
    "form.field.shippingDate": "货物准备日期（如果已知）",
    "reports.lineChart.countOfOrder": "请求计算数量",
    "detailsOfCalculation.orderDetails.title": "附加信息",
    "form.wizard.title.cargoItem": "物品",
    "common.roleManager": "公司经理",
    "constants.charging.ADR_ADR": "地址⇄地址",
    "site.user.logoutSuccess": "您已成功登出您的帐户",
    "crud.field.category.title": "类别",
    "operatorAdmin.directories.interestTypes": "保险。货物类别",
    "partner.generalInformation.form.field.legalAddress.placeholder": "输入您的法定地址",
    "crudForm.bulkOperations.error": "错误",
    "crud.field.hatchSize.title": "飞机舱口尺寸",
    "crud.field.enableLimit.title": "包括请求限制",
    "crud.field.timeOfAction": "有效性",
    "operatorAdmin.directories.additions": "附加费",
    "subscription.page.config.enable": "订阅系统状态（禁用/启用）",
    "deliveryOrders.cargoInformation.cargoSizeTitle": "尺寸",
    "crudForm.field.ports": "港口",
    "wizard.customsOfCargo.containerRP": "货柜",
    "deliveryOrders.cargoTrackingTitle": "跟踪",
    "deliveryOrders.orderList.headerTable.totalPrice": "价格",
    "ratingReport.pressBtnForGenerateReport": "点击“生成报告”获取数据",
    "crudForm.field.seaLines": "船运公司",
    "page.expiredTariffs.title": "尊敬的用户您的报价即将到期",
    "reportResource.report.countTariffs": "收到的价格数量",
    "crud.field.firstName.title": "名字",
    "logistBlock.export": "离开目的地港口",
    "importTranslates.select.sheet": "选择工作表",
    "crudForm.dictionary.field.code_lang": "语言代码",
    "service.reloadThePage": "重新加载页面",
    "constants.weightUnits.lb": "lb",
    "crud.field.modifier.public": "公共",
    "crud.field.error.railway.railwayTariffExists": "无法删除火车站，因为火车站收费已上传到系统中",
    "HOME_BOL_WITHOUT_SYMBOL": "货代提单",
    "trunking.international.form.defaultFileName": "国际货运收费关税",
    "register.link.text": "如果您没有帐户",
    "form.order.contacts": "您的联系方式",
    "indicator.receiptOfDocuments": "Point Of Arrival, receipt of documents",
    "crudForm.field.intraportForwarding.container.from": "来自货柜数量",
    "partner.geography.officeAndBranches": "办公室和分支机构",
    "crudForm.field.palletType": "托盘类型",
    "indicator.customsClearance.departure": "Customs Clearance, departure",
    "partner.rates.airportServices": "机场费用",
    "HOME_AWB_WITHOUT_SYMBOL": "货代空运提单",
    "tracking.fields.key": "键",
    "logistBlock.fact": "事实",
    "crudForm.dictionary.field.coordinates.latitude.placeholder": "纬度",
    "crud.field.condition.title": "附加条款的文本",
    "crud.field.error.airport.roadTariffExists": "无法删除机场，因为已经为它上传了航空价格",
    "crudForm.dictionary.field.description": "描述",
    "constants.weightUnits.kg": "kg",
    "detailsOfCalculation.calcFounded": "可用选项：",
    "contacts.confirm.success": "通知方已删除",
    "form.field.seaPort": "选择海港",
    "crudForm.field.common.documents.file": "下载任何格式的新文件，不超过 20 MB",
    "form.field.countryNew": "请选择或者输入国家",
    "partner.generalInformation.form.field.name": "姓名",
    "crud.field.surcharge.code.title": "代码",
    "subscription.modal.add.title": "新订阅申请",
    "reports.lineChart.countOfAuto": "公路运输",
    "HOME_BOL": "货代提单：",
    "crudForm.field.serviceType": "服务类型",
    "form.field.commission.placeholder": "输入数据",
    "wizard.cargo.title": "货物",
    "wizard.cargo.form.containers.title": "货柜",
    "crud.tabs.historyCalculation": "报价记录",
    "pallet.no.identify.direction": "无法定位主要城市",
    "crud.field.limit.title": "请求(每月)",
    "trunking.plato.platoRate": "每公里价格",
    "wizard.subscribe.placeholder": "您的邮箱地址",
    "constants.customHouseAir.inDestinationPlace": "到达机场清关",
    "partner.personalOrders": "预订",
    "profile.welcome.title": "欢迎",
    "crudForm.field.nds": "增值税, (%)",
    "constants.ordersSortFilterCurrency.eur": "欧元",
    "form.field.commission.type": "类型",
    "wizard.cargo.form.containers.add": "添加",
    "form.field.shippingDate.placeholder": "输入发货日期",
    "crud.filter.value": "值",
    "userRegistration.invalidConfirmationCode": "确认码无效",
    "constants.placeTypes.address": "地址",
    "templates.documents.edit.form.alert.nofile": "您必须附加文件才能继续。",
    "crud.field.dateCreated.title": "报价日期",
    "reports.barChart.WORLD_PART": "大陆",
    "crud.field.error.geoObject.wrongLongitude": "经度应该在 [-180, 180] 范围内",
    "detailsOfCalculation.authLink": "请{注册}或{登录}查看详细信息",
    "crudForm.dictionary.field.hatchSize": "平面影线尺寸",
    "constants.tarifications.private": "私有",
    "indicator.receiptOfDocuments.plan": "Point Of Arrival, receipt of documents (plan)",
    "detailsOfCalculation.outdatedCalculationI.temMsg": "价格计算已过时。请更新以便能够预订。",
    "crudForm.field.commission": "包括货运代理的佣金",
    "crud.field.error.wordRegion.organizationExists": "不能删除一个地理区域，因为它用于公司的授权贸易通道",
    "detailsOfCalculation.saved": "已保存",
    "reports.alert.fail": "发生错误",
    "report.transportation.carriage": "（到）",
    "crudForm.bulkOperations.field.selectOperation": "操作",
    "deliveryOrders.orderInfo.shipperAndConsignee": "发货人/收货人",
    "crudForm.field.common.documents.orderId": "订单号",
    "report.unit.tn": "t",
    "deliveryOrders.orderList.headerTable.destinationPoint": "到",
    "site.header.contactUs": "联系我们",
    "report.cargo.bill": "提单",
    "crudForm.dictionary.field.maxWeight": "最大重量",
    "crud.field.internalPartners.title": "内部合作伙伴",
    "crud.field.longitude.title": "经度",
    "crudForm.field.findTimetable": "查找时间表",
    "constants.ordersSortFilterCurrency.usd": "美金",
    "common.btn.ready": "好的",
    "crud.field.billing.title": "计费",
    "wizard.customsOfCargo.containerMn": "货柜",
    "trunking.form.uploadSuccess": "文件上传成功",
    "protocol.agreeMsg": "您已确认您已阅读并接受本协议的所有段落",
    "detailsOfCalculation.orderTable.pcs": "个",
    "partner.users.title": "用户",
    "detailsOfCalculation.fields.amount": "金额",
    "register.client.title": "注册为客户",
    "site.footer.developBy": "开发者",
    "crudForm.dictionary.field.comment": "评论",
    "partner.userInfo.form.lastName.placeholder": "输入姓",
    "reports.barChart.CITY": "Cities",
    "notifications.new": "你有一个新的通知",
    "crud.field.error.seaLine.freightTariffExists": "无法删除航运公司，因为已为其输入运费附加费",
    "confirm.invalid.link": "无效链接，联系管理员。",
    "crudForm.bulkOperations.nameCode.sendEmail": "发送邮件",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.fifo.container.40": "FIFO 货柜 40 的成本无效。Row {0}, column {1}.",
    "page.expiredTariffs.trunking": "卡派报价",
    "detailsOfCalculation.fields.bl": "提单",
    "common.status.completed": "完全的",
    "trunking.form.sendBtn": "上传",
    "crud.tab.organization.bank": "Bank Account Details",
    "form.order.selectDate": "货物准备日期，计划",
    "detailsOfCalculation.fields.priceInducingVat": "含增值税的价格",
    "LogisticDocType.ORDER_TO_LOGISTICIAN": "物流经理的任务",
    "deliveryOrders.orderList.header": "我的订单",
    "common.nextDateScan": "下一次完整统计数据更新的日期",
    "logistBlock.arrivalDate": "到达",
    "crud.field.subscriptionTariffPlan.title": "订阅",
    "crud.field.externalAPI.manage.title": "Manage",
    "deliveryOrders.status.DELIVERED": "发表",
    "partner.bankDetails.form.field.bankName.placeholder": "Enter the name of the bank",
    "indicator.pointOfPickup.load.fact": "Point Of Pickup, load (plan)",
    "documents.replace.denied": "该文档已经被其他用户上传，不能用新的替换它",
    "crud.tab.organization.acts": "Invoices and Statements",
    "crudForm.bulkOperations.nameCode.removeElements": "删除项目",
    "detailsOfCalculation.makeOrder": "下单",
    "crudForm.field.seaLine": "航运公司",
    "crud.field.typeOfCargo.title": "货物类型",
    "customsOfCargo.fields.customsDuty": "关税",
    "crud.field.worldPartId.title": "地理区域",
    "deliveryOrders.cargoTracking.table.billing": "计费",
    "subscription.switchToFree": "确认切换",
    "crudForm.bulkOperations.nameCode.removeSurcharges": "删除附加费",
    "crudForm.dictionary.field.volume": "体积，（立方米）",
    "crud.field.oldVersion.title": "旧值",
    "detailsOfCalculation.fields.currency": "货币",
    "crudForm.field.weightTo": "到（公斤)",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.fifo.container.20": "FIFO 货柜 20 的成本无效。Row {0}, column {1}.",
    "orderForm.editActionTitle": "编辑",
    "crud.label.no": "否",
    "crud.field.user.title": "用户",
    "service.unavailable.text": "服务现在不可用",
    "person.calculations": "我的报价",
    "form.field.middleName.placeholder": "输入中间名",
    "crudForm.field.consolidator": "共同装运公司",
    "common.emailInvalid": "邮箱地址格式无效",
    "crud.field.countries.title": "国家",
    "form.field.languageEmail": "电子邮件通知的语言",
    "constants.month.october": "十月",
    "deliveryOrders.orderList.headerTable.status": "状态",
    "importTranslates.skipRows": "跳过行",
    "modal.manage.externalAPI": "External API Management",
    "crud.field.client.title": "客户",
    "detailsOfCalculation.loader.title": "正在为您查询所有报价结果",
    "operatorAdmin.directories.railwayOperators": "铁路运营商",
    "partner.geography.geoTypes.worldPart": "大陆",
    "templates.documents.edit.form.lang": "模板语言",
    "crudForm.field.airport": "机场",
    "common.field.from": "来自",
    "SEA_PORT": "港口",
    "organizationsProfiles.status.APPROVED": "已批准",
    "crudForm.button.addEmail": "添加电子邮件",
    "detailsOfCalculation.fields.byHour": "每小时",
    "contacts.toNotify": "通知",
    "deliveryOrders.orderList.status.pickedUp": "货物提取",
    "indicator.pointOfOrigin.departure": "Point Of Origin, departure",
    "deliveryOrders.orderList.headerTable.dateReady": "准备日期",
    "crudForm.field.regionAbsent": "缺席",
    "crudForm.field.kg": "公斤",
    "partner.userInfo.form.phone": "电话",
    "constants.placeTypes.airport": "飞机场",
    "common.placeholder": "输入数据",
    "pallet.tariff.already.exist": "该方向的托盘价格已经存在",
    "deliveryOrders.status.CANCELLED": "取消",
    "templates.documents.edit.form.header.new": "新模板",
    "form.field.commission.integrator.title": "货运代理的佣金",
    "form.field.confirmCode.placeholder": "输入代码",
    "indicator.pointOfOrigin.departure.fact": "Point Of Origin, departure (plan)",
    "crudForm.field.transitTime": "预计运输时间",
    "detailsOfCalculation.fields.includeVat": "包括增值税",
    "general.cargo.air.too.big": "具有此类参数的货物尺寸过大，无法空运。请减少货物重量和尺寸或联系我们获取定制报价。",
    "partner.bankDetails.form.field.numberAccount.placeholder": "Provide the account number",
    "reports.alert.success": "操作成功完成",
    "logistBlock.departureDate": "出发",
    "form.field.limitMounthScan": "统计收集期（以月为单位）。",
    "report.transportation.pickup": "（来自）",
    "palletTariff.replaceFile": "上传新文件",
    "reportResource.report.countUseApi": "查询外部API的次数",
    "crud.filter.yes": "是的",
    "detailsOfCalculation.defaultReportHeader": "报告",
    "templates.documents.edit.form.save": "节省",
    "detailsOfCalculation.callManager.alert": "报价需要澄清",
    "crudForm.dictionary.field.allowCreateCalculations4Guest":"Guest Quotations",
    "deliveryOrders.orderBlock.orderActions.moveToCalculation":"View the quotation",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.currency.empty":"Currency is filled incorrectly. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.currency.not.found":"There is no such currency in the system. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.date.from":"Invalid tariff start date. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.date.to":"Invalid tariff end date. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.hub.from.empty":"Invalid departure station. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.hub.from.not.found":"There is no such departure station in the system. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.hub.to.empty":"Invalid arrival station. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.hub.to.not.found":"There is no such arrival station in the system. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.operator.empty":"Invalid railway operator code. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.operator.not.found":"There is no such railway operator in the system. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.transitTime":"The transit time is incorrect. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.fifo.containers":"No cost of transportation. The string {0}.",
    "wizard.cargo.form.size.totalVolume":"总容积",
    "wizard.cargo.form.size.totalWeight":"总重量",
    "wizard.cargo.form.CubVolume.placeholder.M3":"立方米",
    "wizard.cargo.form.CubVolume.placeholder.FT3":"立方英尺",
    "report.unit.m3":"cbm",
    "report.unit.ft3":"su ft",
    "deliveryOrders.cargoInformation.cargoTypeCube":"立方体",
    "crudForm.field.common.documents.logisticDoc.warning":"Prior to uploading a document of this type, fill in the data on the shipper and consignee",
    "uploadFile.error.text":"During the file processing there appeared the following errors:",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.currency.empty":"Currency is filled incorrectly. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.currency.not.found":"There is no such currency in the system. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.date.from":"Invalid tariff start date. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.date.to":"Invalid tariff end date. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.hub.from.empty":"Invalid origin hub. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.hub.from.not.found":"There is no departure hub in the system. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.hub.to.empty":"Invalid arrival hub. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.hub.to.not.found":"There is no arrival hub in the system. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.transitTime":"The transit time is incorrect. Row {0}, column {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.fifo.containers":"No cost of transportation. The string {0}.",
    "crud.field.ownerId.title":"Owner",
    "crud.field.owner.title":"Owner",
    "wizard.directionForm.disabledButtonHint":"选择其他类型的货物",
    "files.type.logo":"Logo",
    "files.page.title":"Files",
    "files.edit.form.header.edit":"Update file",
    "files.edit.form.header.new":"New file",
    "files.edit.form.alert.nofile":"You must attach a file to continue.",
    "files.edit.form.alert.exist":"A file has already been created for these parameters.",
    "files.table.headers.name":"Name",
    "files.table.headers.type":"Type",
    "files.table.headers.actions":"Actions",
    "files.edit.form.btn.file":"Attach file",
    "files.edit.form.nofile":"File not selected",
    "files.edit.form.save":"Save",
    "files.edit.form.cancel":"Cancel",
    "crud.field.enableIntegratorReward.title":"Apply margin rules",
    "crud.field.enableIntegratorRewardAgora.title":"Apply margin rules (Agora API)",
    'crud.field.freight20.title': 'Price freight 20',
    'crud.field.freight40.title': 'Price freight 40',
    'crud.field.freight40HC.title': 'Price freight  40\'HC',
    'common.errorEmptyFields': 'Check that all fields are filled in',

    'filter.calculation.currencies': 'Currencies:',
    'filter.calculation.sort': 'Sort by:',
    'common.addRow': 'Add row',
    'tableComponent.contextMenu.cut': 'Cut',
    'tableComponent.contextMenu.copy': 'Copy',
    'tableComponent.contextMenu.paste': 'Paste',
    'tableComponent.contextMenu.deleteRow': 'Delete',
    'tableComponent.contextMenu.deleteRows': 'Delete rows',
    'tableComponent.contextMenu.insertRowBellow': 'Insert row bellow',
    'tableComponent.contextMenu.duplicateRow': 'Duplicate',
    'tableComponent.contextMenu.duplicateRows': 'Duplicate rows',
    'crud.form.table.add': 'Add from table',
    'crud.form.table.edit': 'Edit like in Excel',
    'common.selectDate': 'Select date',
    'tableComponent.confirm.cancelChages': 'The changes won\'t be saved.',

    'crud.form.add.table.title': 'Tariffs entry',
    'crud.form.edit.table.title': 'Edit tariffs',

    'tracking.fields.error.dateFactError': 'The selected date cannot be earlier than the planned cargo readiness date',
    'tracking.fields.error.datePlanError': 'The selected cargo readiness date cannot be earlier than the order creation date',
    'templates.documents.type.APPLICATION_TRANSPORTATION':'Shipping instruction',
    "crudField.internationalCompany.label":"International",
    "crud.field.countryFromId.title":"Country from",
    "crud.field.countryToId.title":"Country to",
    "htmlEditor.undo":"Undo",
    "htmlEditor.bold":"Bold",
    "htmlEditor.italic":"Italic",
    "htmlEditor.strikeThrough":"Strike through",
    "htmlEditor.link":"Link",
    "htmlEditor.clearFormatting":"Clear formatting",
    "settingsEmail.emailSender":"Email for reply",
    "settingsEmail.nameSender":"Sender's name",
    "settingsEmail.footerRu":"Signature at the bottom of the email (Russian language)",
    "settingsEmail.footerEn":"Signature at the bottom of the email (English language)",
    "settingsEmail.hideAdvancedSettings":"Advanced settings",
    "settingsEmail.showAdvancedSettings":"Advanced settings",
    "settingsEmail.styles":"More styles",
    "settingsEmail.styles.help":"You can find out about supported CSS style properties by the <a href='https://developers.google.com/gmail/design/css#supported_css'>link</a>.",
    "settingsEmail.save":"Save",
    "settingsEmail.sendTestEmail":"Send test email",
    "settingsEmail.showTestEmail":"Preview",
    "crud.tab.organization.emailSettings":"Email settings",
    "settingsEmail.styles.warning":"This section is intended for additional customization of email styles.",
    "crud.field.nameEn.title":"Name (English)",
    "crud.field.city.title":"City",
    "crud.field.origin.title":"Origin",
    "crud.field.product.title":"Product",
    "crud.field.minPrice.title":"Min. cost",
    "crud.field.terminalProcessing.title":"Terminal processing",
    "crudForm.field.terminalProcessing":"Terminal processing",
    "crud.field.surcharges.title":"Surcharges",
    "table.tariffs.fillAuto":"Enter automatically",
    "tableTariffs.enterRates":"To be able to save, enter at least one rate",
    "common.reset":"Reset",
    "common.removeAll":"Clear all",
    "airTariffWeights.modal.title":"Add weights",
    "operatorAdmin.menu.directories.seaConditions":"Sea Freight",
    "operatorAdmin.menu.directories.airlineDisclaimers":"Air Freight",
    "operatorAdmin.menu.directories.railwayDisclaimers":"Railway Transportation",
    "operatorAdmin.menu.directories.vehicleDisclaimers":"Trucking charges",
    "crud.field.maxClients.title":"Max count of clients",
    "users.usageClients":"Existing client users",
    "files.type.logo_inverted":"Logo (menu)",
    "files.type.FAVICON":"Favicon",
    "crud.edit.generateXLSX":"Export to Excel",
    "priceField.service.title":"Service",
    "priceField.typeService":"Service",
    "priceField.vat":"VAT",
    "priceField.cost":"Price",
    "priceField.comment":"Comment",
    "priceField.cargo":"Cargo",
    "priceField.totalAllCargo":"Total for all cargo",
    "priceField.costWithVat":"Price including VAT",
    "priceField.add":"Add service",
    "deliveryOrders.cargoInformation.serviceType":"Services",
    "cargoType.containers":"Containers",
    "cargoType.box":"Boxes",
    "cargoType.pallet":"Pallets",
    "cargoType.cube":"Cubes",
    "cargoType.car":"Vehicle",
    "services.show":"Show",
    "services.hide":"Hide",
    "orderBlock.selectTypeHub":"Transit hub type",
    "orderBlock.selectHub":"Select transit hub",
    "orderBlock.hubNumber":"Transit hub #",
    "orderBlock.addTransitHub":"Add transit hub",
  },
}
