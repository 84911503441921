import React from 'react';
import PropTypes from 'prop-types';

const CustomizedLabel = (props) => {
  const { x, y, stroke, value } = props;

  return <text x={x} y={y} dy={-10} dx={10} fill={stroke} fontSize={14} textAnchor="middle">{value}</text>;
};

CustomizedLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  stroke: PropTypes.string,
  value: PropTypes.number,
};

export default CustomizedLabel;
