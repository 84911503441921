import * as React from 'react'
import {FC, useCallback, useRef} from 'react'
import {useDocumentEventListener} from "react-datasheet-grid/dist/hooks/useDocumentEventListener";
import {FormattedMessage} from "react-intl";

export const defaultRenderItem = (item) => {
    if (item.type === 'CUT') {
        return <div><FormattedMessage id={"tableComponent.contextMenu.cut"}/></div>
    }

    if (item.type === 'COPY') {
        return <div><FormattedMessage id={"tableComponent.contextMenu.copy"}/></div>
    }

    if (item.type === 'PASTE') {
        return <div><FormattedMessage id={"tableComponent.contextMenu.paste"}/></div>
    }

    if (item.type === 'DELETE_ROW') {
        return <div><FormattedMessage id={"tableComponent.contextMenu.deleteRow"}/></div>
    }

    if (item.type === 'DELETE_ROWS') {
        return (
            <div>
                <FormattedMessage id={"tableComponent.contextMenu.deleteRows"}/>{" "}
                <b>{item.fromRow}</b> -> <b>{item.toRow}</b>
            </div>
        )
    }

    if (item.type === 'INSERT_ROW_BELLOW') {
        return <div><FormattedMessage id={"tableComponent.contextMenu.insertRowBellow"}/></div>
    }

    if (item.type === 'DUPLICATE_ROW') {
        return <div><FormattedMessage id={"tableComponent.contextMenu.duplicateRow"}/></div>
    }

    if (item.type === 'DUPLICATE_ROWS') {
        return (
            <div>
                <FormattedMessage id={"tableComponent.contextMenu.duplicateRows"}/>{" "}
                <b>{item.fromRow}</b> -> <b>{item.toRow}</b>
            </div>
        )
    }

    return item.type
}

export const createContextMenuComponent =
    (renderItem) => ({clientX, clientY, items, close}) => {
        const containerRef = useRef(null)

        const onClickOutside = useCallback(
            (event) => {
                const clickInside = containerRef.current.contains(event.target)

                if (!clickInside) {
                    close()
                }
            },
            [close]
        )
        useDocumentEventListener('mousedown', onClickOutside)

        return (
            <div
                className="dsg-context-menu"
                style={{left: clientX + 'px', top: clientY + 'px'}}
                ref={containerRef}
            >
                {items.map((item) => (
                    <div
                        key={item.type}
                        onClick={item.action}
                        className="dsg-context-menu-item"
                    >
                        {renderItem(item)}
                    </div>
                ))}
            </div>
        )
    }

export const ContextMenu = createContextMenuComponent(defaultRenderItem)

ContextMenu.displayName = 'ContextMenu'