/* eslint-disable */
import { action, computed, observable, toJS, runInAction } from 'mobx';
import { merge, findIndex, get, find } from 'lodash';
import CrudService from '../services/crudService';
import CollectionViewService from '../services/collectionViewService';
import FlashStore from './flashStore';
import AppStore from './appStore';
import BulkOperationsStore from './bulkOperationsStore';
import { addIdFieldToContainerRates } from '../utils/utils';
import HttpProvider from '../services/httpProvider';
import { API } from '../constants/api';

/**
 * Стор crud операций.
 */
class CrudStore {
  @observable items = [];
  @observable total = 0;
  @observable item = null;

  @observable isEdit = false;
  @observable readOnly = false;

  @observable errors = [];
  @observable status = {
    code: null,
    message: '',
  };

  @observable offset = 0;
  @observable limit = 20;
  @observable page = 1;

  @observable collectionView = {};
  @observable sortedColName = '';

  constructor(crudUrlPrefix, defaultListParams = {}, defaultCreateParams = {}, crudActions) {
    this.service = new CrudService(crudUrlPrefix, crudActions);
    this.defaultListParams = defaultListParams;
    this.defaultCreateParams = defaultCreateParams;

    this.__bulkStore = new BulkOperationsStore();
  }

  get bulkStore() {
    return this.__bulkStore;
  }

  @computed get currentPage() {
    return parseInt(Math.floor(this.offset / this.limit), 10) + 1;
  }

  @computed get pageCount() {
    return parseInt(Math.ceil(this.total / this.limit), 10);
  }

  @computed get hasErrors() {
    return this.errors !== null && this.errors.length > 0;
  }

  @computed get hasClientError() {
      return this.status && this.status.code >=400 && this.status.code < 500;
  }

  @computed get statusMessage(){
      return this.status? this.status.message || '': "";
  }

  /**
   * Преобразует item, который получается с бекенда и изменяет список контейнеров в валидный для бекенда вид.
   * Это необходимо для того, чтобы преобразовывать из mobx ObservableArray в обычный массив [{}].
   *
   * Метод может принимать параметр id и выбрать из массива items необходимый item по его id.
   *
   * @param id {Number}
   * @returns {Array}
   */
  itemJS(id = null) {
    return computed(() => {
      let index = null;
      let item = null;
      if (id) {
        index = findIndex(this.items, obj => obj.id === id);
        item = toJS(this.items[index]);
      } else {
        item = toJS(this.item);
      }
      item = addIdFieldToContainerRates(item);

      return item;
    }).get();
  }

  @action
  async get(id) {
    const res = await this.service.get(id);

    if (res.status === 'success') {
      this.item = res.data;
    } else {
      this.item = null;
      FlashStore.error(`Unable to get by ${id}.`);
    }

    return this.item;
  }

  @action
  async edit(id, readOnly) {
    this.isEdit = true;
    this.item = null;
    this.readOnly = readOnly;

    if (id) {
      await this.get(id);
    }
  }

  @action
  cancelEdit() {
    this.isEdit = false;
    this.item = null;
    this.readOnly = false;
  }

  setOperationStatus(resp) {
      if(resp){
          this.status = {
              code: resp.statusCode,
              message: resp.message
          };
      }
  }

  @action
  async reloadList(updateFilter) {
    return await this.list(this.currentPage, updateFilter);
  }

  @action
  async list(page = this.page, updateFilter, isSearch) {
    if (((window.location.pathname.split('/')[window.location.pathname.split('/').length - 1] === 'delivery-orders') || (window.location.pathname.split('/')[window.location.pathname.split('/').length - 1] === 'orders')) && window.location.hash && (parseInt(window.location.hash.substring(6), 10) !== this.page)) {
      this.page = parseInt(window.location.hash.substring(6), 10) || 1;
      // eslint-disable-next-line no-param-reassign
      page = this.page;
    }
    if (updateFilter) {
      this.offset = 0;
      AppStore.history.push(AppStore.history.location.pathname);
    } else {
      this.offset = (page - 1) * this.limit;
    }
    this.page = page;
    let res = {};

    if (isSearch) {
      this.offset = 0;
      res = await this.service.list(this.offset, this.limit,
        Object.assign(
            this.defaultListParams,
            this.collectionView && this.collectionView.id && this.collectionView.id > 0?
                { collectionViewId: this.collectionView.id } : {}
            )
      );
    } else {
      res = await this.service.list(this.offset, this.limit,
        Object.assign(
            this.defaultListParams,
            this.collectionView && this.collectionView.id && this.collectionView.id > 0 ?
                { collectionViewId: this.collectionView.id } : {}
            )
      );
    }

    runInAction(() => {
      if (res.status === 'success') {
        this.items = res.data.items ? res.data.items : res.data.rules;
        this.total = (res.data.total || res.data.total === 0) ? res.data.total : res.data.rules.length;
        this.__bulkStore.setItemsNumber(this.total);

        const hasItemsOnPreviousPages = !this.items.length && this.total;

        if (hasItemsOnPreviousPages && AppStore.history.location.hash !== '') {
          const newPageNumber = Math.ceil(this.total / this.limit);
          this.list(newPageNumber);
          this.page = newPageNumber;

          // решение бага с зацикливанием. если включен фильтр на не 1 странице пагинации.
          // в данном случае происходит перенаправление на тужу старницу без хеша
          AppStore.history.push(AppStore.history.location.pathname);
        }
      } else {
        this.items = [];
        FlashStore.error('Unable to list.');
      }
    });

    return res;
  }

  @action
  async create(data, isDocumnetsQuery = false) {
    const res = await this.service.create(merge(data, this.defaultCreateParams));
    this.errors = [];

    this.setOperationStatus(res);

    if (res.status === 'success') {
      this.item = res.data;
    } else {
      this.item = null;
      this.errors = get(res, 'data.errors', []);
      if ((res.code === 403) && (this.errors.length === 0)) {
        this.errors = get(res, 'data.errors', [{ errorText: 'AccessDenied' }]);
      }
    }

    if (isDocumnetsQuery) {
      return res;
    }
    return this.item;
  }

  @action
  async update(id, data, filename = null) {
    const res = await this.service.update(id, data, filename);

    this.errors = [];

    if (res.status === 'success') {
      this.item = res.data;
    } else {
      // this.item = null;
      this.errors = get(res, 'data.errors', []);
    }
    return this.item;
  }

  @action
  async updateFile(id, data) {
    const res = await this.service.updateFile(id, data);

    this.errors = [];

    if (res.status === 'success') {
      this.item = res.data;
    } else {
      // this.item = null;
      this.errors = get(res, 'data.errors', []);
    }
    return res;
  }

  @action
  async sendEmail(id) {
    const res = await this.service.sendEmail(id);
    return res;
  }

  @action
  async generateAndSendEmail(id) {
    const res = await this.service.sendEmail(id);
    return res;
  }

  @action
  async updateDates(id, data) {
    const crudActions = {
      updateDates: async () => await this.http.put(API.BASE(`${this.urlPrefix}/${id}/setValidaty`), data),
    };
    const res = await crudActions.updateDates(id, data);

    this.errors = [];

    if (res.status === 'success') {
      this.item = res.data;
    } else {
      // this.item = null;
      this.errors = get(res, 'data.errors', []);
    }
    return this.item;
  }

  @action
  async remove(id) {
    const res = await this.service.remove(id);

    this.errors = [];

    if (res.status === 'success') {
      this.item = res.data;
    } else {
      this.item = null;
    }

    return { item: this.item, response: res };
  }

  @action
  async applyCollectionView(context) {
    const res = await CollectionViewService.getByContext(context);

    if (res.status === 'success') {
      this.collectionView = res.data;
      this.reloadList();
    } else {
      console.error(`Failed to apply view by context '${context}'`, res);
    }
  }

  @action
  async loadCollectionView(context) {
    const res = await CollectionViewService.getByContext(context);

    if (res.status === 'success') {
      this.collectionView = res.data;
    }
    if ((window.location.pathname.split('/')[window.location.pathname.split('/').length - 2] === 'delivery-orders' || window.location.pathname.split('/')[window.location.pathname.split('/').length - 1] === 'documents') && res.status === 'fail') {
      this.switchColumnSorting(context, 'created', true);
    }
  }

  @action
  async switchColumnSorting(context, name, isFake = false) {
    const crudActions = (crudEndpoint) => {
      return {
        create: async data => await HttpProvider.post(API.BASE(crudEndpoint), data),
        read: async id => await HttpProvider.get(API.BASE(`${crudEndpoint}/${id}`), false),
        update: async (id, data) => await HttpProvider.put(API.BASE(`${crudEndpoint}/${id}`), data),
      };
    };
    const service = new CrudService('collection-views', crudActions('collection-views'));

    if (!this.collectionView || !this.collectionView.id) {
      let res = await CollectionViewService.getByContext(context, true);
      if (res.status === 'success') {
        this.collectionView = res.data;
      } else {
        res = await service.create({ context });

        if (res.status === 'success') {
          this.collectionView = res.data;
        } else {
          return;
        }
      }
    } else {
      const res = await service.get(this.collectionView.id);
      if (res.status === 'fail' && res.code === 404) {
        this.collectionView = {};
        this.switchColumnSorting(context, name);
      }
    }

    this.sortedColName = name;

    const allSorting = get(this.collectionView, 'sorting', []);
    const columnSorting = find(allSorting, { name });

    const data = { context, sorting: [{ name }] };

    if (isFake) {
      data.sorting = [{ name, type: 'DESC' }];
    }

    if (columnSorting) {
      switch (columnSorting.type) {
        case 'ASC':
          data.sorting = [{ name, type: 'DESC' }];
          break;
        case 'DESC':
          data.sorting = [];
          break;
        default:
          data.sorting = [{ name, type: 'ASC' }];
          break;
      }
    }

    try {
      const res = await service.update(this.collectionView.id, data);

      if (res.status === 'success') {
        this.collectionView = res.data;

        this.reloadList();
      }
    } catch (e) {
      // nop.
    }
  }

  hasService() {
    return !!this.service.urlPrefix;
  }
}

export default CrudStore;
