import React, {useState} from 'react';
import {Col, Popover, PopoverBody, Row} from "reactstrap";
import NotificationService from "../../../services/notificationService";
import {FormattedMessage} from "react-intl";
import {translate} from "../../../utils/utils";
import {Link} from "react-router-dom";


const NotificationItem = ({notificationValue, onRead}) => {
    const [notification, setNotification] = useState(notificationValue)
    const [read, setRead] = useState(notification.statusNotificationMessage)
    const [deleted, setDeleted] = useState(false)
    const [openTooltipRead, setOpenTooltipRead] = useState(false)
    const [openTooltipDelete, setOpenTooltipDelete] = useState(false)

    const updateStatusNotification = (id, status) => {
        const data = {
            id: id,
            action: status
        }
        NotificationService.updateStatusNotifications([data]).then(res => {

        })
    }

    const deleteNotification = (id) => {
        const data = {
            id: id,
            action: "DELETE"
        }
        setDeleted(true)
        NotificationService.updateStatusNotifications([data]).then(res => {

        })
    }

    const prepareDate = (dateAsLong) => {
        let date = new Date(dateAsLong);
        if (date.toDateString() === (new Date()).toDateString()) {
            let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours()
            let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
            return hours + ":" + minutes
        }
        return date.toLocaleDateString();
    }

    const getIcon4Notification = (type) => {
        switch (type) {
            case "ExpiredCalculation":
                return "warning"
            default:
                return "info"
        }
    }

    const readNotification = () => {
        const newStatus = read === "UNREAD" ? "READIED" : "UNREAD"
        setRead(newStatus)
        updateStatusNotification(notification.id, newStatus)
        onRead(notification, newStatus)
    }

    const renderTitleFromTemplate = (templateItems = []) => {
        let templateString = translate({id: `notifications.type.${notification.typeNotificationMessage}.title`})
        for (let item of templateItems) {
            templateString = templateString.replace(item.template, item.value)
        }
        return templateString
    }

    const renderMessageFromTemplate = (templateItems = []) => {
        let templateString = translate({id: `notifications.type.${notification.typeNotificationMessage}.message`})
        for (let item of templateItems) {
            templateString = templateString.replace(item.template, item.value)
        }
        return templateString
    }

    if (notification)
        return (
            <Row className={"notification-item"} style={deleted === true ? {display: "none"} : {display: "flex"}}>
                <Col xs={1}>
                    <div className={`icon ${getIcon4Notification(notification.typeNotificationMessage)}`}></div>
                </Col>
                <Col xs={10} className={"d-block"}>
                    <div className={"title"}>
                        <div>{renderTitleFromTemplate(notification.title)}</div>
                        <div className={"date"}>{prepareDate(notification.timeAsLong)}</div>
                    </div>
                    <div className={"message"}>{renderMessageFromTemplate(notification.message)}</div>
                    {notification.notificationUrls.map((url, index) => {
                        return (
                            <div key={`notification-item-${index}`} className={"message"}><Link to={url.url}><FormattedMessage id={url.title}/></Link>
                            </div>
                        )
                    })}
                </Col>
                <Col xs={1}>
                    <div id={`notification-read-${notification.id}`} onClick={readNotification}
                         onPointerEnter={() => setOpenTooltipRead(true)}
                         onPointerLeave={() => setOpenTooltipRead(false)}
                         className={`action-read ${read.toLowerCase()}`}>
                    </div>
                    <span>
                        {document.getElementById(`notification-read-${notification.id}`) &&
                        <Popover
                            placement="left"
                            isOpen={openTooltipRead}
                            className={"fix-arrow"}
                            target={`notification-read-${notification.id}`}>
                        <PopoverBody>{translate({id: "notifications.read"})}</PopoverBody>
                      </Popover>
                        }
                    </span>
                    <div id={`notification-delete-${notification.id}`}
                         onPointerEnter={() => setOpenTooltipDelete(true)}
                         onPointerLeave={() => setOpenTooltipDelete(false)}
                         onClick={() => {
                             deleteNotification(notification.id)
                         }}
                         className={`action-delete`}>
                    </div>
                    <span>
                        {document.getElementById(`notification-delete-${notification.id}`) &&
                        <Popover
                            placement="left"
                            isOpen={openTooltipDelete}
                            className={"fix-arrow"}
                            target={`notification-delete-${notification.id}`}>
                        <PopoverBody>{translate({id: "notifications.delete"})}</PopoverBody>
                      </Popover>
                        }
                    </span>
                </Col>
            </Row>
        )
    return null;
};

export default NotificationItem;
