import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Button } from 'reactstrap';
import { cloneDeep, set } from 'lodash';
import { addFormatName, findByField, convertKgToLb, convertLbToKg } from './../../utils/utils';
import { MAX_CONTAINER_WEIGHT } from './../../constants/global';
import { NumberInput, SelectBox, Select } from './../ui/form-control';
import { Error } from '../ui/form-control/common';
import SelectBoxRefs from "./SelectBoxRefs";

export const CargoContainersForm = (props) => {
  const { containerTypes, containers, weightUnits, onChange, onAdd, onRemove, errors, isUSADirection, onChangeAmount, errorsRef, onErrorsRefs,isFake } = props;
  const prepareContainerTypes = addFormatName(containerTypes);

  const getContainers = (container, index) => {
    const containerType = prepareContainerTypes.find(item => item.id === container.typeId);

    return (
      <SelectBoxRefs
          value={containerType}
          values={prepareContainerTypes}
          labelKey="formatName"
          label="wizard.cargo.form.containerTypes"
          className="col-12 validation-field"
          onClick={value => {
            onChange(value.id, `cargo.containers[${index}].typeId`)
          }}
          error={errors[`cargo.containers[${index}].typeId`]}
      />
    );
  };

  const renderAddButton = () => {
    return (
      <Row>
        <Col xs={12}>
          <Button className="btn-sm add-container-btn" color="link" onClick={onAdd}>
            <span>+</span>
            <FormattedMessage id="wizard.cargo.form.containers.add"
            />
          </Button>
        </Col>
      </Row>
    );
  };

  const renderWeightError = (pathToField) => {
    if (errors[`${pathToField}.weight`]) {
      return <div ref={(ref)=>{ this.props.errorsRefs.push(ref); this.props.onErrorsRefs();}}><Error>{'common.required'}</Error></div>;
    }

    return null;
  };

  const keyGenerator = (() => {
    let i;

    return () => {
      i = i ? i += 1 : 1;
      return i.toString();
    };
  })();

  const getDefaultWeightValue = (weightUnit, value = 0, typeId) => {
    if(isFake===true)
      return value;
    const weightInKg = isUSADirection ?typeId===1? MAX_CONTAINER_WEIGHT.US: MAX_CONTAINER_WEIGHT.default : MAX_CONTAINER_WEIGHT.default;
    const maxValue = weightUnit === 'LB' ? convertKgToLb(weightInKg) : weightInKg;

    return value > maxValue ? maxValue : value;
  };

  const onChangeWeightUnit = (code, index) => {
    const container = cloneDeep(containers[index]);

    if (container.weightUnit !== code) {
      if (code === 'LB') {
        set(container, 'weight', convertKgToLb(container.weight));
      } else if (code === 'KG') {
        set(container, 'weight', convertLbToKg(container.weight));
      }
    }
    set(container, 'weightUnit', code);

    onChange(container, `cargo.containers[${index}]`);
  };

  const onChangeWeight = (value, code, index, typeId) => {
    onChange(getDefaultWeightValue(code, value, typeId), `cargo.containers[${index}].weight`);
  };

  const rows = containers.map((container, index) => {
    const weightUnit = findByField(weightUnits, 'code', container.weightUnit);

    const handleRemoveItem = () => {
      onRemove(index);
    };

    const weightUnitSelect = (
      <Select
        classNameGroup="wizard__select-weight-unit"
        values={weightUnits}
        labelKey="code"
        valueKey="code"
        value={weightUnit}
        onChange={value => onChangeWeightUnit(value.code, index)}
        clearable={false}
      />
    );

    return (
      <div ref={(ref)=>{
        if (errors[`cargo.containers[${index}].amount`] ||
            errors[`cargo.containers[${index}].weight`] ||
            errors[`cargo.containers[${index}].typeId`]){
          errorsRef.push(ref);
          onErrorsRefs();
        }
      }}><Row key={`containers-item-${keyGenerator()}`} className="f-inline align-items-center cargo-item mt-3">
        <Col xs={12}>
          <Row className="align-items-start">
            <Col xs={12} md={2}>
              <NumberInput
                step={1}
                precision={0}
                placeholder="wizard.field.containersNumber.placeholder"
                value={container.amount}
                label="wizard.cargo.form.amount"
                onChange={value => onChangeAmount(value, `cargo.containers[${index}].amount`)}
                error={errors[`cargo.containers[${index}].amount`]}
              />
            </Col>
            <Col xs={12} md={4} lg={5}>
              <Row className="f-inline row no-gutters align-items-start">
                {getContainers(container, index)}
              </Row>
            </Col>
            <Col xs={8} md={4} lg={3}>
              <div>
                <div className="f-group">
                  <div className="f-group">
                    <NumberInput
                      min={0}
                      placeholder={`wizard.cargo.form.weight.placeholder.${container.weightUnit}`}
                      value={getDefaultWeightValue(container.weightUnit, container.weight, container.typeId)}
                      onChange={value => onChangeWeight(value, container.weightUnit, index, container.typeId)}
                      isSpinner={false}
                      showErrors={false}
                      label="wizard.cargo.form.weight"
                      error={errors[`cargo.containers[${index}].weight`]}
                    />
                    {weightUnitSelect}
                  </div>
                </div>
              </div>
              {renderWeightError(`cargo.containers[${index}]`)}
            </Col>
            <Col xs={4} lg={1} className="justify-content-end" style={{ display: 'flex', alignItems: 'center', paddingTop: 36 }}>
              {containers.length > 1 && (
                <Button
                  className="btn-sm btn-remove"
                  color="default"
                  onClick={handleRemoveItem}
                />
              )}
            </Col>
            <hr />
          </Row>
        </Col>
      </Row></div>
    );
  });

  return (
    <div  className="wizard__cargo-container-block px-2">
      <div className="container">
        {rows}
      </div>
      <div className="container">
        {renderAddButton()}
      </div>
    </div>
  );
};

CargoContainersForm.propTypes = {
  containerTypes: PropTypes.array,
  containers: PropTypes.array,
  onChange: PropTypes.func,
  onChangeAmount: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  errors: PropTypes.object,
  weightUnits: PropTypes.array,
  isUSADirection: PropTypes.bool,
};
