import React from 'react';
import PropTypes from 'prop-types';
import { Label as StrapLabel, FormText, FormFeedback } from 'reactstrap';
import { translate } from './../../../utils/utils';

export const  Label = (props) => {
  const { children, elementId } = props;

  let content = null;

  if (children) {
    content = (
      <StrapLabel for={elementId} className={`f-label ${props.className}`}>
        {typeof children === 'string'?translate(children):children}
      </StrapLabel>
    );
  }

  return content;
};

Label.defaultProps={
  className:""
}

Label.propTypes = {
  elementId: PropTypes.string,
  children: PropTypes.string,
};

export const Note = (props) => {
  const { children } = props;

  let content = null;

  if (children) {
    content = (
      <FormText className="f-note" color="muted">
        {translate(children)}
      </FormText>
    );
  }

  return content;
};

Note.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export const Error = (props) => {
  const { children } = props;

  let content = null;

  if (children) {
    content = (
      <FormFeedback className="f-error">
        {translate(children)}
      </FormFeedback>
    );
  }

  return content;
};

Error.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

