import React from 'react';
import { cloneDeep, get, set, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { FormGroup, Col, Row, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import FontAwesome from 'react-fontawesome';
import { NumberInput } from '../../../components/ui/form-control';
import { Error } from './common';

const RangesGroup = (props) => {
  const {
    fields,
    readOnly,
    onChange,
    errors,
  } = props;

  const addRange = () => {
    const rangeRates = cloneDeep(fields.generalCargoRangeRate.rangeRates);

    const initialRange = {
      min: null,
      max: null,
      price: {
        value: '',
        currency: rangeRates[0].price.currency,
      },
    };
    rangeRates.push(initialRange);

    onChange(rangeRates, 'generalCargoRangeRate.rangeRates');
  };

  const removeRange = (index) => {
    if (index !== 0) {
      const rangeRates = cloneDeep(fields.generalCargoRangeRate.rangeRates);

      rangeRates.splice(index, 1);

      if (index === rangeRates.length) {
        rangeRates[index - 1].max = '';
      }

      onChange(rangeRates, 'generalCargoRangeRate.rangeRates');
    }
  };

  const onChangeFromDiapason = (value, name, index) => {
    const rangeRates = cloneDeep(fields.generalCargoRangeRate.rangeRates);

    if (index !== 0) {
      set(rangeRates, `[${index - 1}].max`, value - 1);
    }
    set(rangeRates, `[${index}].min`, value);

    onChange(rangeRates, 'generalCargoRangeRate.rangeRates', index);
  };

  const onChangeToDiapason = (value, name, index) => {
    const rangeRates = cloneDeep(fields.generalCargoRangeRate.rangeRates);

    if (index !== rangeRates.length - 1) {
      set(rangeRates, `[${index + 1}].min`, value + 1);
    }
    set(rangeRates, `[${index}].max`, value);

    onChange(rangeRates, 'generalCargoRangeRate.rangeRates', index);
  };

  const renderRanges = (rangeRates, name) => {
    return rangeRates.map((_item, index) => {
      const i = index;

      return (
        <Row key={i}>
          <Col xs={12} md={4}>
            <NumberInput
              label={index === 0 ? 'crudForm.field.airTransportation.from' : ''}
              min={0}
              step={1}
              precision={0}
              strict
              max={30000}
              style={{ marginBottom: '0' }}
              value={_item.min}
              disabled={readOnly}
              placeholder="crudForm.field.airTransportation.placeholder"
              onChange={value => onChangeFromDiapason(value, 'forwardRates', index)}
              error={errors[`${name}[${index}].min`]}
            />
          </Col>
          <Col xs={12} md={4}>
            <NumberInput
              label={index === 0 ? 'crudForm.field.airTransportation.to' : ''}
              min={0}
              step={1}
              precision={0}
              strict
              max={30000}
              style={{ marginBottom: '0' }}
              value={_item.max}
              disabled={readOnly}
              placeholder="crudForm.field.airTransportation.auto.placeholder"
              onChange={value => onChangeToDiapason(value, 'forwardRates', index)}
              error={errors[`${name}[${index}].max`]}
            />
          </Col>
          <Col xs={12} md={4} className="d-flex align-self-baseline">
            <Row className="no-gutters">
              <Col xs={10}>
                <NumberInput
                  min={1}
                  label={index === 0 ? 'crudForm.field.airTransportation.price' : ''}
                  style={{ marginBottom: '0', paddingRight: '10px' }}
                  value={get(_item, 'price.value', '')}
                  placeholder="crudForm.field.airTransportation.placeholder"
                  disabled={readOnly}
                  onChange={value => onChange(value, `${name}[${index}].price.value`)}
                  error={errors[`${name}[${index}].price.value`]}
                  showErrors={false}
                />
              </Col>
              <Col xs={2} className="align-self-end">
                <Button
                  style={{ height: 38, marginBottom: 8 }}
                  size="sm"
                  color="danger"
                  onClick={() => removeRange(index)}
                  disabled={readOnly || index === 0}
                >
                  <FontAwesome name="times" />
                </Button>
              </Col>
              { !isEmpty(errors[`${name}[${index}].price.value`]) && <Error>{errors[`${name}[${index}].price.value`]}</Error> }
            </Row>
          </Col>
        </Row>
      );
    });
  };

  const rangeRates = get(fields, 'generalCargoRangeRate.rangeRates', null);

  return (
    <FormGroup>
      { !isEmpty(rangeRates) && renderRanges(rangeRates, 'generalCargoRangeRate.rangeRates') }
      { !readOnly &&
        <Row className="w-100 no-gutters justify-content-center">
          <Button
            outline
            color="primary"
            onClick={() => addRange()}
          >
            <FontAwesome name="plus" />{' '}
            <FormattedMessage id="crudForm.field.airTransportation.addRange" />
          </Button>
        </Row>
      }
    </FormGroup>
  );
};

RangesGroup.propTypes = {
  fields: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default RangesGroup;
