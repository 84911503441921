import rest from './restService';
import HttpProvider from './httpProvider';

import { API } from './../constants/api';
import { urlParams } from '../utils/utils';
import { toJSON } from 'lodash/seq';
import config from '../config';

/* eslint-enable */
class UserService {

  constructor() {
    this.rest = rest;
    this.http = HttpProvider;
  }

  async register(type, data) {
    return await this.rest.fetch(`${type}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }

  async auth(login, password, orderId = null) {

    var details = {
      'username': login,
      'password': password,
      'grant_type': 'password'
    };

    var formBody = [];
    for (var property in details) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    return await fetch(config.endpointSSO+"/oauth2/token", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': config.clientAuth
      },
      body: formBody
    })
  }

  async clientRegistration(data) {
    return await this.http.post(API.CLIENTS.REGISTER, data);
  }

  async clientConfirmCode({ userId, confirmCode }) {
    return await this.http.post(API.CLIENTS.CONFIRM_CODE({ id: userId }), { code: confirmCode });
  }

  async clientRegenerateConfirmCode({ userId }) {
    return await this.http.post(API.CLIENTS.REGENERATE_CODE({ id: userId }));
  }

  async partnerRegistration(data) {
    return await this.http.post(API.PARTNERS.REGISTER, data);
  }

  async partnerConfirmCode({ userId, confirmCode }) {
    return await this.http.post(API.PARTNERS.CONFIRM_CODE({ id: userId }), { code: confirmCode });
  }

  async partnerRegenerateConfirmCode({ userId }) {
    return await this.http.post(API.PARTNERS.REGENERATE_CODE({ id: userId }));
  }

  async userUpdate(data) {
    return await this.http.put(API.USERS.UPDATE(data), data);
  }

  async sendRecoveryInstructionsOnEmail(data) {
    return await this.http.post(API.USERS.SEND_RESTORE_PASSWORD(data), data);
  }

  async senQuestionEmail(data) {
    return await this.http.post(API.NOTIFICATIONS.SEND_QUESTION(data), data);
  }

  async restorePassword(data) {
    return await this.http.put(API.USERS.RESTORE_PASSWORD(data), data);
  }

  async clientSavePersonalInfo(data) {
    return await this.http.put(API.CLIENTS.SAVE_PERSONAL_INFO({ id: data.id }), data);
  }

  async clientChangePassword(data) {
    return await this.http.put(API.CLIENTS.CHANGE_PASSWORD, data);
  }

  async getSubordinateRoles(force = false) {
    return await this.http.get(API.USERS.SUBORDINATE_ROLES, force);
  }

  async getClientsWithOrganization(force = false) {
    return await this.http.get(API.CLIENTS.WITH_ORGANIZATION, force);
  }

  async logout() {
    return await this.rest.fetch('/logout');
  }

  async current() {
    return await this.rest.fetch('/users/current').then(res=>{
      if(res.status === 401)
        localStorage.removeItem("token");
      return res
    });

  }

  async getUsersInOrganization(id){
    return await this.http.get(API.USERS.BY_ORGANIZATION({id}));
  }

  async checkAllowAccess(domain){
    return this.http.get(API.USERS.CHECK_ACCESS_WIZARD({domain}), false)
  }

  async testToken() {
    return await this.rest.fetch("/users/check-token");
  }

  async countClients(){
    return await this.http.get(API.USERS.COUNT_CLIENTS(), false)
  }
}

export default new UserService();
