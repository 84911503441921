import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../ui/Modal';
import AuthForm from './AuthForm';
import Registration from './Registration';
import { translate } from '../../utils/utils';
import PasswordRecovery from '../../pages/auth/PasswordRecovery';
import {Alert} from "reactstrap";
import {FormattedMessage} from "react-intl";

class ModalAuth extends Component {
  state = {
    isShown: false,
    isLogin: true,
    isRegister: false,
    isPasswordRecovery: false,
  };

  chooseLogin = () => {
    this.setState({
      isLogin: true,
      isRegister: false,
      isPasswordRecovery: false,
    });
  }

  chooseRegister = () => {
    this.setState({
      isLogin: false,
      isRegister: true,
      isPasswordRecovery: false,
    });
  }

  choosePasswordRecovery = () => {
    this.setState({
      isLogin: false,
      isRegister: false,
      isPasswordRecovery: true,
    });
  }

  render() {
    const { isShown, onClose } = this.props;

    return (
      <Modal
        isShow={isShown}
        className="registerModal"
        onClose={() => { onClose(); }}
        // eslint-disable-next-line no-nested-ternary
        title={translate(this.state.isLogin ? 'login.title' : this.state.isRegister ? 'register.title' : 'passwordRecovery.title')}
        top
      >
        {this.props.hint.length>0 &&
            <Alert color={"warning"}>
                  <div><FormattedMessage id={this.props.hint} /></div>
            </Alert>
        }
        {this.state.isLogin && <AuthForm {...this.props} isShowHeader={false} chooseRegister={this.chooseRegister} choosePasswordRecovery={this.choosePasswordRecovery} />}
        {this.state.isRegister && <Registration {...this.props} isShowHeader={false} chooseLogin={this.chooseLogin} />}
        {this.state.isPasswordRecovery && <PasswordRecovery {...this.props} isShowHeader={false} chooseLogin={this.chooseLogin} />}
      </Modal>
    );
  }
}
ModalAuth.defaultProps={
  hint:""
}

ModalAuth.propTypes = {
  isShown: PropTypes.bool,
  onClose: PropTypes.func,
  hint: PropTypes.string
};

export default ModalAuth;
