import React from 'react';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { FormGroup, Col, Row } from 'reactstrap';
import { NumberInput } from '../../../components/ui/form-control';
import { translate } from '../../../utils/utils';

const LdmRates = (props) => {
  const {
    fields,
    readOnly,
    onChange,
    errors,
  } = props;

  const renderRanges = (rates, name) => {
    return rates.map((item, index) => {
      const i = index;
      return (
        <Row key={i} className="container-list">
          <Col xs={4} md={6} className="container-unit">
            { index === 0 &&
              <label htmlFor="cost-rates">
                { translate('crudForm.field.ldmValue') }
              </label>
            }
            <span className="btn btn-outline-primary container-type-label">{item.ldmValue}</span>
          </Col>
          <Col xs={8} md={6}>
            <Row className="no-gutters">
              <Col xs={12}>
                <NumberInput
                  step={1}
                  label={index === 0 ? 'crudForm.field.price' : ''}
                  value={get(item, 'price.value', '')}
                  disabled={readOnly}
                  placeholder="crudForm.field.price"
                  onChange={value => onChange(value, `${name}[${index}].price.value`)}
                  error={errors[`${name}[${index}].price.value`]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      );
    });
  };

  const rates = get(fields, 'ldmGeneralCargoRate.rates', null);

  return (
    <FormGroup>
      { !isEmpty(rates) && renderRanges(rates, 'ldmGeneralCargoRate.rates') }
    </FormGroup>
  );
};

LdmRates.propTypes = {
  fields: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default LdmRates;
