import React, {Component} from 'react';
import {FormattedMessage} from 'react-intl';
import {Button, Col, Form, FormGroup, Row} from 'reactstrap';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import {Modal} from './Modal';
import UserStore from './../../stores/userStore';
import ModalChangePassword from './ModalChangePassword';
import {TextInput, Checkbox, SelectLanguage} from '../../components/ui/form-control';
import {convertErrorsArrayToObject, translate} from '../../utils/utils';
import UserService from '../../services/userService';
import FlashStore from '../../stores/flashStore';

export default class ModalCurrentUser extends Component {
    state = {
        user: UserStore.user || {},
        errors: {},
        isShow: this.props.isShow,
    };

    UNSAFE_componentWillReceiveProps({isShow}) {
        this.setState({isShow});
    }

    onChangeField = (event, name="", value=null) => {
        const fieldName = name.length===0?event.target.name:name;
        const fieldValue = value===null?event.target.value:value;

        this.setState({
            user: {
                ...this.state.user,
                [fieldName]: fieldValue,
            },
            errors: {
                [fieldName]: '',
            },
        });
    };

    onChangeCheckbox = (value, name) => {
        this.setState({
            user: {
                ...this.state.user,
                [name]: value,
            },
        });
    };

    updateCurrentUser = async (event) => {
        event.preventDefault();

        let response = Promise.resolve();

        if (UserStore.isClient()) {
            response = await UserService.clientSavePersonalInfo(this.state.user);
        } else {
            response = await UserService.userUpdate(this.state.user);
        }

        const status = get(response, 'status');
        const errors = get(response, 'data.errors');
        const message = get(response, 'message', 'modal.currentUserInfo.success');

        if (status === 'success') {
            this.setState({
                errors: [],
            });

            UserStore.update(true);
            FlashStore.successNow(translate(message), true);
            this.props.onClose();
        } else if (status === 'fail') {
            this.setState({
                errors: convertErrorsArrayToObject(errors),
            });
        }
    };

    renderLanguageField4User(errors, fieldValue) {
        let inputValue = "";
        if (fieldValue !== null && fieldValue !== undefined)
            inputValue = fieldValue.toUpperCase()

        return (
            <Col xs={12} className={"px-0"}>
                <SelectLanguage
                    leftAddon={
                        <span style={{minWidth:"210px"}} className="flag-required">
                  {translate('form.field.languageEmail')}
                </span>
                    }
                    labelKey={'name'}
                    onChange={(value)=>{this.onChangeField("", "languageEmail", value.value)}}
                    value={inputValue}
                    error={errors.lastName}
                />
            </Col>
        );
    }

    render() {
        const {user, errors} = this.state;


        return (
            <div>
                <Modal
                    top
                    isShow={this.state.isShow}
                    onClose={this.props.onClose}
                    title={<FormattedMessage id="modal.currentUserInfo"/>}
                >
                    <Form
                        className="b-current-user-info"
                        onSubmit={this.updateCurrentUser}
                    >
                        <Row className="no-gutters">
                            <Col xs={12} className={"px-0"}>
                                <TextInput
                                    value={user.lastName}
                                    placeholder="form.field.lastName.placeholder"
                                    onChange={this.onChangeField}
                                    name="lastName"
                                    error={errors.lastName}
                                    leftAddon={
                                        <span style={{minWidth:"210px"}} className="flag-required">
                    {translate('form.field.lastName')}
                  </span>
                                    }
                                />
                            </Col>
                            <Col xs={12} className={"px-0"}>
                                <TextInput
                                    value={user.firstName}
                                    placeholder="form.field.firstName.placeholder"
                                    onChange={this.onChangeField}
                                    name="firstName"
                                    error={errors.firstName}
                                    leftAddon={
                                        <span style={{minWidth:"210px"}} className="flag-required">
                    {translate('form.field.firstName')}
                  </span>
                                    }
                                />
                            </Col>
                            <Col xs={12} className={"px-0"}>
                                <TextInput
                                    value={user.middleName}
                                    placeholder="form.field.middleName.placeholder"
                                    onChange={this.onChangeField}
                                    name="middleName"
                                    error={errors.middleName}
                                    leftAddon={<span style={{minWidth:"210px"}}>{translate('form.field.middleName')}</span>}
                                />
                            </Col>
                            <Col xs={12} className={"px-0"}>
                                <TextInput
                                    value={user.email}
                                    placeholder="form.field.email.placeholder"
                                    onChange={this.onChangeField}
                                    name="email"
                                    disabled
                                    error={errors.email}
                                    leftAddon={<span style={{minWidth:"210px"}}>{translate('form.field.email')}</span>}
                                />
                            </Col>
                            <Col xs={12} className={"px-0"}>
                                <TextInput
                                    value={user.phone}
                                    placeholder="form.field.phone"
                                    onChange={this.onChangeField}
                                    name="phone"
                                    error={errors.phone}
                                    leftAddon={<span style={{minWidth:"210px"}}>{translate('form.field.phone')}</span>}
                                />
                            </Col>
                            {this.renderLanguageField4User(errors, user.languageEmail)}

                            {UserStore.isClient() ? (
                                <div className="w-100">
                                    <Col xs={12} className={"px-0"}>
                                        <TextInput
                                            value={user.organizationName}
                                            placeholder="form.field.organizationName.placeholder"
                                            onChange={this.onChangeField}
                                            name="organizationName"
                                            error={errors.organizationName}
                                            leftAddon={<span style={{minWidth:"210px"}}>{translate('form.field.organizationName')}</span>}
                                        />
                                    </Col>
                                    <Col xs={12} className={"px-0"}>
                                        <TextInput
                                            value={user.position}
                                            placeholder="form.field.position.placeholder"
                                            onChange={this.onChangeField}
                                            name="position"
                                            error={errors.position}
                                            leftAddon={<span style={{minWidth:"210px"}}>{translate('form.field.position')}</span>}
                                        />
                                    </Col>
                                    <Col xs={12} className={"px-0"}>
                                        <Checkbox
                                            checked={user.notificationOnTariffChange}
                                            onChange={ev => this.onChangeCheckbox(ev.target.checked, 'notificationOnTariffChange')}
                                            label="form.field.notificationOnTariffChange"
                                        />
                                    </Col>
                                </div>
                            ) : null}
                        </Row>
                        <FormGroup className="btn-controls">
                            <Row className="no-gutters justify-content-start">
                                <Button color="primary">
                                    <FormattedMessage id="person.button.save"/>
                                </Button>
                                <ModalChangePassword/>
                            </Row>
                        </FormGroup>
                    </Form>
                </Modal>
            </div>
        );
    }
}

ModalCurrentUser.propTypes = {
    isShow: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};
