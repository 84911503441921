import React, { Component, cloneElement } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { find } from 'lodash';
import CrudStore from '../../../stores/crudStore';
import AppStore from '../../../stores/appStore';
import FilterService from '../../../services/filterService';
import { translate } from '../../../utils/utils';
import { EXPIRED_TARIFFS_FILTER_NAME } from '../../../constants/global';

@observer
class Crud extends Component {
  state = {
    flag: false,
    currentFilter: {},
    filtersWithNames: [],
    loaded: false,
  };

  UNSAFE_componentWillMount() {
    const { endpoint, staticData, listParams, createParams, crudActions = null } = this.props;

    this.store = new CrudStore(endpoint, listParams, createParams, crudActions);

    if (staticData) {
      this.store.items = staticData;
    }

    let context = '';

    React.Children.forEach(this.props.children, (child) => {
      if (child.props.crudType === 'filter') {
        context = child.props.context;
      }
    });

    if (context === '') {
      this.setState({
        flag: true,
      });

      return;
    }

    FilterService.getFiltersByContext(context).then((filters) => {
      const filtersWithNames = filters.map(filter => ({
        ...filter,
        formatName: translate(filter.name ? `crud.filter.${filter.name}` : 'crud.filter.userFilter'),
      }));

      let currentFilter = find(filtersWithNames, ({ name }) => name !== EXPIRED_TARIFFS_FILTER_NAME) || {};

      const expiredTariffsFilterIsActive = (
        window.location.hash.replace('#', '') === EXPIRED_TARIFFS_FILTER_NAME
      );

      if (expiredTariffsFilterIsActive) {
        currentFilter = find(filtersWithNames, { name: EXPIRED_TARIFFS_FILTER_NAME }) || {};
      }

      if (currentFilter.id && this.store.bulkStore) {
        this.store.bulkStore.setFilterId(currentFilter.id);
      }

      this.store.defaultListParams = Object.assign(
        {}, (listParams || {}),
        currentFilter.id ? {
          filterId: currentFilter.id,
          filterLang: AppStore.userLang,
        } : {}
      );

      this.setState({
        flag: true,
        currentFilter,
        filtersWithNames,
        loaded: true,
      });
    });
  }

  renderChildren() {
    let crudTitle = null;
    let crudFilters = null;
    let crudForm = null;
    let crudList = null;
    let crudBulkOperations = null;

    React.Children.forEach(this.props.children, (child) => {
      const { crudType } = child.props;

      if (crudType === 'title') {
        crudTitle = cloneElement(
          child,
          {
            total: this.store.total,
          },
        );
      } else if (crudType === 'filter') {
        crudFilters = cloneElement(
          child,
          {
            currentFilter: this.state.currentFilter,
            filtersWithNames: this.state.filtersWithNames,
            onDelete: () => {
              this.setState({
                currentFilter: {},
              });

              this.store.defaultListParams = this.props.listParams || {};

              // сохраняем id в стор массовых операций
              this.store.bulkStore.setFilterId(null);

              this.store.reloadList();
            },
            onChange: (currentFilter, reloadList = false) => {
              const updatedFilter = currentFilter || {};

              this.setState({
                currentFilter: updatedFilter,
              });

              this.store.defaultListParams = Object.assign(
                {}, (this.props.listParams || {}),
                updatedFilter.id ? {
                  filterId: updatedFilter.id,
                  filterLang: AppStore.userLang,
                } : {}
              );

              // сохраняем id в стор массовых операций
              if (updatedFilter.id) {
                this.store.bulkStore.setFilterId(updatedFilter.id);
              }
              if(reloadList===true){
                this.setState({ loaded: false });
                this.store.reloadList(updatedFilter).then(() => {
                this.setState({ loaded: true });
              });
              }
            },
          },
        );
      } else if (crudType === 'form') {
        crudForm = cloneElement(
          child,
          {
            store: this.store,
            isShow: this.store.isEdit,
            item: this.store.item,
            endpoint: this.props.endpoint,
          },
        );
      } else if (crudType === 'bulkOperations') {
        crudBulkOperations = cloneElement(
          child,
          {
            store: this.store.bulkStore,
            reloadList: () => this.store.reloadList(),
            isShow: this.store.bulkStore.isBulkOperations,
            items: this.store.bulkStore.bulkEditItems,
            endpoint: this.props.endpoint,
          },
        );
      } else if (this.state.flag) { // list
        crudList = cloneElement(
          child,
          {
            store: this.store,
            isLoaded: this.state.loaded,
          },
        );
      }
    });

    return (
      <div>
        {crudTitle}
        {crudFilters}
        {crudBulkOperations}
        {crudForm}
        {crudList}
      </div>
    );
  }

  render() {
    const { children } = this.props;

    if (children) {
      return this.renderChildren();
    }

    return null;
  }
}

Crud.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  staticData: PropTypes.array,
  endpoint: PropTypes.string,
  listParams: PropTypes.object,
  createParams: PropTypes.object,
  crudActions: PropTypes.object,
};

export default injectIntl(Crud);
