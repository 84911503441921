import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Col, Row, TabContent, TabPane } from 'reactstrap';
import { cloneDeep, get, isEmpty, set } from 'lodash';
import userService from './../../services/userService';
// import { RegisterPartnerForm } from '../../components/Auth/RegisterPartnerForm';
import { RegisterClientForm } from '../../components/Auth/RegisterClientForm';
import { ModalConfirmCode } from '../../components/Auth/ModalConfirmCode';
import { ModalAgreement } from '../../components/Auth/ModalAgreement';
import { Preloader } from '../../components/ui/Preloader';
import { translate, startPageRedirect } from '../../utils/utils';
import FlashStore from '../../stores/flashStore';
import AppStore from '../../stores/appStore';
import UserStore from '../../stores/userStore';
import { ADMIN_ORDERS, CLIENT_ORDERS, PARTNER_ORDERS } from '../../constants/routes';
import { metricsEvents, pushAnalyticalEvent } from '../../utils/analyticsMetrics';

const CLIENT = 'client';
// const PARTNER = 'partner';

export default class Registration extends Component {
  state = {
    activeTab: CLIENT,
    clientFields: {
      email: '',
      firstName: '',
      lastName: '',
      middleName: '',
      organizationName: '',
      password: '',
      passwordConfirmation: '',
      phone: '',
      position: '',
      privateInfoConfirmed: false,
      languageEmail: AppStore.userLang.toUpperCase(),
      orderId: UserStore.guestOrder,
    },
    clientErrors: {},
    partnerFields: {
      email: '',
      languageEmail:'',
      firstName: '',
      lastName: '',
      middleName: '',
      organization: {
        email: '',
        factAddress: '',
        legalAddress: '',
        name: '',
        phone: '',
      },
      password: '',
      passwordConfirmation: '',
      phone: '',
      privateInfoConfirmed: false,
      orderId: UserStore.guestOrder,
    },
    partnerErrors: {},
    isWaiting: false,
    isShowConfirmCodeModal: false,
    isShowAgreementModal: false,
    isNeedSubmit: false,
    confirmCode: '',
    confirmCodeError: '',
    userData: {},
    agreementContent: {},
    isShowAdditionalFields: false,
    isResendCode: false,
  };

  async UNSAFE_componentWillMount() {
    const newState = {};
    const hash = get(this.props, 'history.location.hash', null);
    const agreement = await import('./agreement');

    if (hash) {
      const isShowAllFields = hash.replace('#', '') === 'showAllFields';
      set(newState, 'isShowAdditionalFields', isShowAllFields);
    }

    set(newState, 'agreementContent', {
      en: agreement.en,
      ru: agreement.ru,
    });

    this.setState({ ...newState });
  }

  setTab = (index) => {
    this.setState({
      activeTab: index,
    });
  };

  onChangePartnerFields = (value, name) => {
    this.onChangeFields('partnerFields', value, name, 'partnerErrors');
  };

  onChangeClientFields = (value, name) => {
    this.onChangeFields('clientFields', value, name, 'clientErrors');
  };

  onChangeFields(fieldsGroup, value, name, error) {
    const fields = cloneDeep(this.state[fieldsGroup]);
    const errors = cloneDeep(this.state[error]);

    delete errors[name];

    set(fields, name, value);

    this.setState({
      [fieldsGroup]: fields,
      [error]: errors,
    });
  }

  onChangeConfirmCode = (ev) => {
    const confirmCode = ev.target.value;

    this.setState({
      confirmCode,
    });
  };

  onSubmitRegistrationData(type) {
    debugger;
    const { privateInfoConfirmed } = type === CLIENT ? this.state.clientFields : this.state.partnerFields;

    if (!privateInfoConfirmed) {
      this.setState({ isNeedSubmit: true });
      this.toggleModalView('isShowAgreementModal');

      return;
    }

    let registrationRequest = null;

    this.setState({
      isWaiting: true,
    });

    if (type === CLIENT) {
      registrationRequest = userService.clientRegistration(this.state.clientFields);
    } else {
      registrationRequest = userService.partnerRegistration(this.state.partnerFields);
    }

    registrationRequest.then((res) => {
      const newState = {
        isWaiting: false,
        isNeedSubmit: false,
      };

      if (res.status === 'success') {
        // eslint-disable-next-line quote-props
        pushAnalyticalEvent(metricsEvents.newUserRegistered);
        newState.isShowConfirmCodeModal = true;
        newState.userData = res.data;
        if(newState.userData.resendConfirmationCode===true){
          newState.isResendCode = true
        }
      } else if (res.status === 'fail') {
        const errors = {};

        get(res, 'data.errors', []).forEach((error) => {
          errors[error.fieldName] = error.errorText;
        });

        const errorsKey = type === CLIENT ? 'clientErrors' : 'partnerErrors';

        newState[errorsKey] = errors;
      }

      this.setState(newState);
    }).catch(() => {
      this.setState({
        isWaiting: false,
      });
    });
  }

  onSubmitConfirmCode = () => {
    const userId = this.state.userData.id;
    const confirmCode = this.state.confirmCode;

    if (!userId) return;

    this.setState({
      isWaiting: true,
    });

    let request = null;

    if (this.state.activeTab === CLIENT) {
      request = userService.clientConfirmCode({ userId, confirmCode });
    } else {
      request = userService.partnerConfirmCode({ userId, confirmCode });
    }

    request.then((res) => {
      const newState = {
        isWaiting: false,
      };

      if (res.status === 'fail') {
        newState.confirmCodeError = get(res, 'data.errors[0].errorText', '');
        this.setState(newState);
      } else {
        this.setState(newState);

        const { userData, clientFields } = this.state;

        UserStore.auth(clientFields.email, clientFields.password).then(() => {
          const orderId = UserStore.guestOrder;

          if (orderId) {
            let url = '';

            if (UserStore.isClient()) {
              url = CLIENT_ORDERS;
            } else if (UserStore.isPartner()) {
              url = PARTNER_ORDERS;
            } else if (UserStore.isOperator()) {
              url = ADMIN_ORDERS;
            }

            if (!isEmpty(url)) {
              UserStore.addCalcToBeShowAll(orderId);
              this.props.history.push(`${url}/${orderId}`);
            }
          } else {
            startPageRedirect(userData.generalRole, this.props.history);
          }

          FlashStore.successNow(translate({ id: 'confirm.success.text' }), true);
        });
      }
    });
  };

  onRegenerateConfirmCode = () => {
    const userId = this.state.userData.id;

    this.setState({
      isWaiting: true,
      confirmCodeError: '',
    });

    let request = null;

    if (this.state.activeTab === CLIENT) {
      request = userService.clientRegenerateConfirmCode({ userId });
    } else {
      request = userService.partnerRegenerateConfirmCode({ userId });
    }

    request.then(() => {
      this.setState({
        isWaiting: false,
      });
    });
  };

  onAcceptAgreement = (type) => {
    const fieldsGroup = type === CLIENT ? 'clientFields' : 'partnerFields';
    const fields = cloneDeep(this.state[fieldsGroup]);
    set(fields, 'privateInfoConfirmed', true);

    this.setState({
      [fieldsGroup]: fields,
    }, () => {
      if (this.state.isNeedSubmit) {
        this.onSubmitRegistrationData(type);
      }
    });
  };

  toggleModalView(propName) {
    this.setState({
      [propName]: !this.state[propName],
    });
  }

  render() {
    return (
      <div className="b-registration pane pane-primary pane-hg">
        <Preloader isShow={this.state.isWaiting && !this.state.isShowConfirmCodeModal} />
        { this.props.isShowHeader &&
          <div className="pane-header">
            <h2><FormattedMessage id="register.title" /></h2>
          </div>
        }
        <Row className="justify-content-center">
          <Col lg={12}>
            {/*eslint-disable */}
            {/*<Nav tabs>*/}
            {/*<NavItem>*/}
            {/*<NavLink*/}
            {/*className={`${this.state.activeTab === CLIENT ? 'active' : ''}`}*/}
            {/*onClick={() => this.setTab(CLIENT)}*/}
            {/*>*/}
            {/*<FormattedMessage id="role.client" />*/}
            {/*</NavLink>*/}
            {/*</NavItem>*/}
            {/*<NavItem>*/}
            {/*<NavLink*/}
            {/*className={`${this.state.activeTab === PARTNER ? 'active' : ''}`}*/}
            {/*onClick={() => this.setTab(PARTNER)}*/}
            {/*>*/}
            {/*<FormattedMessage id="role.partner" />*/}
            {/*</NavLink>*/}
            {/*</NavItem>*/}
            {/*</Nav>*/}
            {/*eslint-enable */}
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId={CLIENT}>
                <Row className="no-gutters">
                  <Col xs={12}>
                    <RegisterClientForm
                      fields={this.state.clientFields}
                      errors={this.state.clientErrors}
                      onChange={this.onChangeClientFields}
                      chooseLogin={this.props.chooseLogin}
                      isShowHeader={this.props.isShowHeader}
                      showAgreement={() => this.toggleModalView('isShowAgreementModal')}
                      onSubmit={() => this.onSubmitRegistrationData(CLIENT)}
                      isShowAdditionalFields={this.state.isShowAdditionalFields}
                    />
                  </Col>
                </Row>
              </TabPane>
              {/*eslint-disable */}
              {/*<TabPane tabId={PARTNER}>*/}
              {/*<Row>*/}
              {/*/!*<Col className="description" xs={12}>*/}
              {/*<FormattedMessage id="register.partner.description" />*/}
              {/*</Col>*!/*/}
              {/*<Col xs={12}>*/}
              {/*<RegisterPartnerForm*/}
              {/*fields={this.state.partnerFields}*/}
              {/*errors={this.state.partnerErrors}*/}
              {/*onChange={this.onChangePartnerFields}*/}
              {/*onSubmit={() => this.onSubmitRegistrationData(PARTNER)}*/}
              {/*/>*/}
              {/*</Col>*/}
              {/*</Row>*/}
              {/*</TabPane>*/}
              {/*eslint-enable */}
            </TabContent>
          </Col>
        </Row>
        <ModalConfirmCode
          isShow={this.state.isShowConfirmCodeModal}
          isUserNotConfirm = {this.state.isResendCode}
          code={this.state.confirmCode}
          error={this.state.confirmCodeError}
          onChange={this.onChangeConfirmCode}
          onClose={() => this.toggleModalView('isShowConfirmCodeModal')}
          onRegenerate={this.onRegenerateConfirmCode}
          onSubmit={this.onSubmitConfirmCode}
          isWaiting={this.state.isWaiting}
        />
        <ModalAgreement
          isShown={this.state.isShowAgreementModal}
          onClose={() => this.toggleModalView('isShowAgreementModal')}
          onAcceptAgreement={() => this.onAcceptAgreement(CLIENT)}
          content={this.state.agreementContent[AppStore.userLang]}
        />
      </div>
    );
  }
}

Registration.defaultProps = {
  isShowHeader: true,
};

Registration.propTypes = {
  history: PropTypes.object,
  isShowHeader: PropTypes.bool,
  chooseLogin: PropTypes.func,
};
