import HttpProvider from "./httpProvider";
import {API} from "../constants/api";

class SubscriptionService {
    constructor() {
        this.http = HttpProvider;
    }

    async getConfig(){
        return this.http.get(API.SUBSCRIPTION.CONFIG(), false)
    }

    async updateConfig(data){
        return this.http.put(API.SUBSCRIPTION.CONFIG(), data)
    }

    async getDetailsSubscription(id){
        return this.http.get(API.SUBSCRIPTION.USER_SUBSCRIPTION({id}), false)
    }
    async getDetailsSubscription4User(id){
        return this.http.get(API.SUBSCRIPTION.USER_SUBSCRIPTION_BY_USER_ID({id}), false)
    }

    async getDetailsSelfSubscription(){
        return this.http.get(API.SUBSCRIPTION.SELF_SUBSCRIPTION(),false)
    }

    async getTariffs(){
        return this.http.get(API.SUBSCRIPTION.TARIFFS(), false)
    }

    async createNewSubscription(data){
        return this.http.put(API.SUBSCRIPTION.CREATE_USER_SUBSCRIPTION(), data)
    }

    async switchToFreeTariff(){
        return this.http.put(API.SUBSCRIPTION.SWITCH_TO_FREE(),{})
    }

    async checkEnabled(){
        return this.http.get(API.SUBSCRIPTION.CHECK_ENABLED(), false)
    }

    async createRequest(data){
        return this.http.post(API.SUBSCRIPTION_REQUESTS.ORDER_NEW_REQUEST(), data)
    }

    async getNewOrProcessedRequest(){
        return this.http.get(API.SUBSCRIPTION_REQUESTS.GET_NEW_OR_UNPROCESSED_REQUEST(), false)
    }
}

export default new SubscriptionService()