import React from 'react';
import Page from '../../../layouts/main';
import { getLangInStorage } from '../../../utils/utils';

const ClientDashboard = (props) => {
  const text = {
    ru: 'Страница недоступна для данного профиля или не существует',
    en: 'This page is not available for this profile or does not exist',
  };
  return (
    <Page
      pageTitle={text[getLangInStorage()]}
      scope="user"
      {...props}
    >
      <h3>{text[getLangInStorage()]}</h3>
    </Page>
  );
};

export default ClientDashboard;
