import { FormattedMessage } from 'react-intl';
import React from 'react';

const Link = (props) => {

  const {items, index} = props;
  const isShowLinkTrackingPage = () => {
    if (items[index].trackingPageUrl) {
      return true;
    } else return false;
  }

  const openTrackingPage = () => {
    const url = items[index].trackingPageUrl;
    window.open(url, '_blank');
  }

    return (
      <div>
        {isShowLinkTrackingPage() && <div
          className={'actions'}
          onClick={openTrackingPage}>
          <FormattedMessage id="deliveryOrders.orderBlock.orderActions.openTrackingPage"/> <a className={"btn-link"}><FormattedMessage id="orderForm.button.open"/></a>
        </div>}
      </div>
    );
}

export default Link;
