import React, {useEffect, useState} from 'react';
import {Col, Label, Row} from "reactstrap";
import PropTypes from "prop-types";
import subscriptionService from "../../services/subscriptionService";
import {getExistLangValue} from "../../utils/utils";
import AddSubscription from "./AddSubscription";
import {FormattedMessage} from "react-intl";
import moment from "moment";

const HistorySubscription = (props) => {
    const [subscriptionHistory, setSubscriptionHistory] = useState(null)
    const [subscription, setSubscription] = useState(null)


    useEffect(() => {
        if (props.idSubscription) {
            loadSubscription()
        }
    }, [props])

    const loadSubscription = () => {
        subscriptionService.getDetailsSubscription(props.idSubscription).then(res => {
            let array = []
            if (res.data) {
                setSubscription(res.data)
                for (let item of res.data.historyBillingList) {
                    if (item && item.tariffPlan) {
                        array.push(item)
                    }
                }
            }
            setSubscriptionHistory(array)
        })
    }

    const onCreateNewSubscription = (data) => {
        subscriptionService.createNewSubscription(data).then(res => {
            if (res.code === 200 || res.code === 201) {
                loadSubscription()
            }
        })
    }

    if (subscription && subscriptionHistory)
        return (
            <div className={"w-100 history-subscription"}>
                <AddSubscription onSave={onCreateNewSubscription}
                                 idSubscription={props.idSubscription}
                                 userId={subscription.user.id}
                />
                <Label className={"d-block d-lg-none f-label"}><FormattedMessage id={"subscription.history.text"}/></Label>
                <Label className={"d-none d-lg-block"}><FormattedMessage id={"subscription.history.text"}/></Label>
                <div className={"history-table"}>
                    <Row>
                        <Col className={"d-none d-lg-block"} lg={4}><FormattedMessage id={"subscription.history.tariff"}/></Col>
                        <Col className={"d-none d-lg-block"} lg={4}><FormattedMessage id={"subscription.history.date"}/></Col>
                        <Col className={"d-none d-lg-block"} lg={4}><FormattedMessage id={"subscription.history.idContract"}/></Col>
                    </Row>
                    <div className={"content-table"}>
                        {
                            subscriptionHistory.map(el => {
                                return (
                                    <Row key={el.id}>
                                        <Col xs={12} lg={4}><span
                                            className={"d-block d-lg-none"}><FormattedMessage id={"subscription.history.tariff"}/></span> {getExistLangValue(el.tariffPlan.name)}
                                        </Col>
                                        <Col xs={12} lg={4}><span
                                            className={"d-block d-lg-none"}><FormattedMessage id={"subscription.history.date"}/></span> {moment(el.billingDateString).toDate().toLocaleDateString()}
                                        </Col>
                                        <Col xs={12} lg={4}><span className={"d-block d-lg-none"}><FormattedMessage id={"subscription.history.idContract"}/></span> {el.transactionId}
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    return null
};

HistorySubscription.props = {
    idSubscription: PropTypes.number
}

export default HistorySubscription;