import React, { Component } from 'react';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import Select from './Select';
import UserService from '../../../services/userService';
import { addFormatName } from '../../../utils/utils';

export default class SelectSubordinateRole extends Component {
  state = {
    subordinateRoles: [],
  };

  async UNSAFE_componentWillMount() {
    const response = await UserService.getSubordinateRoles();

    if (!_.isArray(response.data)) {
      return;
    }

    this.setState({
      subordinateRoles: addFormatName(response.data),
    });
  }

  render() {
    const { multi, value, leftAddon, error, label, onChange } = this.props;

    return (
      <Select
        searchable
        multi={multi}
        label={label}
        leftAddon={leftAddon}
        value={value ? addFormatName(value) : value}
        values={this.state.subordinateRoles}
        labelKey="formatName"
        valueKey="id"
        error={error}
        onChange={onChange}
      />
    );
  }
}

SelectSubordinateRole.propTypes = {
  multi: PropTypes.bool,
  label: PropTypes.string, // eslint-disable-line
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]), // eslint-disable-line
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // eslint-disable-line
  error: PropTypes.string, // eslint-disable-line
  onChange: PropTypes.func, // eslint-disable-line
};
