import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontAwesome from 'react-fontawesome';
import { forEach } from 'lodash';
import { FormattedMessage } from 'react-intl';
import {Form, FormGroup, Button, Row, Label} from 'reactstrap';
import { Modal } from '../../Modal';
import { TextInput } from '../../form-control/index';
import AppStore from '../../../../stores/appStore';
import { camelCaseToTile, translate, setName } from '../../../../utils/utils';

class TranslationText extends Component {
  state = {
    isShow: false,
  };

  showModal() {
    this.setState({
      isShow: true,
    });
  }

  closeModal() {
    this.setState({
      isShow: false,
    });
  }

  modalContent() {
    const { fields, fieldName, type, disabled, onChange } = this.props;
    const fieldValue = fields[fieldName] || {};

    forEach(AppStore.languages, (language) => {
      if (!fieldValue[language.code]) {
        fieldValue[language.code] = '';
      }
    });

    const langName = Object.entries(fieldValue);

    return langName.map((el) => {
      const value = fields[fieldName] ? fields[fieldName][el[0]] : '';

      //const label = translate({ id: `crudForm.dictionary.field.${fieldName}`, defaultMessage: camelCaseToTile(fieldName) });

      const languageByCode = AppStore.getLanguageByCode(el[0]);

      if (!languageByCode) {
        return null;
      }

      const label = languageByCode ? setName(languageByCode) : '';
      const labelLang = `${label} (${el[0]})`;
      const className = type === 'textarea' ? ' translateArea' : '';

      return (

        <FormGroup key={`${fields.id}_${el[0]}`}>
          <FormGroup className={"d-none d-lg-block"}>
            <TextInput
                className={`translation-text${className}`}
                value={value || ''}
                placeholder="crudForm.dictionary.field.translate.placeholder"
                leftAddon={labelLang}
                rightAddon={null/*<Button size="sm" color="danger" onClick={() => deleteTranslate(el[0])}><FontAwesome name="trash-o" /></Button>*/}
                onChange={ev => onChange(ev.target.value, `${fieldName}[${el[0]}]`)}
                type={type}
                disabled={disabled}
            />
          </FormGroup>
          <FormGroup className={"d-block d-lg-none"}>
            <TextInput
                className={`translation-text${className}`}
                value={value || ''}
                placeholder="crudForm.dictionary.field.translate.placeholder"
                label={<Label className={"f-label"}>{labelLang}</Label>}
                rightAddon={null/*<Button size="sm" color="danger" onClick={() => deleteTranslate(el[0])}><FontAwesome name="trash-o" /></Button>*/}
                onChange={ev => onChange(ev.target.value, `${fieldName}[${el[0]}]`)}
                type={type}
                disabled={disabled}
            />
          </FormGroup>
        </FormGroup>
      );
    });
  }

  render() {
    const { fields, fieldName,fieldNameLabel, type, onChange, required, disabled, error } = this.props;
    const lang = AppStore.userLang;

    const value = fields[fieldName] ? fields[fieldName][lang] : '';
    console.log("TN: ",`crudForm.dictionary.field.${fieldNameLabel?fieldNameLabel:fieldName}`)
    const label = translate({
      id: `crudForm.dictionary.field.${fieldNameLabel?fieldNameLabel:fieldName}`,
      defaultMessage: translate({ id: `crud.field.${fieldName}.title`, defaultMessage: camelCaseToTile(fieldName) }),
    });

    let labelLang = `${label} (${lang})`;
    console.log("TN_1: ",`${label} (${lang})`)
    if (required) {
      labelLang = <span className="flag-required">{labelLang}</span>;
    }

    const className = type === 'textarea' ? ' translateArea' : '';

    return (
      <div>
        <FormGroup className={"d-block d-lg-none"}>
          <TextInput
            className={`translation-text${className}`}
            value={value || ''}
            placeholder="crudForm.dictionary.field.translate.placeholder"
            label={<Label className={"f-label"}>{labelLang}</Label>}
            rightAddon={<Button size="sm" color="light" style={{ height: '100%' }} onClick={() => this.showModal()}><FontAwesome name="list-ul" /></Button>}
            onChange={ev => onChange(ev.target.value, `${fieldName}[${lang}]`, `${fieldName}`)}
            type={type}
            disabled={disabled}
            error={error}
          />
        </FormGroup>
        <FormGroup className={"d-none d-lg-block"}>
          <TextInput
              className={`translation-text${className}`}
              value={value || ''}
              placeholder="crudForm.dictionary.field.translate.placeholder"
              leftAddon={labelLang}
              rightAddon={<Button size="sm" color="light" style={{ height: '100%' }} onClick={() => this.showModal()}><FontAwesome name="list-ul" /></Button>}
              onChange={ev => onChange(ev.target.value, `${fieldName}[${lang}]`, `${fieldName}`)}
              type={type}
              disabled={disabled}
              error={error}
          />
        </FormGroup>
        <Modal
          isShow={this.state.isShow}
          className="crud-modal"
          title={`${label} - Редактирование перевода `}
          onClose={() => this.closeModal()}
          top
        >
          <Form>
            { this.modalContent() }
            {/*<FormGroup>
              <TextInput
                className="translation-text"
                value={newTranslate}
                placeholder="crudForm.dictionary.field.translate.placeholder"
                rightAddon={<Button color="success" size="sm" onClick={() => addTranslate(newTranslate)}><FontAwesome name="plus" /></Button>}
                onChange={ev => onChangeTranslate(ev.target.value, 'newTranslate')}
              />
            </FormGroup>
            */}
            { !disabled &&
              <FormGroup>
                <Row className="no-gutters btn-controls" style={{ justifyContent: 'flex-start' }}>
                  <Button color="primary" onClick={() => this.closeModal()}><FormattedMessage id="crudForm.button.save" /></Button>
                </Row>
              </FormGroup>
            }
          </Form>
        </Modal>
      </div>
    );
  }
}


TranslationText.defaultProps = {
  fieldName: 'name',
  type: 'text',
};


TranslationText.propTypes = {
  fields: PropTypes.object,
  fieldName: PropTypes.string,
  //Custom translates
  fieldNameLabel: PropTypes,
  // newTranslate: PropTypes.string,
  onChange: PropTypes.func,
  // onChangeTranslate: PropTypes.func,
  // deleteTranslate: PropTypes.func,
  // addTranslate: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,

  required: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default TranslationText;
