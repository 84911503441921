import React, {useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl";
import Page from "../../layouts/main";
import {Button, Col, Label, Row, TabContent, TabPane} from "reactstrap";
import subscriptionService from "../../services/subscriptionService";
import {useHistory} from "react-router";
import {
    getCargoInfoForCrud,
    getExistLangValue,
    getLangInStorage,
    getLocationNameWithIcon,
    translate
} from "../../utils/utils";
import {Checkbox} from "../../components/ui/form-control";
import AddSubscription from "../../components/subscriptions/AddSubscription";
import {Modal} from "../../components/ui/Modal";
import TariffSubscriptionModal from "../../components/subscriptions/TariffSubscriptionModal";
import {Crud, CrudColumn, CrudField, CrudFilters, CrudForm, CrudFormats, CrudList} from "../../components/ui/Crud";
import DictionaryForm from "../../components/ui/Crud/forms/DictionaryForm";
import HttpProvider from "../../services/httpProvider";
import {API} from "../../constants/api";
import {FILTER_EDITORS, FILTER_FIELD_TYPES} from "../../constants/global";
import {WaitingStatus} from "../../components/common/Statuses";
import {cloneDeep} from "lodash";
import moment from "moment";
import CrudTabs from "../../components/ui/Crud/CrudTabs";
import FormatOrderCost from "../../components/ui/Crud/fields/FormatOrderCost";
import userStore from "../../stores/userStore";
import ClientDashboard from "../client/pages/ClientDashboard";

const ManageSubscription = (props) => {
    const [subscription, setSubscription] = useState(null)
    const [showAlertModal, setShowAlertModal] = useState(false)
    const [showChooseTariffModal, setShowChooseTariffModal] = useState(false)
    const [isExistRequest, setIsExistsRequest] = useState(false)
    const [isUpdated, setIsUpdated] = useState(false)
    const [tabIndex, setTabIndex] = useState(1)
    const [subscriptionSystemEnabled, setSubscriptionSystemEnabled] = useState(false)
    const history = useHistory()

    const {CONTAINS, SIMPLE, DATE_RANGE} = FILTER_FIELD_TYPES;
    const {SELECT_USER, DATE, DATE_RANGE_EDITOR} = FILTER_EDITORS

    const loadData = () => {
        subscriptionService.checkEnabled().then(res=>{
            if(res.code===200)
                setSubscriptionSystemEnabled(res.data)
            subscriptionService.getDetailsSelfSubscription().then(res => {
                if (res.code === 401 || res.code === 403) {
                    history.push("/auth/login")
                }
                setSubscription(res.data)
            })
            subscriptionService.getNewOrProcessedRequest().then(res => {
                if (res.code === 200) {
                    setIsExistsRequest(res.data)
                }
            })
        })
        setIsUpdated(true)
    }

    useEffect(() => {
        loadData()

    }, [])

    const getCurrentSubscriptionName = () => {
        if (subscription)
            return getExistLangValue(subscription.historyBillingList[subscription.historyBillingList.length - 1].tariffPlan.name)
        return "-"
    }

    const getCurrentTariffId = () => {
        if (subscription)
            return subscription.historyBillingList[subscription.historyBillingList.length - 1].tariffPlan.id
        return -1
    }

    const isFree = () => {
        if (subscription)
            return subscription.freeTariffPlan
        return false
    }

    const getCurrentSubscriptionDateCreated = () => {
        if (subscription && subscription.billingDateString)
            return moment(subscription.billingDateString).toDate().toLocaleDateString()
        return "-"
    }
    const getCurrentSubscriptionNextDateBilling = () => {
        if (subscription && subscription.nextBillingDateString)
            return moment(subscription.nextBillingDateString).toDate().toLocaleDateString()
        return "-"
    }

    const crudEndpoint = 'subscription/requests';
    const crudActions = {
        list: async (offset, limit, query) => await HttpProvider.get(API.BASE(`${crudEndpoint}/self?offset=${offset}&limit=${limit}${query}`), false),
        create: async data => await HttpProvider.post(API.BASE(crudEndpoint), data),
        read: async id => await HttpProvider.get(API.BASE(`${crudEndpoint}/${id}`), false),
        update: async (id, data) => await HttpProvider.put(API.BASE(`${crudEndpoint}/${id}`), data),
        delete: async id => await HttpProvider.remove(API.BASE(`${crudEndpoint}/cancel-request/${id}`)),
    };

    const crudEndpointReport = 'resource-registration-report';


    const formatInternalPartners = (value) => {
        return (
            <div>
                {value.map(partner => {
                    return (<div className={"mb-1"}>{partner.name}</div>);
                })}
            </div>
        );
    }

    const formatUseApi = (value) => {
        const getName = (api) => {
            if(api && api.id && api.id.name)
                return api.id.name
            return "N/A"
        }
        return (
            <div>
                {value.map(api => {
                    return (<div className={"mb-1"}>
                        <p>{getName(api)}: </p>
                        <p>{api.countSuccessRequests+api.countFailRequests}/{api.countSummaryTariffs}</p>
                    </div>);
                })}
                {value.length === 0 &&
                    "-"
                }
            </div>
        )
    }
    const formatOrderCost = (item) => {
        if(item) {
            return <FormatOrderCost item={item.order}/>
        }
        return "-"
    }

    const numberFormat = (value) => {
        if (value)
            return value
        return 0;
    }

    const formatUser = (value) => {
        if (value)
            return (
                <div>
                    {value.lastName}{" "}{value.firstName}{" | "}{value.email}
                </div>
            );
        return "-";
    }

    const dateFormat = (value) => {
        return (
            <div>
                {moment(value).toDate().toLocaleDateString()} {moment(value).toDate().toLocaleTimeString()}
            </div>
        )
    }

    const switchToFreeTariff = () => {
        subscriptionService.switchToFreeTariff().then(res => {
            if (res.code === 401 || res.code === 403) {
                history.push("/auth/login")
            }
            setSubscription(res.data)
        })
        setShowAlertModal(false)
    }

    const setTab = (tabIndex) => {
        setTabIndex(tabIndex)
    }

    if (!subscription)
        return (
            <Page scope="user" {...props} pageTitle={subscriptionSystemEnabled===true? <FormattedMessage id={"subscription.page.title"}/>:""}>
                <Row>
                    <Col xs={12}><WaitingStatus/></Col>
                </Row>
            </Page>
        )
    const text = {
        ru: 'Страница недоступна для данного профиля или не существует',
        en: 'This page is not available for this profile or does not exist',
    };
    if(subscriptionSystemEnabled===false)
        return (
            <Page
                pageTitle={getExistLangValue(text)}
                scope="user"
                {...props}
            >
                <h3>{getExistLangValue(text)}</h3>
            </Page>
        );

    const crudActionsReport = {
        list: async (offset, limit, query) => await HttpProvider.get(API.BASE(`${crudEndpointReport}/user/crud/${subscription.user.id}?offset=${offset}&limit=${limit}${query}`), false),
        create: async data => await HttpProvider.post(API.BASE(crudEndpointReport), data),
        read: async id => await HttpProvider.get(API.BASE(`${crudEndpointReport}/${id}`), false),
        update: async (id, data) => await HttpProvider.put(API.BASE(`${crudEndpointReport}/${id}`), data),
        delete: async id => await HttpProvider.remove(API.BASE(`${crudEndpointReport}/${id}`)),
    };

    return (
        <Page scope="user" {...props} pageTitle={<FormattedMessage id={"subscription.page.title"}/>}>
            <CrudTabs
                navArray={['deliveryOrders.order.commonInfoTitle', 'crud.tabs.historyCalculation']}
                tabIndex={tabIndex}
                setTab={setTab}
            />

            <TabContent activeTab={tabIndex}>
                <TabPane tabId={1}>
                    <div className={"page-manage-subscription"}>
                        <TariffSubscriptionModal
                            isShow={showChooseTariffModal}
                            currentTariffId={getCurrentTariffId()}
                            onCreateRequest={() => {
                                loadData()
                                //window.location.reload()
                            }}
                            onClose={() => setShowChooseTariffModal(false)}
                        />
                        <Modal
                            isShow={showAlertModal}
                            onClose={() => setShowAlertModal(false)}
                            title={<FormattedMessage id={"subscription.switchToFree"}/>}
                        >
                            <div>
                                <FormattedMessage id={"subscription.switchToFree.text"}/>
                            </div>
                            <div className={"d-flex mt-2"}>
                                <Button onClick={switchToFreeTariff} className={"btn-primary-new"}><FormattedMessage
                                    id={"subscription.switchToFree.btn.yes.text"}/></Button>
                                <Button onClick={() => setShowAlertModal(false)}
                                        className={"btn-outline-primary-new ml-2"}><FormattedMessage
                                    id={"dialog.confirm.no"}/></Button>
                            </div>
                        </Modal>
                        <Row>
                            <Col xs={12}>
                                <h2><FormattedMessage id={"subscription.main.text"}/></h2>
                            </Col>
                        </Row>
                        <Row className={"subscription-info-section"}>
                            <Col xs={12} lg={"auto"}><FormattedMessage id={"subscription.main.tariff.text"}/></Col>
                            <Col xs={12} lg={"auto"}>{getCurrentSubscriptionName()}</Col>
                        </Row>
                        <Row className={"subscription-info-section"}>
                            <Col xs={12} lg={"auto"}><FormattedMessage id={"subscription.main.dateCreated.text"}/></Col>
                            <Col xs={12} lg={"auto"}>{getCurrentSubscriptionDateCreated()}</Col>
                        </Row>
                        <Row className={"subscription-info-section"}>
                            <Col xs={12} lg={"auto"}><FormattedMessage id={"subscription.main.datePayNext.text"}/></Col>
                            <Col xs={12} lg={"auto"}>{getCurrentSubscriptionNextDateBilling()}</Col>
                        </Row>
                        {subscription.infinite &&
                            <Row className={"subscription-info-section"}>
                                <Col xs={12} lg={"auto"}><FormattedMessage
                                    id={"subscription.main.infinite.text"}/></Col>
                                <Col xs={12} lg={"auto"}>
                                    <Checkbox onChange={() => {
                                    }} checked={subscription.infinite}/>
                                </Col>
                            </Row>
                        }
                        <Row className={"subscription-info-section"}>
                            <Col xs={12} lg={"auto"}><FormattedMessage id={"subscription.main.usage.text"}/></Col>
                            <Col xs={12}
                                 lg={"auto"}>{subscription.usageCalculations} / {subscription.limitCalculations}</Col>
                        </Row>
                        {/*<Row className={"subscription-info-section"}>
                    <Col xs={12}>
                        <h2><FormattedMessage id={"subscription.history.text"}/></h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className={"w-100 history-subscription"}>
                            <div className={"history-table"}>
                                <Row>
                                    <Col className={"d-none d-lg-block"} lg={4}><FormattedMessage
                                        id={"subscription.history.tariff"}/></Col>
                                    <Col className={"d-none d-lg-block"} lg={4}><FormattedMessage
                                        id={"subscription.history.date"}/></Col>
                                    <Col className={"d-none d-lg-block"} lg={4}><FormattedMessage
                                        id={"subscription.history.idContract"}/></Col>
                                </Row>
                                <div className={"content-table"}>
                                    {
                                        cloneDeep(subscription.historyBillingList).reverse().map(el => {
                                            return (
                                                <Row key={el.id}>
                                                    <Col xs={12} lg={4}><span
                                                        className={"d-block d-lg-none"}><FormattedMessage
                                                        id={"subscription.history.tariff"}/></span> {getExistLangValue(el.tariffPlan.name)}
                                                    </Col>
                                                    <Col xs={12} lg={4}><span
                                                        className={"d-block d-lg-none"}><FormattedMessage
                                                        id={"subscription.history.date"}/></span> {moment(el.billingDate).toDate().toLocaleDateString()}
                                                    </Col>
                                                    <Col xs={12} lg={4}><span
                                                        className={"d-block d-lg-none"}><FormattedMessage
                                                        id={"subscription.history.idContract"}/></span> {el.transactionId ? el.transactionId : '-'}
                                                    </Col>
                                                </Row>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>*/}
                        {isExistRequest === false &&
                            <div>
                                <Row className={"mt-4"}>
                                    <Col xs={12}>
                                        <h2><FormattedMessage id={"subscription.manage.tariffs.section"}/></h2>
                                    </Col>
                                </Row>
                                <Row className={"actions-btn"}>
                                    {!isFree() &&
                                        <Col xs={12} lg={"auto"}>
                                            <Button onClick={() => {
                                                setShowAlertModal(true)
                                            }} className={"btn-primary-new"}><FormattedMessage
                                                id={"subscription.switchToFree.btn.text"}/></Button>
                                        </Col>
                                    }
                                    <Col xs={12} lg={"auto"}>
                                        <Button onClick={() => {
                                            setShowChooseTariffModal(true)
                                        }} className={"btn-outline-primary-new"}><FormattedMessage
                                            id={"subscription.chooseTariff.btn"}/></Button>
                                    </Col>
                                </Row>
                            </div>
                        }
                        <Row className={"mt-4"}>
                            <Col xs={12}><h2><FormattedMessage id={"subscription.request.title"}/></h2></Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Crud
                                    endpoint={'subscription/requests'}
                                    crudActions={crudActions}
                                >
                                    <CrudForm>
                                        <DictionaryForm
                                            {...props}
                                            readonlyForm={true}
                                        >
                                            <CrudField name="id" disableOnAdd readonly/>
                                            <CrudField name={"status"} readonly editor={"statusRequestSubscription"}/>
                                            <CrudField name={"comment"} readonly editor={"text"}/>
                                            <CrudField name={"subscriptionTariffPlan"} editor={"tariffPlanNameOnly"}/>
                                            <CrudField name={"transactionId"} readonly editor={"text"}/>
                                        </DictionaryForm>
                                    </CrudForm>
                                    <CrudList
                                        customRemoveMessage={<FormattedMessage id={"crud.remove.request.label"}/>}
                                        isManageSubscriptionPage
                                        onReloadData={() => {
                                            loadData()
                                        }}
                                        isUpdated={isUpdated}
                                        isUpdateFn={() => setIsUpdated(false)}
                                        isAdd={false}>
                                        <CrudColumn unsortable name={'id'}/>
                                        <CrudColumn unsortable name={'status'} format={(value) => <FormattedMessage
                                            id={`subscription.request.status.${value}`}/>}/>
                                        <CrudColumn unsortable name={'comment'}/>
                                        <CrudColumn unsortable name={'createDateTime'} format={dateFormat}/>
                                        <CrudColumn unsortable name={'subscriptionTariffPlan'}
                                                    format={(value) => <div>{getExistLangValue(value.name)}</div>}/>
                                        <CrudColumn unsortable name={'transactionId'}/>

                                    </CrudList>
                                </Crud>
                            </Col>
                        </Row>
                    </div>
                </TabPane>
                <TabPane tabId={2}>
                    <div>
                        <Crud
                            endpoint={'resource-registration-report'}
                            crudActions={crudActionsReport}
                        >
                            <CrudFilters
                                context="commonResourceReport"
                                fields={[{
                                    name: 'dateCreated',
                                    type: DATE_RANGE,
                                    editor: DATE_RANGE_EDITOR,
                                    label: 'crud.field.dateCreated.title',
                                }]}
                            />

                            <CrudList isEdit={false} isAdd={false}>
                                <CrudColumn name={'id'}/>
                                <CrudColumn name={"order.id"}/>
                                <CrudColumn name="order.fromLocation" title={translate("crud.field.fromLocation.title")} format={getLocationNameWithIcon}/>
                                <CrudColumn name="order.toLocation" title={translate("crud.field.toLocation.title")} format={getLocationNameWithIcon}/>
                                <CrudColumn name="order.cargo" title={translate("crud.field.cargo.title")} width={200} format={item => getCargoInfoForCrud(item, true)}/>
                                <CrudColumn name={"dateCreated"} format={dateFormat}/>
                                <CrudColumn name={'countVariantsCalculations'} width={120} format={numberFormat}/>
                                {userStore.isClient() === false &&
                                    <CrudColumn name={"counterUsePartnersApi"} format={formatUseApi}/>
                                }

                            </CrudList>
                        </Crud>
                    </div>
                </TabPane>
            </TabContent>
        </Page>
    );
};

export default ManageSubscription;