import React from 'react';
import { toJS } from 'mobx';
import { Row, Col } from 'reactstrap';
import { cloneDeep, find, isEmpty, get } from 'lodash';
import {
  translate, keyGenerator, addFormatNameToConstant,
  getIconByServiceName, getExistLangValue, getDirectionWithIcon, getLangInStorage, formatCost
} from '../../../utils/utils';
import { NDS, CHARGING } from '../../../constants/staticData';
import {FormattedMessage} from "react-intl";
import VehicleRateFormat from "../../trunkingTariff/VehicleRateFormat";

const CrudFormats = {
  /**
   * Переводимое поле.
   * @param value
   * @returns {*}
   */
  translationColumnFormat: (value) => {
    if (value) {
      const item = value.name ? value.name : value;
      return getExistLangValue(item);
    }

    return '-';
  },

  translationHub: (value, direction, addition = '') => {
    if (value) {
      let text = getExistLangValue(value.name);

      if (!isEmpty(addition)) {
        const addText = getExistLangValue(get(value, addition, ''));
        text = `${text} (${addText})`;
      }

      return getDirectionWithIcon(direction, text);
    }

    return '-';
  },

  translationAirline: (value) => {
    if (value) {
      const text = getExistLangValue(value.name);

      return `${text} (${value.code})`;
    }

    return '-';
  },

  /**
   * Поле размеры (L X W X H (unit))
   * @param value
   */
  size3DFormat: (value) => {
    if (!value) {
      return '-';
    }
    return `${value.length ? value.length : '?'} x ${value.width ? value.width : '?'} x ${value.height ? value.height : '?'} (${value.unit})`;
  },
  /**
   * Поле размер люка самолета (L X W X H (unit))
   * @param value
   */
  hatchSizeFormat: (value) => {
    if (!value) {
      return '-';
    }
    return `${value.length ? value.length : '?'} x ${value.width ? value.width : '?'} (${value.unit})`;
  },

  costFormat: (value)=>{
    if(!value)
      return '-';

    return `${value.value} ${value.currency}`
  },

  /**
   * Latitude + Longitude
   * @param value
   */
  coordinates: (value) => {
    if (!value) {
      return '-';
    }

    return `${value.latitude ? value.latitude : '?'} x ${value.longitude ? value.longitude : '?'}`;
  },

  /**
   * Для boolean значений, выводит Да для true, Нет - для false.
   * @param value
   */
  yesNo: (value) => {
    return translate({ id: value ? 'crud.label.yes' : 'crud.label.no' });
  },

  /**
   * Выводит контейнеры и цены
   * @param item
   */
  cargoContainersFormat: (item) => {
    if (!item) {
      return '-';
    }

    if (item.cargoContainerRates && item.cargoContainerRates.length !== 0) {
      return item.cargoContainerRates.map((el) => {
        const containerType = getExistLangValue(el.containerType.name);
        const surcharge = (el.transitSurcharge && (el.transitSurcharge.value !== 0)) ? (
          <p>({translate('crud.field.surchargeText')} - {el.transitSurcharge.value})</p>
        ) : '';

        return (
          <div key={keyGenerator()} className="nowrap">
            {containerType} - {el.price.value} {el.price.currency}{surcharge}
          </div>
        );
      });
    }

    if (item.distanceContainerRate && item.distanceContainerRate.rates.length !== 0) {
      return item.distanceContainerRate.rates.map((range) => {
        return (
          <div key={keyGenerator()} className="nowrap">
            {range.minDistanceInKm} - {range.maxDistanceInKm} {(getLangInStorage() === 'ru') ? 'км' : 'km'}: {range.price.value} {range.price.currency} {range.perKm && translate({ id: 'crudForm.field.perKm' }).toLowerCase()}
          </div>
        );
      });
    }

    return <span> - </span>;
  },

  /**
   * Выводит цену, валюту и единицу измерения для сборных грузов
   * @param item
   */
  cargoGeneralsFormat: (item) => {
    if (!item) {
      return '-';
    }

    if (item.length !== 0) {
      const newEl = cloneDeep(item);

      if (newEl.transitSurcharge) {
        newEl.price.value += newEl.transitSurcharge.value;
      }

      return (
        <Row>
          <Col xs={12}>
            {newEl.price.value} {newEl.price.currency} {translate({ id: 'crudForm.field.forWM' })}
          </Col>
        </Row>
      );
    }

    return <span> - </span>;
  },

  vehicleRatesFormat: (item)=>{
    return <VehicleRateFormat item={item} />
  },

  cargoGeneralsRoadTransportationFormat: (item) => {
    if (item.distanceGeneralRate) {
      const newEl = cloneDeep(toJS(item.distanceGeneralRate));
      const { rates = null } = newEl;
      if (!rates) return '-';

      const getCol = (halfOfRanges) => {
        return halfOfRanges.map((range) => {
          return (
            <div key={keyGenerator()} className="nowrap">
              {range.minDistanceInKm} - {range.maxDistanceInKm} {(getLangInStorage() === 'ru') ? 'км' : 'km'}: {range.price.value} {range.price.currency} {range.perKm && translate({ id: 'crudForm.field.perKm' }).toLowerCase()}
            </div>
          );
        });
      };

      return (
        <Row>
          <Col xs={12}>
            { getCol(rates) }
          </Col>
        </Row>
      );
    }
    if (item.generalCargoRate) {
      const newEl = cloneDeep(item.generalCargoRate);

      if (newEl.transitSurcharge) {
        newEl.price.value += newEl.transitSurcharge.value;
      }
      const textForCharging = item.charging === 'HOURLY' ? 'crudForm.field.forHour' : 'crudForm.field.forWM';

      return (
        <Row>
          <Col xs={12}>
            {newEl.price.value} {newEl.price.currency} {translate({ id: textForCharging })}
          </Col>
        </Row>
      );
    }

    if (item.regionalGeneralCargoRate) {
      const newEl = cloneDeep(item.regionalGeneralCargoRate);

      return (
        <Row>
          <Col xs={12}>
            {newEl.pricePerCbm.value} {newEl.pricePerCbm.currency} {translate({ id: 'crudForm.field.forCbm' })}
          </Col>
          <Col xs={12}>
            {newEl.pricePerKg.value} {newEl.pricePerKg.currency} {translate({ id: 'crudForm.field.forKg' })}
          </Col>
        </Row>
      );
    }

    if (item.generalCargoRangeRate) {
      const newEl = cloneDeep(toJS(item.generalCargoRangeRate));
      const { rangeRates = null } = newEl;
      if (!rangeRates) return '-';

      const rows = rangeRates.map((range) => {
        return (
          <div key={keyGenerator()} className="nowrap">
            { range.min } - { range.max } {translate({ id: 'crudForm.field.kg' })} - {range.price.value} {range.price.currency}
          </div>
        );
      });

      return (
        <Row>
          <Col xs={12}>
            { rows }
          </Col>
        </Row>
      );
    }

    if (item.ldmGeneralCargoRate) {
      const newEl = cloneDeep(toJS(item.ldmGeneralCargoRate));
      const { rates = null } = newEl;
      if (!rates) return '-';

      const middleIdx = Math.round(rates.length / 2);

      const getCol = (halfOfRanges) => {
        return halfOfRanges.map((range) => {
          return (
            <div key={keyGenerator()} className="nowrap">
              {range.ldmValue} - {range.price.value} {range.price.currency}
            </div>
          );
        });
      };

      return (
        <Row>
          <Col xs={6}>
            { getCol(rates.slice(0, middleIdx)) }
          </Col>
          <Col xs={6} className="pl-0">
            { getCol(rates.slice(middleIdx)) }
          </Col>
        </Row>
      );
    }

    return <span> - </span>;
  },

  /**
   * Выводит цену, валюту и единицу измерения для B/L
   * @param item
   */
  billRateFormat: (item) => {
    if (!item) {
      return '-';
    }

    if (item.length !== 0) {
      return (
        <span>{item.price.value} {item.price.currency}</span> // {translate({ id: 'crudForm.field.forBL' })}</span>
      );
    }

    return <span> - </span>;
  },

  totalCostFormat: (item) => {
    if (!item || !item.totalCost) {
      return <span> - </span>;
    }

    return (
      <span>{item.totalCost.value} {item.totalCost.currency}</span>
    );
  },

  /**
   * Выводит процент НДС
   * @param item
   */
  ndsFormat: (item) => {
    if (!item) {
      return '-';
    }

    const prepareNDS = addFormatNameToConstant(NDS);
    const nds = prepareNDS.find(el => el.value === item.value);

    if (item.value === 'NONE') {
      return <div>{nds.formatName}</div>;
    }

    if (item.included) {
      return <div>{nds.formatName} % <br /> { translate({ id: 'crud.field.nds.include' }) }</div>;
    }
    return <div>{nds.formatName} % <br /> { translate({ id: 'crud.field.nds.notInclude' }) }</div>;
  },

  /**
   * Выводит тип тарификации для автоперевозок
   * @param item
   */
  chargingFormat: (item) => {
    const prepareCharging = addFormatNameToConstant(CHARGING);
    const charging = prepareCharging.find(el => el.charging === item);

    if (!charging) {
      return <span> - </span>;
    }

    return <div>{charging.formatName}</div>;
  },

  modifierStatus: (item) => {
    if (!item || item === 'PUBLIC') {
      return translate({ id: 'crud.field.modifier.public' });
    }
    return translate({ id: 'crud.field.modifier.private' });
  },

  /**
   * Выводит название организации в тарифах админа
   * @param id
   * @param organizations
   * @returns {*}
   */
  showOrganizationName: (id, organizations) => {
    if (!id) {
      return <span> - </span>;
    }
    // toString() для корректной обработки на странице с документами
    const organization = find(organizations, org => org.id.toString() === id.toString());

    return organization ? organization.name : '-';
  },

  /**
   * Выводит иконку типа услуги а автоперевозках
   * @param id
   * @param organizations
   * @returns {*}
   */
  serviceFormat: (item) => {
    return getIconByServiceName(item.uid);
  },
};

export default CrudFormats;
