import HttpProvider from "./httpProvider";
import {API} from "../constants/api";

class NotificationService {
    constructor() {
        this.http = HttpProvider
    }


    async getOldNotifications(start=0){
        return await this.http.get(API.NOTIFICATIONS.OLD_NOTIFICATIONS({start}), false)
    }

    async updateStatusNotifications(data){
        return await this.http.put(API.NOTIFICATIONS.UPDATE_STATUS_NOTIFICATION(), data)
    }
}

export default new NotificationService()