import moment from 'moment/moment';
import { saveAs } from 'file-saver';

import { getLangInStorage } from '../utils/utils';

class FileService {
  static requests = {};

  getUrlWithTimeSuffix(url) {
    const timestamp = (new Date()).getTime();

    return url.indexOf('?') > -1 ? `${url}&v=${timestamp}` : `${url}?v=${timestamp}`;
  }

  async fetchFile(url) {
    const token = localStorage.getItem('token');
    let headers = {
      'Accept-Language': getLangInStorage(),
      'X-TimeZone': moment.tz.guess(),
    };
    if(token) {
      headers = {
        'Authorization': `Bearer ${token}`,
        'Accept-Language': getLangInStorage(),
        'X-TimeZone': moment.tz.guess(),
      };
    }
    return await fetch(this.getUrlWithTimeSuffix(url), {
      headers
    });
  }

  async uploadFile(url, method = 'POST', data) {
    const token = localStorage.getItem('token');
    const formData = this.jsonToFormData(data);
    let headers={}
    if(token) {
      headers = {
        'Authorization': `Bearer ${token}`,
        'Accept-Language': getLangInStorage(),
        'X-TimeZone': moment.tz.guess(),
      };
    }
    return await fetch(this.getUrlWithTimeSuffix(url), {
      headers,
      method,
      body: formData,
    });
  }

  downloadFile(url, fileName) {
    return new Promise((resolve, reject) => {
      FileService.requests[url] = FileService.requests[url] || [];
      FileService.requests[url].push({ resolve, reject });
      if (FileService.requests[url].length === 1) {
        this.fetchFile(url)
          .then(res => res.blob())
          .then((blob) => {
            saveAs(blob, fileName);
            delete FileService.requests[url];
          })
          .catch((err) => {
            console.error(err, 'Fetch failed');
            delete FileService.requests[url];
          });
      }
    });
  }

  async createFile(url, data) {
    return await this.uploadFile(url, 'POST', data);
  }

  async updateFile(url, data) {
    return await this.uploadFile(url, 'PUT', data);
  }

  jsonToFormData(inJSON, inFormData = null, parentKey = null) {
    const formData = inFormData || new FormData();

    Object.entries(inJSON).forEach(([key, value]) => { /// Почему-то идет мимо этого блока
      let constructedKey = key;

      if (parentKey) {
        constructedKey = `${parentKey}.${key}`;
      }

      if (value && value.constructor === {}.constructor) {
        this.jsonToFormData(value, formData, constructedKey);
      } else {
        formData.append(constructedKey, value);
      }
    });
    formData.append('file', inJSON); /// Так файл загружается, но скачивается версия в 0 байт
    return formData;
  }
}

export default new FileService();
