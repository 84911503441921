import React, {useEffect, useState} from 'react';
import {Modal} from "../ui/Modal";
import {Alert, Button, Col, Label, Row} from "reactstrap";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";
import moment from "moment";
import {NumberInput, Select} from "../ui/form-control";
import {addFormatNameToConstant, getCountryNameFull, translate} from "../../utils/utils";
import ResourceReportService from "../../services/resourceReportService";
import {Bar, BarChart, Brush, CartesianGrid, Layer, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {CustomizedAxisTick, CustomizedAxisTickDate, CustomizedAxisTickPD} from "../reports/CustomizedAxisTick";
import CustomizedLabel from "../reports/CustomizedLabel";

const CustomTooltip = ({active, payload, label, isCargoShow = false, isTrend = false, isCountry = false}) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                {isCountry === true &&
                    <div>
                        <p>{payload[0].payload.nameCountryFull}</p>
                        <p style={{color: "#82ca9d"}}>{`${translate({id: "reports.field.count"})}: ${Math.abs(payload[0].payload.count)}`}</p>
                    </div>
                }
                {isCargoShow === true &&
                    <div>
                        <p>{payload[0].payload.dateString}</p>
                        <p style={{color: "#2B34A4"}}>{`${translate({id: "form.field.container"})}: ${Math.abs(payload[0].payload.countContainer) === 500 && isTrend === true ? payload[0].payload.countContainer > 0 ? '> 500' : '< -500' : payload[0].payload.countContainer}`}</p>
                        <p style={{color: "#F8AF24"}}>{`${translate({id: "form.field.general"})}: ${Math.abs(payload[0].payload.countGeneral) === 500 && isTrend === true ? payload[0].payload.countGeneral > 0 ? '> 500' : '< -500' : payload[0].payload.countGeneral}`}</p>
                        <p style={{color: "#F5541D"}}>{`${translate({id: "form.field.vehicle"})}: ${Math.abs(payload[0].payload.countVehicle) === 500 && isTrend === true ? payload[0].payload.countVehicle > 0 ? '> 500' : '< -500' : payload[0].payload.countVehicle}`}</p>
                    </div>
                }
            </div>
        );
    }

    return null;
};
const DetailedUserReport = (props) => {
    const rangesDateReport = [
        {
            message: "month",
            value: "month"
        },
        {
            message: "quarter",
            value: "quarter"
        },
        {
            message: "semiannual",
            value: "semiannual"
        }
    ]
    const [showModalReport, setShowModalReport] = useState(false)
    const [valuesRanges, setValuesRanges] = useState(addFormatNameToConstant(rangesDateReport))
    const [valueRangeReport, setValueRangeReport] = useState("month")
    const [report, setReport] = useState([])
    const [reportCountry, setReportCountry] = useState([])
    const [reportLimit, setReportLimit] = useState([])
    const [reportCountryLimit, setReportCountryLimit] = useState([])
    const [limit, setLimit] = useState(10)
    const [isLoadedData, setIsLoadedData] = useState(false)
    const [isEmptyReport, setIsEmptyReport] = useState(false)
    const [isEmptyCountryReport, setIsEmptyCountryReport] = useState(false)
    const [isLoadReport, setIsLoadReport] = useState(false)
    const [isLoadCountryReport, setIsLoadCountryReport] = useState(false)
    const [data, setData] = useState(props.data)
    const [reportCalculations, setReportCalculations] = useState({
        totalContainerCalculations: '-',
        totalGeneralCalculations: '-',
        totalVehicleCalculations: '-',
        emptyContainerCalculations: '-',
        emptyGeneralCalculations: '-',
        emptyVehicleCalculations: '-',
        unknownOrderCount: '-',
    })

    const onCloseOpenModal = () => {
        setShowModalReport(!showModalReport)
    }

    const generateReport = (userId, period) => {
        ResourceReportService.getReport4User(userId, period).then(res => {
            if (res.code === 200) {
                setReport(res.data)
                setReportLimit(res.data.slice(0, limit))
                setIsEmptyReport(res.data.length === 0)
                setIsLoadReport(true)
            }
        })
        ResourceReportService.getReportCountry4User(userId, period).then(res => {
            if (res.code === 200) {
                setReportCountry(res.data)
                setReportCountryLimit(res.data.slice(0, limit))
                setIsEmptyCountryReport(res.data.length === 0)
                setIsLoadCountryReport(true)
            }
        })
        ResourceReportService.getCalculationsReport4User(userId, period).then(res=>{
            if (res.code === 200) {
                setReportCalculations(res.data)
            }
        })
        setIsLoadedData(true)
    }


    useEffect(() => {
        setReportLimit(report.slice(0, limit))
        setReportCountryLimit(reportCountry.slice(0, limit))
    }, [limit])
    useEffect(()=>{
        if(props && props.data)
            setData(props.data)
    }, [props])

    const onClickGenerate = () => {
        if (props.data && props.data.user.id && valueRangeReport)
            generateReport(props.data.user.id, valueRangeReport);
    }

    const renderBrushBar = (dataKey) => {
        if (limit < 12)
            return ''
        return (
            <Brush dataKey={dataKey} height={30} stroke="#8884d8"/>
        )
    }

    const formatCountryReport = (data = []) => {
        return data.map((element, index) => {
            let countryFrom = getCountryNameFull(element.countryFrom)
            let countryTo = getCountryNameFull(element.countryTo)
            return {
                nameCountryFull: `${countryFrom} ⟶ ${countryTo}`,
                nameCountryShort: `${countryFrom.slice(0, 10)}${countryFrom.length > 9 ? '...' : ''} ⟶ ${countryTo.slice(0, 10)}${countryTo.length > 9 ? '...' : ''}`,
                count: element.count
            }
        })
    }
    const getModalTitle=(value)=>{
        if(value && value.user){
            return(
                <div className={"d-flex"}>
                    <FormattedMessage id={"ratingReport.modal.title"}/>&nbsp;{value.user.lastName}{" "}{value.user.firstName}{" | "}{value.user.email}
                </div>
            )
        }
    }

    return (
        <div className={"rating-reports"}>
            <Button
                onClick={() => {
                    onCloseOpenModal();
                    onClickGenerate()
                }}
                className="btn-primary-new"
            >
                <FormattedMessage id={"crud.field.ratingReportBtn.title"}/>
            </Button>
            <Modal
                isShow={showModalReport}
                onClose={onCloseOpenModal}
                className={"rating-reports__modal-dialog"}
                title={getModalTitle(props.data)}
            >
                <Row>
                    {/*<Col xs={12} md={6} lg={3}>
                        <Select
                            label={<FormattedMessage id={"reportResource.filter.period"}/>}
                            values={valuesRanges}
                            value={valueRangeReport}
                            valueKey={"value"}
                            labelKey={"formatName"}
                            onChange={(value) => setValueRangeReport(value.value)}
                        />
                    </Col>*/}
                    <Col xs={12} md={6} lg={3}>
                        <NumberInput
                            step={1}
                            label={<FormattedMessage id={"form.field.limitDays"} />}
                            value={limit}
                            valueKey={"value"}
                            labelKey={"formatName"}
                            onChange={(value) => setLimit(value)}
                        />
                    </Col>
                </Row>
                {/*<Row>
                    <Col xs={12} md={12} lg={6}>
                        <Button
                            style={{marginBottom: "13px"}}
                            onClick={onClickGenerate}
                            className="btn-primary-new"
                        >
                            <FormattedMessage id={"ratingReport.generateReport"}/>
                        </Button>
                    </Col>
                </Row>*/}
                {isLoadedData === false &&
                    <div>
                        <Row>
                            <Col xs={12}>
                                <Alert color="info">
                                    <FormattedMessage id="ratingReport.pressBtnForGenerateReport"/>
                                </Alert>
                            </Col>
                        </Row>
                    </div>
                }
                {isLoadedData === true &&
                    <div>
                        <Row className="">
                            <Col xs={12}>
                                <h5 className="reports__charts_title pl-0">{translate({id: "crud.field.cargoTypeCalculation.title"})}</h5>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div>
                                    <FormattedMessage
                                        id={"modal.orderInfo.container"}/>{": "}{reportCalculations.totalContainerCalculations} (<FormattedMessage
                                    id={"ratingReport.noResult"}/>{": "}{reportCalculations.emptyContainerCalculations})
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div>
                                    <FormattedMessage
                                        id={"modal.orderInfo.general"}/>{": "}{reportCalculations.totalGeneralCalculations} (<FormattedMessage
                                    id={"ratingReport.noResult"}/>{": "}{reportCalculations.emptyGeneralCalculations})
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div>
                                    <FormattedMessage
                                        id={"modal.orderInfo.vehicle"}/>{": "}{reportCalculations.totalVehicleCalculations} (<FormattedMessage
                                    id={"ratingReport.noResult"}/>{": "}{reportCalculations.emptyVehicleCalculations})
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div>
                                    <FormattedMessage id={"ratingReport.noOrderData"}/>{": "}{reportCalculations.unknownOrderCount}
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div></div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <h5 className="reports__charts_title">{translate({id: "reports.types.ORDER_COUNT"})}</h5>
                        </Row>
                        <Row>
                            {isLoadReport === false ?
                                <Col xs={12}>
                                    <Alert color="info">
                                        <FormattedMessage id="importTranslates.btn.wait"/>
                                    </Alert>
                                </Col>
                                :
                                isEmptyReport === true ?
                                    <Col xs={12}>
                                        <Alert color="info">
                                            <FormattedMessage id="reports.statusTest.empty"/>
                                        </Alert>
                                    </Col>
                                    :
                                    <div className={"report-section"}>
                                        <Col xs={12}>
                                            <ResponsiveContainer minWidth="100%" minHeight="500px">
                                                <BarChart
                                                    layout="horizontal"
                                                    width={1000}
                                                    height={600}
                                                    data={reportLimit}>
                                                    <XAxis interval={0} dataKey="dateString" height={120}
                                                           tick={<CustomizedAxisTickDate/>}/>
                                                    <YAxis domain={[0, 'dataMax + 50']}/>
                                                    <CartesianGrid strokeDasharray="3 3"/>
                                                    {renderBrushBar("dateString")}
                                                    <Tooltip content={<CustomTooltip isCargoShow={true}/>}/>
                                                    <Bar
                                                        name={translate('reports.field.count')}
                                                        dataKey="countContainer"
                                                        fill="#2B34A4"
                                                        stackId="a"
                                                        label={<CustomizedLabel/>}
                                                    />
                                                    <Bar
                                                        name={translate('reports.field.count')}
                                                        dataKey="countGeneral"
                                                        fill="#F8AF24"
                                                        stackId="b"
                                                        label={<CustomizedLabel/>}
                                                    />
                                                    <Bar
                                                        name={translate('reports.field.count')}
                                                        dataKey="countVehicle"
                                                        fill="#F5541D"
                                                        stackId="c"
                                                        label={<CustomizedLabel/>}
                                                    />
                                                </BarChart>
                                            </ResponsiveContainer>

                                        </Col>
                                        {limit >= 12 &&
                                            <Col xs={12} className={"d-flex justify-content-center"}>
                                                <Label className={"scale-label"}>
                                                    <FormattedMessage id={"ratingReport.scale"}/>
                                                </Label>
                                            </Col>
                                        }
                                        <Col xs={12}>
                                            <hr/>
                                        </Col>
                                    </div>
                            }
                        </Row>
                        <Row className="justify-content-center">
                            <h5 className="reports__charts_title">{translate({id: "reports.types.POPULAR_DESTINATIONS_COUNTRY"})}</h5>
                        </Row>
                        <Row>
                            {isLoadCountryReport === false ?
                                <Col xs={12}>
                                    <Alert color="info">
                                        <FormattedMessage id="importTranslates.btn.wait"/>
                                    </Alert>
                                </Col>
                                :
                                isEmptyCountryReport === true ?
                                    <Col xs={12}>
                                        <Alert color="info">
                                            <FormattedMessage id="reports.statusTest.empty"/>
                                        </Alert>
                                    </Col>
                                    :
                                    <div className={"report-section"}>
                                        <Col xs={12}>
                                            <ResponsiveContainer minWidth="100%" minHeight="500px">
                                                <BarChart
                                                    layout="horizontal"
                                                    width={1000}
                                                    height={600}
                                                    data={formatCountryReport(reportCountryLimit)}>
                                                    <XAxis interval={0} dataKey="nameCountryFull" height={120}
                                                           tick={<CustomizedAxisTickDate/>}/>
                                                    <YAxis domain={[0, 'dataMax + 50']}/>
                                                    <CartesianGrid strokeDasharray="3 3"/>
                                                    {renderBrushBar("nameCountryShort")}
                                                    <Tooltip content={<CustomTooltip isCountry={true}/>}/>
                                                    <Bar
                                                        name={translate('reports.field.count')}
                                                        dataKey="count"
                                                        fill="#82ca9d"
                                                        stackId="a"
                                                        label={<CustomizedLabel/>}
                                                    />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </Col>
                                        {limit >= 12 &&
                                            <Col xs={12} className={"d-flex justify-content-center"}>
                                                <Label className={"scale-label"}>
                                                    <FormattedMessage id={"ratingReport.scale"}/>
                                                </Label>
                                            </Col>
                                        }
                                        <Col xs={12}>
                                            <hr/>
                                        </Col>
                                    </div>

                            }
                        </Row>
                    </div>
                }

            </Modal>
        </div>
    );
};
DetailedUserReport.propTypes = {
    data: PropTypes.object.isRequired
}
export default DetailedUserReport;
