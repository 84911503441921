/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, InputGroupAddon, InputGroup, InputGroupText, Input } from 'reactstrap';
import { uniqueId } from 'lodash';
import { Label, Error, Note } from './common';

import { translate } from './../../../utils/utils';

class TextInput extends Component {
  state = {
    id: '',
  };

  UNSAFE_componentWillMount() {
    if (!this.props.id) {
      this.setState({
        id: uniqueId('f-element-text-'),
      });
    }
  }

  render() {
    const {
      type,
      note,
      className,
      placeholder,
      label,
      value,
      name,
      onChange,
      onBlur,
      leftAddon,
      rightAddon,
      disabled,
      style,
      id,
      error,
      maxLength,
    } = this.props;

    let input = (
      <Input
        maxLength={maxLength}
        style={style}
        type={type}
        value={value}
        id={id || this.state.id}
        name={name}
        onChange={onChange}
        placeholder={translate(placeholder)}
        onBlur={onBlur}
        disabled={disabled}
      />
    );

    if (leftAddon || rightAddon) {
      input = (
        <InputGroup>
          {leftAddon && <InputGroupAddon className="left-addon" addonType="prepend">
            <InputGroupText>{leftAddon}</InputGroupText>
          </InputGroupAddon>}
          {input}
          {rightAddon && <InputGroupAddon className="right-addon" addonType="append">
            <InputGroupText>{rightAddon}</InputGroupText>
          </InputGroupAddon>}
        </InputGroup>
      );
    }

    return (
      <FormGroup className={`f-element f-element-text ${error ? 'has-danger' : ''} ${className}`}>
        <Label elementId={id || this.state.id}>{label}</Label>
        {input}
        <Note>{note}</Note>

        <Error>{error}</Error>
      </FormGroup>
    );
  }
}

TextInput.defaultProps = {
  type: 'text',
  placeholder: 'form.field.enter.placeholder',
  className: '',
  name: '',
  disabled: false,
};

TextInput.propTypes = {
  id: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  note: PropTypes.string,
  maxLength: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  leftAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightAddon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
};

export default TextInput;
