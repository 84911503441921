/* eslint-disable */

import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import AppStore from '../../stores/appStore';

function WidgetHeader(props) {
  const companyLogo = AppStore.companyLogo;

    const [modal, setModal] = React.useState(AppStore.isModal);

    useEffect(() => {
        setModal(AppStore.isModal);
        },[AppStore.isModal]);

    return (
        modal === true &&
        <div className="widgetHeader">
            {companyLogo && <img className="widgetHeader__logo" src={companyLogo} alt="company-logo"/>}
            <span className="widgetHeader__title"><FormattedMessage id="form.wizard.header"/></span>
            <button
                className="widgetHeader__closeButton"
                onClick={props.onClose}
                onMouseOver={props.onClosing}
            />
        </div>
    );
}

WidgetHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClosing: PropTypes.func.isRequired,
};

export default WidgetHeader;
